@import "../../utilities/variables";
@import "subscription-status";
@import "modal-success";
@import "buttons";
@import "attached-files";

.return-link {
  font-family: $font-main;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: $color-grey;
}

.create-sub-item-link{
  color: $color-1;
  &:hover{
    color: $color-1;
  }
}
.user-img{
  height: 46px;
  width: 46px;
  border-radius: 50%;
}