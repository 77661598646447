@import "common/common";
@import "carousel/carousel";
@import "slide-card/slide-card";
@import "checkout";

.slide-card__img_wrapper {
    position: relative;
  }
  .slide-card__img_wrapper__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
  .slide-card__title {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
    margin-top: 8px;
    word-break: break-word;
    word-wrap: break-word;
    flex: auto;
  }
  .slide-card__actions {
    display: flex;
    gap: 0.25rem;
    justify-content: flex-end;
    align-items: center;
    margin: 0.25rem 0px;
    font-size: 0.75rem;
  }