@use '../../utilities/_variables.scss' as var;
:root {
    --brand-color: #12aeb4;
  }
.swiper-button-next, .swiper-button-prev{
    color: var(--brand-color) !important;
}
.swiper-wrapper{
    width: 2544px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
}