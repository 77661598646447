@import "../../utilities/variables";

.btn-load-more {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: $color-white;
  border-radius: 9px;
  width: 12em;
  height: 42px;
  margin-top: 2.3em;
}

.btn-primary {
  background-color: var(--brand-color);
  border: 1px solid var(--brand-color);
  &:hover {
    background-color: var(--brand-color);
    border: 1px solid $color-white;
  }
}

.edit-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 0.5rem;

  .btn-cancel {
    background-color: #fff;
    color: var(--brand-color);
    border: 1px solid var(--brand-color);
    width: 5rem;
  }

  .btn-save {
    background-color: var(--brand-color);
    width: 5rem;
  }
}