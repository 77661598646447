html.scroll-smooth {
  scroll-behavior: smooth;
}

html,
body,
.wrapper {
  min-height: 100%;
}

.wrapper {
  position: relative;
}

.wrapper .content-wrapper {
  min-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px));
}

.layout-boxed .wrapper {
  box-shadow: 0 0 10 rgba(0, 0, 0, 0.3);
}

.layout-boxed .wrapper, .layout-boxed .wrapper::before {
  margin: 0 auto;
  max-width: 1250px;
}

.layout-boxed .wrapper .main-sidebar {
  left: inherit;
}

@supports not (-webkit-touch-callout: none) {
  .layout-fixed .wrapper .sidebar {
    height: calc(100vh - (3.5rem + 1px));
  }
  .layout-fixed.text-sm .wrapper .sidebar {
    height: calc(100vh - (2.93725rem + 1px));
  }
}

.layout-navbar-fixed.layout-fixed .wrapper .control-sidebar {
  top: calc(3.5rem + 1px);
}

.layout-navbar-fixed.layout-fixed .wrapper .main-header.text-sm ~ .control-sidebar {
  top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
  margin-top: calc(3.5rem + 1px);
}

.layout-navbar-fixed.layout-fixed .wrapper .brand-link.text-sm ~ .sidebar {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.layout-fixed.text-sm .wrapper .control-sidebar {
  top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.sidebar-mini.sidebar-collapse .wrapper .brand-link,
.layout-navbar-fixed.sidebar-mini-md.sidebar-collapse .wrapper .brand-link,
.layout-navbar-fixed.sidebar-mini-xs.sidebar-collapse .wrapper .brand-link {
  height: calc(3.5rem + 1px);
  width: 4.6rem;
}

.layout-navbar-fixed.sidebar-mini.sidebar-collapse .wrapper .brand-link.text-sm,
.layout-navbar-fixed.sidebar-mini-md.sidebar-collapse .wrapper .brand-link.text-sm,
.layout-navbar-fixed.sidebar-mini-xs.sidebar-collapse .wrapper .brand-link.text-sm {
  height: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.sidebar-mini.sidebar-collapse.text-sm .wrapper .brand-link,
.layout-navbar-fixed.sidebar-mini-md.sidebar-collapse.text-sm .wrapper .brand-link,
.layout-navbar-fixed.sidebar-mini-xs.sidebar-collapse.text-sm .wrapper .brand-link {
  height: calc(2.93725rem + 1px);
}

body:not(.layout-fixed).layout-navbar-fixed.text-sm .wrapper .main-sidebar {
  margin-top: calc(calc(2.93725rem + 1px) / -1);
}

body:not(.layout-fixed).layout-navbar-fixed.text-sm .wrapper .main-sidebar .sidebar {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed .wrapper .control-sidebar {
  top: 0;
}

.layout-navbar-fixed .wrapper a.anchor {
  display: block;
  position: relative;
  top: calc((3.5rem + 1px + (0.5rem * 2)) / -1);
}

.layout-navbar-fixed .wrapper .main-sidebar:hover .brand-link {
  transition: width 0.3s ease-in-out;
  width: 250px;
}

.layout-navbar-fixed .wrapper .brand-link {
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: width 0.3s ease-in-out;
  width: 250px;
  z-index: 1035;
}

.layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .content-wrapper {
  margin-top: calc(3.5rem + 1px);
}

.layout-navbar-fixed .wrapper .main-header.text-sm ~ .content-wrapper {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed .wrapper .main-header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1033;
}

.layout-navbar-fixed.text-sm .wrapper .content-wrapper {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-not-fixed .wrapper .brand-link {
  position: static;
}

.layout-navbar-not-fixed .wrapper .sidebar,
.layout-navbar-not-fixed .wrapper .content-wrapper {
  margin-top: 0;
}

.layout-navbar-not-fixed .wrapper .main-header {
  position: static;
}

.layout-navbar-not-fixed.layout-fixed .wrapper .sidebar {
  margin-top: 0;
}

.layout-navbar-fixed.layout-fixed .wrapper .control-sidebar {
  top: calc(3.5rem + 1px);
}

.text-sm .layout-navbar-fixed.layout-fixed .wrapper .main-header ~ .control-sidebar,
.layout-navbar-fixed.layout-fixed .wrapper .main-header.text-sm ~ .control-sidebar {
  top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.layout-fixed .wrapper .sidebar {
  margin-top: calc(3.5rem + 1px);
}

.text-sm .layout-navbar-fixed.layout-fixed .wrapper .brand-link ~ .sidebar,
.layout-navbar-fixed.layout-fixed .wrapper .brand-link.text-sm ~ .sidebar {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.layout-fixed.text-sm .wrapper .control-sidebar {
  top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed .wrapper .control-sidebar {
  top: 0;
}

.layout-navbar-fixed .wrapper a.anchor {
  display: block;
  position: relative;
  top: calc((3.5rem + 1px + (0.5rem * 2)) / -1);
}

.layout-navbar-fixed .wrapper.sidebar-collapse .brand-link {
  height: calc(3.5rem + 1px);
  transition: width 0.3s ease-in-out;
  width: 4.6rem;
}

.text-sm .layout-navbar-fixed .wrapper.sidebar-collapse .brand-link, .layout-navbar-fixed .wrapper.sidebar-collapse .brand-link.text-sm {
  height: calc(2.93725rem + 1px);
}

.layout-navbar-fixed .wrapper.sidebar-collapse .main-sidebar:hover .brand-link {
  transition: width 0.3s ease-in-out;
  width: 250px;
}

.layout-navbar-fixed .wrapper .brand-link {
  overflow: hidden;
  position: fixed;
  top: 0;
  transition: width 0.3s ease-in-out;
  width: 250px;
  z-index: 1035;
}

.layout-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-primary .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-secondary .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-secondary .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-success .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-success .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-info .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-info .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-warning .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-warning .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-danger .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-danger .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-light .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-light .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .sidebar-dark-dark .brand-link:not([class*="navbar"]) {
  background-color: #343a40;
}

.layout-navbar-fixed .wrapper .sidebar-light-dark .brand-link:not([class*="navbar"]) {
  background-color: #fff;
}

.layout-navbar-fixed .wrapper .content-wrapper {
  margin-top: calc(3.5rem + 1px);
}

.text-sm .layout-navbar-fixed .wrapper .main-header ~ .content-wrapper,
.layout-navbar-fixed .wrapper .main-header.text-sm ~ .content-wrapper {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-fixed .wrapper .main-header {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1037;
}

.layout-navbar-fixed.text-sm .wrapper .content-wrapper {
  margin-top: calc(2.93725rem + 1px);
}

body:not(.layout-fixed).layout-navbar-fixed.text-sm .wrapper .main-sidebar {
  margin-top: calc(calc(2.93725rem + 1px) / -1);
}

body:not(.layout-fixed).layout-navbar-fixed.text-sm .wrapper .main-sidebar .sidebar {
  margin-top: calc(2.93725rem + 1px);
}

.layout-navbar-not-fixed .wrapper .brand-link {
  position: static;
}

.layout-navbar-not-fixed .wrapper .sidebar,
.layout-navbar-not-fixed .wrapper .content-wrapper {
  margin-top: 0;
}

.layout-navbar-not-fixed .wrapper .main-header {
  position: static;
}

.layout-navbar-not-fixed.layout-fixed .wrapper .sidebar {
  margin-top: 0;
}

@media (min-width: 576px) {
  .layout-sm-navbar-fixed.layout-fixed .wrapper .control-sidebar {
    top: calc(3.5rem + 1px);
  }
  .text-sm .layout-sm-navbar-fixed.layout-fixed .wrapper .main-header ~ .control-sidebar,
  .layout-sm-navbar-fixed.layout-fixed .wrapper .main-header.text-sm ~ .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-fixed.layout-fixed .wrapper .sidebar {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-sm-navbar-fixed.layout-fixed .wrapper .brand-link ~ .sidebar,
  .layout-sm-navbar-fixed.layout-fixed .wrapper .brand-link.text-sm ~ .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-fixed.layout-fixed.text-sm .wrapper .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-fixed .wrapper .control-sidebar {
    top: 0;
  }
  .layout-sm-navbar-fixed .wrapper a.anchor {
    display: block;
    position: relative;
    top: calc((3.5rem + 1px + (0.5rem * 2)) / -1);
  }
  .layout-sm-navbar-fixed .wrapper.sidebar-collapse .brand-link {
    height: calc(3.5rem + 1px);
    transition: width 0.3s ease-in-out;
    width: 4.6rem;
  }
  .text-sm .layout-sm-navbar-fixed .wrapper.sidebar-collapse .brand-link, .layout-sm-navbar-fixed .wrapper.sidebar-collapse .brand-link.text-sm {
    height: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-fixed .wrapper.sidebar-collapse .main-sidebar:hover .brand-link {
    transition: width 0.3s ease-in-out;
    width: 250px;
  }
  .layout-sm-navbar-fixed .wrapper .brand-link {
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: width 0.3s ease-in-out;
    width: 250px;
    z-index: 1035;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-secondary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-secondary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-success .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-success .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-info .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-info .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-warning .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-warning .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-danger .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-danger .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-light .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-light .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-dark-dark .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-sm-navbar-fixed .wrapper .sidebar-light-dark .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-sm-navbar-fixed .wrapper .content-wrapper {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-sm-navbar-fixed .wrapper .main-header ~ .content-wrapper,
  .layout-sm-navbar-fixed .wrapper .main-header.text-sm ~ .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-fixed .wrapper .main-header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1037;
  }
  .layout-sm-navbar-fixed.text-sm .wrapper .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  body:not(.layout-fixed).layout-sm-navbar-fixed.text-sm .wrapper .main-sidebar {
    margin-top: calc(calc(2.93725rem + 1px) / -1);
  }
  body:not(.layout-fixed).layout-sm-navbar-fixed.text-sm .wrapper .main-sidebar .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-sm-navbar-not-fixed .wrapper .brand-link {
    position: static;
  }
  .layout-sm-navbar-not-fixed .wrapper .sidebar,
  .layout-sm-navbar-not-fixed .wrapper .content-wrapper {
    margin-top: 0;
  }
  .layout-sm-navbar-not-fixed .wrapper .main-header {
    position: static;
  }
  .layout-sm-navbar-not-fixed.layout-fixed .wrapper .sidebar {
    margin-top: 0;
  }
}

@media (min-width: 768px) {
  .layout-md-navbar-fixed.layout-fixed .wrapper .control-sidebar {
    top: calc(3.5rem + 1px);
  }
  .text-sm .layout-md-navbar-fixed.layout-fixed .wrapper .main-header ~ .control-sidebar,
  .layout-md-navbar-fixed.layout-fixed .wrapper .main-header.text-sm ~ .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-fixed.layout-fixed .wrapper .sidebar {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-md-navbar-fixed.layout-fixed .wrapper .brand-link ~ .sidebar,
  .layout-md-navbar-fixed.layout-fixed .wrapper .brand-link.text-sm ~ .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-fixed.layout-fixed.text-sm .wrapper .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-fixed .wrapper .control-sidebar {
    top: 0;
  }
  .layout-md-navbar-fixed .wrapper a.anchor {
    display: block;
    position: relative;
    top: calc((3.5rem + 1px + (0.5rem * 2)) / -1);
  }
  .layout-md-navbar-fixed .wrapper.sidebar-collapse .brand-link {
    height: calc(3.5rem + 1px);
    transition: width 0.3s ease-in-out;
    width: 4.6rem;
  }
  .text-sm .layout-md-navbar-fixed .wrapper.sidebar-collapse .brand-link, .layout-md-navbar-fixed .wrapper.sidebar-collapse .brand-link.text-sm {
    height: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-fixed .wrapper.sidebar-collapse .main-sidebar:hover .brand-link {
    transition: width 0.3s ease-in-out;
    width: 250px;
  }
  .layout-md-navbar-fixed .wrapper .brand-link {
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: width 0.3s ease-in-out;
    width: 250px;
    z-index: 1035;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-secondary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-secondary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-success .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-success .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-info .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-info .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-warning .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-warning .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-danger .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-danger .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-light .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-light .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-dark-dark .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-md-navbar-fixed .wrapper .sidebar-light-dark .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-md-navbar-fixed .wrapper .content-wrapper {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-md-navbar-fixed .wrapper .main-header ~ .content-wrapper,
  .layout-md-navbar-fixed .wrapper .main-header.text-sm ~ .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-fixed .wrapper .main-header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1037;
  }
  .layout-md-navbar-fixed.text-sm .wrapper .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  body:not(.layout-fixed).layout-md-navbar-fixed.text-sm .wrapper .main-sidebar {
    margin-top: calc(calc(2.93725rem + 1px) / -1);
  }
  body:not(.layout-fixed).layout-md-navbar-fixed.text-sm .wrapper .main-sidebar .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-md-navbar-not-fixed .wrapper .brand-link {
    position: static;
  }
  .layout-md-navbar-not-fixed .wrapper .sidebar,
  .layout-md-navbar-not-fixed .wrapper .content-wrapper {
    margin-top: 0;
  }
  .layout-md-navbar-not-fixed .wrapper .main-header {
    position: static;
  }
  .layout-md-navbar-not-fixed.layout-fixed .wrapper .sidebar {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .layout-lg-navbar-fixed.layout-fixed .wrapper .control-sidebar {
    top: calc(3.5rem + 1px);
  }
  .text-sm .layout-lg-navbar-fixed.layout-fixed .wrapper .main-header ~ .control-sidebar,
  .layout-lg-navbar-fixed.layout-fixed .wrapper .main-header.text-sm ~ .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-fixed.layout-fixed .wrapper .sidebar {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-lg-navbar-fixed.layout-fixed .wrapper .brand-link ~ .sidebar,
  .layout-lg-navbar-fixed.layout-fixed .wrapper .brand-link.text-sm ~ .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-fixed.layout-fixed.text-sm .wrapper .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-fixed .wrapper .control-sidebar {
    top: 0;
  }
  .layout-lg-navbar-fixed .wrapper a.anchor {
    display: block;
    position: relative;
    top: calc((3.5rem + 1px + (0.5rem * 2)) / -1);
  }
  .layout-lg-navbar-fixed .wrapper.sidebar-collapse .brand-link {
    height: calc(3.5rem + 1px);
    transition: width 0.3s ease-in-out;
    width: 4.6rem;
  }
  .text-sm .layout-lg-navbar-fixed .wrapper.sidebar-collapse .brand-link, .layout-lg-navbar-fixed .wrapper.sidebar-collapse .brand-link.text-sm {
    height: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-fixed .wrapper.sidebar-collapse .main-sidebar:hover .brand-link {
    transition: width 0.3s ease-in-out;
    width: 250px;
  }
  .layout-lg-navbar-fixed .wrapper .brand-link {
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: width 0.3s ease-in-out;
    width: 250px;
    z-index: 1035;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-secondary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-secondary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-success .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-success .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-info .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-info .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-warning .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-warning .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-danger .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-danger .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-light .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-light .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-dark-dark .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-lg-navbar-fixed .wrapper .sidebar-light-dark .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-lg-navbar-fixed .wrapper .content-wrapper {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-lg-navbar-fixed .wrapper .main-header ~ .content-wrapper,
  .layout-lg-navbar-fixed .wrapper .main-header.text-sm ~ .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-fixed .wrapper .main-header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1037;
  }
  .layout-lg-navbar-fixed.text-sm .wrapper .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  body:not(.layout-fixed).layout-lg-navbar-fixed.text-sm .wrapper .main-sidebar {
    margin-top: calc(calc(2.93725rem + 1px) / -1);
  }
  body:not(.layout-fixed).layout-lg-navbar-fixed.text-sm .wrapper .main-sidebar .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-lg-navbar-not-fixed .wrapper .brand-link {
    position: static;
  }
  .layout-lg-navbar-not-fixed .wrapper .sidebar,
  .layout-lg-navbar-not-fixed .wrapper .content-wrapper {
    margin-top: 0;
  }
  .layout-lg-navbar-not-fixed .wrapper .main-header {
    position: static;
  }
  .layout-lg-navbar-not-fixed.layout-fixed .wrapper .sidebar {
    margin-top: 0;
  }
}

@media (min-width: 1200px) {
  .layout-xl-navbar-fixed.layout-fixed .wrapper .control-sidebar {
    top: calc(3.5rem + 1px);
  }
  .text-sm .layout-xl-navbar-fixed.layout-fixed .wrapper .main-header ~ .control-sidebar,
  .layout-xl-navbar-fixed.layout-fixed .wrapper .main-header.text-sm ~ .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-fixed.layout-fixed .wrapper .sidebar {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-xl-navbar-fixed.layout-fixed .wrapper .brand-link ~ .sidebar,
  .layout-xl-navbar-fixed.layout-fixed .wrapper .brand-link.text-sm ~ .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-fixed.layout-fixed.text-sm .wrapper .control-sidebar {
    top: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-fixed .wrapper .control-sidebar {
    top: 0;
  }
  .layout-xl-navbar-fixed .wrapper a.anchor {
    display: block;
    position: relative;
    top: calc((3.5rem + 1px + (0.5rem * 2)) / -1);
  }
  .layout-xl-navbar-fixed .wrapper.sidebar-collapse .brand-link {
    height: calc(3.5rem + 1px);
    transition: width 0.3s ease-in-out;
    width: 4.6rem;
  }
  .text-sm .layout-xl-navbar-fixed .wrapper.sidebar-collapse .brand-link, .layout-xl-navbar-fixed .wrapper.sidebar-collapse .brand-link.text-sm {
    height: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-fixed .wrapper.sidebar-collapse .main-sidebar:hover .brand-link {
    transition: width 0.3s ease-in-out;
    width: 250px;
  }
  .layout-xl-navbar-fixed .wrapper .brand-link {
    overflow: hidden;
    position: fixed;
    top: 0;
    transition: width 0.3s ease-in-out;
    width: 250px;
    z-index: 1035;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-primary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-primary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-secondary .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-secondary .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-success .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-success .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-info .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-info .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-warning .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-warning .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-danger .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-danger .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-light .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-light .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-dark-dark .brand-link:not([class*="navbar"]) {
    background-color: #343a40;
  }
  .layout-xl-navbar-fixed .wrapper .sidebar-light-dark .brand-link:not([class*="navbar"]) {
    background-color: #fff;
  }
  .layout-xl-navbar-fixed .wrapper .content-wrapper {
    margin-top: calc(3.5rem + 1px);
  }
  .text-sm .layout-xl-navbar-fixed .wrapper .main-header ~ .content-wrapper,
  .layout-xl-navbar-fixed .wrapper .main-header.text-sm ~ .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-fixed .wrapper .main-header {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1037;
  }
  .layout-xl-navbar-fixed.text-sm .wrapper .content-wrapper {
    margin-top: calc(2.93725rem + 1px);
  }
  body:not(.layout-fixed).layout-xl-navbar-fixed.text-sm .wrapper .main-sidebar {
    margin-top: calc(calc(2.93725rem + 1px) / -1);
  }
  body:not(.layout-fixed).layout-xl-navbar-fixed.text-sm .wrapper .main-sidebar .sidebar {
    margin-top: calc(2.93725rem + 1px);
  }
  .layout-xl-navbar-not-fixed .wrapper .brand-link {
    position: static;
  }
  .layout-xl-navbar-not-fixed .wrapper .sidebar,
  .layout-xl-navbar-not-fixed .wrapper .content-wrapper {
    margin-top: 0;
  }
  .layout-xl-navbar-not-fixed .wrapper .main-header {
    position: static;
  }
  .layout-xl-navbar-not-fixed.layout-fixed .wrapper .sidebar {
    margin-top: 0;
  }
}

.layout-footer-fixed .wrapper .control-sidebar {
  bottom: 0;
}

.layout-footer-fixed .wrapper .main-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1032;
}

.layout-footer-not-fixed .wrapper .main-footer {
  position: static;
}

.layout-footer-not-fixed .wrapper .content-wrapper {
  margin-bottom: 0;
}

.layout-footer-fixed .wrapper .control-sidebar {
  bottom: 0;
}

.layout-footer-fixed .wrapper .main-footer {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 1032;
}

.layout-footer-fixed .wrapper .content-wrapper {
  padding-bottom: calc(3.5rem + 1px);
}

.layout-footer-not-fixed .wrapper .main-footer {
  position: static;
}

@media (min-width: 576px) {
  .layout-sm-footer-fixed .wrapper .control-sidebar {
    bottom: 0;
  }
  .layout-sm-footer-fixed .wrapper .main-footer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1032;
  }
  .layout-sm-footer-fixed .wrapper .content-wrapper {
    padding-bottom: calc(3.5rem + 1px);
  }
  .layout-sm-footer-not-fixed .wrapper .main-footer {
    position: static;
  }
}

@media (min-width: 768px) {
  .layout-md-footer-fixed .wrapper .control-sidebar {
    bottom: 0;
  }
  .layout-md-footer-fixed .wrapper .main-footer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1032;
  }
  .layout-md-footer-fixed .wrapper .content-wrapper {
    padding-bottom: calc(3.5rem + 1px);
  }
  .layout-md-footer-not-fixed .wrapper .main-footer {
    position: static;
  }
}

@media (min-width: 992px) {
  .layout-lg-footer-fixed .wrapper .control-sidebar {
    bottom: 0;
  }
  .layout-lg-footer-fixed .wrapper .main-footer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1032;
  }
  .layout-lg-footer-fixed .wrapper .content-wrapper {
    padding-bottom: calc(3.5rem + 1px);
  }
  .layout-lg-footer-not-fixed .wrapper .main-footer {
    position: static;
  }
}

@media (min-width: 1200px) {
  .layout-xl-footer-fixed .wrapper .control-sidebar {
    bottom: 0;
  }
  .layout-xl-footer-fixed .wrapper .main-footer {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 1032;
  }
  .layout-xl-footer-fixed .wrapper .content-wrapper {
    padding-bottom: calc(3.5rem + 1px);
  }
  .layout-xl-footer-not-fixed .wrapper .main-footer {
    position: static;
  }
}

.layout-top-nav .wrapper {
  margin-left: 0;
}

.layout-top-nav .wrapper .main-header .brand-image {
  margin-top: -.5rem;
  margin-right: .2rem;
  height: 33px;
}

.layout-top-nav .wrapper .main-sidebar {
  bottom: inherit;
  height: inherit;
}

.layout-top-nav .wrapper .content-wrapper,
.layout-top-nav .wrapper .main-header,
.layout-top-nav .wrapper .main-footer {
  margin-left: 0;
}

body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .content-wrapper, body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .content-wrapper::before,
body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .main-footer,
body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .main-footer::before,
body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .main-header,
body.sidebar-collapse:not(.sidebar-mini-xs):not(.sidebar-mini-md):not(.sidebar-mini) .main-header::before {
  margin-left: 0;
}

@media (min-width: 768px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 300px;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar-collapse body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper, .sidebar-collapse
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer, .sidebar-collapse
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    margin-left: 0;
  }
}

@media (max-width: 991.98px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .sidebar-mini-md .content-wrapper,
  .sidebar-mini-md .main-footer,
  .sidebar-mini-md .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 250px;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-mini-md .content-wrapper,
  .sidebar-mini-md .main-footer,
  .sidebar-mini-md .main-header {
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar-collapse .sidebar-mini-md .content-wrapper, .sidebar-collapse
  .sidebar-mini-md .main-footer, .sidebar-collapse
  .sidebar-mini-md .main-header {
    margin-left: 4.6rem;
  }
}

@media (max-width: 991.98px) {
  .sidebar-mini-md .content-wrapper,
  .sidebar-mini-md .main-footer,
  .sidebar-mini-md .main-header {
    margin-left: 4.6rem;
  }
}

@media (max-width: 767.98px) {
  .sidebar-mini-md .content-wrapper,
  .sidebar-mini-md .main-footer,
  .sidebar-mini-md .main-header {
    margin-left: 0;
  }
}

@media (min-width: 768px) {
  .sidebar-mini-xs .content-wrapper,
  .sidebar-mini-xs .main-footer,
  .sidebar-mini-xs .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 250px;
  }
}

@media (min-width: 768px) and (prefers-reduced-motion: reduce) {
  .sidebar-mini-xs .content-wrapper,
  .sidebar-mini-xs .main-footer,
  .sidebar-mini-xs .main-header {
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar-collapse .sidebar-mini-xs .content-wrapper, .sidebar-collapse
  .sidebar-mini-xs .main-footer, .sidebar-collapse
  .sidebar-mini-xs .main-header {
    margin-left: 4.6rem;
  }
}

@media (max-width: 991.98px) {
  .sidebar-mini-xs .content-wrapper,
  .sidebar-mini-xs .main-footer,
  .sidebar-mini-xs .main-header {
    margin-left: 4.6rem;
  }
}

.content-wrapper {
  background-color: #f4f6f9;
}

.content-wrapper > .content {
  padding: 0 0.5rem;
}

.main-sidebar, .main-sidebar::before {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  width: 300px;
}

@media (prefers-reduced-motion: reduce) {
  .main-sidebar, .main-sidebar::before {
    transition: none;
  }
}

.sidebar-collapse:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) .main-sidebar, .sidebar-collapse:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) .main-sidebar::before {
  box-shadow: none !important;
}

.sidebar-collapse .main-sidebar, .sidebar-collapse .main-sidebar::before {
  margin-left: -300px;
}

.sidebar-collapse .main-sidebar .nav-sidebar.nav-child-indent .nav-treeview {
  padding: 0;
}

@media (max-width: 767.98px) {
  .main-sidebar, .main-sidebar::before {
    box-shadow: none !important;
    margin-left: -250px;
  }
  .sidebar-open .main-sidebar, .sidebar-open .main-sidebar::before {
    margin-left: 0;
  }
}

body:not(.layout-fixed) .main-sidebar {
  height: inherit;
  min-height: 100%;
  position: absolute;
  top: 0;
}

body:not(.layout-fixed) .main-sidebar .sidebar {
  overflow-y: auto;
}

.layout-fixed .brand-link {
  width: 250px;
}

.layout-fixed .main-sidebar {
  bottom: 0;
  float: none;
  left: 0;
  position: fixed;
  top: 0;
}

.layout-fixed .control-sidebar {
  bottom: 0;
  float: none;
  position: fixed;
  top: 0;
}

.layout-fixed .control-sidebar .control-sidebar-content::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.layout-fixed .control-sidebar .control-sidebar-content::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

.layout-fixed .control-sidebar .control-sidebar-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.layout-fixed .control-sidebar .control-sidebar-content::-webkit-scrollbar-corner {
  background-color: transparent;
}

.layout-fixed .control-sidebar .control-sidebar-content {
  height: calc(100vh - calc(3.5rem + 1px));
  overflow-y: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #a9a9a9 transparent;
}

@supports (-webkit-touch-callout: none) {
  .layout-fixed .main-sidebar {
    height: inherit;
  }
}

.main-footer {
  background-color: #fff;
  border-top: 1px solid #dee2e6;
  color: #869099;
  padding: 1rem;
}

.text-sm .main-footer, .main-footer.text-sm {
  padding: 0.812rem;
}

.content-header {
  padding: 15px 0.5rem;
}

.text-sm .content-header {
  padding: 10px 0.5rem;
}

.content-header h1 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  color: #060136;
  margin: 0;
}
.content-header h1>small{
  font-size: 18px;
  line-height: 27px;
  color: #7D8592;
  font-family: 'Poppins', sans-serif;
}

.text-sm .content-header h1 {
  font-size: 1.5rem;
}

.content-header .breadcrumb {
  background-color: transparent;
  line-height: 1.8rem;
  margin-bottom: 0;
  padding: 0;
}

.text-sm .content-header .breadcrumb {
  line-height: 1.5rem;
}

.hold-transition .content-wrapper,
.hold-transition .main-header,
.hold-transition .main-sidebar,
.hold-transition .main-sidebar *,
.hold-transition .control-sidebar,
.hold-transition .control-sidebar *,
.hold-transition .main-footer {
  transition: none !important;
  -webkit-animation-duration: 0s !important;
  animation-duration: 0s !important;
}

.main-header {
  border-bottom: 1px solid #dee2e6;
  z-index: 1034;
}

.main-header .nav-link {
  height: 2.5rem;
  position: relative;
}

.text-sm .main-header .nav-link, .main-header.text-sm .nav-link {
  height: 1.93725rem;
  padding: 0.35rem 1rem;
}

.text-sm .main-header .nav-link > .fa,
.text-sm .main-header .nav-link > .fas,
.text-sm .main-header .nav-link > .far,
.text-sm .main-header .nav-link > .fab,
.text-sm .main-header .nav-link > .fal,
.text-sm .main-header .nav-link > .fad,
.text-sm .main-header .nav-link > .svg-inline--fa,
.text-sm .main-header .nav-link > .ion, .main-header.text-sm .nav-link > .fa,
.main-header.text-sm .nav-link > .fas,
.main-header.text-sm .nav-link > .far,
.main-header.text-sm .nav-link > .fab,
.main-header.text-sm .nav-link > .fal,
.main-header.text-sm .nav-link > .fad,
.main-header.text-sm .nav-link > .svg-inline--fa,
.main-header.text-sm .nav-link > .ion {
  font-size: 0.875rem;
}

.main-header .navbar-nav .nav-item {
  margin: 0;
}

.main-header .navbar-nav[class*="-right"] .dropdown-menu {
  left: auto;
  margin-top: -3px;
  right: 0;
}

@media (max-width: 575.98px) {
  .main-header .navbar-nav[class*="-right"] .dropdown-menu {
    left: 0;
    right: auto;
  }
}

.main-header.dropdown-legacy .dropdown-menu {
  top: 3rem;
  margin-top: 0;
}

.navbar-img {
  height: calc(3.5rem + 1px)/2;
  width: auto;
}

.navbar-badge {
  font-size: .6rem;
  font-weight: 300;
  padding: 4px;
  position: absolute;
  right: 12px;
  top: 2px;
  border-radius: 20px;
}

.btn-navbar {
  background-color: transparent;
  border-left-width: 0;
}

.form-control-navbar {
  border-right-width: 0;
}

.form-control-navbar + .input-group-append {
  margin-left: 0;
}

.form-control-navbar,
.btn-navbar {
  transition: none;
}

.navbar-dark .form-control-navbar,
.navbar-dark .btn-navbar {
  background-color: #343a40;
  border-color: #6c757d;
}

.navbar-dark .form-control-navbar::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .form-control-navbar::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .form-control-navbar:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .form-control-navbar::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .form-control-navbar::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .form-control-navbar + .input-group-append > .btn-navbar {
  color: rgba(255, 255, 255, 0.6);
}

.navbar-dark .form-control-navbar:focus,
.navbar-dark .form-control-navbar:focus + .input-group-append .btn-navbar {
  background-color: #495057;
  border-color: #6c757d !important;
  color: #ced4da;
}

.navbar-light .form-control-navbar,
.navbar-light .btn-navbar {
  background-color: #dadfe4;
  border-color: #ced4da;
}

.navbar-light .form-control-navbar::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .form-control-navbar::-moz-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .form-control-navbar:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .form-control-navbar::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .form-control-navbar::placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .form-control-navbar + .input-group-append > .btn-navbar {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-light .form-control-navbar:focus,
.navbar-light .form-control-navbar:focus + .input-group-append .btn-navbar {
  background-color: #d3d9df;
  border-color: #c7ced5 !important;
  color: #ced4da;
}

.navbar-light .navbar-search-block .form-control-navbar:focus,
.navbar-light .navbar-search-block .form-control-navbar:focus + .input-group-append .btn-navbar {
  color: rgba(0, 0, 0, 0.6);
}

.navbar-search-block {
  position: absolute;
  padding: 0 1rem;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: none;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: initial;
  align-items: center;
}

.navbar-search-block.navbar-search-open {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.navbar-search-block .input-group {
  width: 100%;
}

.brand-link {
  display: block;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.8125rem 0.5rem;
  transition: width 0.3s ease-in-out;
}

.sidebar-collapse .brand-link{
  white-space: nowrap;
}

.sidebar-collapse .main-sidebar:hover .brand-link{
  white-space: normal;
}

.brand-link:hover {
  color: #fff;
  text-decoration: none;
}

.text-sm .brand-link {
  font-size: inherit;
}

[class*="sidebar-dark"] .brand-link,
[class*="sidebar-dark"] .brand-link .pushmenu {
  color: rgba(255, 255, 255, 0.8);
}

[class*="sidebar-dark"] .brand-link:hover,
[class*="sidebar-dark"] .brand-link .pushmenu:hover {
  color: #fff;
}

[class*="sidebar-light"] .brand-link {
  border-bottom: 1px solid #dee2e6;
}

[class*="sidebar-light"] .brand-link,
[class*="sidebar-light"] .brand-link .pushmenu {
  color: rgba(0, 0, 0, 0.8);
}

[class*="sidebar-light"] .brand-link:hover,
[class*="sidebar-light"] .brand-link .pushmenu:hover {
  color: #000;
}

.brand-link .pushmenu {
  margin-right: 0.5rem;
  font-size: 1rem;
}

.brand-link .brand-link {
  padding: 0;
  border-bottom: none;
}

.brand-link .brand-image {
  float: left;
  line-height: .8;
  margin-left: .8rem;
  margin-right: .5rem;
  margin-top: -3px;
  max-height: 33px;
  width: auto;
}

.brand-link .brand-image-xs {
  float: left;
  line-height: .8;
  margin-top: -.1rem;
  max-height: 33px;
  width: auto;
}

.brand-link .brand-image-xl {
  line-height: .8;
  max-height: 40px;
  width: auto;
}

.brand-link .brand-image-xl.single {
  margin-top: -.3rem;
}

.brand-link.text-sm .brand-image,
.text-sm .brand-link .brand-image {
  height: 29px;
  margin-bottom: -.25rem;
  margin-left: .95rem;
  margin-top: -.25rem;
}

.brand-link.text-sm .brand-image-xs,
.text-sm .brand-link .brand-image-xs {
  margin-top: -.2rem;
  max-height: 29px;
}

.brand-link.text-sm .brand-image-xl,
.text-sm .brand-link .brand-image-xl {
  margin-top: -.225rem;
  max-height: 38px;
}

.main-sidebar {
  height: 100vh;
  overflow-y: hidden;
  z-index: 1038;
  border-radius: 0px 41px 41px 0px;
}

.main-sidebar a:-moz-focusring {
  border: 0;
  outline: none;
}

.sidebar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.sidebar {
  height: calc(100% - (3.5rem + 1px));
  overflow-x: none;
  overflow-y: initial;
  padding-bottom: 0;
  padding-left: 31px;
  padding-right: 31px;
  padding-top: 0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #a9a9a9 transparent;
}

.user-panel {
  position: relative;
}

[class*="sidebar-dark"] .user-panel {
  border-bottom: 1px solid #4f5962;
}

[class*="sidebar-light"] .user-panel {
  border-bottom: 1px solid #dee2e6;
}

.user-panel,
.user-panel .info {
  overflow: hidden;
  white-space: nowrap;
}

.user-panel .image {
  display: inline-block;
  padding-left: 0.8rem;
}

.user-panel img {
  height: auto;
  width: 2.1rem;
}

.user-panel .info {
  display: inline-block;
  padding: 5px 5px 5px 10px;
}

.user-panel .status,
.user-panel .dropdown-menu {
  font-size: 0.875rem;
}

.nav-sidebar .nav-item > .nav-link {
  margin-bottom: .2rem;
}

.nav-sidebar .nav-item > .nav-link .right {
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .nav-sidebar .nav-item > .nav-link .right {
    transition: none;
  }
}

.nav-sidebar .nav-link > .right,
.nav-sidebar .nav-link > p > .right {
  position: absolute;
  right: 1rem;
  top: .7rem;
}

.nav-sidebar .nav-link > .right i,
.nav-sidebar .nav-link > .right span,
.nav-sidebar .nav-link > p > .right i,
.nav-sidebar .nav-link > p > .right span {
  margin-left: .5rem;
}

.nav-sidebar .nav-link > .right:nth-child(2),
.nav-sidebar .nav-link > p > .right:nth-child(2) {
  right: 2.2rem;
}

.nav-sidebar .menu-open > .nav-treeview {
  display: block;
}

.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link i.right {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nav-sidebar > .nav-item {
  margin-bottom: 0;
}

.nav-sidebar > .nav-item .nav-icon {
  margin-left: .05rem;
  font-size: 1.2rem;
  margin-right: 28.5px;
  text-align: center;
  width: 1.6rem;
}

.nav-sidebar > .nav-item .nav-icon.fa, .nav-sidebar > .nav-item .nav-icon.fas, .nav-sidebar > .nav-item .nav-icon.far, .nav-sidebar > .nav-item .nav-icon.fab, .nav-sidebar > .nav-item .nav-icon.fal, .nav-sidebar > .nav-item .nav-icon.fad, .nav-sidebar > .nav-item .nav-icon.svg-inline--fa, .nav-sidebar > .nav-item .nav-icon.ion {
  font-size: 1.1rem;
}

.nav-sidebar > .nav-item .float-right {
  margin-top: 3px;
}

.nav-sidebar .nav-treeview {
  display: none;
  list-style: none;
  padding: 0;
}

.nav-sidebar .nav-treeview > .nav-item > .nav-link > .nav-icon {
  width: 1.6rem;
}

.nav-sidebar.nav-child-indent .nav-treeview {
  transition: padding 0.3s ease-in-out;
  padding-left: 1rem;
}

.text-sm .nav-sidebar.nav-child-indent .nav-treeview {
  padding-left: .5rem;
}

.nav-sidebar.nav-child-indent.nav-legacy .nav-treeview .nav-treeview {
  padding-left: 2rem;
  margin-left: -1rem;
}

.text-sm .nav-sidebar.nav-child-indent.nav-legacy .nav-treeview .nav-treeview {
  padding-left: 1rem;
  margin-left: -.5rem;
}

.nav-sidebar .nav-header {
  font-size: .9rem;
  padding: 0.5rem 0.75rem;
}

.nav-sidebar .nav-link p {
  display: inline;
  margin: 0;
  white-space: normal;
}

.sidebar-is-opening .nav-sidebar .nav-link p {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1037;
}

@media (max-width: 991.98px) {
  .sidebar-open #sidebar-overlay {
    display: block;
  }
}

[class*="sidebar-light-"] {
  background-color: #fff;
}

[class*="sidebar-light-"] .user-panel a:hover {
  color: #212529;
}

[class*="sidebar-light-"] .user-panel .status {
  background-color: rgba(0, 0, 0, 0.1);
  color: #343a40;
}

[class*="sidebar-light-"] .user-panel .status:hover, [class*="sidebar-light-"] .user-panel .status:focus, [class*="sidebar-light-"] .user-panel .status:active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #212529;
}

[class*="sidebar-light-"] .user-panel .dropdown-menu {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  border-color: rgba(0, 0, 0, 0.1);
}

[class*="sidebar-light-"] .user-panel .dropdown-item {
  color: #212529;
}

[class*="sidebar-light-"] .nav-sidebar > .nav-item > .nav-link:active, [class*="sidebar-light-"] .nav-sidebar > .nav-item > .nav-link:focus {
  color: #343a40;
}

[class*="sidebar-light-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*="sidebar-light-"] .nav-sidebar > .nav-item:hover > .nav-link {
  background-color: rgba(0, 0, 0, 0.1);
  color: #212529;
}

[class*="sidebar-light-"] .nav-sidebar > .nav-item > .nav-link.active {
  color: #000;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

[class*="sidebar-light-"] .nav-sidebar > .nav-item > .nav-treeview {
  background-color: transparent;
}

[class*="sidebar-light-"] .nav-header {
  background-color: inherit;
  color: #292d32;
}

[class*="sidebar-light-"] .sidebar a {
  color: #343a40;
}

[class*="sidebar-light-"] .sidebar a:hover {
  text-decoration: none;
}

[class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link {
  color: #777;
}

[class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link:hover, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link:focus {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000;
}

[class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link.active, [class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link.active:hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #212529;
}

[class*="sidebar-light-"] .nav-treeview > .nav-item > .nav-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

[class*="sidebar-light-"] .nav-flat .nav-item .nav-treeview .nav-treeview {
  border-color: rgba(0, 0, 0, 0.1);
}

[class*="sidebar-light-"] .nav-flat .nav-item .nav-treeview > .nav-item > .nav-link, [class*="sidebar-light-"] .nav-flat .nav-item .nav-treeview > .nav-item > .nav-link.active {
  border-color: rgba(0, 0, 0, 0.1);
}

[class*="sidebar-dark-"] {
  background-color: #343a40;
}

[class*="sidebar-dark-"] .user-panel a:hover {
  color: #fff;
}

[class*="sidebar-dark-"] .user-panel .status {
  background-color: rgba(255, 255, 255, 0.1);
  color: #c2c7d0;
}

[class*="sidebar-dark-"] .user-panel .status:hover, [class*="sidebar-dark-"] .user-panel .status:focus, [class*="sidebar-dark-"] .user-panel .status:active {
  background-color: rgba(247, 247, 247, 0.1);
  color: #fff;
}

[class*="sidebar-dark-"] .user-panel .dropdown-menu {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  border-color: rgba(242, 242, 242, 0.1);
}

[class*="sidebar-dark-"] .user-panel .dropdown-item {
  color: #212529;
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link:active {
  color: #c2c7d0;
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*="sidebar-dark-"] .nav-sidebar > .nav-item:hover > .nav-link,
[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link:focus {
  background-color: transparent;
  color: #fff;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
  cursor: pointer;
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-link.active {
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item > .nav-treeview {
  background-color: transparent;
}

[class*="sidebar-dark-"] .nav-header {
  background-color: inherit;
  color: #d0d4db;
}

[class*="sidebar-dark-"] .sidebar a {
  color: #c2c7d0;
}

[class*="sidebar-dark-"] .sidebar a:hover, [class*="sidebar-dark-"] .sidebar a:focus {
  text-decoration: none;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link {
  color: #c2c7d0;
  cursor: pointer;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:hover, [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link:focus {
  background-color: transparent;
  color: #fff;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
}

[class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active, [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active:hover, [class*="sidebar-dark-"] .nav-treeview > .nav-item > .nav-link.active:focus {
  background-color: transparent;
  color: #fff;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
}

[class*="sidebar-dark-"] .nav-flat .nav-item .nav-treeview .nav-treeview {
  border-color: rgba(255, 255, 255, 0.9);
}

[class*="sidebar-dark-"] .nav-flat .nav-item .nav-treeview > .nav-item > .nav-link, [class*="sidebar-dark-"] .nav-flat .nav-item .nav-treeview > .nav-item > .nav-link.active {
  border-color: rgba(255, 255, 255, 0.9);
}

.sidebar-dark-primary .nav-sidebar > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar > .nav-item > .nav-link.active {
  background-color: transparent;
  color: #fff;
  border: 1px solid #E3E3E3;
  border-radius: 30px;
}

.sidebar-dark-primary .nav-sidebar.nav-legacy > .nav-item > .nav-link.active,
.sidebar-light-primary .nav-sidebar.nav-legacy > .nav-item > .nav-link.active {
  border-color: #007bff;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand) .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand) .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand) .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini .main-sidebar.sidebar-focused .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-compact.nav-sidebar.nav-child-indent:not(.nav-flat) .nav-treeview {
  padding-left: 1rem;
  margin-left: -.5rem;
}

.nav-flat {
  margin: -0.25rem -0.5rem 0;
}

.nav-flat .nav-item > .nav-link {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-flat .nav-item > .nav-link > .nav-icon {
  margin-left: .55rem;
}

.nav-flat:not(.nav-child-indent) .nav-treeview .nav-item > .nav-link > .nav-icon {
  margin-left: .4rem;
}

.nav-flat.nav-child-indent .nav-treeview {
  padding-left: 0;
}

.nav-flat.nav-child-indent .nav-treeview .nav-icon {
  margin-left: .85rem;
}

.nav-flat.nav-child-indent .nav-treeview .nav-treeview {
  border-left: .2rem solid;
}

.nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-icon {
  margin-left: 1.15rem;
}

.nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: 1.45rem;
}

.nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: 1.75rem;
}

.nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: 2.05rem;
}

.sidebar-collapse .nav-flat.nav-child-indent .nav-treeview .nav-icon {
  margin-left: .55rem;
}

.sidebar-collapse .nav-flat.nav-child-indent .nav-treeview .nav-link {
  padding-left: calc(1rem - .2rem);
}

.sidebar-collapse .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-icon {
  margin-left: .35rem;
}

.sidebar-collapse .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: .15rem;
}

.sidebar-collapse .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: -.15rem;
}

.sidebar-collapse .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: -.35rem;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-compact.nav-sidebar .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-compact.nav-sidebar .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-compact.nav-sidebar .nav-treeview .nav-icon,
.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-compact.nav-sidebar .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-compact.nav-sidebar .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-compact.nav-sidebar .nav-treeview .nav-icon {
  margin-left: .4rem;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-icon,
.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-icon {
  margin-left: .85rem;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-icon {
  margin-left: 1.15rem;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: 1.45rem;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: 1.75rem;
}

.sidebar-mini .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar:not(.sidebar-no-expand):hover .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon,
.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-sidebar.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-icon {
  margin-left: 2.05rem;
}

.nav-flat .nav-icon {
  transition: margin-left ease-in-out 0.3s;
}

@media (prefers-reduced-motion: reduce) {
  .nav-flat .nav-icon {
    transition: none;
  }
}

.nav-flat .nav-treeview .nav-icon {
  margin-left: -.2rem;
}

.nav-flat.nav-sidebar > .nav-item .nav-treeview,
.nav-flat.nav-sidebar > .nav-item > .nav-treeview {
  background-color: rgba(255, 255, 255, 0.05);
}

.nav-flat.nav-sidebar > .nav-item .nav-treeview .nav-item > .nav-link,
.nav-flat.nav-sidebar > .nav-item > .nav-treeview .nav-item > .nav-link {
  border-left: .2rem solid;
}

.nav-legacy {
  margin: -0.25rem -0.5rem 0;
}

.nav-legacy.nav-sidebar .nav-item > .nav-link {
  border-radius: 0;
  margin-bottom: 0;
}

.nav-legacy.nav-sidebar .nav-item > .nav-link > .nav-icon {
  margin-left: .55rem;
}

.text-sm .nav-legacy.nav-sidebar .nav-item > .nav-link > .nav-icon {
  margin-left: .75rem;
}

.nav-legacy.nav-sidebar > .nav-item > .nav-link.active {
  background-color: inherit;
  border-left: 3px solid transparent;
  box-shadow: none;
}

.nav-legacy.nav-sidebar > .nav-item > .nav-link.active > .nav-icon {
  margin-left: calc(.55rem - 3px);
}

.text-sm .nav-legacy.nav-sidebar > .nav-item > .nav-link.active > .nav-icon {
  margin-left: calc(.75rem - 3px);
}

.text-sm .nav-legacy.nav-sidebar.nav-flat .nav-treeview .nav-item > .nav-link > .nav-icon {
  margin-left: calc(.75rem - 3px);
}

.sidebar-mini .nav-legacy > .nav-item .nav-link .nav-icon,
.sidebar-mini-md .nav-legacy > .nav-item .nav-link .nav-icon,
.sidebar-mini-xs .nav-legacy > .nav-item .nav-link .nav-icon {
  transition: margin-left ease-in-out 0.3s;
  margin-left: .6rem;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar-mini .nav-legacy > .nav-item .nav-link .nav-icon,
  .sidebar-mini-md .nav-legacy > .nav-item .nav-link .nav-icon,
  .sidebar-mini-xs .nav-legacy > .nav-item .nav-link .nav-icon {
    transition: none;
  }
}

.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-md.sidebar-collapse .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview {
  padding-left: 1rem;
}

.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-md.sidebar-collapse .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview {
  padding-left: 2rem;
  margin-left: -1rem;
}

.sidebar-mini.sidebar-collapse.text-sm .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini.sidebar-collapse.text-sm .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-md.sidebar-collapse.text-sm .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-md.sidebar-collapse.text-sm .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-xs.sidebar-collapse.text-sm .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview,
.sidebar-mini-xs.sidebar-collapse.text-sm .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview {
  padding-left: .5rem;
}

.sidebar-mini.sidebar-collapse.text-sm .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini.sidebar-collapse.text-sm .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-md.sidebar-collapse.text-sm .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-md.sidebar-collapse.text-sm .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-xs.sidebar-collapse.text-sm .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-xs.sidebar-collapse.text-sm .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview {
  padding-left: 1rem;
  margin-left: -.5rem;
}

.sidebar-mini.sidebar-collapse .nav-legacy > .nav-item > .nav-link .nav-icon,
.sidebar-mini-md.sidebar-collapse .nav-legacy > .nav-item > .nav-link .nav-icon,
.sidebar-mini-xs.sidebar-collapse .nav-legacy > .nav-item > .nav-link .nav-icon {
  margin-left: .55rem;
}

.sidebar-mini.sidebar-collapse .nav-legacy > .nav-item > .nav-link.active > .nav-icon,
.sidebar-mini-md.sidebar-collapse .nav-legacy > .nav-item > .nav-link.active > .nav-icon,
.sidebar-mini-xs.sidebar-collapse .nav-legacy > .nav-item > .nav-link.active > .nav-icon {
  margin-left: .36rem;
}

.sidebar-mini.sidebar-collapse .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-md.sidebar-collapse .nav-legacy.nav-child-indent .nav-treeview .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .nav-legacy.nav-child-indent .nav-treeview .nav-treeview {
  padding-left: 0;
  margin-left: 0;
}

.sidebar-mini.sidebar-collapse.text-sm .nav-legacy > .nav-item > .nav-link .nav-icon,
.sidebar-mini-md.sidebar-collapse.text-sm .nav-legacy > .nav-item > .nav-link .nav-icon,
.sidebar-mini-xs.sidebar-collapse.text-sm .nav-legacy > .nav-item > .nav-link .nav-icon {
  margin-left: .75rem;
}

.sidebar-mini.sidebar-collapse.text-sm .nav-legacy > .nav-item > .nav-link.active > .nav-icon,
.sidebar-mini-md.sidebar-collapse.text-sm .nav-legacy > .nav-item > .nav-link.active > .nav-icon,
.sidebar-mini-xs.sidebar-collapse.text-sm .nav-legacy > .nav-item > .nav-link.active > .nav-icon {
  margin-left: calc(.75rem - 3px);
}

[class*="sidebar-dark"] .nav-legacy.nav-sidebar > .nav-item .nav-treeview,
[class*="sidebar-dark"] .nav-legacy.nav-sidebar > .nav-item > .nav-treeview {
  background-color: rgba(255, 255, 255, 0.05);
}

[class*="sidebar-dark"] .nav-legacy.nav-sidebar > .nav-item > .nav-link.active {
  color: #fff;
}

[class*="sidebar-dark"] .nav-legacy .nav-treeview > .nav-item > .nav-link.active, [class*="sidebar-dark"] .nav-legacy .nav-treeview > .nav-item > .nav-link:focus, [class*="sidebar-dark"] .nav-legacy .nav-treeview > .nav-item > .nav-link:hover {
  background-color: transparent;
  color: #fff;
}

[class*="sidebar-light"] .nav-legacy.nav-sidebar > .nav-item .nav-treeview,
[class*="sidebar-light"] .nav-legacy.nav-sidebar > .nav-item > .nav-treeview {
  background-color: rgba(0, 0, 0, 0.05);
}

[class*="sidebar-light"] .nav-legacy.nav-sidebar > .nav-item > .nav-link.active {
  color: #000;
}

[class*="sidebar-light"] .nav-legacy .nav-treeview > .nav-item > .nav-link.active, [class*="sidebar-light"] .nav-legacy .nav-treeview > .nav-item > .nav-link:focus, [class*="sidebar-light"] .nav-legacy .nav-treeview > .nav-item > .nav-link:hover {
  background-color: transparent;
  color: #000;
}

.nav-collapse-hide-child .menu-open > .nav-treeview {
  max-height: -webkit-min-content;
  max-height: -moz-min-content;
  max-height: min-content;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sidebar-collapse .nav-collapse-hide-child .menu-open > .nav-treeview {
  max-height: 0;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .nav-collapse-hide-child .menu-open > .nav-treeview,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .nav-collapse-hide-child .menu-open > .nav-treeview,
.sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .nav-collapse-hide-child .menu-open > .nav-treeview,
.sidebar-mini-md.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .nav-collapse-hide-child .menu-open > .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .nav-collapse-hide-child .menu-open > .nav-treeview,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .nav-collapse-hide-child .menu-open > .nav-treeview {
  max-height: -webkit-min-content;
  max-height: -moz-min-content;
  max-height: min-content;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.nav-compact .nav-link,
.nav-compact .nav-header {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.nav-compact .nav-header:not(:first-of-type) {
  padding-top: 0.75rem;
  padding-bottom: 0.25rem;
}

.nav-compact .nav-link > .right,
.nav-compact .nav-link > p > .right {
  top: .465rem;
}

.text-sm .nav-compact .nav-link > .right,
.text-sm .nav-compact .nav-link > p > .right {
  top: .7rem;
}

[class*="sidebar-dark"] .form-control-sidebar,
[class*="sidebar-dark"] .btn-sidebar {
  background-color: #3f474e;
  border: 1px solid #56606a;
  color: white;
}

[class*="sidebar-dark"] .form-control-sidebar:focus,
[class*="sidebar-dark"] .btn-sidebar:focus {
  border: 1px solid #7a8793;
}

[class*="sidebar-dark"] .btn-sidebar:hover {
  background-color: #454d55;
}

[class*="sidebar-dark"] .btn-sidebar:focus {
  background-color: #4b545c;
}

[class*="sidebar-dark"] .list-group-item {
  background-color: #454d55;
  border-color: #56606a;
  color: #c2c7d0;
}

[class*="sidebar-dark"] .list-group-item:hover {
  background-color: #4b545c;
}

[class*="sidebar-dark"] .list-group-item:focus {
  background-color: #515a63;
}

[class*="sidebar-dark"] .list-group-item .search-path {
  color: #adb5bd;
}

[class*="sidebar-light"] .form-control-sidebar,
[class*="sidebar-light"] .btn-sidebar {
  background-color: #f2f2f2;
  border: 1px solid #d9d9d9;
  color: #1f2d3d;
}

[class*="sidebar-light"] .form-control-sidebar:focus,
[class*="sidebar-light"] .btn-sidebar:focus {
  border: 1px solid #b3b3b3;
}

[class*="sidebar-light"] .btn-sidebar:hover {
  background-color: #ececec;
}

[class*="sidebar-light"] .btn-sidebar:focus {
  background-color: #e6e6e6;
}

[class*="sidebar-light"] .list-group-item {
  border-color: #d9d9d9;
}

[class*="sidebar-light"] .list-group-item:hover {
  background-color: #ececec;
}

[class*="sidebar-light"] .list-group-item:focus {
  background-color: #e6e6e6;
}

[class*="sidebar-light"] .list-group-item .search-path {
  color: #6c757d;
}

.sidebar .form-inline .input-group {
  width: 100%;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.sidebar nav .form-inline {
  margin-bottom: .2rem;
}

.layout-boxed:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs).sidebar-collapse .main-sidebar {
  margin-left: 0;
}

.layout-boxed:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) .content-wrapper,
.layout-boxed:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) .main-header,
.layout-boxed:not(.sidebar-mini):not(.sidebar-mini-md):not(.sidebar-mini-xs) .main-footer {
  z-index: 9999;
  position: relative;
}

.sidebar-collapse .form-control-sidebar,
.sidebar-collapse .form-control-sidebar ~ .input-group-append,
.sidebar-collapse .sidebar-search-results {
  display: none;
}

[data-widget="sidebar-search"] input[type="search"]::-ms-clear, [data-widget="sidebar-search"] input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

[data-widget="sidebar-search"] input[type="search"]::-webkit-search-cancel-button, [data-widget="sidebar-search"] input[type="search"]::-webkit-search-decoration, [data-widget="sidebar-search"] input[type="search"]::-webkit-search-results-button, [data-widget="sidebar-search"] input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.sidebar-search-results {
  position: relative;
  display: none;
  width: 100%;
}

.sidebar-search-open .sidebar-search-results {
  display: inline-block;
}

.sidebar-search-results .search-title {
  margin-bottom: -.1rem;
}

.sidebar-search-results .list-group {
  position: absolute;
  width: 100%;
  z-index: 1039;
}

.sidebar-search-results .list-group > .list-group-item {
  padding: 0.375rem 0.75rem;
}

.sidebar-search-results .list-group > .list-group-item:-moz-focusring {
  margin-top: 0;
  border-left: 1px solid transparent;
  border-top: 0;
  border-bottom: 1px solid transparent;
}

.sidebar-search-results .list-group > .list-group-item:first-child {
  margin-top: 0;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.sidebar-search-results .search-path {
  font-size: 80%;
}

.sidebar-search-open .btn,
.sidebar-search-open .form-control {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

[class*="sidebar-dark"] .sidebar-custom {
  border-top: 1px solid #4f5962;
}

[class*="sidebar-light"] .sidebar-custom {
  border-top: 1px solid #dee2e6;
}

.layout-fixed.sidebar-collapse .hide-on-collapse {
  display: none;
}

.layout-fixed.sidebar-collapse:hover .hide-on-collapse {
  display: block;
}

.layout-fixed .main-sidebar-custom .sidebar {
  height: calc(100% - ((3.5rem + 4rem) + 1px));
}

.layout-fixed .main-sidebar-custom .sidebar-custom {
  height: 4rem;
  padding: 0.85rem 0.5rem;
}

.layout-fixed .main-sidebar-custom-lg .sidebar {
  height: calc(100% - ((3.5rem + 6rem) + 1px));
}

.layout-fixed .main-sidebar-custom-lg .sidebar-custom {
  height: 6rem;
}

.layout-fixed .main-sidebar-custom-xl .sidebar {
  height: calc(100% - ((3.5rem + 8rem) + 1px));
}

.layout-fixed .main-sidebar-custom-xl .sidebar-custom {
  height: 8rem;
}

.layout-fixed .main-sidebar-custom .pos-right,
.layout-fixed .main-sidebar-custom-lg .pos-right,
.layout-fixed .main-sidebar-custom-xl .pos-right {
  position: absolute;
  right: .5rem;
}

.logo-xs,
.logo-xl {
  opacity: 1;
  position: absolute;
  visibility: visible;
}

.logo-xs.brand-image-xs,
.logo-xl.brand-image-xs {
  left: 18px;
  top: 12px;
}

.logo-xs.brand-image-xl,
.logo-xl.brand-image-xl {
  left: 12px;
  top: 6px;
}

.logo-xs {
  opacity: 0;
  visibility: hidden;
}

.logo-xs.brand-image-xl {
  left: 16px;
  top: 8px;
}

.brand-link.logo-switch::before {
  content: "\00a0";
}

@media (min-width: 992px) {
  .sidebar-mini .nav-sidebar,
  .sidebar-mini .nav-sidebar > .nav-header,
  .sidebar-mini .nav-sidebar .nav-link {
    white-space: nowrap;
  }
  .sidebar-mini.sidebar-collapse .d-hidden-mini {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 4.6rem !important;
  }
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-header {
    display: none;
  }
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p {
    width: 100%;
    white-space: nowrap;
  }
  .sidebar-mini.sidebar-collapse .sidebar .user-panel > .info,
  .sidebar-mini.sidebar-collapse .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .brand-text {
    margin-left: -10px;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
  .sidebar-mini.sidebar-collapse .logo-xl {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
  .sidebar-mini.sidebar-collapse .logo-xs {
    display: inline-block;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    overflow-x: hidden;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar, .sidebar-mini.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 4.6rem;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar .user-panel .image {
    float: none;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused {
    width: 300px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-link, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .brand-link {
    width: 300px;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .user-panel {
    text-align: left;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel .image, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .user-panel .image {
    float: left;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel > .info,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-text,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .logo-xl, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info,
  .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .brand-text,
  .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .logo-xl {
    display: inline-block;
    margin-left: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .logo-xs, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .logo-xs {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .brand-image, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .brand-image {
    margin-right: .5rem;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .sidebar-form,
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .user-panel > .info, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .sidebar-form,
  .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info {
    display: block !important;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar > .nav-item > .nav-link > span, .sidebar-mini.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar > .nav-item > .nav-link > span {
    display: inline-block !important;
  }
  .sidebar-mini.sidebar-collapse .visible-sidebar-mini {
    display: block !important;
  }
  .sidebar-mini.sidebar-collapse.layout-fixed .main-sidebar:hover .brand-link {
    width: 300px;
  }
  .sidebar-mini.sidebar-collapse.layout-fixed .brand-link, .sidebar-mini.sidebar-collapse .brand-link {
    width: 4.6rem;
    margin: 0;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover + .content-wrapper {
    margin-left: 300px!important;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar > .nav-item > .nav-link > .support{
    display: none!important;
  }
  .sidebar-mini.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link .support-img svg{
    display: block!important;
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .sidebar-mini.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }
}

@media (min-width: 768px) {
  .sidebar-mini-md .nav-sidebar,
  .sidebar-mini-md .nav-sidebar > .nav-header,
  .sidebar-mini-md .nav-sidebar .nav-link {
    white-space: nowrap;
  }
  .sidebar-mini-md.sidebar-collapse .d-hidden-mini {
    display: none;
  }
  .sidebar-mini-md.sidebar-collapse .content-wrapper,
  .sidebar-mini-md.sidebar-collapse .main-footer,
  .sidebar-mini-md.sidebar-collapse .main-header {
    margin-left: 4.6rem !important;
  }
  .sidebar-mini-md.sidebar-collapse .nav-sidebar .nav-header {
    display: none;
  }
  .sidebar-mini-md.sidebar-collapse .nav-sidebar .nav-link p {
    width: 0;
    white-space: nowrap;
  }
  .sidebar-mini-md.sidebar-collapse .sidebar .user-panel > .info,
  .sidebar-mini-md.sidebar-collapse .nav-sidebar .nav-link p,
  .sidebar-mini-md.sidebar-collapse .brand-text {
    margin-left: -10px;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
  .sidebar-mini-md.sidebar-collapse .logo-xl {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
  .sidebar-mini-md.sidebar-collapse .logo-xs {
    display: inline-block;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar {
    overflow-x: hidden;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar, .sidebar-mini-md.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 4.6rem;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar .user-panel .image {
    float: none;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused {
    width: 250px;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .brand-link, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .brand-link {
    width: 250px;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .user-panel, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .user-panel {
    text-align: left;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .user-panel .image, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .user-panel .image {
    float: left;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .user-panel > .info,
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link p,
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .brand-text,
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .logo-xl, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info,
  .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
  .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .brand-text,
  .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .logo-xl {
    display: inline-block;
    margin-left: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .logo-xs, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .logo-xs {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .brand-image, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .brand-image {
    margin-right: .5rem;
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .sidebar-form,
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .user-panel > .info, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .sidebar-form,
  .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info {
    display: block !important;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  .sidebar-mini-md.sidebar-collapse .main-sidebar:hover .nav-sidebar > .nav-item > .nav-link > span, .sidebar-mini-md.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar > .nav-item > .nav-link > span {
    display: inline-block !important;
  }
  .sidebar-mini-md.sidebar-collapse .visible-sidebar-mini {
    display: block !important;
  }
  .sidebar-mini-md.sidebar-collapse.layout-fixed .main-sidebar:hover .brand-link {
    width: 250px;
  }
  .sidebar-mini-md.sidebar-collapse.layout-fixed .brand-link {
    width: 4.6rem;
  }
}

@media (max-width: 767.98px) {
  .sidebar-mini-md.sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }
}

.sidebar-mini-xs .nav-sidebar,
.sidebar-mini-xs .nav-sidebar > .nav-header,
.sidebar-mini-xs .nav-sidebar .nav-link {
  white-space: nowrap;
}

.sidebar-mini-xs.sidebar-collapse .d-hidden-mini {
  display: none;
}

.sidebar-mini-xs.sidebar-collapse .content-wrapper,
.sidebar-mini-xs.sidebar-collapse .main-footer,
.sidebar-mini-xs.sidebar-collapse .main-header {
  margin-left: 4.6rem !important;
}

.sidebar-mini-xs.sidebar-collapse .nav-sidebar .nav-header {
  display: none;
}

.sidebar-mini-xs.sidebar-collapse .nav-sidebar .nav-link p {
  width: 0;
  white-space: nowrap;
}

.sidebar-mini-xs.sidebar-collapse .sidebar .user-panel > .info,
.sidebar-mini-xs.sidebar-collapse .nav-sidebar .nav-link p,
.sidebar-mini-xs.sidebar-collapse .brand-text {
  margin-left: -10px;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.sidebar-mini-xs.sidebar-collapse .logo-xl {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.sidebar-mini-xs.sidebar-collapse .logo-xs {
  display: inline-block;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar {
  overflow-x: hidden;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar, .sidebar-mini-xs.sidebar-collapse .main-sidebar::before {
  margin-left: 0;
  width: 4.6rem;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar .user-panel .image {
  float: none;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused {
  width: 250px;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .brand-link, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .brand-link {
  width: 250px;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .user-panel, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .user-panel {
  text-align: left;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .user-panel .image, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .user-panel .image {
  float: left;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .user-panel > .info,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .nav-sidebar .nav-link p,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .brand-text,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .logo-xl, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .brand-text,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .logo-xl {
  display: inline-block;
  margin-left: 0;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .logo-xs, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .logo-xs {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .brand-image, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .brand-image {
  margin-right: .5rem;
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .sidebar-form,
.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .user-panel > .info, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .sidebar-form,
.sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .user-panel > .info {
  display: block !important;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.sidebar-mini-xs.sidebar-collapse .main-sidebar:hover .nav-sidebar > .nav-item > .nav-link > span, .sidebar-mini-xs.sidebar-collapse .main-sidebar.sidebar-focused .nav-sidebar > .nav-item > .nav-link > span {
  display: inline-block !important;
}

.sidebar-mini-xs.sidebar-collapse .visible-sidebar-mini {
  display: block !important;
}

.sidebar-mini-xs.sidebar-collapse.layout-fixed .main-sidebar:hover .brand-link {
  width: 250px;
}

.sidebar-mini-xs.sidebar-collapse.layout-fixed .brand-link {
  width: 4.6rem;
}

.sidebar-mini .main-sidebar .nav-child-indent .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 1rem);
}

.sidebar-mini .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 2rem);
}

.sidebar-mini .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 3rem);
}

.sidebar-mini .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 4rem);
}

.sidebar-mini .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 5rem);
}

.sidebar-mini .main-sidebar .nav-legacy .nav-link,
.sidebar-mini-md .main-sidebar .nav-legacy .nav-link,
.sidebar-mini-xs .main-sidebar .nav-legacy .nav-link {
  width: 250px;
}

.sidebar-mini .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-link {
  width: calc(250px - 1rem);
}

.sidebar-mini .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 1rem);
}

.sidebar-mini .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 2rem);
}

.sidebar-mini .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 3rem);
}

.sidebar-mini .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 4rem);
}

.sidebar-mini .main-sidebar .nav-flat .nav-link,
.sidebar-mini-md .main-sidebar .nav-flat .nav-link,
.sidebar-mini-xs .main-sidebar .nav-flat .nav-link {
  width: 250px;
}

.sidebar-mini .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-link {
  width: calc(250px);
}

.sidebar-mini .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem);
}

.sidebar-mini .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem * 2);
}

.sidebar-mini .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem * 3);
}

.sidebar-mini .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem * 4);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - .5rem);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 1rem);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 1.5rem);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 2rem);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 0.5rem * 2 - 2.5rem);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-link {
  width: 250px;
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link {
  width: calc(250px - .5rem);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 2);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 3);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 4);
}

.sidebar-mini .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-md .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link,
.sidebar-mini-xs .main-sidebar .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 5);
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: calc(250px - 0.5rem * 2);
  transition: width ease-in-out 0.3s;
  border: 1px solid transparent;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar-mini .main-sidebar .nav-link,
  .sidebar-mini-md .main-sidebar .nav-link,
  .sidebar-mini-xs .main-sidebar .nav-link {
    transition: none;
  }
}

.sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar .nav-link {
  width: 3.6rem;
}

.sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar.nav-flat .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar.nav-legacy .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar.nav-flat .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar.nav-legacy .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar.nav-flat .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar.nav-legacy .nav-link {
  width: 4.6rem;
}

.sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar.nav-child-indent.nav-compact .nav-treeview, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar.nav-child-indent.nav-compact .nav-treeview, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar.nav-child-indent.nav-compact .nav-treeview {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar.nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar.nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar.nav-child-indent.nav-compact .nav-link {
  width: calc(4.6rem - 0.5rem * 2);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-link {
  width: calc(250px - 0.5rem * 2);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-header, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-header, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-header, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-header, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-header, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-header {
  display: inline-block;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent .nav-link {
  width: calc(250px - 0.5rem * 2);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-legacy .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-legacy .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-legacy .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-legacy .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-legacy .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-legacy .nav-link {
  width: 250px;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-link {
  width: calc(250px - 1rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 1rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 2rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 3rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-legacy.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - 1rem - 4rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-flat .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-flat .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-flat .nav-link {
  width: 250px;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-link {
  width: calc(250px);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem * 2);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem * 3);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-flat.nav-child-indent .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .2rem * 4);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-compact .nav-link {
  width: calc(250px - 0.5rem * 2);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-link {
  width: 250px;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-link {
  width: calc(250px - .5rem);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 2);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 3);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 4);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .nav-child-indent.nav-legacy.nav-compact .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-treeview .nav-link {
  width: calc(250px - .5rem * 5);
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini .main-sidebar:hover .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .sidebar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-thumb, .sidebar-collapse.sidebar-mini .main-sidebar:hover .sidebar::-webkit-scrollbar-thumb, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-thumb, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .sidebar::-webkit-scrollbar-thumb, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-thumb, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .sidebar::-webkit-scrollbar-thumb {
  background-color: #a9a9a9;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-track, .sidebar-collapse.sidebar-mini .main-sidebar:hover .sidebar::-webkit-scrollbar-track, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-track, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .sidebar::-webkit-scrollbar-track, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-track, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-corner, .sidebar-collapse.sidebar-mini .main-sidebar:hover .sidebar::-webkit-scrollbar-corner, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-corner, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .sidebar::-webkit-scrollbar-corner, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .sidebar::-webkit-scrollbar-corner, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .sidebar::-webkit-scrollbar-corner {
  background-color: transparent;
}

.sidebar-collapse.sidebar-mini .main-sidebar.sidebar-focused .sidebar, .sidebar-collapse.sidebar-mini .main-sidebar:hover .sidebar, .sidebar-collapse.sidebar-mini-md .main-sidebar.sidebar-focused .sidebar, .sidebar-collapse.sidebar-mini-md .main-sidebar:hover .sidebar, .sidebar-collapse.sidebar-mini-xs .main-sidebar.sidebar-focused .sidebar, .sidebar-collapse.sidebar-mini-xs .main-sidebar:hover .sidebar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: thin;
  scrollbar-color: #a9a9a9 transparent;
}

.sidebar-collapse.sidebar-mini .main-sidebar .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini-md .main-sidebar .sidebar::-webkit-scrollbar, .sidebar-collapse.sidebar-mini-xs .main-sidebar .sidebar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.sidebar-collapse.sidebar-mini .main-sidebar .sidebar, .sidebar-collapse.sidebar-mini-md .main-sidebar .sidebar, .sidebar-collapse.sidebar-mini-xs .main-sidebar .sidebar {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
  padding-left: 0.5rem; 
  padding-right: 0.5rem;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover {
  width: 4.6rem;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .nav-header,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .nav-header, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .nav-header,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .nav-header, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .nav-header,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .nav-header {
  display: none;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .brand-link,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .brand-link, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .brand-link,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .brand-link, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .brand-link,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .brand-link {
  width: 4.6rem !important;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .user-panel .image,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .user-panel .image, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .user-panel .image,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .user-panel .image, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .user-panel .image,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .user-panel .image {
  float: none !important;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .logo-xs,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .logo-xs, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .logo-xs,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .logo-xs, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .logo-xs,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .logo-xs {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: visible;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .logo-xl,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .logo-xl, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .logo-xl,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .logo-xl, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .logo-xl,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .logo-xl {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar.nav-child-indent .nav-treeview,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .nav-sidebar.nav-child-indent .nav-treeview, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar.nav-child-indent .nav-treeview,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .nav-sidebar.nav-child-indent .nav-treeview, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar.nav-child-indent .nav-treeview,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .nav-sidebar.nav-child-indent .nav-treeview {
  padding-left: 0;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .brand-text,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .user-panel > .info,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .brand-text,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .user-panel > .info,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .nav-sidebar .nav-link p, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .brand-text,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .user-panel > .info,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .brand-text,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .user-panel > .info,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .nav-sidebar .nav-link p, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .brand-text,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .user-panel > .info,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar .nav-link p,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .brand-text,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .user-panel > .info,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .nav-sidebar .nav-link p {
  margin-left: -10px;
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  visibility: hidden;
  width: 0;
}

.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar > .nav-item .nav-icon,
.sidebar-collapse.sidebar-mini .sidebar-no-expand.main-sidebar:hover .nav-sidebar > .nav-item .nav-icon, .sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar > .nav-item .nav-icon,
.sidebar-collapse.sidebar-mini-md .sidebar-no-expand.main-sidebar:hover .nav-sidebar > .nav-item .nav-icon, .sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar.sidebar-focused .nav-sidebar > .nav-item .nav-icon,
.sidebar-collapse.sidebar-mini-xs .sidebar-no-expand.main-sidebar:hover .nav-sidebar > .nav-item .nav-icon {
  margin-right: 0;
}

.nav-sidebar {
  position: relative;
}

.nav-sidebar:hover {
  overflow: visible;
}

.sidebar-form,
.nav-sidebar > .nav-header {
  overflow: hidden;
  text-overflow: clip;
}

.nav-sidebar .nav-item > .nav-link {
  position: relative;
}

.nav-sidebar .nav-item > .nav-link > .float-right {
  margin-top: -7px;
  position: absolute;
  right: 10px;
  top: 50%;
}

.sidebar .nav-link p,
.main-sidebar .brand-text,
.main-sidebar .logo-xs,
.main-sidebar .logo-xl,
.sidebar .user-panel .info {
  transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
}

@media (prefers-reduced-motion: reduce) {
  .sidebar .nav-link p,
  .main-sidebar .brand-text,
  .main-sidebar .logo-xs,
  .main-sidebar .logo-xl,
  .sidebar .user-panel .info {
    transition: none;
  }
}

.text-sm .dropdown-menu {
  font-size: 0.875rem !important;
}

.text-sm .dropdown-toggle::after {
  vertical-align: .2rem;
}

.dropdown-item-title {
  font-size: 1rem;
  margin: 0;
}

.dropdown-icon::after {
  margin-left: 0;
}

.dropdown-menu-lg {
  max-width: 30vw;
  min-width: 29vw;
  padding: 0;
}

.dropdown-menu-lg .dropdown-divider {
  margin: 0;
}

.dropdown-menu-lg .dropdown-item {
  padding: 0.5rem 1rem;
}

.dropdown-menu-lg p {
  margin: 0;
  white-space: normal;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > a::after {
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
  float: right;
  margin-left: .5rem;
  margin-top: .5rem;
}

.dropdown-submenu > .dropdown-menu {
  left: 100%;
  margin-left: 0;
  margin-top: 0;
  top: 0;
}

.dropdown-hover:hover > .dropdown-menu, .dropdown-hover.nav-item.dropdown:hover > .dropdown-menu,
.dropdown-hover .dropdown-submenu:hover > .dropdown-menu, .dropdown-hover.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu-xl {
  max-width: 420px;
  min-width: 360px;
  padding: 0;
}

.dropdown-menu-xl .dropdown-divider {
  margin: 0;
}

.dropdown-menu-xl .dropdown-item {
  padding: 0.5rem 1rem;
}

.dropdown-menu-xl p {
  margin: 0;
  white-space: normal;
}

.dropdown-footer,
.dropdown-header {
  display: block;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  text-align: center;
}

.open:not(.dropup) > .animated-dropdown-menu {
  -webkit-animation: flipInX 0.7s both;
  animation: flipInX 0.7s both;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.navbar-custom-menu > .navbar-nav > li {
  position: relative;
}

.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}

@media (max-width: 767.98px) {
  .navbar-custom-menu > .navbar-nav {
    float: right;
  }
  .navbar-custom-menu > .navbar-nav > li {
    position: static;
  }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 5%;
    left: auto;
    border: 1px solid #ddd;
    background-color: #fff;
  }
}

.navbar-nav > .user-menu > .nav-link::after {
  content: none;
}

.navbar-nav > .user-menu > .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 0;
  width: 280px;
}

.navbar-nav > .user-menu > .dropdown-menu,
.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header {
  height: 175px;
  padding: 10px;
  text-align: center;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
  z-index: 5;
  height: 90px;
  width: 90px;
  border: 3px solid;
  border-color: transparent;
  border-color: rgba(255, 255, 255, 0.2);
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
  z-index: 5;
  font-size: 17px;
  margin-top: 10px;
}

.navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
  display: block;
  font-size: 12px;
}

.navbar-nav > .user-menu > .dropdown-menu > .user-body {
  border-bottom: 1px solid #495057;
  border-top: 1px solid #dee2e6;
  padding: 15px;
}

.navbar-nav > .user-menu > .dropdown-menu > .user-body::after {
  display: block;
  clear: both;
  content: "";
}

@media (min-width: 576px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
    background-color: #fff !important;
    color: #495057 !important;
  }
}

.navbar-nav > .user-menu > .dropdown-menu > .user-footer {
  background-color: #f8f9fa;
  padding: 10px;
}

.navbar-nav > .user-menu > .dropdown-menu > .user-footer::after {
  display: block;
  clear: both;
  content: "";
}

.navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default {
  color: #6c757d;
}

@media (min-width: 576px) {
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f8f9fa;
  }
}

.navbar-nav > .user-menu .user-image {
  border-radius: 50%;
  float: left;
  height: 2.1rem;
  margin-right: 10px;
  margin-top: -2px;
  width: 2.1rem;
}

@media (min-width: 576px) {
  .navbar-nav > .user-menu .user-image {
    float: none;
    line-height: 10px;
    margin-right: .4rem;
    margin-top: -8px;
  }
}

body.text-sm .input-group-text {
  font-size: 0.875rem;
}

.custom-switch.custom-switch-off-primary .custom-control-input ~ .custom-control-label::before {
  background-color: #007bff;
  border-color: #004a99;
}

.custom-switch.custom-switch-off-primary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.custom-switch.custom-switch-off-primary .custom-control-input ~ .custom-control-label::after {
  background-color: #003e80;
}

.custom-switch.custom-switch-on-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
  border-color: #004a99;
}

.custom-switch.custom-switch-on-primary .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.custom-switch.custom-switch-on-primary .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #99caff;
}

.custom-switch.custom-switch-off-secondary .custom-control-input ~ .custom-control-label::before {
  background-color: #6c757d;
  border-color: #3d4246;
}

.custom-switch.custom-switch-off-secondary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(108, 117, 125, 0.25);
}

.custom-switch.custom-switch-off-secondary .custom-control-input ~ .custom-control-label::after {
  background-color: #313539;
}

.custom-switch.custom-switch-on-secondary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c757d;
  border-color: #3d4246;
}

.custom-switch.custom-switch-on-secondary .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(108, 117, 125, 0.25);
}

.custom-switch.custom-switch-on-secondary .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #bcc1c6;
}

.custom-switch.custom-switch-off-success .custom-control-input ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #145523;
}

.custom-switch.custom-switch-off-success .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(40, 167, 69, 0.25);
}

.custom-switch.custom-switch-off-success .custom-control-input ~ .custom-control-label::after {
  background-color: #0f401b;
}

.custom-switch.custom-switch-on-success .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #145523;
}

.custom-switch.custom-switch-on-success .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(40, 167, 69, 0.25);
}

.custom-switch.custom-switch-on-success .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #86e29b;
}

.custom-switch.custom-switch-off-info .custom-control-input ~ .custom-control-label::before {
  background-color: #17a2b8;
  border-color: #0c525d;
}

.custom-switch.custom-switch-off-info .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(23, 162, 184, 0.25);
}

.custom-switch.custom-switch-off-info .custom-control-input ~ .custom-control-label::after {
  background-color: #093e47;
}

.custom-switch.custom-switch-on-info .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #17a2b8;
  border-color: #0c525d;
}

.custom-switch.custom-switch-on-info .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(23, 162, 184, 0.25);
}

.custom-switch.custom-switch-on-info .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #7adeee;
}

.custom-switch.custom-switch-off-warning .custom-control-input ~ .custom-control-label::before {
  background-color: #ffc107;
  border-color: #a07800;
}

.custom-switch.custom-switch-off-warning .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(255, 193, 7, 0.25);
}

.custom-switch.custom-switch-off-warning .custom-control-input ~ .custom-control-label::after {
  background-color: #876500;
}

.custom-switch.custom-switch-on-warning .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107;
  border-color: #a07800;
}

.custom-switch.custom-switch-on-warning .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(255, 193, 7, 0.25);
}

.custom-switch.custom-switch-on-warning .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffe7a0;
}

.custom-switch.custom-switch-off-danger .custom-control-input ~ .custom-control-label::before {
  background-color: #dc3545;
  border-color: #921925;
}

.custom-switch.custom-switch-off-danger .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(220, 53, 69, 0.25);
}

.custom-switch.custom-switch-off-danger .custom-control-input ~ .custom-control-label::after {
  background-color: #7c151f;
}

.custom-switch.custom-switch-on-danger .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #dc3545;
  border-color: #921925;
}

.custom-switch.custom-switch-on-danger .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(220, 53, 69, 0.25);
}

.custom-switch.custom-switch-on-danger .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f3b7bd;
}

.custom-switch.custom-switch-off-light .custom-control-input ~ .custom-control-label::before {
  background-color: #f8f9fa;
  border-color: #bdc6d0;
}

.custom-switch.custom-switch-off-light .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(248, 249, 250, 0.25);
}

.custom-switch.custom-switch-off-light .custom-control-input ~ .custom-control-label::after {
  background-color: #aeb9c5;
}

.custom-switch.custom-switch-on-light .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f8f9fa;
  border-color: #bdc6d0;
}

.custom-switch.custom-switch-on-light .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(248, 249, 250, 0.25);
}

.custom-switch.custom-switch-on-light .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
}

.custom-switch.custom-switch-off-dark .custom-control-input ~ .custom-control-label::before {
  background-color: #343a40;
  border-color: #060708;
}

.custom-switch.custom-switch-off-dark .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(52, 58, 64, 0.25);
}

.custom-switch.custom-switch-off-dark .custom-control-input ~ .custom-control-label::after {
  background-color: black;
}

.custom-switch.custom-switch-on-dark .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
  border-color: #060708;
}

.custom-switch.custom-switch-on-dark .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(52, 58, 64, 0.25);
}

.custom-switch.custom-switch-on-dark .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #7a8793;
}

.custom-switch.custom-switch-off-lightblue .custom-control-input ~ .custom-control-label::before {
  background-color: #3c8dbc;
  border-color: #23536f;
}

.custom-switch.custom-switch-off-lightblue .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(60, 141, 188, 0.25);
}

.custom-switch.custom-switch-off-lightblue .custom-control-input ~ .custom-control-label::after {
  background-color: #1d455b;
}

.custom-switch.custom-switch-on-lightblue .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3c8dbc;
  border-color: #23536f;
}

.custom-switch.custom-switch-on-lightblue .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(60, 141, 188, 0.25);
}

.custom-switch.custom-switch-on-lightblue .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #acd0e5;
}

.custom-switch.custom-switch-off-navy .custom-control-input ~ .custom-control-label::before {
  background-color: #001f3f;
  border-color: black;
}

.custom-switch.custom-switch-off-navy .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(0, 31, 63, 0.25);
}

.custom-switch.custom-switch-off-navy .custom-control-input ~ .custom-control-label::after {
  background-color: black;
}

.custom-switch.custom-switch-on-navy .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #001f3f;
  border-color: black;
}

.custom-switch.custom-switch-on-navy .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(0, 31, 63, 0.25);
}

.custom-switch.custom-switch-on-navy .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #006ad8;
}

.custom-switch.custom-switch-off-olive .custom-control-input ~ .custom-control-label::before {
  background-color: #3d9970;
  border-color: #20503b;
}

.custom-switch.custom-switch-off-olive .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(61, 153, 112, 0.25);
}

.custom-switch.custom-switch-off-olive .custom-control-input ~ .custom-control-label::after {
  background-color: #193e2d;
}

.custom-switch.custom-switch-on-olive .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #3d9970;
  border-color: #20503b;
}

.custom-switch.custom-switch-on-olive .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(61, 153, 112, 0.25);
}

.custom-switch.custom-switch-on-olive .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #99d6bb;
}

.custom-switch.custom-switch-off-lime .custom-control-input ~ .custom-control-label::before {
  background-color: #01ff70;
  border-color: #009a43;
}

.custom-switch.custom-switch-off-lime .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(1, 255, 112, 0.25);
}

.custom-switch.custom-switch-off-lime .custom-control-input ~ .custom-control-label::after {
  background-color: #008138;
}

.custom-switch.custom-switch-on-lime .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #01ff70;
  border-color: #009a43;
}

.custom-switch.custom-switch-on-lime .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(1, 255, 112, 0.25);
}

.custom-switch.custom-switch-on-lime .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #9affc6;
}

.custom-switch.custom-switch-off-fuchsia .custom-control-input ~ .custom-control-label::before {
  background-color: #f012be;
  border-color: #930974;
}

.custom-switch.custom-switch-off-fuchsia .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(240, 18, 190, 0.25);
}

.custom-switch.custom-switch-off-fuchsia .custom-control-input ~ .custom-control-label::after {
  background-color: #7b0861;
}

.custom-switch.custom-switch-on-fuchsia .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #f012be;
  border-color: #930974;
}

.custom-switch.custom-switch-on-fuchsia .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(240, 18, 190, 0.25);
}

.custom-switch.custom-switch-on-fuchsia .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f9a2e5;
}

.custom-switch.custom-switch-off-maroon .custom-control-input ~ .custom-control-label::before {
  background-color: #d81b60;
  border-color: #7d1038;
}

.custom-switch.custom-switch-off-maroon .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(216, 27, 96, 0.25);
}

.custom-switch.custom-switch-off-maroon .custom-control-input ~ .custom-control-label::after {
  background-color: #670d2e;
}

.custom-switch.custom-switch-on-maroon .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #d81b60;
  border-color: #7d1038;
}

.custom-switch.custom-switch-on-maroon .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(216, 27, 96, 0.25);
}

.custom-switch.custom-switch-on-maroon .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f29aba;
}

.custom-switch.custom-switch-off-blue .custom-control-input ~ .custom-control-label::before {
  background-color: #007bff;
  border-color: #004a99;
}

.custom-switch.custom-switch-off-blue .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.custom-switch.custom-switch-off-blue .custom-control-input ~ .custom-control-label::after {
  background-color: #003e80;
}

.custom-switch.custom-switch-on-blue .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff;
  border-color: #004a99;
}

.custom-switch.custom-switch-on-blue .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.custom-switch.custom-switch-on-blue .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #99caff;
}

.custom-switch.custom-switch-off-indigo .custom-control-input ~ .custom-control-label::before {
  background-color: #6610f2;
  border-color: #3d0894;
}

.custom-switch.custom-switch-off-indigo .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(102, 16, 242, 0.25);
}

.custom-switch.custom-switch-off-indigo .custom-control-input ~ .custom-control-label::after {
  background-color: #33077c;
}

.custom-switch.custom-switch-on-indigo .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6610f2;
  border-color: #3d0894;
}

.custom-switch.custom-switch-on-indigo .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(102, 16, 242, 0.25);
}

.custom-switch.custom-switch-on-indigo .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #c3a1fa;
}

.custom-switch.custom-switch-off-purple .custom-control-input ~ .custom-control-label::before {
  background-color: #6f42c1;
  border-color: #432776;
}

.custom-switch.custom-switch-off-purple .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(111, 66, 193, 0.25);
}

.custom-switch.custom-switch-off-purple .custom-control-input ~ .custom-control-label::after {
  background-color: #382063;
}

.custom-switch.custom-switch-on-purple .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6f42c1;
  border-color: #432776;
}

.custom-switch.custom-switch-on-purple .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(111, 66, 193, 0.25);
}

.custom-switch.custom-switch-on-purple .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #c7b5e7;
}

.custom-switch.custom-switch-off-pink .custom-control-input ~ .custom-control-label::before {
  background-color: #e83e8c;
  border-color: #ac145a;
}

.custom-switch.custom-switch-off-pink .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(232, 62, 140, 0.25);
}

.custom-switch.custom-switch-off-pink .custom-control-input ~ .custom-control-label::after {
  background-color: #95124e;
}

.custom-switch.custom-switch-on-pink .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e83e8c;
  border-color: #ac145a;
}

.custom-switch.custom-switch-on-pink .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(232, 62, 140, 0.25);
}

.custom-switch.custom-switch-on-pink .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f8c7dd;
}

.custom-switch.custom-switch-off-red .custom-control-input ~ .custom-control-label::before {
  background-color: #dc3545;
  border-color: #921925;
}

.custom-switch.custom-switch-off-red .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(220, 53, 69, 0.25);
}

.custom-switch.custom-switch-off-red .custom-control-input ~ .custom-control-label::after {
  background-color: #7c151f;
}

.custom-switch.custom-switch-on-red .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #dc3545;
  border-color: #921925;
}

.custom-switch.custom-switch-on-red .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(220, 53, 69, 0.25);
}

.custom-switch.custom-switch-on-red .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #f3b7bd;
}

.custom-switch.custom-switch-off-orange .custom-control-input ~ .custom-control-label::before {
  background-color: #fd7e14;
  border-color: #aa4e01;
}

.custom-switch.custom-switch-off-orange .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(253, 126, 20, 0.25);
}

.custom-switch.custom-switch-off-orange .custom-control-input ~ .custom-control-label::after {
  background-color: #904201;
}

.custom-switch.custom-switch-on-orange .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fd7e14;
  border-color: #aa4e01;
}

.custom-switch.custom-switch-on-orange .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(253, 126, 20, 0.25);
}

.custom-switch.custom-switch-on-orange .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fed1ac;
}

.custom-switch.custom-switch-off-yellow .custom-control-input ~ .custom-control-label::before {
  background-color: #ffc107;
  border-color: #a07800;
}

.custom-switch.custom-switch-off-yellow .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(255, 193, 7, 0.25);
}

.custom-switch.custom-switch-off-yellow .custom-control-input ~ .custom-control-label::after {
  background-color: #876500;
}

.custom-switch.custom-switch-on-yellow .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107;
  border-color: #a07800;
}

.custom-switch.custom-switch-on-yellow .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(255, 193, 7, 0.25);
}

.custom-switch.custom-switch-on-yellow .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffe7a0;
}

.custom-switch.custom-switch-off-green .custom-control-input ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #145523;
}

.custom-switch.custom-switch-off-green .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(40, 167, 69, 0.25);
}

.custom-switch.custom-switch-off-green .custom-control-input ~ .custom-control-label::after {
  background-color: #0f401b;
}

.custom-switch.custom-switch-on-green .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #28a745;
  border-color: #145523;
}

.custom-switch.custom-switch-on-green .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(40, 167, 69, 0.25);
}

.custom-switch.custom-switch-on-green .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #86e29b;
}

.custom-switch.custom-switch-off-teal .custom-control-input ~ .custom-control-label::before {
  background-color: #20c997;
  border-color: #127155;
}

.custom-switch.custom-switch-off-teal .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(32, 201, 151, 0.25);
}

.custom-switch.custom-switch-off-teal .custom-control-input ~ .custom-control-label::after {
  background-color: #0e5b44;
}

.custom-switch.custom-switch-on-teal .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #20c997;
  border-color: #127155;
}

.custom-switch.custom-switch-on-teal .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(32, 201, 151, 0.25);
}

.custom-switch.custom-switch-on-teal .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #94eed3;
}

.custom-switch.custom-switch-off-cyan .custom-control-input ~ .custom-control-label::before {
  background-color: #17a2b8;
  border-color: #0c525d;
}

.custom-switch.custom-switch-off-cyan .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(23, 162, 184, 0.25);
}

.custom-switch.custom-switch-off-cyan .custom-control-input ~ .custom-control-label::after {
  background-color: #093e47;
}

.custom-switch.custom-switch-on-cyan .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #17a2b8;
  border-color: #0c525d;
}

.custom-switch.custom-switch-on-cyan .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(23, 162, 184, 0.25);
}

.custom-switch.custom-switch-on-cyan .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #7adeee;
}

.custom-switch.custom-switch-off-white .custom-control-input ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #cccccc;
}

.custom-switch.custom-switch-off-white .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(255, 255, 255, 0.25);
}

.custom-switch.custom-switch-off-white .custom-control-input ~ .custom-control-label::after {
  background-color: #bfbfbf;
}

.custom-switch.custom-switch-on-white .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
  border-color: #cccccc;
}

.custom-switch.custom-switch-on-white .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(255, 255, 255, 0.25);
}

.custom-switch.custom-switch-on-white .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
}

.custom-switch.custom-switch-off-gray .custom-control-input ~ .custom-control-label::before {
  background-color: #6c757d;
  border-color: #3d4246;
}

.custom-switch.custom-switch-off-gray .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(108, 117, 125, 0.25);
}

.custom-switch.custom-switch-off-gray .custom-control-input ~ .custom-control-label::after {
  background-color: #313539;
}

.custom-switch.custom-switch-on-gray .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #6c757d;
  border-color: #3d4246;
}

.custom-switch.custom-switch-on-gray .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(108, 117, 125, 0.25);
}

.custom-switch.custom-switch-on-gray .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #bcc1c6;
}

.custom-switch.custom-switch-off-gray-dark .custom-control-input ~ .custom-control-label::before {
  background-color: #343a40;
  border-color: #060708;
}

.custom-switch.custom-switch-off-gray-dark .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(52, 58, 64, 0.25);
}

.custom-switch.custom-switch-off-gray-dark .custom-control-input ~ .custom-control-label::after {
  background-color: black;
}

.custom-switch.custom-switch-on-gray-dark .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #343a40;
  border-color: #060708;
}

.custom-switch.custom-switch-on-gray-dark .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px rgba(52, 58, 64, 0.25);
}

.custom-switch.custom-switch-on-gray-dark .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #7a8793;
}

.card-header::after,
.card-body::after,
.card-footer::after {
  display: block;
  clear: both;
  content: "";
}

.card-header {
  background-color: transparent;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.25rem;
  position: relative;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.collapsed-card .card-header {
  border-bottom: 0;
}

.card-header > .card-tools {
  float: right;
  margin-right: -0.625rem;
}

.card-header > .card-tools .input-group,
.card-header > .card-tools .nav,
.card-header > .card-tools .pagination {
  margin-bottom: -0.3rem;
  margin-top: -0.3rem;
}

.card-header > .card-tools [data-toggle="tooltip"] {
  position: relative;
}

.card-title {
  float: left;
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0;  
  font-family: 'Poppins', sans-serif;
  color: #060136;
}

.card-text {
  clear: both;
}

.btn-tool {
  background-color: transparent;
  color: #adb5bd;
  font-size: 0.875rem;
  margin: -0.75rem 0;
  padding: .25rem .5rem;
}

.btn-group.show .btn-tool, .btn-tool:hover {
  color: #495057;
}

.show .btn-tool, .btn-tool:focus {
  box-shadow: none !important;
}

.back-to-top {
  bottom: 1.25rem;
  position: fixed;
  right: 1.25rem;
  z-index: 1032;
}

.back-to-top:focus {
  box-shadow: none;
}

.text-xs {
  font-size: 0.75rem !important;
}

.text-sm {
  font-size: 0.875rem !important;
}

[class^="bg-"].disabled {
  opacity: .65;
}

a.text-muted:hover {
  color: #007bff !important;
}

.link-muted {
  color: #5d6974;
}

.link-muted:hover, .link-muted:focus {
  color: #464f58;
}

.link-black {
  color: #6c757d;
}

.link-black:hover, .link-black:focus {
  color: #e6e8ea;
}
