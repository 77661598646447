@import "../css/bootstrap.min.css";
@import "../css/fontawesome.min.css";
@import "../css/admin.min.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700&display=swap");
.sun-editor {
  width: auto;
  height: auto;
  box-sizing: border-box;
  font-family: Helvetica Neue;
  border: 1px solid #dadada;
  background-color: #fff;
  color: #000;
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sun-editor * {
  box-sizing: border-box;
  -webkit-user-drag: none;
  overflow: visible;
}

.sun-editor-common button, .sun-editor-common input, .sun-editor-common select, .sun-editor-common textarea {
  font-size: 14px;
  line-height: 1.5;
}

.sun-editor-common blockquote, .sun-editor-common body, .sun-editor-common button, .sun-editor-common code, .sun-editor-common dd, .sun-editor-common div, .sun-editor-common dl, .sun-editor-common dt, .sun-editor-common fieldset, .sun-editor-common form, .sun-editor-common h1, .sun-editor-common h2, .sun-editor-common h3, .sun-editor-common h4, .sun-editor-common h5, .sun-editor-common h6, .sun-editor-common input, .sun-editor-common legend, .sun-editor-common li, .sun-editor-common ol, .sun-editor-common p, .sun-editor-common pre, .sun-editor-common select, .sun-editor-common td, .sun-editor-common textarea, .sun-editor-common th, .sun-editor-common ul {
  margin: 0;
  padding: 0;
  border: 0;
}

.sun-editor-common dl, .sun-editor-common li, .sun-editor-common menu, .sun-editor-common ol, .sun-editor-common ul {
  list-style: none !important;
}

.sun-editor-common hr {
  margin: 6px 0 !important;
}

.sun-editor textarea {
  resize: none;
  border: 0;
  padding: 0;
}

.sun-editor button {
  border: 0;
  background-color: transparent;
  touch-action: manipulation;
  cursor: pointer;
  outline: none;
}

.sun-editor button, .sun-editor input, .sun-editor select, .sun-editor textarea {
  vertical-align: middle;
}

.sun-editor button span {
  display: block;
  margin: 0;
  padding: 0;
}

.sun-editor button .txt {
  display: block;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sun-editor button * {
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.sun-editor svg {
  fill: currentColor;
}

.sun-editor .se-svg, .sun-editor button > svg {
  width: 16px;
  height: 16px;
  margin: auto;
  fill: currentColor;
  display: block;
  text-align: center;
  float: none;
}

.sun-editor .close > svg, .sun-editor .se-dialog-close > svg {
  width: 10px;
  height: 10px;
}

.sun-editor .se-btn-select > svg {
  float: right;
  width: 10px;
  height: 10px;
}

.sun-editor .se-btn-list > .se-list-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin: -1px 10px 0 0;
  vertical-align: middle;
}

.sun-editor .se-line-breaker > button > svg {
  width: 24px;
  height: 24px;
}

.sun-editor button > i:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  font-size: 15px;
  line-height: 2;
}

.sun-editor button > [class=se-icon-text] {
  font-size: 20px;
  line-height: 1;
}

.sun-editor .se-arrow, .sun-editor .se-arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: 11px solid transparent;
}

.sun-editor .se-arrow.se-arrow-up {
  top: -11px;
  left: 20px;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #dadada;
}

.sun-editor .se-arrow.se-arrow-up:after {
  top: 1px;
  margin-left: -11px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.sun-editor .se-toolbar .se-arrow.se-arrow-up:after {
  border-bottom-color: #fafafa;
}

.sun-editor .se-arrow.se-arrow-down {
  top: 0;
  left: 0;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #dadada;
}

.sun-editor .se-arrow.se-arrow-down:after {
  top: -12px;
  margin-left: -11px;
  content: " ";
  border-bottom-width: 0;
  border-top-color: #fff;
}

.sun-editor .se-toolbar .se-arrow.se-arrow-down:after {
  border-top-color: #fafafa;
}

.sun-editor .se-container {
  position: relative;
  width: auto;
  height: auto;
}

.sun-editor button {
  color: #000;
}

.sun-editor .se-btn {
  float: left;
  width: 34px;
  height: 34px;
  border: 0;
  border-radius: 4px;
  margin: 1px !important;
  padding: 0;
  font-size: 12px;
  line-height: 27px;
}

.sun-editor .se-btn:enabled:focus, .sun-editor .se-btn:enabled:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-btn:enabled:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  -webkit-box-shadow: inset 0 3px 5px #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-btn-primary {
  color: #000;
  background-color: #c7deff;
  border: 1px solid #80bdff;
  border-radius: 4px;
}

.sun-editor .se-btn-primary:focus, .sun-editor .se-btn-primary:hover {
  color: #000;
  background-color: #80bdff;
  border-color: #3f9dff;
  outline: 0 none;
}

.sun-editor .se-btn-primary:active {
  color: #fff;
  background-color: #3f9dff;
  border-color: #4592ff;
  -webkit-box-shadow: inset 0 3px 5px #4592ff;
  box-shadow: inset 0 3px 5px #4592ff;
}

.sun-editor input, .sun-editor select, .sun-editor textarea {
  color: #000;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.sun-editor input:focus, .sun-editor select:focus, .sun-editor textarea:focus {
  border: 1px solid #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #c7deff;
  box-shadow: 0 0 0 0.2rem #c7deff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sun-editor .se-btn:enabled.active {
  color: #4592ff;
  outline: 0 none;
}

.sun-editor .se-btn:enabled.active:focus, .sun-editor .se-btn:enabled.active:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-btn:enabled.active:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  -webkit-box-shadow: inset 0 3px 5px #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-btn:enabled.on {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-btn:enabled.on:focus, .sun-editor .se-btn:enabled.on:hover {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}

.sun-editor .se-btn:enabled.on:active {
  background-color: #c1c1c1;
  border-color: #b1b1b1;
  -webkit-box-shadow: inset 0 3px 5px #b1b1b1;
  box-shadow: inset 0 3px 5px #b1b1b1;
}

.sun-editor .se-btn-list:disabled, .sun-editor .se-btn:disabled, .sun-editor button:disabled {
  cursor: not-allowed;
  background-color: inherit;
  color: #bdbdbd;
}

.sun-editor .se-loading-box {
  position: absolute;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.7;
  filter: alpha(opacity=70);
  z-index: 2147483647;
}

.sun-editor .se-loading-box .se-loading-effect {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  height: 25px;
  width: 25px;
  border-top: 2px solid #07d;
  border-right: 2px solid transparent;
  border-radius: 50%;
  animation: spinner 0.8s linear infinite;
  margin: -25px 0 0 -25px;
}

.sun-editor .se-line-breaker {
  position: absolute;
  display: none;
  width: 100%;
  height: 1px;
  cursor: text;
  border-top: 1px solid #3288ff;
  z-index: 7;
}

.sun-editor .se-line-breaker > button.se-btn {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  top: -15px;
  float: none;
  left: -50%;
  background-color: #fff;
  border: 1px solid #0c2240;
  opacity: 0.6;
  cursor: pointer;
}

.sun-editor .se-line-breaker > button.se-btn:hover {
  opacity: 0.9;
  background-color: #fff;
  border-color: #041b39;
}

.sun-editor .se-line-breaker-component {
  position: absolute;
  display: none;
  width: 24px;
  height: 24px;
  background-color: #fff;
  border: 1px solid #0c2240;
  opacity: 0.6;
  border-radius: 4px;
  cursor: pointer;
  z-index: 7;
}

.sun-editor .se-line-breaker-component:hover {
  opacity: 0.9;
}

.sun-editor .se-toolbar {
  display: block;
  position: relative;
  height: auto;
  width: 100%;
  overflow: visible;
  padding: 0;
  margin: 0;
  background-color: #fafafa;
  outline: 1px solid #dadada;
  z-index: 5;
}

.sun-editor .se-toolbar-shadow {
  display: block !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
  z-index: 0 !important;
}

.sun-editor .se-toolbar-cover {
  position: absolute;
  display: none;
  font-size: 36px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fefefe;
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: not-allowed;
  z-index: 4;
}

.sun-editor .se-toolbar-separator-vertical {
  display: inline-block;
  height: 0;
  width: 0;
  margin: 0;
  vertical-align: top;
}

.sun-editor .se-toolbar.se-toolbar-balloon, .sun-editor .se-toolbar.se-toolbar-inline {
  display: none;
  position: absolute;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.sun-editor .se-toolbar.se-toolbar-balloon {
  z-index: 2147483647;
  width: auto;
}

.sun-editor .se-toolbar.se-toolbar-sticky {
  position: fixed;
  top: 0;
}

.sun-editor .se-toolbar-sticky-dummy {
  display: none;
  position: static;
  z-index: -1;
}

.sun-editor .se-btn-module {
  display: inline-block;
}

.sun-editor .se-btn-module-border {
  border: 1px solid #dadada;
  border-radius: 4px;
  margin-left: 1px;
  margin-right: 1px;
}

.sun-editor .se-btn-module-enter {
  display: block;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.sun-editor .se-toolbar-more-layer {
  margin: 0 -3px;
  background-color: #fafafa;
}

.sun-editor .se-toolbar-more-layer .se-more-layer {
  display: none;
  border-top: 1px solid #dadada;
}

.sun-editor .se-toolbar-more-layer .se-more-layer .se-more-form {
  display: inline-block;
  width: 100%;
  height: auto;
  padding: 4px 3px 0;
}

.sun-editor .se-btn-module .se-btn-more.se-btn-more-text {
  width: auto;
  padding: 0 4px;
}

.sun-editor .se-btn-module .se-btn-more:focus, .sun-editor .se-btn-module .se-btn-more:hover {
  color: #000;
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}

.sun-editor .se-btn-module .se-btn-more.on {
  color: #333;
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}

.sun-editor .se-btn-module .se-btn-more.on:hover {
  color: #000;
  background-color: #c1c1c1;
  border-color: #b1b1b1;
  outline: 0 none;
}

.sun-editor .se-menu-list, .sun-editor .se-menu-list li {
  float: left;
  padding: 0;
  margin: 0;
}

.sun-editor .se-menu-list li {
  position: relative;
}

.sun-editor .se-btn-select {
  width: auto;
  display: flex;
  padding: 4px 6px;
}

.sun-editor .se-btn-select .txt {
  flex: auto;
  text-align: left;
}

.sun-editor .se-btn-select.se-btn-tool-font {
  width: 100px;
}

.sun-editor .se-btn-select.se-btn-tool-format {
  width: 82px;
}

.sun-editor .se-btn-select.se-btn-tool-size {
  width: 78px;
}

.sun-editor .se-btn-tray {
  position: relative;
  width: 100%;
  height: auto;
  padding: 4px 3px 0;
  margin: 0;
}

.sun-editor .se-menu-tray {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
}

.sun-editor .se-submenu {
  overflow-x: hidden;
  overflow-y: auto;
}

.sun-editor .se-menu-container {
  overflow-x: unset;
  overflow-y: unset;
}

.sun-editor .se-list-layer {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  z-index: 5;
  border: 1px solid #bababa;
  border-radius: 4px;
  padding: 6px 0;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0 none;
}

.sun-editor .se-list-layer .se-list-inner {
  padding: 0;
  margin: 0;
  overflow-x: initial;
  overflow-y: initial;
  overflow: visible;
}

.sun-editor .se-list-layer button {
  margin: 0;
  width: 100%;
}

.sun-editor .se-list-inner ul {
  width: 100%;
  padding: 0;
}

.sun-editor .se-list-inner li > button {
  min-width: 100%;
  width: max-content;
}

.sun-editor .se-list-inner .se-list-basic li {
  width: 100%;
}

.sun-editor .se-list-inner .se-list-basic li button.active {
  background-color: #80bdff;
  border: 1px solid #3f9dff;
  border-left: 0;
  border-right: 0;
}

.sun-editor .se-list-inner .se-list-basic li button.active:hover {
  background-color: #3f9dff;
  border: 1px solid #4592ff;
  border-left: 0;
  border-right: 0;
}

.sun-editor .se-list-inner .se-list-basic li button.active:active {
  background-color: #4592ff;
  border: 1px solid #407dd1;
  border-left: 0;
  border-right: 0;
  -webkit-box-shadow: inset 0 3px 5px #407dd1;
  box-shadow: inset 0 3px 5px #407dd1;
}

.sun-editor .se-list-inner .se-list-checked li button > .se-svg {
  float: left;
  padding: 6px 6px 0 0;
}

.sun-editor .se-list-inner .se-list-checked li button > .se-svg > svg {
  display: none;
}

.sun-editor .se-list-inner .se-list-checked li button.se-checked {
  color: #4592ff;
}

.sun-editor .se-list-inner .se-list-checked li button.se-checked > .se-svg > svg {
  display: block;
}

.sun-editor .se-btn-list {
  width: 100%;
  height: auto;
  min-height: 32px;
  padding: 0 14px;
  cursor: pointer;
  font-size: 12px;
  line-height: normal;
  text-indent: 0;
  text-decoration: none;
  text-align: left;
}

.sun-editor .se-btn-list.default_value {
  background-color: #f3f3f3;
  border-top: 1px dotted #b1b1b1;
  border-bottom: 1px dotted #b1b1b1;
}

.sun-editor .se-btn-list:focus, .sun-editor .se-btn-list:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-btn-list:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  -webkit-box-shadow: inset 0 3px 5px #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-list-layer.se-list-font-size {
  min-width: 140px;
  max-height: 300px;
}

.sun-editor .se-list-layer.se-list-font-family {
  min-width: 156px;
}

.sun-editor .se-list-layer.se-list-font-family .default {
  border-bottom: 1px solid #ccc;
}

.sun-editor .se-list-layer.se-list-line {
  width: 125px;
}

.sun-editor .se-list-layer.se-list-line hr {
  border-width: 1px 0 0;
  height: 1px;
}

.sun-editor .se-list-layer.se-list-align .se-list-inner {
  left: 9px;
}

.sun-editor .se-list-layer.se-list-format {
  min-width: 156px;
}

.sun-editor .se-list-layer.se-list-format li {
  padding: 0;
  width: 100%;
}

.sun-editor .se-list-layer.se-list-format ul .se-btn-list {
  line-height: 100%;
}

.sun-editor .se-list-layer.se-list-format ul .se-btn-list[data-value=h1] {
  height: 40px;
}

.sun-editor .se-list-layer.se-list-format ul .se-btn-list[data-value=h2] {
  height: 34px;
}

.sun-editor .se-list-layer.se-list-format ul p {
  font-size: 13px;
}

.sun-editor .se-list-layer.se-list-format ul div {
  font-size: 13px;
  padding: 4px 2px;
}

.sun-editor .se-list-layer.se-list-format ul h1 {
  font-size: 2em;
  font-weight: 700;
  color: #333;
}

.sun-editor .se-list-layer.se-list-format ul h2 {
  font-size: 1.5em;
  font-weight: 700;
  color: #333;
}

.sun-editor .se-list-layer.se-list-format ul h3 {
  font-size: 1.17em;
  font-weight: 700;
  color: #333;
}

.sun-editor .se-list-layer.se-list-format ul h4 {
  font-size: 1em;
  font-weight: 700;
  color: #333;
}

.sun-editor .se-list-layer.se-list-format ul h5 {
  font-size: 0.83em;
  font-weight: 700;
  color: #333;
}

.sun-editor .se-list-layer.se-list-format ul h6 {
  font-size: 0.67em;
  font-weight: 700;
  color: #333;
}

.sun-editor .se-list-layer.se-list-format ul blockquote {
  font-size: 13px;
  color: #999;
  height: 22px;
  margin: 0;
  background-color: transparent;
  line-height: 1.5;
  border-color: #b1b1b1;
  padding: 0 0 0 7px;
  border-left: 5px #b1b1b1;
  border-style: solid;
}

.sun-editor .se-list-layer.se-list-format ul pre {
  font-size: 13px;
  color: #666;
  padding: 4px 11px;
  margin: 0;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
}

.sun-editor .se-selector-table {
  display: none;
  position: absolute;
  top: 34px;
  left: 1px;
  z-index: 5;
  padding: 5px 0;
  float: left;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.sun-editor .se-selector-table .se-table-size {
  font-size: 18px;
  padding: 0 5px;
}

.sun-editor .se-selector-table .se-table-size-picker {
  position: absolute !important;
  z-index: 3;
  font-size: 18px;
  width: 10em;
  height: 10em;
  cursor: pointer;
}

.sun-editor .se-selector-table .se-table-size-highlighted {
  position: absolute !important;
  z-index: 2;
  font-size: 18px;
  width: 1em;
  height: 1em;
  background: url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAADJmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4QTZCNzMzN0I3RUYxMUU4ODcwQ0QwMjM1NTgzRTJDNyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4QTZCNzMzNkI3RUYxMUU4ODcwQ0QwMjM1NTgzRTJDNyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOCAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MzYyNEUxRUI3RUUxMUU4ODZGQzgwRjNBODgyNTdFOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MzYyNEUxRkI3RUUxMUU4ODZGQzgwRjNBODgyNTdFOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl0yAuwAAABBSURBVDhPY/wPBAxUAGCDGvdBeWSAeicIDTfIXREiQArYeR9hEBOEohyMGkQYjBpEGAxjg6ib+yFMygCVvMbAAABj0hwMTNeKJwAAAABJRU5ErkJggg==") repeat;
}

.sun-editor .se-selector-table .se-table-size-unhighlighted {
  position: relative !important;
  z-index: 1;
  font-size: 18px;
  width: 10em;
  height: 10em;
  background: url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat;
}

.sun-editor .se-selector-table .se-table-size-display {
  padding-left: 5px;
}

.sun-editor .se-list-layer.se-table-split {
  top: 36px;
}

.sun-editor .se-list-layer .se-selector-color {
  display: flex;
  width: max-content;
  max-width: 270px;
  height: auto;
  padding: 0;
  margin: auto;
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet {
  width: 100%;
  height: 100%;
  padding: 0;
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet li {
  display: flex;
  float: left;
  position: relative;
  margin: 0;
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet button {
  display: block;
  cursor: default;
  width: 30px;
  height: 30px;
  text-indent: -9999px;
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet button.active, .sun-editor .se-list-layer .se-selector-color .se-color-pallet button:focus, .sun-editor .se-list-layer .se-selector-color .se-color-pallet button:hover {
  border: 3px solid #fff;
}

.sun-editor .se-form-group {
  display: flex;
  width: 100%;
  min-height: 40px;
  height: auto;
  padding: 4px;
}

.sun-editor .se-form-group input {
  flex: auto;
  display: inline-block;
  width: auto;
  height: 33px;
  font-size: 12px;
  margin: 1px 0;
  padding: 0;
  border-radius: 0.25rem;
  border: 1px solid #ccc;
}

.sun-editor .se-form-group button, .sun-editor .se-submenu-form-group button {
  float: right;
  width: 34px;
  height: 34px;
  margin: 0 2px !important;
}

.sun-editor .se-form-group button.se-btn {
  border: 1px solid #ccc;
}

.sun-editor .se-form-group > div {
  position: relative;
}

.sun-editor .se-form-group label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.sun-editor .se-form-group-label {
  width: 100%;
  height: auto;
  padding: 0 4px;
}

.sun-editor .se-form-group-label label {
  font-size: 13px;
  font-weight: 700;
}

.sun-editor .se-submenu .se-form-group input {
  width: auto;
  height: 33px;
  color: #555;
}

.sun-editor .se-submenu .se-form-group .se-color-input {
  width: 72px;
  text-transform: uppercase;
  border: none;
  border-bottom: 2px solid #b1b1b1;
  outline: none;
}

.sun-editor .se-submenu .se-form-group .se-color-input:focus {
  border-bottom: 3px solid #b1b1b1;
}

.sun-editor .se-wrapper {
  position: relative !important;
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: 1;
}

.sun-editor .se-wrapper .se-wrapper-inner {
  width: 100%;
  height: 100%;
  min-height: 65px;
  overflow-y: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}

.sun-editor .se-wrapper .se-wrapper-inner:focus {
  outline: none;
}

.sun-editor .se-wrapper .se-wrapper-code {
  background-color: #191919;
  color: #fff;
  font-size: 13px;
  word-break: break-all;
  padding: 4px;
  margin: 0;
  resize: none !important;
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
  display: block;
}

.sun-editor .se-wrapper .se-wrapper-code-mirror {
  font-size: 13px;
}

.sun-editor .se-wrapper .se-placeholder {
  position: absolute;
  display: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 1;
  color: #b1b1b1;
  font-size: 13px;
  line-height: 1.5;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin-top: 0;
  padding-top: 16px;
  padding-left: 16px;
  margin-left: 0;
  padding-right: 16px;
  margin-right: 0;
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.sun-editor .se-resizing-bar {
  display: flex;
  width: auto;
  height: auto;
  min-height: 16px;
  border-top: 1px solid #dadada;
  padding: 0 4px;
  background-color: #fafafa;
  cursor: ns-resize;
}

.sun-editor .se-resizing-bar.se-resizing-none {
  cursor: default;
}

.sun-editor .se-resizing-back {
  position: absolute;
  display: none;
  cursor: default;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}

.sun-editor .se-resizing-bar .se-navigation {
  flex: auto;
  position: relative;
  width: auto;
  height: auto;
  color: #666;
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 1.5;
  background: transparent;
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper {
  flex: none;
  position: relative;
  display: block;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  color: #999;
  font-size: 13px;
  background: transparent;
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper.se-blink {
  color: #b94a48;
  animation: blinker 0.2s linear infinite;
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper .se-char-label {
  margin-right: 4px;
}

.sun-editor .se-dialog {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}

.sun-editor .se-dialog button, .sun-editor .se-dialog input, .sun-editor .se-dialog label {
  font-size: 14px;
  line-height: 1.5;
  color: #111;
  margin: 0;
}

.sun-editor .se-dialog .se-dialog-back {
  background-color: #222;
  opacity: 0.5;
}

.sun-editor .se-dialog .se-dialog-back, .sun-editor .se-dialog .se-dialog-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
  position: relative;
  width: auto;
  max-width: 500px;
  margin: 1.75rem auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 509px) {
  .sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
    width: 100%;
  }
}
.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
  display: inline-block;
  padding: 6px 12px;
  margin: 0 0 10px !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  border-radius: 4px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header {
  height: 50px;
  padding: 6px 15px;
  border-bottom: 1px solid #e5e5e5;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close {
  float: right;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  -webkit-appearance: none;
  filter: alpha(opacity=100);
  opacity: 1;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 2.5;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-body {
  position: relative;
  padding: 15px 15px 5px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form {
  margin-bottom: 10px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer {
  margin-top: 10px;
  margin-bottom: 0;
}

.sun-editor .se-dialog .se-dialog-inner input:disabled {
  background-color: #f3f3f3;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text {
  width: 100%;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text .size-h, .sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text .size-w {
  width: 70px;
  text-align: center;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-x {
  margin: 0 8px;
  width: 25px;
  text-align: center;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {
  height: auto;
  min-height: 55px;
  padding: 10px 15px 0;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div {
  float: left;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
  margin: 0 5px 0 0;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-radio {
  margin-left: 12px;
  margin-right: 6px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-check {
  margin-left: 12px;
  margin-right: 4px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer .se-dialog-btn-check {
  margin-left: 0;
  margin-right: 4px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer label:first-child {
  margin-right: 16px;
  margin-left: 0;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files {
  position: relative;
  display: flex;
  align-items: center;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files > input {
  flex: auto;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button {
  flex: auto;
  opacity: 0.8;
  border: 1px solid #ccc;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button.se-file-remove > svg {
  width: 8px;
  height: 8px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button:hover {
  background-color: #f0f0f0;
  outline: 0 none;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button:active {
  background-color: #e9e9e9;
  -webkit-box-shadow: inset 0 3px 5px #d6d6d6;
  box-shadow: inset 0 3px 5px #d6d6d6;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-select {
  display: inline-block;
  width: auto;
  height: 34px;
  font-size: 14px;
  text-align: center;
  line-height: 1.42857143;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control {
  display: inline-block;
  width: 70px;
  height: 34px;
  font-size: 14px;
  text-align: center;
  line-height: 1.42857143;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
  display: block;
  width: 100%;
  height: 34px;
  font-size: 14px;
  line-height: 1.42857143;
  padding: 0 4px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form.se-input-url {
  direction: ltr;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form.se-input-url:disabled {
  text-decoration: line-through;
  color: #999;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-video-ratio {
  width: 70px;
  margin-left: 4px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form a {
  color: #004cff;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert {
  border: 1px solid #ccc;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  -webkit-box-shadow: inset 0 3px 5px #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-dialog-tabs {
  width: 100%;
  height: 25px;
  border-bottom: 1px solid #e5e5e5;
}

.sun-editor .se-dialog-tabs button {
  background-color: #e5e5e5;
  border-right: 1px solid #e5e5e5;
  float: left;
  outline: none;
  padding: 2px 13px;
  transition: 0.3s;
}

.sun-editor .se-dialog-tabs button:hover {
  background-color: #fff;
}

.sun-editor .se-dialog-tabs button.active {
  background-color: #fff;
  border-bottom: 0;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form.se-math-exp {
  resize: vertical;
  height: 14em;
  border: 1px solid #ccc;
  font-size: 13px;
  padding: 4px;
  direction: ltr;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-select.se-math-size {
  width: 6em;
  height: 28px;
  margin-left: 1em;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview {
  font-size: 13px;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview > span {
  display: inline-block;
  -webkit-box-shadow: 0 0 0 0.1rem #c7deff;
  box-shadow: 0 0 0 0.1rem #c7deff;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview > span * {
  direction: ltr;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview > .se-math-katex-error {
  color: #b94a48;
  -webkit-box-shadow: 0 0 0 0.1rem #f2dede;
  box-shadow: 0 0 0 0.1rem #f2dede;
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview > .se-math-katex-error svg {
  width: auto;
  height: 30px;
  color: #b94a48;
}

.sun-editor .se-dialog .se-dialog-inner .se-link-preview {
  display: block;
  height: auto;
  max-height: 18px;
  font-size: 13px;
  font-weight: 400;
  font-family: inherit;
  color: #666;
  background-color: transparent;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: pre;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form {
  width: 100%;
  display: flex;
  margin-top: 4px;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form .se-svg.se-anchor-preview-icon {
  flex: unset;
  display: none;
  line-height: 1.5;
  color: #4592ff;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form .se-link-preview {
  flex: auto;
  margin: 0;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel {
  height: 34px;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-btn {
  width: 46px;
  color: #3f9dff;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-wrapper {
  display: flex;
  line-height: 1.5;
  padding-top: 6px;
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-preview {
  text-align: left;
}

.sun-editor .se-controller .se-arrow.se-arrow-up {
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.sun-editor .se-controller {
  position: absolute;
  display: none;
  overflow: visible;
  z-index: 6;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}

.sun-editor .se-controller .se-btn-group {
  position: relative;
  display: flex;
  vertical-align: middle;
  padding: 2px;
  top: 0;
  left: 0;
}

.sun-editor .se-controller .se-btn-group .se-btn-group-sub {
  left: 50%;
  min-width: auto;
  width: max-content;
  display: none;
}

.sun-editor .se-controller .se-btn-group .se-btn-group-sub button {
  margin: 0;
  min-width: 72px;
}

.sun-editor .se-controller .se-btn-group button {
  position: relative;
  min-height: 34px;
  height: auto;
  border: none;
  border-radius: 4px;
  margin: 1px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

.sun-editor .se-controller .se-btn-group button:focus:enabled, .sun-editor .se-controller .se-btn-group button:hover:enabled {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-controller .se-btn-group button:active:enabled {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  -webkit-box-shadow: inset 0 3px 5px #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-controller .se-btn-group button span {
  display: block;
  padding: 0;
  margin: 0;
}

.sun-editor .se-controller .se-btn-group button:enabled.active {
  color: #4592ff;
  outline: 0 none;
}

.sun-editor .se-controller .se-btn-group button:enabled.active:focus, .sun-editor .se-controller .se-btn-group button:enabled.active:hover {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-controller .se-btn-group button:enabled.active:active {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  -webkit-box-shadow: inset 0 3px 5px #c1c1c1;
  box-shadow: inset 0 3px 5px #c1c1c1;
}

.sun-editor .se-controller .se-btn-group button:enabled.on {
  background-color: #e1e1e1;
  border-color: #d1d1d1;
  outline: 0 none;
}

.sun-editor .se-controller .se-btn-group button:enabled.on:focus, .sun-editor .se-controller .se-btn-group button:enabled.on:hover {
  background-color: #d1d1d1;
  border-color: #c1c1c1;
  outline: 0 none;
}

.sun-editor .se-controller .se-btn-group button:enabled.on:active {
  background-color: #c1c1c1;
  border-color: #b1b1b1;
  -webkit-box-shadow: inset 0 3px 5px #b1b1b1;
  box-shadow: inset 0 3px 5px #b1b1b1;
}

.sun-editor .se-controller .se-form-group input {
  min-width: 120px;
}

.sun-editor .se-controller-resizing {
  margin-top: -50px !important;
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
}

.sun-editor .se-controller-resizing .se-btn-group .se-btn-group-sub.se-resizing-align-list {
  width: 74px;
}

.sun-editor .se-resizing-container {
  position: absolute;
  display: none;
  outline: 1px solid #3f9dff;
  background-color: transparent;
}

.sun-editor .se-resizing-container .se-modal-resize {
  position: absolute;
  display: inline-block;
  background-color: #3f9dff;
  opacity: 0.3;
}

.sun-editor .se-resizing-container .se-resize-dot {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sun-editor .se-resizing-container .se-resize-dot > span {
  position: absolute;
  width: 7px;
  height: 7px;
  background-color: #3f9dff;
  border: 1px solid #4592ff;
}

.sun-editor .se-resizing-container .se-resize-dot > span.tl {
  top: -5px;
  left: -5px;
  cursor: nw-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.tr {
  top: -5px;
  right: -5px;
  cursor: ne-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.bl {
  bottom: -5px;
  left: -5px;
  cursor: sw-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.br {
  right: -5px;
  bottom: -5px;
  cursor: se-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.lw {
  left: -7px;
  bottom: 50%;
  cursor: w-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.th {
  left: 50%;
  top: -7px;
  cursor: n-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.rw {
  right: -7px;
  bottom: 50%;
  cursor: e-resize;
}

.sun-editor .se-resizing-container .se-resize-dot > span.bh {
  right: 50%;
  bottom: -7px;
  cursor: s-resize;
}

.sun-editor .se-resizing-container .se-resize-display {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  color: #fff;
  background-color: #333;
  border-radius: 4px;
}

.sun-editor .se-controller-table, .sun-editor .se-controller-table-cell {
  width: auto;
}

.sun-editor .se-controller-link, .sun-editor .se-controller-table, .sun-editor .se-controller-table-cell {
  padding: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
}

.sun-editor .se-controller-link:after, .sun-editor .se-controller-link:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.sun-editor .se-controller-link .link-content {
  padding: 0;
  margin: 0;
}

.sun-editor .se-controller-link .link-content a {
  display: inline-block;
  color: #4592ff;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: 5px;
}

.sun-editor .se-select-list {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  width: auto;
  max-width: 100%;
  background-color: #fff;
  padding: 0;
  margin: 0;
  border: 1px solid #bababa;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0 none;
}

.sun-editor .se-select-list .se-select-item {
  line-height: 28px;
  min-height: 28px;
  font-size: 13px;
  padding: 0 5px;
  margin: 2px 0;
  cursor: pointer;
}

.sun-editor .se-select-list.__se_select-menu-mouse-move .se-select-item:hover, .sun-editor .se-select-list:not(.__se_select-menu-mouse-move) .se-select-item.active {
  background-color: #e1e1e1;
}

.sun-editor .se-dialog-form-files .se-select-list {
  width: 100%;
}

.sun-editor .se-file-browser {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483647;
}

.sun-editor .se-file-browser button, .sun-editor .se-file-browser input, .sun-editor .se-file-browser label {
  font-size: 14px;
  line-height: 1.5;
  color: #111;
  margin: 0;
}

.sun-editor .se-file-browser .se-file-browser-back {
  background-color: #222;
  opacity: 0.5;
}

.sun-editor .se-file-browser .se-file-browser-back, .sun-editor .se-file-browser .se-file-browser-inner {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
  position: relative;
  width: 960px;
  max-width: 100%;
  margin: 20px auto;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.sun-editor .se-file-browser .se-file-browser-header {
  height: auto;
  min-height: 50px;
  padding: 6px 15px;
  border-bottom: 1px solid #e5e5e5;
}

.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-close {
  float: right;
  font-weight: 700;
  text-shadow: 0 1px 0 #fff;
  -webkit-appearance: none;
  filter: alpha(opacity=100);
  opacity: 1;
}

.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-close > svg {
  width: 12px;
  height: 12px;
}

.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-title {
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 2.2;
}

.sun-editor .se-file-browser .se-file-browser-tags {
  display: block;
  width: 100%;
  padding: 0;
  text-align: left;
  margin: 0 -15px;
}

.sun-editor .se-file-browser .se-file-browser-tags a {
  display: inline-block;
  background-color: #f5f5f5;
  padding: 6px 12px;
  margin: 8px 0 8px 8px;
  color: #333;
  text-decoration: none;
  border-radius: 32px;
  -moz-border-radius: 32px;
  -webkit-border-radius: 32px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  cursor: pointer;
}

.sun-editor .se-file-browser .se-file-browser-tags a:hover {
  background-color: #e1e1e1;
}

.sun-editor .se-file-browser .se-file-browser-tags a:active {
  background-color: #d1d1d1;
}

.sun-editor .se-file-browser .se-file-browser-tags a.on {
  background-color: #ebf3fe;
  color: #4592ff;
}

.sun-editor .se-file-browser .se-file-browser-tags a.on:hover {
  background-color: #d8e8fe;
}

.sun-editor .se-file-browser .se-file-browser-tags a.on:active {
  background-color: #c7deff;
}

.sun-editor .se-file-browser .se-file-browser-body {
  position: relative;
  height: auto;
  min-height: 350px;
  padding: 20px;
  overflow-y: auto;
}

.sun-editor .se-file-browser .se-file-browser-body .se-file-browser-list {
  position: relative;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
    width: 748px;
  }
}
@media screen and (max-width: 768px) {
  .sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
    width: 600px;
  }
}
.sun-editor .se-file-browser .se-file-browser-list .se-file-item-column {
  position: relative;
  display: block;
  height: auto;
  float: left;
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-column {
  width: calc(25% - 20px);
  margin: 0 10px;
}

@media screen and (max-width: 992px) {
  .sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-column {
    width: calc(33% - 20px);
  }
}
@media screen and (max-width: 768px) {
  .sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-column {
    width: calc(50% - 20px);
  }
}
.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img {
  position: relative;
  display: block;
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 4px;
  outline: 0;
  margin: 10px 0;
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img:hover {
  opacity: 0.8;
  -webkit-box-shadow: 0 0 0 0.2rem #3288ff;
  box-shadow: 0 0 0 0.2rem #3288ff;
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img > img {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  outline: 0;
  height: auto;
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img > .se-file-img-name {
  position: absolute;
  z-index: 1;
  font-size: 13px;
  color: #fff;
  left: 0;
  bottom: 0;
  padding: 5px 10px;
  background-color: transparent;
  width: 100%;
  height: 30px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img > .se-file-img-name.se-file-name-back {
  background-color: #333;
  opacity: 0.6;
}

.sun-editor .se-notice {
  position: absolute;
  top: 0;
  display: none;
  z-index: 7;
  width: 100%;
  height: auto;
  word-break: break-all;
  font-size: 13px;
  color: #b94a48;
  background-color: #f2dede;
  padding: 15px;
  margin: 0;
  border: 1px solid #eed3d7;
  user-select: auto;
  -o-user-select: auto;
  -moz-user-select: auto;
  -khtml-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
}

.sun-editor .se-notice button {
  float: right;
  padding: 7px;
}

.sun-editor .se-tooltip {
  position: relative;
  overflow: visible;
}

.sun-editor .se-tooltip .se-tooltip-inner {
  visibility: hidden;
  position: absolute;
  display: block;
  width: auto;
  height: auto;
  top: 120%;
  left: 50%;
  background: transparent;
  opacity: 0;
  z-index: 1;
  line-height: 1.5;
  transition: opacity 0.5s;
  margin: 0;
  padding: 0;
  bottom: auto;
  float: none;
  pointer-events: none;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  left: -50%;
  font-size: 0.9em;
  margin: 0;
  padding: 4px 6px;
  border-radius: 2px;
  background-color: #333;
  color: #fff;
  text-align: center;
  line-height: unset;
  white-space: nowrap;
  cursor: auto;
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text:after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border: 5px solid transparent;
  border-bottom-color: #333;
}

.sun-editor .se-tooltip:hover .se-tooltip-inner {
  visibility: visible;
  opacity: 1;
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut {
  display: block !important;
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut > .se-shortcut-key {
  display: inline;
  font-weight: 700;
}

.sun-editor.se-rtl .se-btn-tray {
  direction: rtl;
}

.sun-editor.se-rtl .se-btn-select svg {
  margin: auto 1px;
}

.sun-editor.se-rtl .se-btn-select .txt {
  flex: auto;
  text-align: right;
  direction: rtl;
}

.sun-editor.se-rtl .se-btn-list {
  text-align: right;
}

.sun-editor.se-rtl .se-btn-list > .se-list-icon {
  margin: -1px 0 0 10px;
}

.sun-editor.se-rtl .se-menu-list:not(.se-menu-dir-fix), .sun-editor.se-rtl .se-menu-list:not(.se-menu-dir-fix) li {
  float: right;
}

.sun-editor.se-rtl .se-list-layer * {
  direction: rtl;
}

.sun-editor.se-rtl .se-list-layer.se-list-format ul blockquote {
  padding: 0 7px 0 0;
  border-right-width: 5px;
  border-left-width: 0;
}

.sun-editor.se-rtl .se-list-layer .se-selector-color .se-color-pallet li {
  float: right;
}

.sun-editor.se-rtl .se-list-inner .se-list-checked li button > .se-svg {
  float: right;
  padding: 6px 0 0 6px;
}

.sun-editor.se-rtl .se-tooltip .se-tooltip-inner .se-tooltip-text, .sun-editor.se-rtl .se-wrapper .se-placeholder {
  direction: rtl;
}

.sun-editor.se-rtl .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut {
  direction: ltr;
}

.sun-editor.se-rtl .se-dialog * {
  direction: rtl;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-form .se-video-ratio {
  margin-left: 0;
  margin-right: 4px;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close {
  float: left;
}

.sun-editor.se-rtl .se-dialog-tabs button, .sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
  float: right;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-size-text {
  padding-right: 34px;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer .se-btn-primary {
  float: left;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer > div {
  float: right;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
  margin: 0 0 0 5px;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-form-footer label:first-child {
  margin-left: 16px;
  margin-right: 0;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-anchor-rel-preview {
  margin-left: 4px;
  text-align: right;
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-anchor-rel-btn {
  float: right;
}

.sun-editor.se-rtl .se-file-browser * {
  direction: rtl;
}

.sun-editor.se-rtl .se-file-browser .se-file-browser-tags {
  text-align: right;
}

.sun-editor.se-rtl .se-file-browser .se-file-browser-tags a {
  margin: 8px 8px 0;
}

.sun-editor.se-rtl .se-file-browser .se-file-browser-header .se-file-browser-close {
  float: left;
}

.sun-editor.se-rtl .se-controller .se-btn-group, .sun-editor.se-rtl .se-resizing-container .se-resize-display {
  direction: rtl;
}

.sun-editor .se-btn-module-border.module-float-left {
  float: left;
}

.sun-editor .se-btn-module-border.module-float-right {
  float: right;
}

.sun-editor .se-error {
  color: #d9534f;
}

.sun-editor input.se-error:focus, select.se-error:focus, textarea.se-error:focus {
  border: 1px solid #f2dede;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #eed3d7;
  box-shadow: 0 0 0 0.2rem #eed3d7;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sun-editor hr.__se__solid {
  border-style: solid none none;
}

.sun-editor hr.__se__dotted {
  border-style: dotted none none;
}

.sun-editor hr.__se__dashed {
  border-style: dashed none none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@keyframes spinner {
  to {
    transform: rotate(361deg);
  }
}
.sun-editor-editable {
  font-family: Helvetica Neue;
  font-size: 13px;
  color: #333;
  background-color: #fff;
  line-height: 1.5;
  word-break: normal;
  word-wrap: break-word;
  padding: 16px;
  margin: 0;
}

.sun-editor-editable * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
}

.sun-editor-editable.se-rtl * {
  direction: rtl;
}

.sun-editor-editable .se-component > figure {
  direction: ltr;
}

.sun-editor-editable audio, .sun-editor-editable figcaption, .sun-editor-editable figure, .sun-editor-editable iframe, .sun-editor-editable img, .sun-editor-editable td, .sun-editor-editable th, .sun-editor-editable video {
  position: relative;
}

.sun-editor-editable span {
  display: inline;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

.sun-editor-editable span.katex {
  display: inline-block;
}

.sun-editor-editable span.katex * {
  direction: ltr;
}

.sun-editor-editable a {
  color: #004cff;
  text-decoration: none;
}

.sun-editor-editable span[style~="color:"] a {
  color: inherit;
}

.sun-editor-editable a:focus, .sun-editor-editable a:hover {
  cursor: pointer;
  color: #0093ff;
  text-decoration: underline;
}

.sun-editor-editable a.on {
  color: #0093ff;
  background-color: #e8f7ff;
}

.sun-editor-editable pre {
  display: block;
  padding: 8px;
  margin: 0 0 10px;
  font-family: monospace;
  color: #666;
  line-height: 1.45;
  background-color: #f9f9f9;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  white-space: pre-wrap !important;
  word-wrap: break-word;
  overflow: visible;
}

.sun-editor-editable ol {
  list-style-type: decimal;
}

.sun-editor-editable ol, .sun-editor-editable ul {
  list-style-position: outside;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 40px;
}

.sun-editor-editable ul {
  list-style-type: disc;
}

.sun-editor-editable li {
  display: list-item;
  text-align: -webkit-match-parent;
  margin-bottom: 5px;
}

.sun-editor-editable ol ol, .sun-editor-editable ol ul, .sun-editor-editable ul ol, .sun-editor-editable ul ul {
  margin: 0;
}

.sun-editor-editable ol ol, .sun-editor-editable ul ol {
  list-style-type: lower-alpha;
}

.sun-editor-editable ol ol ol, .sun-editor-editable ul ol ol, .sun-editor-editable ul ul ol {
  list-style-type: upper-roman;
}

.sun-editor-editable ol ul, .sun-editor-editable ul ul {
  list-style-type: circle;
}

.sun-editor-editable ol ol ul, .sun-editor-editable ol ul ul, .sun-editor-editable ul ul ul {
  list-style-type: square;
}

.sun-editor-editable sub, .sun-editor-editable sup {
  font-size: 75%;
  line-height: 0;
}

.sun-editor-editable sub {
  vertical-align: sub;
}

.sun-editor-editable sup {
  vertical-align: super;
}

.sun-editor-editable p {
  display: block;
  margin: 0 0 10px;
}

.sun-editor-editable div {
  display: block;
  margin: 0;
  padding: 0;
}

.sun-editor-editable blockquote {
  display: block;
  font-family: inherit;
  font-size: inherit;
  color: #999;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0 5px 0 20px;
  border: solid #b1b1b1;
  border-width: 0 0 0 5px;
}

.sun-editor-editable blockquote blockquote {
  border-color: #c1c1c1;
}

.sun-editor-editable blockquote blockquote blockquote {
  border-color: #d1d1d1;
}

.sun-editor-editable blockquote blockquote blockquote blockquote {
  border-color: #e1e1e1;
}

.sun-editor-editable.se-rtl blockquote {
  padding-left: 5px;
  padding-right: 20px;
  border-left-width: 0;
  border-right-width: 5px;
}

.sun-editor-editable h1 {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

.sun-editor-editable h1, .sun-editor-editable h2 {
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 700;
}

.sun-editor-editable h2 {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

.sun-editor-editable h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.sun-editor-editable h3, .sun-editor-editable h4 {
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 700;
}

.sun-editor-editable h4 {
  font-size: 1em;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

.sun-editor-editable h5 {
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

.sun-editor-editable h5, .sun-editor-editable h6 {
  display: block;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: 700;
}

.sun-editor-editable h6 {
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

.sun-editor-editable hr {
  display: flex;
  border-width: 1px 0 0;
  border-color: #000;
  border-image: initial;
  height: 1px;
}

.sun-editor-editable hr.__se__solid {
  border-style: solid none none;
}

.sun-editor-editable hr.__se__dotted {
  border-style: dotted none none;
}

.sun-editor-editable hr.__se__dashed {
  border-style: dashed none none;
}

.sun-editor-editable hr.on {
  border-color: #4592ff;
  -webkit-box-shadow: 0 0 0 0.1rem #c7deff;
  box-shadow: 0 0 0 0.1rem #c7deff;
}

.sun-editor-editable table {
  display: table;
  table-layout: auto !important;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 100%;
  margin: 0 0 10px;
  background-color: transparent;
  border-spacing: 0;
  border-collapse: collapse;
}

.sun-editor-editable.se-rtl table {
  margin: 0 0 10px auto;
}

.sun-editor-editable table thead {
  border-bottom: 2px solid #333;
}

.sun-editor-editable table tr {
  border: 1px solid #efefef;
}

.sun-editor-editable table th {
  background-color: #f3f3f3;
}

.sun-editor-editable table td, .sun-editor-editable table th {
  border: 1px solid #e1e1e1;
  padding: 0.4em;
  background-clip: padding-box;
}

.sun-editor-editable table.se-table-size-auto {
  width: auto !important;
}

.sun-editor-editable table.se-table-size-100 {
  width: 100% !important;
}

.sun-editor-editable table.se-table-layout-auto {
  table-layout: auto !important;
}

.sun-editor-editable table.se-table-layout-fixed {
  table-layout: fixed !important;
}

.sun-editor-editable table td.se-table-selected-cell, .sun-editor-editable table th.se-table-selected-cell {
  outline: 1px double #4592ff;
}

.sun-editor-editable.se-disabled * {
  user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.sun-editor-editable .se-component {
  display: flex;
  padding: 1px;
  margin: 0 0 10px;
}

.sun-editor-editable[contenteditable=true] .se-component {
  outline: 1px dashed #e1e1e1;
}

.sun-editor-editable[contenteditable=true] .se-component.se-component-copy {
  -webkit-box-shadow: 0 0 0 0.2rem #80bdff;
  box-shadow: 0 0 0 0.2rem #3f9dff;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sun-editor-editable .__se__float-left {
  float: left;
  margin-right: 4px;
}

.sun-editor-editable .__se__float-right {
  float: right;
  margin-left: 4px;
}

.sun-editor-editable .__se__float-center {
  float: center;
}

.sun-editor-editable .__se__float-none {
  float: none;
}

.sun-editor-editable audio, .sun-editor-editable iframe, .sun-editor-editable img, .sun-editor-editable video {
  display: block;
  margin: 0;
  padding: 0;
  width: auto;
  height: auto;
  max-width: 100%;
}

.sun-editor-editable[contenteditable=true]:not(.se-read-only) figure:after {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  display: block;
  background: transparent;
}

.sun-editor-editable[contenteditable=true] figure a, .sun-editor-editable[contenteditable=true] figure iframe, .sun-editor-editable[contenteditable=true] figure img, .sun-editor-editable[contenteditable=true] figure video {
  z-index: 0;
}

.sun-editor-editable[contenteditable=true] figure figcaption {
  display: block;
  z-index: 2;
}

.sun-editor-editable[contenteditable=true] figure figcaption:focus {
  border-color: #80bdff;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #c7deff;
  box-shadow: 0 0 0 0.2rem #c7deff;
}

.sun-editor-editable .se-image-container, .sun-editor-editable .se-video-container {
  width: auto;
  height: auto;
  max-width: 100%;
}

.sun-editor-editable figure {
  display: block;
  outline: none;
  padding: 0;
  margin: 0;
}

.sun-editor-editable .__se__float-center figure, .sun-editor-editable .__se__float-left figure, .sun-editor-editable .__se__float-right figure {
  margin: auto !important;
}

.sun-editor-editable figure figcaption {
  padding: 1em 0.5em;
  margin: 0;
  background-color: #f9f9f9;
  outline: none;
}

.sun-editor-editable figure figcaption p {
  line-height: 2;
  margin: 0;
}

.sun-editor-editable .se-image-container a img {
  padding: 1px;
  margin: 1px;
  outline: 1px solid #4592ff;
}

.sun-editor-editable .se-video-container iframe, .sun-editor-editable .se-video-container video {
  outline: 1px solid #9e9e9e;
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  width: 100%;
  height: 100%;
}

.sun-editor-editable .se-video-container figure {
  left: 0;
  width: 100%;
  max-width: 100%;
}

.sun-editor-editable audio {
  width: 300px;
  height: 54px;
}

.sun-editor-editable audio.active {
  outline: 2px solid #80bdff;
}

.sun-editor-editable.se-show-block div, .sun-editor-editable.se-show-block h1, .sun-editor-editable.se-show-block h2, .sun-editor-editable.se-show-block h3, .sun-editor-editable.se-show-block h4, .sun-editor-editable.se-show-block h5, .sun-editor-editable.se-show-block h6, .sun-editor-editable.se-show-block li, .sun-editor-editable.se-show-block ol, .sun-editor-editable.se-show-block p, .sun-editor-editable.se-show-block pre, .sun-editor-editable.se-show-block ul {
  border: 1px dashed #3f9dff !important;
  padding: 14px 8px 8px !important;
}

.sun-editor-editable.se-show-block ol, .sun-editor-editable.se-show-block ul {
  border: 1px dashed #d539ff !important;
}

.sun-editor-editable.se-show-block pre {
  border: 1px dashed #27c022 !important;
}

.se-show-block p {
  padding: 0;
}

.se-show-block li {
  background: url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA7SURBVDhPYxgFcNDQ0PAfykQBIHEYhgoRB/BpwCfHBKWpBkaggYxQGgOgBzyQD1aLLA4TGwWDGjAwAACR3RcEU9Ui+wAAAABJRU5ErkJggg==") no-repeat;
}

.se-show-block ol {
  background: url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABHSURBVDhPYxgFcNDQ0PAfhKFcFIBLHCdA1oBNM0kGEmMAPgOZoDTVANUNxAqQvURMECADRiiNAWCagDSGGhyW4DRrMAEGBgAu0SX6WpGgjAAAAABJRU5ErkJggg==") no-repeat;
}

.se-show-block ul {
  background: url("data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVDhPYxgFDA0NDf+hTBSALI5LDQgwQWmqgVEDKQcsUBoF4ItFGEBXA+QzQpmDGjAwAAA8DQ4Lni6gdAAAAABJRU5ErkJggg==") no-repeat;
}

.sun-editor-editable .__se__p-bordered, .sun-editor .__se__p-bordered {
  border-top: 1px solid #b1b1b1;
  border-bottom: 1px solid #b1b1b1;
  padding: 4px 0;
}

.sun-editor-editable .__se__p-spaced, .sun-editor .__se__p-spaced {
  letter-spacing: 1px;
}

.sun-editor-editable .__se__p-neon, .sun-editor .__se__p-neon {
  font-weight: 200;
  font-style: italic;
  background: #000;
  color: #fff;
  padding: 6px 4px;
  border: 2px solid #fff;
  border-radius: 6px;
  text-transform: uppercase;
  animation: neonFlicker 1.5s infinite alternate;
}

@keyframes neonFlicker {
  0%, 19%, 21%, 23%, 25%, 54%, 56%, to {
    text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff, 0 0 2px #f40, 0 0 4px #f40, 0 0 6px #f40, 0 0 8px #f40, 0 0 10px #f40;
    box-shadow: 0 0 0.5px #fff, inset 0 0 0.5px #fff, 0 0 2px #08f, inset 0 0 2px #08f, 0 0 4px #08f, inset 0 0 4px #08f;
  }
  20%, 24%, 55% {
    text-shadow: none;
    box-shadow: none;
  }
}
.sun-editor-editable .__se__t-shadow, .sun-editor .__se__t-shadow {
  text-shadow: -0.2rem -0.2rem 1rem #fff, 0.2rem 0.2rem 1rem #fff, 0 0 0.2rem #999, 0 0 0.4rem #888, 0 0 0.6rem #777, 0 0 0.8rem #666, 0 0 1rem #555;
}

.sun-editor-editable .__se__t-code, .sun-editor .__se__t-code {
  font-family: monospace;
  color: #666;
  background-color: rgba(27, 31, 35, 0.05);
  border-radius: 6px;
  padding: 0.2em 0.4em;
}

.branded-text {
  color: var(--brand-color);
}

.branded-background {
  background-color: var(--brand-color);
}

/*@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 500;
  src: local('Poppins'), url('../fonts/Poppins-Medium.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  src: local('Poppins'), url('../fonts/Poppins-Regular.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 300;
  src: local('Poppins'), url('../fonts/Poppins-Light.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  src: local('Poppins'), url('../fonts/Poppins-SemiBold.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins', sans-serif;
  font-style: italic;
  font-weight: 400;
  src: local('Poppins'), url('../fonts/Poppins-Italic.ttf') format('ttf');
}
@font-face {
  font-family: 'Poppins-Bold', sans-serif;
  font-style: normal;
  font-weight: 700;
  src: local('Poppins-Bold'), url('../fonts/Poppins-Bold.ttf') format('ttf');
}*/
:root {
  --brand-color: #12aeb4;
  --color-grey: #7d8592;
}

body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-image: url("../images/body-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 50%;
}

input.form-control,
select.form-control,
input.form-control:focus,
select.form-control:focus {
  background-color: #EBF8FF;
  color: #222;
  border-color: #EBF8FF;
  font-size: 14px;
  line-height: 21px;
  height: 52px;
}

.errormessage,
.errorMessage {
  color: red;
  font-size: 10px;
}

#login .custom-control-label::before,
#register .custom-control-label::before {
  border-color: #00AEB3;
}

#login .custom-checkbox .custom-control-label::before,
#register .custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

#login .custom-control-input:checked ~ .custom-control-label::before,
#register .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #00AEB3;
  background-color: #00AEB3;
}

.btn-info {
  background-color: #00AEB3;
  width: 184px;
  height: 52px;
}

#banner,
#login,
#register,
#forgot,
#create-password,
#account-verification,
#welcome,
#tour,
#membership,
#multiple-membership,
#user-membership-login,
#user-welcome {
  height: 100vh;
}

#login .card,
#register .card,
#forgot .card,
#create-password .card,
#account-verification .card,
#welcome .card,
#tour .card,
#membership .card,
#multiple-membership .card,
#user-membership-login .card,
#user-welcome .card {
  box-shadow: 0px 3px 17px rgba(0, 0, 0, 0.1607843137);
  border-radius: 0;
}

#login .card-body {
  padding: 42px 67px;
}

#login .login-title,
#register .register-title {
  color: #172B4D;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-bottom: 0px;
}

#login .login-text {
  color: #7A869A;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 5px;
}

#login .login-msg,
#register .register-msg {
  color: #7A869A;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 25px;
}

#login #otp input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 50px;
  height: 52px;
  border-radius: 16px;
  background-color: #fff;
  border-color: #C7C7C7;
  color: #31373E;
  font-size: 23px;
  line-height: 36px;
}

#login #otp input.form-control:focus,
#login #otp input.form-control.active {
  background-color: #fff;
  border-color: #00AEB3;
  box-shadow: none;
}

#login .login-or {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

#login .login-msg2 {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 17px;
}

#login .login-msg2 .join {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

#login .login-msg3 {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 17px;
}

#login .login-msg3 .resend {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

#login input[type=password],
#register input[type=password] {
  border-right: none;
}

#login .input-group-text,
#register .input-group-text {
  background-color: #EBF8FF;
  border-left: none;
  border-color: #EBF8FF;
}

#login .form-check .form-check-label,
#register .form-check .form-check-label {
  color: #5A5A5A;
  font-size: 13px;
  line-height: 22px;
}

#login .forgot-password {
  color: #00AEB3;
  font-size: 13px;
  line-height: 22px;
}

#login .custom-control-label,
#register .custom-control-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #5A5A5A;
}

#login .btn-info {
  margin-top: 25px;
  margin-bottom: 13px;
}

#register .card-body {
  padding: 42px 67px 30px;
}

#register .btn-info {
  margin-top: 40px;
  margin-bottom: 35px;
}

#register .footer-text {
  color: #5A5A5A;
  font-size: 13px;
  line-height: 22px;
  margin-bottom: 0px;
}

#forgot .card-body {
  padding: 51px 67px 60px;
}

#forgot .forgot-title {
  color: #172B4D;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 23px;
}

#forgot .forgot-msg {
  color: #7A7A7A;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 15px;
}

#forgot .btn-info {
  margin-top: 52px;
}

#create-password .card-body {
  padding: 51px 67px 60px;
}

#create-password .create-title {
  color: #172B4D;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 23px;
}

#create-password .input-group-text {
  background-color: #EBF8FF;
  border-left: none;
  border-color: #EBF8FF;
}

#create-password .btn-info {
  margin-top: 52px;
}

#account-verification .card-body {
  padding: 51px 67px 60px;
}

#account-verification .verification-title {
  color: #172B4D;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  margin-top: 24px;
  margin-bottom: 18px;
}

#account-verification .verification-msg {
  color: #7A7A7A;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 20px;
}

#account-verification .verification-msg a {
  color: #00AEB3;
}

#account-verification input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 50px;
  height: 52px;
  border-radius: 16px;
  background-color: #fff;
  border-color: #C7C7C7;
  color: #31373E;
  font-size: 23px;
  line-height: 36px;
}

#account-verification input.form-control:focus {
  background-color: #fff;
  border-color: #00AEB3;
  box-shadow: none;
}

#account-verification .btn-info {
  margin-top: 51px;
}

#verifiedModal .modal-body {
  padding: 0px 44px;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1607843137);
}

#verifiedModal .modal-content {
  border-radius: 14px;
}

#verifiedModal .modal-body img {
  margin-top: -42px;
  width: 115px;
}

#verifiedModal .title {
  color: #00AEB3;
  font-size: 21px;
  line-height: 41px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 18px;
}

#verifiedModal .text {
  color: #342A2A;
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 35px;
}

#welcome .card-body {
  padding: 88px 92px 49px;
}

#welcome .welcome-title {
  color: #172B4D;
  font-size: 26px;
  line-height: 41px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 16px;
  margin-bottom: 16px;
}

#welcome .welcome-text {
  color: #7A869A;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

#welcome .company-info {
  background-color: #7284CC;
  border: 1px solid #E3E3E3;
  border-radius: 20px;
  padding: 11px;
  margin: 8px auto 39px;
}

#welcome .company-info .company-name {
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}

#welcome .company-info .company-name small {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.87px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

#tour .card-body {
  padding: 30px 45px 49px;
}

#tour .tour-title {
  color: #37474F;
  font-size: 26px;
  line-height: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-top: 41px;
  margin-bottom: 34px;
}

#tour .tour-text {
  color: #828386;
  font-size: 17px;
  line-height: 32px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 39px;
}

#tour .tour-slider .owl-nav {
  position: absolute;
  top: -30px;
  right: -25px;
}

#tour .tour-slider .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

#tour .tour-slider .owl-nav [class*=owl-]:hover {
  background: none;
}

#tour .tour-slider .owl-dots .owl-dot.active span {
  background: #8FD0D5;
  width: 20px;
  height: 12px;
}

#membership .card-body {
  padding: 27px 49px 65px 49px;
}

#membership .membership-subtitle {
  color: #192D46;
  font-size: 18px;
  line-height: 27px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin-bottom: 21px;
}

#membership .membership-title {
  color: #333244;
  font-size: 24px;
  line-height: 35px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 0px;
}

#membership .membership-text {
  color: #707070;
  font-size: 15px;
  line-height: 23px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 23px;
}

#membership .card-body label {
  color: #506278;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

#membership .card-body label span {
  color: #BEBEBE;
  font-size: 12px;
  line-height: 18px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

#membership .card-body .btn-preview {
  background-color: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  color: #fff;
  height: 57px;
}

#membership .card-body .color-picker {
  width: 65px;
}

#membership .card {
  background-image: url("../images/pen.svg");
  background-repeat: no-repeat;
  background-size: auto;
  background-position: right bottom;
}

#preview_img {
  background-color: #EBF7F6;
  height: 194px;
}

#choose-file {
  display: none;
  visibility: hidden;
}

.upload-file {
  position: absolute;
  right: 0px;
  top: 10px;
}

/* Dashboard css */
body.sidebar-mini {
  background-image: none;
}

body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
  background-color: #8fd0d5;
  margin-left: 265px;
  padding-left: 35px;
}

.sidebar-mini.sidebar-collapse .main-header {
  margin-left: 2.5rem !important;
}

.main-sidebar .company-info {
  background-color: #7284CC;
  padding: 11px 5px;
  margin: 8px 20px 62px;
  display: flex;
}

.main-sidebar .company-info .company-name {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
}

.main-sidebar .company-info .company-name small {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.13px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.user-name {
  line-height: 12px;
  color: #fff;
  margin-bottom: 0px;
}

.nav-sidebar .dashboard {
  background-image: url("../images/dashboard/home.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .step {
  background-image: url("../images/dashboard/3step.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .member {
  background-image: url("../images/dashboard/member.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  padding: 10px;
}

.nav-sidebar .support {
  display: none;
}

[class*=sidebar-dark-] .nav-sidebar .nav-item.menu-open .dashboard,
[class*=sidebar-dark-] .nav-sidebar .nav-item:hover .dashboard,
[class*=sidebar-dark-] .nav-sidebar .nav-link.active .dashboard,
[class*=sidebar-dark-] .nav-sidebar .nav-item .dashboard:focus {
  background-image: url("../images/dashboard/home-hover.svg") !important;
}

[class*=sidebar-dark-] .nav-sidebar .nav-item.menu-open .step,
[class*=sidebar-dark-] .nav-sidebar .nav-item:hover .step,
[class*=sidebar-dark-] .nav-sidebar .nav-link.active .step,
[class*=sidebar-dark-] .nav-sidebar .nav-item .step:focus {
  background-image: url("../images/dashboard/3step-hover.svg") !important;
}

.sidebar-mini .main-sidebar .collapse-sidebar {
  width: 95%;
  color: #fff;
  text-align: right;
  font-size: 20px;
}

.sidebar-mini.sidebar-collapse .main-sidebar .collapse-sidebar {
  width: 85%;
}

.sidebar-mini.sidebar-collapse .main-sidebar .nav-sidebar .support {
  display: block;
}

.navbar-expand .navbar-nav .nav-link {
  color: #fff;
}

.navbar-expand .navbar-nav .nav-link i {
  font-size: 25px;
}

.download-link {
  background-color: #E4FEFF;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
}

.download-link span {
  color: #7D8592;
  font-size: 15px;
  line-height: 23px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.daterange input {
  width: 55%;
  background-image: url("../images/dashboard/Path 177.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 26px;
}

.calendar.card,
.active-users.card {
  box-shadow: 0px 3px 22px #E2E9F2;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
}

.events.card,
.activity.card,
.messages.card {
  box-shadow: 0px 3px 24px #D7F1F2;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
}

.calendar .day {
  background-color: rgba(18, 174, 180, 0.1568627451);
  color: #12AEB4;
  border-radius: 4px;
  padding: 25px 10px;
  /*display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;*/
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 55px;
  height: 97px;
  margin: 0 10px;
  margin-bottom: 10px;
}

.calendar .day span:first-child {
  font-size: 16px;
  line-height: 25px;
}

.calendar .day span:nth-child(2) {
  font-size: 14px;
  line-height: 21px;
}

.calendar .day.active {
  background-color: #12AEB4;
  color: #fff;
}

.calendar .dots {
  background-color: #A0DBDD;
  width: 7px;
  height: 7px;
  border-radius: 10px;
  padding: 0px 4px;
  font-size: 6px;
}

.calendar .dots.active {
  background-color: #12AEB4;
}

.events .card-title,
.activity .card-title,
.active-users .card-title,
.calendar .card-title,
.messages .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.events .card-tools,
.activity .card-tools,
.active-users .card-tools {
  color: #12AEB4;
}

.events .event-list {
  padding: 15px;
}

.event-odd {
  background-color: #F2FEFF;
}

.event-list h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 19px;
}

.event-list p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #707070;
}

.activity-list .media {
  margin-bottom: 24px;
}

.activity-list h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 37px;
  color: #333244;
  margin-bottom: 0px;
}

.activity-list p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #707070;
  margin-bottom: 0px;
}

.activity-list .activity-status {
  background-color: #F2FEFF;
  border-radius: 14px;
  padding: 21px 30px;
}

.activity-list .activity-status p {
  color: #0A1629;
  font-size: 16px;
  line-height: 24px;
}

.messages .search {
  background-color: #fff;
  margin-bottom: 32px;
}

.messages .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #12AEB4;
}

.messages .messages-list {
  max-height: 890px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
}

.messages-list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.messages-list::-webkit-scrollbar-thumb {
  background: #8D9296;
  border-radius: 10px;
  height: 50px;
}

.messages-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.messages-list .media {
  border-bottom: 1px solid #E8E8E8;
  padding: 10px;
}

.messages-list .media:hover {
  background-color: #F4F9FD;
}

.messages .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #333244;
}

.messages .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 29px;
  color: #7D8592;
}

.messages .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #91929E;
}

.messages .msg-count {
  background-color: #12AEB4;
  border: 2px solid #FFFFFF;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

/*3steps main page css*/
.steps-mainpage .margin-left {
  margin-left: 6%;
}

.steps-mainpage .welcome-info {
  box-shadow: 0px 3px 17px #C7DCDA;
  border-radius: 6px;
}

.steps-mainpage .welcome-info h5 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
}

.steps-mainpage .welcome-info p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: #000000;
}

.steps-mainpage .welcome-info .company-logo {
  background-color: #8fd0d5;
}

.steps-mainpage .plus-icon,
.steps-mainpage .plus-selected {
  position: absolute;
  right: -15px;
  top: -15px;
}

.steps-mainpage .edit,
.steps-mainpage .engage,
.steps-mainpage .promote {
  box-shadow: 0px 10px 20px rgba(64, 117, 205, 0.0784313725);
  border: 1px solid #12AEB4;
  border-radius: 6px;
  margin-bottom: 45px;
  min-height: 175px;
}

.steps-mainpage .edit:hover,
.steps-mainpage .engage:hover,
.steps-mainpage .promote:hover {
  box-shadow: 0px 10px 20px #12AEB4;
  border-radius: 6px;
}

.steps-mainpage .edit .plus-selected,
.steps-mainpage .engage .plus-selected,
.steps-mainpage .promote .plus-selected {
  display: none;
}

.steps-mainpage .edit:hover .plus-icon,
.steps-mainpage .engage:hover .plus-icon,
.steps-mainpage .promote:hover .plus-icon {
  display: none;
}

.steps-mainpage .edit:hover .plus-selected,
.steps-mainpage .engage:hover .plus-selected,
.steps-mainpage .promote:hover .plus-selected {
  display: block;
}

.steps-mainpage .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #12AEB4;
  margin-bottom: 11px;
}

.steps-mainpage .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7A869A;
  margin-bottom: 11px;
}

.steps-mainpage .edit-list,
.steps-mainpage .engage-list,
.steps-mainpage .promote-list {
  background-color: #A0DBDD;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 20px;
}

.steps-mainpage .edit-list .title,
.steps-mainpage .engage-list .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12AEB4;
  margin-bottom: 1px;
}

.steps-mainpage .edit-list .text,
.steps-mainpage .engage-list .text {
  color: #FFFFFF;
  margin-bottom: 0px;
}

.steps-mainpage .promote-list .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #172B4D;
  margin-bottom: 6px;
}

.steps-mainpage .promote-list .link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #12AEB4;
}

.steps-mainpage .promote-list .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #fff;
}

.promote-list .text ol {
  list-style-position: outside;
  padding-left: 12px;
  padding-top: 20px;
}

.steps-mainpage .promote-list:last-child {
  padding: 17px 20px 7px 34px;
}

.steps-mainpage .edit-list:hover,
.steps-mainpage .engage-list:hover,
.steps-mainpage .promote-list:hover {
  background-color: #333244;
}

.steps-mainpage .edit-list:hover .title,
.steps-mainpage .engage-list:hover .title,
.steps-mainpage .promote-list:hover .title,
.steps-mainpage .edit-list:hover .text,
.steps-mainpage .engage-list:hover .text,
.steps-mainpage .promote-list:hover .text,
.steps-mainpage .promote-list:hover .link {
  color: #fff !important;
}

/* Admin create membership level modal */
#createMembershipLevelModal .modal-content,
#membershipBillingOptionsModal .modal-content,
#renewalAndSuceessModal .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
}

#createMembershipLevelModal .modal-body,
#membershipBillingOptionsModal .modal-body {
  padding: 39px 39px 42px 48px;
}

#createMembershipLevelModal .modal-body .go-back,
#membershipBillingOptionsModal .modal-body .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
  margin-bottom: 22px;
}

#createMembershipLevelModal .form-group,
#membershipBillingOptionsModal .form-group {
  margin-bottom: 30px;
}

#createMembershipLevelModal .form-control,
#membershipBillingOptionsModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #3c3d43;
  background-color: #EBF7F6;
  border-color: #EBF7F6;
  border-radius: 4px;
  padding-left: 35px;
}

#createMembershipLevelModal input.form-control,
#membershipBillingOptionsModal input.form-control {
  height: 58px;
}

#createMembershipLevelModal .upload-file {
  position: absolute;
  right: 32px;
  top: auto;
  margin-top: 25px;
}

#createMembershipLevelModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 53px;
}

#createMembershipLevelModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}

#createMembershipLevelModal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BEBEBE;
}

#createMembershipLevelModal .access {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
  background-color: #EBF7F6;
  border-color: #EBF7F6;
  border-radius: 4px;
  padding: 8px 35px 40px;
}

#createMembershipLevelModal .access-level {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 10px;
}

#createMembershipLevelModal .btn-custom,
#membershipBillingOptionsModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 425px;
  height: 57px;
  margin-top: 76px;
}

/* admin membership level list */
#createMembershipLevelModal .subscription-info {
  background: #FFFFFF;
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.1411764706);
  border: 1px solid #12AEB4;
  border-radius: 15px;
}

#createMembershipLevelModal .subscription-info .thumbnail-img {
  width: 96px;
  height: 127px;
}

#createMembershipLevelModal .subscription-info .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #12AEB4;
  margin-bottom: 0px;
}

#createMembershipLevelModal .subscription-info .card-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #060136;
}

/* member billing */
#membershipBillingOptionsModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 11px;
}

#membershipBillingOptionsModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 0px;
}

#membershipBillingOptionsModal .subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 0px;
}

#membershipBillingOptionsModal .frequency-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  background: #EBF7F6;
  border-radius: 4px;
  padding: 12px 20px 20px 20px;
  text-align: center;
}

#membershipBillingOptionsModal .frequency-btn:hover,
#membershipBillingOptionsModal .frequency-btn.active {
  color: #ffffff;
  background: #12AEB4;
  cursor: pointer;
}

#membershipBillingOptionsModal .price-info {
  position: relative;
  display: inline-block;
}

#membershipBillingOptionsModal .price-info .info-icon {
  cursor: pointer;
}

#membershipBillingOptionsModal .info-hover {
  visibility: hidden;
  position: absolute;
  top: 35%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #C9C9C9;
  background-color: rgba(0, 0, 0, 0.7215686275);
  border-radius: 25px;
  padding: 29px 11px 23px 17px;
  width: 474px;
}

#membershipBillingOptionsModal .price-info:hover .info-hover {
  visibility: visible;
  z-index: 2;
}

#membershipBillingOptionsModal input.price-input {
  padding-left: 12px;
  width: 100px;
}

#membershipBillingOptionsModal .price-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
  margin-right: 8px;
  margin-bottom: 0px;
}

/* renewal and success modal */
#renewalAndSuceessModal .modal-header {
  padding: 39px 39px 0px 48px;
}

#renewalAndSuceessModal .modal-header .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
}

#renewalAndSuceessModal .modal-body {
  padding: 12px 39px 42px 48px;
}

#renewalAndSuceessModal .nav-pills .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3C3D43;
  background-color: #EBF7F6;
  border-radius: 4px;
  height: 58px;
  padding: 16px;
}

#renewalAndSuceessModal .nav-pills .nav-link.active {
  color: #fff;
  background-color: #12AEB4;
}

#renewalAndSuceessModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 0px;
}

#renewalAndSuceessModal .subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 22px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 17px;
}

#renewalAndSuceessModal .form-group {
  margin-bottom: 30px;
}

#renewalAndSuceessModal textarea.form-control:focus {
  height: auto;
}

#renewalAndSuceessModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}

#renewalAndSuceessModal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BEBEBE;
}

#renewalAndSuceessModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #342E2E;
  background-color: #EBF7F6;
  border-color: #EBF7F6;
  border-radius: 4px;
  padding-left: 35px;
}

#renewalAndSuceessModal #success-messages select {
  appearance: none;
}

#renewalAndSuceessModal .dropdown-img {
  position: absolute;
  bottom: 20px;
  right: 15px;
}

#renewalAndSuceessModal #renewal-reminder .attach-info {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-bottom: 6px;
}

#renewalAndSuceessModal #renewal-reminder .attach-info .info {
  visibility: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  color: #024F6C;
  background: rgba(160, 219, 221, 0.4588235294);
  border: 1px solid #12AEB4;
  border-radius: 8px;
  padding: 5px;
  width: 230px;
  position: absolute;
  bottom: 0px;
  right: 16px;
}

#renewalAndSuceessModal #renewal-reminder .attach-info:hover .info {
  visibility: visible;
}

#renewalAndSuceessModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 425px;
  height: 57px;
}

/* popup message modal */
#popupMessageModal .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
}

#popupMessageModal .modal-body {
  padding: 61px 46px 51px 46px;
}

#popupMessageModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 65px;
}

#popupMessageModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  border: 1px solid #D8D8D8;
  border-radius: 30px;
  width: 320px;
  height: 85px;
}

/* reconfirm modal */
#reConfirmModal .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
}

#reConfirmModal .modal-body {
  padding: 26px 70px 51px 70px;
  text-align: center;
}

#reConfirmModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #12AEB4;
  margin-bottom: 18px;
}

#reConfirmModal .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 40px;
  line-height: 50px;
  color: #655A5A;
  margin-bottom: 18px;
}

#reConfirmModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 42px;
}

#reConfirmModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #BEBEBE;
  border-radius: 9px;
  width: 362px;
  height: 57px;
  margin-left: 22px;
  margin-right: 22px;
  margin-bottom: 10px;
}

#reConfirmModal .btn-custom:hover,
#reConfirmModal .btn-custom:focus,
#reConfirmModal .btn-custom:active {
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
}

/* setup guide modal */
#setupGuide .modal-content,
#stepsSetup .modal-content {
  border: 1px solid #D8D8D8;
  border-radius: 31px;
}

#setupGuide p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#setupGuide .btn-setup {
  background: #12AEB4;
  border: 1px solid #D8D8D8;
  color: #fff;
  padding: 29px;
  border-radius: 25px;
  width: 320px;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  line-height: 35px;
  margin-bottom: 16px;
}

#stepsSetup .company-logo {
  background-color: #8fd0d5;
  margin: 0 28% 0 auto;
}

#stepsSetup .step-count {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
}

#stepsSetup .step-count a {
  padding: 10px;
  border-radius: 50%;
  color: #12AEB4;
  background-color: #C7EBEB;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 40px;
  height: 40px;
}

#stepsSetup .step-count a.active {
  background-color: #12AEB4;
  border: 6px solid #C7EBEB;
  color: #fff;
  padding: 4px;
}

#stepsSetup .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 15px;
}

#stepsSetup .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 2px;
}

#stepsSetup .title1 {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 0px;
}

#stepsSetup .text1 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #3C3D43;
  margin-bottom: 10px;
}

#stepsSetup form {
  padding: 0px 36px;
}

#stepsSetup form label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

#stepsSetup form .form-group {
  margin-bottom: 30px;
}

#stepsSetup form .form-group .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #2F3332;
  padding-left: 35px;
}

#stepsSetup form .form-group .input-group-text {
  color: #12AEB4;
}

#stepsSetup form .form-check-label a {
  color: #12AEB4;
}

#stepsSetup form .btn-custom {
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
}

#stepsSetup .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
}

#stepsSetup .uplaod-picture label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #AAAAAA;
  margin-top: 19px;
}

#stepsSetup .subscription img {
  width: 205px;
  background: #C7EBEB;
  border-radius: 15px;
  padding: 29px;
}

#stepsSetup .subscription .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #192D46;
  margin-bottom: 12px;
}

#stepsSetup .subscription .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #054143;
}

#stepsSetup .plan-info {
  background: #FFFFFF;
  box-shadow: 0px 3px 22px rgba(0, 0, 0, 0.1411764706);
  border: 1px solid #12AEB4;
  border-radius: 15px;
}

#stepsSetup .plan-info.active {
  background: #FFFFFF;
  box-shadow: 0px 3px 29px rgba(18, 174, 180, 0.2117647059);
  border: 1px solid #DEDEDE;
  border-radius: 15px;
}

#stepsSetup .plan-info.selected,
#stepsSetup .plan-info:hover {
  border: 1px solid #0D7174;
}

#stepsSetup .plan-info .card-body {
  padding: 30px;
}

#stepsSetup .plan-info .plan-name span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #12AEB4;
}

#stepsSetup .plan-info.active .plan-name span {
  color: #000000;
}

#stepsSetup .plan-info.selected .plan-name span,
#stepsSetup .plan-info.selected .plan-type span,
#stepsSetup .plan-info:hover .plan-name span,
#stepsSetup .plan-info:hover .plan-type span {
  color: #0D7174;
}

#stepsSetup .plan-info .plan-name span:last-child {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  color: #054143;
  background: #C7EBEB;
  border-radius: 15px;
  padding: 6px 29px;
}

#stepsSetup .plan-info .plan-type {
  text-align: right;
}

#stepsSetup .plan-info .plan-type span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #12AEB4;
}

#stepsSetup .plan-info .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #2D3142;
  margin-bottom: 15px;
}

#stepsSetup .plan-info.active .text,
#stepsSetup .plan-info.selected .text,
#stepsSetup .plan-info:hover .text {
  color: #5A5A5A;
}

#stepsSetup .plan-info .btn-custom {
  background: #12AEB4;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border-radius: 9px;
  width: 330px;
  height: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #FFFFFF;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#stepsSetup .plan-info .btn-outline-custom {
  background: transparent;
  box-shadow: 0px 3px 18px rgba(18, 174, 180, 0.2392156863);
  border: 1px solid #12AEB4;
  border-radius: 9px;
  width: 330px;
  height: 38px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #12AEB4;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

#stepsSetup .plan-info.selected .btn-custom,
#stepsSetup .plan-info.selected .btn-outline-custom,
#stepsSetup .plan-info:hover .btn-custom,
#stepsSetup .plan-info:hover .btn-outline-custom {
  background: #0D7174;
  color: #fff;
}

#stepsSetup .modal-dialog-scrollable .modal-body {
  height: 890px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.modal-dialog-scrollable .modal-body::-webkit-scrollbar-track {
  border-radius: 10px;
}

#stepsSetup .payment .title {
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#stepsSetup .payment .text {
  font-size: 18px;
  line-height: 27px;
  color: #00AEB3;
}

#paymentSuccessfull .modal-content,
#landingPage .modal-content {
  border: 1px solid #D8D8D8;
  border-radius: 15px;
}

#paymentSuccessfull .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0C0A08;
  margin-bottom: 24px;
}

#paymentSuccessfull .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #A6A7AE;
  margin-bottom: 93px;
}

#landingPage .modal-content {
  box-shadow: 0px 3px 24px #2D3142;
  border: 1px solid #E8E8E8;
}

#landingPage .title {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 55px;
  color: #172B4D;
  margin-bottom: 62px;
}

#landingPage .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-top: 68px;
  margin-bottom: 49px;
}

#landingPage .sub-title2 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 49px;
}

#landingPage .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  color: #2D3142;
  margin-bottom: 34px;
}

#landingPage .btn-custom {
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
}

#multiple-membership .card-body {
  padding: 38px;
}

#multiple-membership .multiple-membership-logo {
  margin-bottom: 31px;
}

#multiple-membership .multiple-membership-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: #172B4D;
  margin-bottom: 8.59px;
}

#multiple-membership .multiple-membership-msg {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7A869A;
  margin-bottom: 17.41px;
}

#multiple-membership .company-info,
#user-membership-login .company-info,
#user-welcome .company-info {
  border: 1px solid #E3E3E3;
  border-radius: 35px;
  padding: 15px 31px;
  margin: 8px auto 20px;
  height: 128px;
}

.membership-box-color {
  background-color: #7284CC !important;
}

.membership-box-color2 {
  background-color: #F2A32D !important;
}

#multiple-membership .company-info .company-name,
#user-membership-login .company-info .company-name,
#user-welcome .company-info .company-name {
  color: #fff;
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
  word-wrap: break-word;
  word-break: break-word;
}

#multiple-membership .company-info .company-name small,
#user-membership-login .company-info .company-name small,
#user-welcome .company-info .company-name small {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.31px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

#multiple-membership .custom-control-label::before {
  width: 35px;
  height: 35px;
  top: 0;
  background-color: #E3E3E3;
  border: 1px solid #fcfcfc;
}

#multiple-membership .custom-control-label::after {
  width: 35px;
  height: 35px;
  top: 0;
}

#multiple-membership .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%232f449a' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

#multiple-membership .btn-info {
  margin-top: 46px;
  margin-bottom: 30px;
}

#user-membership-login .card-body,
#user-welcome .card-body {
  padding: 50px 38px;
}

#user-membership-login .user-membership-login-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: #172B4D;
  margin-bottom: 22.5px;
}

#user-membership-login .user-membership-login-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7A869A;
  margin-bottom: 17.41px;
}

#user-membership-login .input-group {
  margin-bottom: 22px;
}

#user-membership-login .input-group.input2 {
  margin-bottom: 27px;
}

#user-membership-login input[type=text] {
  background-color: #fff;
  border: 1px solid #2F4A92;
  border-left: 0;
  height: 46px;
}

#user-membership-login input[type=password] {
  background-color: #fff;
  border: 1px solid #2F4A92;
  border-radius: 0px;
  border-left: 0;
  border-right: 0;
  height: 46px;
}

#user-membership-login .input-group-prepend .input-group-text {
  background-color: #fff;
  border-right: none;
  border-color: #2F4A92;
}

#user-membership-login .input-group-append .input-group-text {
  background-color: #fff;
  border-left: none;
  border-color: #2F4A92;
}

#user-membership-login .custom-control-label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2F4A92;
  padding-top: 0.25rem;
}

#user-membership-login .forgot-password {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #232323;
}

#user-membership-login .btn-info {
  background-color: #2F4A92;
  border-radius: 5px;
  width: 184px;
  height: 52px;
  margin-top: 40px;
  margin-bottom: 30px;
}

#user-welcome .user-welcome-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 41px;
  color: #172B4D;
  margin-top: 52.98px;
  margin-bottom: 48.59px;
}

#user-welcome .user-welcome-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #7A869A;
  margin-bottom: 17.41px;
}

#user-welcome .user-welcome-text.scroll {
  margin-bottom: 50px;
  max-height: 150px;
  overflow-y: scroll;
}

#user-welcome .user-welcome-text.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#user-welcome .user-welcome-text.scroll::-webkit-scrollbar-thumb {
  background: #2f4a92;
  border-radius: 10px;
  height: 50px;
}

#user-welcome .user-welcome-text.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#user-welcome .btn-info {
  background-color: #2F4A92;
  border-radius: 5px;
  width: 184px;
  height: 52px;
  margin-top: 102.41px;
  margin-bottom: 30px;
}

/*Admin audio library page css start*/
.admin-audio-library.content-wrapper,
.admin-video-library.content-wrapper {
  background-color: #fff;
}

.admin-audio-library .title,
.admin-video-library .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.admin-audio-library .title a,
.admin-video-library .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.admin-audio-library .playlist .title,
.admin-video-library .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.admin-audio-library .playlist .name,
.admin-video-library .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.admin-audio-library .playlist .left-img,
.admin-video-library .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
}

.admin-audio-library .playlist .right-img,
.admin-video-library .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.admin-audio-library .audio-folders,
.admin-video-library .video-folders {
  padding-left: 7.5px;
  margin-bottom: 34px;
}

.admin-audio-library .audio-folders .title,
.admin-video-library .video-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-audio-library .audio-folders label,
.admin-video-library .video-folders label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
}

.admin-audio-library .audio-folders .form-control,
.admin-video-library .video-folders .form-control {
  width: 220px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background: #EBF7F6;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-audio-library .audio-folders .react-datepicker-wrapper,
.admin-video-library .video-folders .react-datepicker-wrapper,
.admin-content-library .content-folders .react-datepicker-wrapper {
  width: auto !important;
}

.admin-audio-library .audio-folders .btn-custom,
.admin-video-library .video-folders .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12AEB4;
  background: #C7EBEB;
  border-radius: 6px;
  width: 150px;
  height: 42px;
}

.admin-audio-library .audio-folders .btn-custom:hover,
.admin-video-library .video-folders .btn-custom:hover {
  background-color: #12AEB4;
  color: #fff;
}

.admin-audio-library .audio-folders .audio-folders-slider,
.admin-video-library .video-folders .video-folders-slider {
  margin-top: 40px;
  margin-bottom: 30px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-stage-outer,
.admin-video-library .video-folders .video-folders-slider .owl-stage-outer {
  padding: 30px 0px;
}

.admin-audio-library .audio-folders .audio-folders-slider .item,
.admin-video-library .video-folders .video-folders-slider .item,
.admin-video-library .video-folders .inside-video-folder .item {
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #EBEBEB;
  border-radius: 13px;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  cursor: pointer;
}

.admin-audio-library .audio-folders .audio-folders-slider .item:hover,
.admin-video-library .video-folders .video-folders-slider .item:hover {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1803921569);
  border-radius: 7px;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  transform: scale(1.05);
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-nav [class*=owl-],
.admin-video-library .video-folders .video-folders-slider .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-prev,
.admin-video-library .video-folders .video-folders-slider .owl-prev {
  position: absolute;
  top: 135px;
  left: -10px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-next,
.admin-video-library .video-folders .video-folders-slider .owl-next {
  position: absolute;
  top: 130px;
  right: 0px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-prev img,
.admin-video-library .video-folders .video-folders-slider .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-audio-library .audio-folders .audio-folders-slider .owl-next img,
.admin-video-library .video-folders .video-folders-slider .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-audio-library .audio-folders .audio-folders-slider .img-over,
.admin-video-library .video-folders .video-folders-slider .img-over,
.admin-video-library .video-folders .inside-video-folder .img-over {
  margin-bottom: 21px;
}

.admin-audio-library .audio-folders .audio-folders-slider .arrows-img,
.admin-video-library .video-folders .video-folders-slider .arrows-img {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 40px;
  color: #fff;
}

.admin-video-library .video-folders .video-folders-slider .drag-img {
  position: absolute;
  bottom: -18px;
  right: 0px;
  width: 40px;
  color: #fff;
}

.admin-audio-library .audio-folders .audio-folders-slider .slide-title,
.admin-video-library .video-folders .video-folders-slider .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 9px;
  padding-left: 20px;
}

.admin-audio-library .audio-folders .audio-folders-slider .slide-text,
.admin-video-library .video-folders .video-folders-slider .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  padding-left: 20px;
}

.admin-audio-library .audio-list #audioLibraryTab,
.admin-video-library .video-list #videoLibraryTab {
  border: none;
  align-items: center;
}

.admin-audio-library .audio-list .nav-item,
.admin-video-library .video-list .nav-item {
  width: 196px;
  margin-right: 35px;
}

.admin-audio-library .audio-list .nav-link,
.admin-video-library .video-list .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8D9296;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-audio-library .audio-list .nav-link.active,
.admin-video-library .video-list .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #12AEB4;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #12AEB4;
}

.admin-audio-library .audio-list .search-library,
.admin-video-library .video-list .search-library {
  position: relative;
}

.admin-audio-library .audio-list .search-library input,
.admin-video-library .video-list .search-library input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #E5E5E5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}

.admin-audio-library .audio-list .search-icon,
.admin-video-library .video-list .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.admin-audio-library .audio-list .search-icon .fa-search,
.admin-video-library .video-list .search-icon .fa-search {
  font-size: 39px;
  color: #7E828F;
}

.admin-audio-library .audio-list #audioLibraryTabContent,
.admin-video-library .video-list #videoLibraryTabContent {
  background: #FFFFFF;
  box-shadow: 0px 3px 17px #C7DCDA;
  border-radius: 4px;
  padding: 8px 22px 4px 48px;
  margin-top: 45px;
  margin-bottom: 100px;
}

.admin-audio-library .audio-list #audioLibraryTabContent .table tr:first-child td,
.admin-video-library .video-list #videoLibraryTabContent .table tr:first-child td {
  border-top: none;
}

.admin-audio-library .audio-list #audioLibraryTabContent .table td,
.admin-video-library .video-list #videoLibraryTabContent .table td {
  vertical-align: baseline;
  border-top: 1px solid #CCCFCF;
}

.admin-audio-library .audio-list #audioLibraryTabContent .table-title,
.admin-video-library .video-list #videoLibraryTabContent .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 0px;
}

.admin-audio-library .audio-list #audioLibraryTabContent .views,
.admin-video-library .video-list #videoLibraryTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

.admin-video-library .video-list .after-search {
  margin-top: 20px;
}

.admin-video-library .video-list .after-search .sort-by select {
  width: 60%;
  display: inline-block;
  margin-left: 10px;
}

.admin-video-library .video-list .after-search .video-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-video-library .video-list #videoLibraryTabContent .video-banner {
  border: 1px solid #707070;
  width: 124px;
  height: 82px;
  padding: 10px 0px;
  position: relative;
}

.admin-video-library .video-list #videoLibraryTabContent .video-banner .video-reel {
  position: absolute;
  right: -40px;
}

.admin-video-library .video-folders .inside-video-folder {
  margin-bottom: 40px;
}

.admin-video-library .video-folders .inside-video-folder .arrows-img {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 40px;
  color: #fff;
}

.admin-video-library .video-folders .inside-video-folder .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 9px;
  padding-left: 20px;
}

.admin-video-library .video-folders .inside-video-folder .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7D8592;
  padding-left: 20px;
}

.admin-video-library .video-folders .video-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 24px;
  line-height: 35px;
  color: #060136;
  margin-bottom: 10px;
}

/* Admin audio select folder and sub folder */
#selectFolderModal .modal-content,
#selectAudioSubFolderModal .modal-content,
#selectVideoSubFolderModal .modal-content,
#insideFolderModal .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}

#selectFolderModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#selectFolderModal .select-icon {
  padding: 60px 0px;
}

#selectFolderModal .folder .fa-folder {
  padding: 56.5px 68px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12AEB4;
  background-color: #C7EBEB;
}

#selectFolderModal .audio .fa-microphone {
  padding: 56.5px 77.38px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12AEB4;
  background-color: #C7EBEB;
}

#selectFolderModal .folder .fa-folder:hover,
#selectFolderModal .audio .fa-microphone:hover,
#selectFolderModal .folder .fa-folder:active,
#selectFolderModal .audio .fa-microphone:active {
  background-color: #12AEB4;
  color: #fff;
}

#selectFolderModal .icon-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #A6A7AE;
  margin-bottom: 0px;
}

#selectFolderModal .select-folder,
#selectAudioSubFolderModal .folder,
#selectVideoSubFolderModal .folder,
#insideFolderModal .folder {
  padding-left: 18px;
  padding-right: 10px;
}

#selectFolderModal .select-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3C3D43;
  margin-bottom: 23px;
  margin-left: 26px;
}

#selectFolderModal .select-folder .scroll,
#selectAudioSubFolderModal .select-folder .scroll,
#selectVideoSubFolderModal .select-folder .scroll,
#insideFolderModal .select-folder .scroll {
  max-height: 620px;
  overflow-y: scroll;
  border-right: 2px solid #D8D8D8;
}

#selectFolderModal .select-folder .scroll::-webkit-scrollbar,
#selectAudioSubFolderModal .folder .scroll::-webkit-scrollbar,
#selectVideoSubFolderModal .folder .scroll::-webkit-scrollbar,
#insideFolderModal .folder .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#selectFolderModal .select-folder .scroll::-webkit-scrollbar-thumb,
#selectAudioSubFolderModal .folder .scroll::-webkit-scrollbar-thumb,
#selectVideoSubFolderModal .folder .scroll::-webkit-scrollbar-thumb,
#insideFolderModal .folder .scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

#selectFolderModal .select-folder .scroll::-webkit-scrollbar-track,
#selectAudioSubFolderModal .folder .scroll::-webkit-scrollbar-track,
#selectVideoSubFolderModal .folder .scroll::-webkit-scrollbar-track,
#insideFolderModal .folder .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#selectFolderModal .select-folder .card,
#selectAudioSubFolderModal .folder .card,
#selectVideoSubFolderModal .folder .card,
#insideFolderModal .folder .card {
  background: #F2FFFF;
  border-radius: 9px;
  margin: 15px;
}

#selectFolderModal .select-folder .card:hover,
#selectAudioSubFolderModal .folder .card:hover,
#selectVideoSubFolderModal .folder .card:hover,
#insideFolderModal .folder .card:hover,
#selectFolderModal .select-folder .card.active,
#selectAudioSubFolderModal .folder .card.active,
#selectVideoSubFolderModal .folder .card.active,
#insideFolderModal .folder .card.active {
  background: #FFFFFF;
  box-shadow: 5px 5px 18px rgba(41, 168, 174, 0.5411764706);
}

#selectFolderModal .select-folder .card .card-body,
#selectAudioSubFolderModal .folder .card .card-body,
#selectVideoSubFolderModal .folder .card .card-body,
#insideFolderModal .folder .card .card-body {
  padding: 22px 20px;
}

#selectFolderModal .select-folder .audio-img,
#selectAudioSubFolderModal .folder .audio-img,
#selectVideoSubFolderModal .folder .video-img,
#insideFolderModal .folder .audio-img {
  width: 149px;
  height: 124px;
}

#selectFolderModal .select-folder .card .card-title,
#selectAudioSubFolderModal .folder .card .card-title,
#selectVideoSubFolderModal .folder .card .card-title,
#insideFolderModal .folder .card .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  word-break: break-word;
  word-wrap: break-word;
}

#selectFolderModal .select-folder .card .card-text,
#selectAudioSubFolderModal .folder .card .card-text,
#selectVideoSubFolderModal .folder .card .card-text,
#insideFolderModal .folder .card .card-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #929AA5;
}

#selectFolderModal .select-folder .card .card-body .page-info,
#selectAudioSubFolderModal .folder .card .card-body .page-info,
#selectVideoSubFolderModal .folder .card .card-body .page-info,
#insideFolderModal .folder .card .card-body .page-info {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #00AEB3;
}

#selectFolderModal .select-folder .btn-custom,
#selectAudioSubFolderModal .folder .btn-custom,
#selectVideoSubFolderModal .folder .btn-custom,
#insideFolderModal .folder .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  margin-top: 32px;
  margin-bottom: 51px;
}

/* select sub folder and aduio inside modal */
#selectAudioSubFolderModal .go-back,
#selectVideoSubFolderModal .go-back,
#insideFolderModal .go-back,
#addLessonDetailsModal .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
}

#selectAudioSubFolderModal .folder .title,
#selectVideoSubFolderModal .folder .title,
#insideFolderModal .folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3C3D43;
  margin-left: 26px;
  margin-top: 22px;
  margin-bottom: 0px;
}

#selectAudioSubFolderModal .folder .scroll,
#selectVideoSubFolderModal .folder .scroll,
#insideFolderModal .folder .scroll {
  max-height: 210px;
  overflow-y: scroll;
  border-right: 2px solid #D8D8D8;
}

#insideFolderModal .folder .scroll {
  padding: 5px;
  max-height: 516px;
  margin-bottom: 20px;
}

#insideFolderModal .folder .uploaded-file {
  padding: 15px 0px;
  margin-right: 0px;
  border-bottom: 1px solid #D8D8D8;
}

#insideFolderModal .folder .uploaded-file .file-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 9px;
}

#insideFolderModal .folder .uploaded-file .file-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929AA5;
  margin-bottom: 0px;
}

/*Audio preview modal*/
#audioPreviewModal .modal-content,
#videoPreviewModal .modal-content {
  box-shadow: 0px 3px 24px #D7F1F2;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
}

#audioPreviewModal .modal-title,
#videoPreviewModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-right: 100px;
}

#audioPreviewModal .audio-title,
#videoPreviewModal .video-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 33px;
  color: #060136;
  text-align: center;
  margin-bottom: 32px;
}

#audioPreviewModal .chat-like,
#videoPreviewModal .chat-like {
  padding: 0 80px;
  margin-top: 15px;
  margin-bottom: 45px;
}

#audioPreviewModal .file-name,
#videoPreviewModal .file-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  text-align: center;
  margin-bottom: 0px;
  word-wrap: break-word;
  word-break: break-word;
}

#videoPreviewModal .player-description {
  word-wrap: break-word;
  word-break: break-word;
}

#audioPreviewModal .cursor,
#videoPreviewModal .cursor {
  position: relative;
  border: 1px solid #E8E8E8;
  margin-bottom: 21px;
}

#audioPreviewModal .cursor .cursor-img,
#videoPreviewModal .cursor .cursor-img {
  position: absolute;
  top: -12px;
  right: 50px;
}

#audioPreviewModal .timing span,
#videoPreviewModal .timing span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7E7D88;
}

#audioPreviewModal .controls,
#videoPreviewModal .controls {
  padding: 0 80px;
  margin-top: 36px;
  margin-bottom: 44px;
}

#audioPreviewModal .comments .leave-comment,
#videoPreviewModal .comments .leave-comment {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* color: #8F92A1; */
  background-color: #fff;
  box-shadow: 0px -3px 6px rgba(63, 83, 165, 0.1568627451);
  border: 1px solid #D8E0F0;
  border-radius: 16px;
  height: 38px;
}

#audioPreviewModal .comments .comment-img {
  cursor: pointer;
}

#audioPreviewModal .comments .comment-img:hover {
  width: 58px;
}

#audioPreviewModal .comments .title,
#videoPreviewModal .comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #8D9296;
}

#audioPreviewModal .messages-list,
#videoPreviewModal .messages-list {
  max-height: 340px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
}

#audioPreviewModal .messages-list .media,
#videoPreviewModal .messages-list .media {
  border-bottom: 1px solid #E8E8E8;
  padding: 10px;
}

#audioPreviewModal .messages-list .media:hover,
#videoPreviewModal .messages-list .media:hover {
  background-color: #F4F9FD;
}

#audioPreviewModal .messages-list .user-img,
#videoPreviewModal .messages-list .user-img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}

#audioPreviewModal .messages-list .messanger-name,
#videoPreviewModal .messages-list .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 5px;
  word-wrap: break-word;
  word-break: break-word;
}

#audioPreviewModal .messages-list .msg-time,
#videoPreviewModal .messages-list .msg-time {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

#audioPreviewModal .messages-list .messanger-text,
#videoPreviewModal .messages-list .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* color: #91929E; */
  margin-bottom: 5px;
  word-wrap: break-word;
  word-break: break-word;
}

#audioPreviewModal .messages-list .msg-count,
#videoPreviewModal .messages-list .msg-count {
  background-color: #12AEB4;
  border: 2px solid #FFFFFF;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

#audioPreviewModal .messages-list .link,
#videoPreviewModal .messages-list .link {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 25px;
  color: #12AEB4;
  text-decoration: none;
  float: right;
}

/*Admin Audio modals  */
#wantToCreateModal .modal-content,
#createFolderModal .modal-content,
#addVideoDetailsModal .modal-content,
#addAudioDetailsModal .modal-content,
#addLessonDetailsModal .modal-content,
#addAudioModal .modal-content,
#addVideoModal .modal-content,
#createSubFolderModal .modal-content,
#uploadAudioModal .modal-content,
#uploadVideoModal .modal-content,
#uploadLessonModal .modal-content,
#selectSubFolderModal .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}

#wantToCreateModal .modal-title,
#createFolderModal .modal-title,
#addVideoDetailsModal .modal-title,
#addAudioDetailsModal .modal-title,
#addAudioModal .modal-title,
#addVideoModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#addLessonDetailsModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  padding-left: 40px;
}

#wantToCreateModal .modal-body,
#addAudioModal .modal-body,
#addVideoModal .modal-body {
  padding: 68px 16px;
}

#wantToCreateModal .folder .fa-folder,
#wantToCreateModal .folder .fa-file-alt,
#wantToCreateModal .folder .fa-book,
#addAudioModal .folder .fa-folder,
#addVideoModal .folder .fa-folder {
  padding: 56.5px 68px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12AEB4;
  background-color: #C7EBEB;
}

#wantToCreateModal .audio .fa-microphone,
#wantToCreateModal .audio .fa-file-alt,
#wantToCreateModal .audio .fa-file-audio,
#addAudioModal .audio .fa-microphone,
#wantToCreateModal .video .fa-video,
#selectFolderModal .video .fa-video,
#addVideoModal .video .fa-video {
  padding: 56.5px 77.38px;
  margin-bottom: 24px;
  border-radius: 20px;
  font-size: 60px;
  color: #12AEB4;
  background-color: #C7EBEB;
}

#wantToCreateModal .video .fa-video,
#selectFolderModal .video .fa-video {
  padding: 56px 65px;
}

#wantToCreateModal .folder .fa-folder:hover,
#wantToCreateModal .folder .fa-file-alt:hover,
#wantToCreateModal .audio .fa-microphone:hover,
#addAudioModal .folder .fa-folder:hover,
#addVideoModal .folder .fa-folder:hover,
#addAudioModal .audio .fa-microphone:hover,
#wantToCreateModal .folder .fa-folder:active,
#wantToCreateModal .folder .fa-file-alt:active,
#wantToCreateModal .audio .fa-microphone:active,
#addAudioModal .folder .fa-folder:active,
#addAudioModal .audio .fa-microphone:active,
#wantToCreateModal .audio .fa-file-alt:hover,
#wantToCreateModal .audio .fa-file-alt:active,
#wantToCreateModal .video .fa-video:hover,
#wantToCreateModal .video .fa-video:active,
#selectFolderModal .video .fa-video:hover,
#selectFolderModal .video .fa-video:active,
#addVideoModal .video .fa-video:hover,
#addVideoModal .video .fa-video:active,
#wantToCreateModal .folder .fa-book:hover,
#wantToCreateModal .folder .fa-book:active,
#wantToCreateModal .audio .fa-file-audio:hover,
#wantToCreateModal .audio .fa-file-audio:active {
  background-color: #12AEB4;
  color: #fff;
}

#wantToCreateModal .audio .fa-file-alt:active,
#wantToCreateModal .audio .fa-file-alt.inactive:hover,
#wantToCreateModal .audio .fa-file-alt.inactive,
#wantToCreateModal .audio .fa-file-audio.inactive:hover,
#wantToCreateModal .audio .fa-file-audio.inactive:active,
#wantToCreateModal .folder .folder-img.inactive,
#wantToCreateModal .folder .folder-img.inactive:hover,
#wantToCreateModal .folder .folder-img.inactive:active {
  background-color: #C3CCCC !important;
  color: #7E7E7E;
}

#wantToCreateModal .folder-img,
#selectFolderModal .folder-img {
  background-color: #C7EBEB;
  padding: 50px 70px;
  border-radius: 23px;
}

#wantToCreateModal .folder-img:hover,
#selectFolderModal .folder-img:hover {
  background-color: #12AEB4;
}

#wantToCreateModal .folder-img .agenda-hover,
#wantToCreateModal .folder-img:hover .agenda,
#wantToCreateModal .folder-img .music-hover,
#wantToCreateModal .folder-img:hover .music,
#selectFolderModal .folder-img .agenda-hover,
#selectFolderModal .folder-img:hover .agenda,
#selectFolderModal .folder-img .music-hover,
#selectFolderModal .folder-img:hover .music {
  display: none;
}

#wantToCreateModal .folder-img:hover .agenda-hover,
#wantToCreateModal .folder-img:hover .music-hover,
#selectFolderModal .folder-img:hover .agenda-hover,
#selectFolderModal .folder-img:hover .music-hover {
  display: block;
}

#wantToCreateModal .audio .info-click {
  position: absolute;
  top: 2%;
  right: -28%;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #C2C3CC;
  background-color: rgba(41, 35, 35, 0.7411764706);
  border-radius: 40px;
  padding: 20px;
  width: 331px;
  height: 83px;
}

#wantToCreateModal .title,
#addAudioModal .title,
#addVideoModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 43px;
  color: #A6A7AE;
  margin-bottom: 0px;
}

#createFolderModal .modal-header,
#createFolderModal .modal-body,
#addVideoDetailsModal .modal-header,
#addVideoDetailsModal .modal-body,
#addAudioDetailsModal .modal-header,
#addAudioDetailsModal .modal-body,
#addLessonDetailsModal .modal-header,
#addLessonDetailsModal .modal-body,
#createSubFolderModal .modal-header,
#createSubFolderModal .modal-body,
#uploadAudioModal .modal-header,
#uploadAudioModal .modal-body,
#uploadVideoModal .modal-header,
#uploadVideoModal .modal-body,
#uploadLessonModal .modal-header,
#uploadLessonModal .modal-body {
  padding: 41px 40px;
}

#createFolderModal label,
#createSubFolderModal label,
#addVideoDetailsModal label,
#addAudioDetailsModal label,
#addLessonDetailsModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #506278;
  margin-bottom: 4px;
}

#createFolderModal label small,
#createSubFolderModal label small,
#addVideoDetailsModal label small,
#addAudioDetailsModal label small,
#addLessonDetailsModal label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BEBEBE;
}

#addVideoDetailsModal .folder-name,
#addAudioDetailsModal .folder-name,
#addLessonDetailsModal .folder-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #BEBEBE;
  padding-left: 16px;
  padding-left: 40px;
}

#addVideoDetailsModal .notice,
#addAudioDetailsModal .notice,
#addLessonDetailsModal .notice {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #A6A7AE;
}

#createFolderModal .form-group,
#createSubFolderModal .form-group,
#addVideoDetailsModal .form-group,
#addAudioDetailsModal .form-group,
#addLessonDetailsModal .form-group {
  margin-bottom: 30px;
}

#createFolderModal .form-control,
#createSubFolderModal .form-control,
#addVideoDetailsModal .form-control,
#addAudioDetailsModal .form-control,
#addLessonDetailsModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #EBF7F6;
  color: #242424;
  border-color: #EBF7F6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding-left: 35px;
  height: 58px;
}

#createFolderModal .upload-file,
#createSubFolderModal .upload-file,
#addVideoDetailsModal .upload-file,
#addAudioDetailsModal .upload-file {
  position: absolute;
  right: 32px;
  top: auto;
  margin-top: 25px;
}

#createFolderModal .date,
#createSubFolderModal .date,
#addVideoDetailsModal .date,
#addVideoDetailsModal .select,
#addAudioDetailsModal .date,
#addAudioDetailsModal .select,
#addLessonDetailsModal .date,
#addLessonDetailsModal .select,
#createFolderModal .select {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

#createFolderModal .access,
#createSubFolderModal .access,
#addVideoDetailsModal .access,
#addAudioDetailsModal .access,
#addLessonDetailsModal .access {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #EBF7F6;
  color: #242424;
  border-color: #EBF7F6;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
  padding: 8px 35px 40px;
}

#createFolderModal .access-level,
#createSubFolderModal .access-level,
#addVideoDetailsModal .access-level,
#addAudioDetailsModal .access-level,
#addLessonDetailsModal .access-level {
  padding: 20px 10px;
}

#createFolderModal .access-level,
#createSubFolderModal .access-level,
#addVideoDetailsModal .access-level,
#addAudioDetailsModal .access-level,
#addLessonDetailsModal .access-level {
  border-bottom: 1px solid #ccc;
}

#createFolderModal .btn-custom,
#createSubFolderModal .btn-custom,
#addVideoDetailsModal .btn-custom,
#addAudioDetailsModal .btn-custom,
#addLessonDetailsModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 282px;
  height: 57px;
  margin: 20px 0px 10px 0px;
}

#createSubFolderModal .go-back,
#uploadAudioModal .go-back,
#uploadVideoModal .go-back,
#uploadLessonModal .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
}

#createSubFolderModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 0px;
  padding-left: 40px;
}

#createSubFolderModal small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #BEBEBE;
  padding-left: 40px;
}

#addAudioModal .upload-audio-file,
#addVideoModal .upload-video-file,
#addVideoModal .upload-lesson-file {
  padding: 0px 50px 80px;
}

#addAudioModal .upload-audio-file label,
#addVideoModal .upload-video-file label,
#addVideoModal .upload-lesson-file label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
  margin-bottom: 35px;
}

#uploadAudioModal .title,
#uploadVideoModal .title,
#uploadLessonModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
  margin-bottom: 15px;
  padding-left: 40px;
}

#uploadAudioModal .subtitle,
#uploadVideoModal .subtitle,
#uploadLessonModal .subtitle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 0px;
  padding-left: 40px;
}

#uploadAudioModal small,
#uploadVideoModal small,
#uploadLessonModal small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #BEBEBE;
  padding-left: 40px;
}

#uploadAudioModal .uploaded-file,
#uploadVideoModal .uploaded-file,
#uploadLessonModal .uploaded-file {
  border-bottom: 1px solid #DEDEDE;
  padding-top: 31px;
  padding-bottom: 31px;
}

#uploadAudioModal .uploaded-file:last-child,
#uploadVideoModal .uploaded-file:last-child,
#uploadLessonModal .uploaded-file:last-child {
  border: none;
}

#uploadAudioModal .uploaded-file .name,
#uploadVideoModal .uploaded-file .name,
#uploadLessonModal .uploaded-file .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 17px;
}

#uploadAudioModal .uploaded-file .perc,
#uploadVideoModal .uploaded-file .perc,
#uploadLessonModal .uploaded-file .perc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #849292;
  float: right;
  margin-bottom: 17px;
}

#uploadAudioModal .uploaded-file .progress,
#uploadVideoModal .uploaded-file .progress,
#uploadLessonModal .uploaded-file .progress {
  height: 8px;
}

#uploadAudioModal .uploaded-file .progress-bar,
#uploadVideoModal .uploaded-file .progress-bar,
#uploadLessonModal .uploaded-file .progress-bar {
  background-color: #12AEB4;
  border-radius: 5px;
}

#audioAddedModal .modal-content,
#videoAddedModal .modal-content,
#lessonAddedModal .modal-content {
  border-radius: 20px;
}

#audioAddedModal .modal-body,
#videoAddedModal .modal-body {
  padding-left: 44px;
  padding-right: 44px;
}

#audioAddedModal .success-img,
#videoAddedModal .success-img,
#lessonAddedModal .success-img {
  margin-top: -100px;
  margin-left: -70px;
}

#audioAddedModal .title,
#videoAddedModal .title,
#lessonAddedModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0C0A08;
  margin-bottom: 24px;
}

#audioAddedModal .text,
#videoAddedModal .text,
#lessonAddedModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #A6A7AE;
  margin-bottom: 19px;
}

/*audio / video library grid view*/
.admin-video-library .video-folders .grid-view,
.admin-audio-library .audio-folders .grid-view {
  margin-top: 50px;
  padding-top: 25px;
  padding-right: 50px;
}

.admin-video-library .video-folders .grid-view.scroll,
.admin-audio-library .audio-folders .grid-view.scroll {
  max-height: 1120px;
  overflow-y: scroll;
  border-right: 1px solid #D8D8D8;
}

.admin-video-library .video-folders .grid-view.scroll::-webkit-scrollbar,
.admin-audio-library .audio-folders .grid-view.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-video-library .video-folders .grid-view.scroll::-webkit-scrollbar-thumb,
.admin-audio-library .audio-folders .grid-view.scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.admin-video-library .video-folders .grid-view.scroll::-webkit-scrollbar-track,
.admin-audio-library .audio-folders .grid-view.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-video-library .video-folders .grid-view .folder,
.admin-audio-library .audio-folders .grid-view .folder {
  background: #FFFFFF;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #EBEBEB;
  border-radius: 13px;
  margin-bottom: 50px;
}

.admin-video-library .grid-view .folder .img-over,
.admin-audio-library .audio-folders .folder .img-over {
  width: 100%;
  margin-bottom: 19px;
  position: relative;
}

.admin-video-library .grid-view .folder .arrows-img,
.admin-audio-library .audio-folders .folder .arrows-img {
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 30px;
  color: #fff;
}

.admin-video-library .grid-view .folder .folder-title,
.admin-audio-library .audio-folders .folder .folder-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 9px;
  padding-left: 20px;
}

.admin-video-library .grid-view .folder .folder-text,
.admin-audio-library .audio-folders .folder .folder-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7D8592;
  padding-left: 20px;
}

/*audio / video list view*/
.admin-video-library .video-folders .list-view,
.admin-audio-library .audio-folders .list-view {
  margin-top: 50px;
  padding-top: 25px;
  padding-right: 50px;
}

.admin-video-library .video-folders .list-view.scroll,
.admin-audio-library .audio-folders .list-view.scroll {
  max-height: 894px;
  overflow-y: scroll;
  border-right: 1px solid #D8D8D8;
}

.admin-video-library .video-folders .list-view.scroll::-webkit-scrollbar,
.admin-audio-library .audio-folders .list-view.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-video-library .video-folders .list-view.scroll::-webkit-scrollbar-thumb,
.admin-audio-library .audio-folders .list-view.scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.admin-video-library .video-folders .list-view.scroll::-webkit-scrollbar-track,
.admin-audio-library .audio-folders .list-view.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-video-library .video-folders .list-view .table tr td,
.admin-audio-library .audio-folders .list-view .table tr td {
  vertical-align: middle;
}

.admin-video-library .video-folders .list-view .table tr td:first-child,
.admin-audio-library .audio-folders .list-view .table tr td:first-child {
  width: 54px;
  height: 54px;
  padding-left: 0px;
}

.admin-video-library .video-folders .list-view .table-title,
.admin-audio-library .audio-folders .list-view .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.admin-video-library .video-folders .list-view .table-text,
.admin-audio-library .audio-folders .list-view .table-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  color: #7D8592;
}

/* content page setting */
.content-page-setting.content-wrapper {
  background-color: #fff;
}

.content-page-setting section {
  padding-left: 45px;
  padding-right: 97px;
}

.content-page-setting .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
  margin-top: 64px;
  margin-bottom: 22px;
}

.content-page-setting .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.content-page-setting .breadcrumb {
  background-color: transparent;
}

.content-page-setting .breadcrumb-item a,
.content-page-setting .breadcrumb-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.content-page-setting .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #12AEB4;
  content: ">";
}

.content-page-setting label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

.content-page-setting label small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #A6A7AE;
  padding-left: 5px;
}

.content-page-setting .form-control {
  background-color: #EBF7F6;
  color: #242424;
  border-color: #EBF7F6;
  font-size: 16px;
  line-height: 25px;
}

.content-page-setting input.form-control {
  height: 58px;
}

.content-page-setting .date {
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

.content-page-setting .access .scroll {
  max-height: 160px;
  overflow-y: scroll;
  border-right: 2px solid #D8D8D8;
}

.content-page-setting .access .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.content-page-setting .access .scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.content-page-setting .access .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content-page-setting .access .level span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
}

.content-page-setting .access .level span small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #12AEB4;
}

.content-page-setting .access {
  background-color: #EBF7F6;
  border-color: #EBF7F6;
  padding: 11px 15px 40px 35px;
}

.content-page-setting .source {
  background-color: #EBF7F6;
  border-color: #EBF7F6;
  padding: 11px 20px 40px 35px;
}

.content-page-setting .link,
.content-page-setting .create,
.content-page-setting .level {
  padding: 15px 10px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #242424;
  border-radius: 4px;
  font-size: 16px;
  line-height: 25px;
}

.content-page-setting .upload-file {
  position: absolute;
  right: 0px;
  top: auto;
}

.content-page-setting #preview_img {
  background-color: #EBF7F6;
  height: 230px;
}

.content-page-setting .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  margin-top: 92px;
  margin-bottom: 16px;
  height: 57px;
  width: 797px;
}

.content-page-setting .msg {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 27px;
  color: #A6A7AE;
  margin-bottom: 23px;
  text-align: center;
}

/* content library want to create modal */
#wantToCreateModal .select-folder,
#selectSubFolderModal .folder {
  padding-left: 30px;
  padding-right: 16px;
}

#wantToCreateModal .select-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3C3D43;
  margin-bottom: 23px;
  margin-left: 26px;
}

#wantToCreateModal .select-folder .scroll {
  max-height: 620px;
  overflow-y: scroll;
  border-right: 2px solid #D8D8D8;
}

#wantToCreateModal .select-folder .scroll::-webkit-scrollbar,
#selectSubFolderModal .folder .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#wantToCreateModal .select-folder .scroll::-webkit-scrollbar-thumb,
#selectSubFolderModal .folder .scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

#wantToCreateModal .select-folder .scroll::-webkit-scrollbar-track,
#selectSubFolderModal .folder .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#wantToCreateModal .select-folder .card,
#selectSubFolderModal .folder .card {
  background: #F2FFFF;
  border-radius: 9px;
  margin: 15px 26px;
}

#wantToCreateModal .select-folder .card:hover,
#selectSubFolderModal .folder .card:hover,
#wantToCreateModal .select-folder .card.active,
#selectSubFolderModal .folder .card.active {
  background: #FFFFFF;
  box-shadow: 5px 5px 18px rgba(41, 168, 174, 0.5411764706);
}

#wantToCreateModal .select-folder .card .card-body,
#selectSubFolderModal .folder .card .card-body {
  padding: 23px 21px 24px 34px;
}

#wantToCreateModal .select-folder .card .card-title,
#selectSubFolderModal .folder .card .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
}

#wantToCreateModal .select-folder .card .card-text,
#selectSubFolderModal .folder .card .card-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #929AA5;
}

#wantToCreateModal .select-folder .card .card-body .page-info,
#selectSubFolderModal .folder .card .card-body .page-info {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #00AEB3;
}

#wantToCreateModal .select-folder .btn-custom,
#selectSubFolderModal .folder .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  margin-top: 32px;
  margin-bottom: 51px;
}

/* select sub folder modal */
#selectSubFolderModal .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
}

#selectSubFolderModal .folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 36px;
  color: #3C3D43;
  margin-left: 26px;
  margin-top: 22px;
  margin-bottom: 0px;
}

#selectSubFolderModal .folder .scroll {
  max-height: 210px;
  overflow-y: scroll;
  border-right: 2px solid #D8D8D8;
}

/*content library css start*/
.content-wrapper.admin-content-library {
  background-color: #fff;
  margin-right: 30px;
}

.admin-content-library .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.admin-content-library .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.admin-content-library .readinglist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.admin-content-library .readinglist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.admin-content-library .readinglist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
}

.admin-content-library .readinglist .right-img {
  position: absolute;
  right: 0px;
  top: 5px;
  z-index: 1;
  width: 52px;
  height: 78px;
}

.admin-content-library .content-folders {
  padding-left: 7.5px;
  margin-bottom: 34px;
}

.admin-content-library .content-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-content-library .content-folders .title small {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 0px;
}

.admin-content-library .content-folders label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.admin-content-library .content-folders .sort,
.admin-content-library .content-folders .date {
  width: 220px;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background: #EBF7F6;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-content-library .content-folders .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12AEB4;
  background: #C7EBEB;
  border-radius: 6px;
  width: 150px;
  height: 42px;
}

.admin-content-library .content-folders .btn-custom:hover {
  background-color: #12AEB4;
  color: #fff;
}

.admin-content-library .content-folders .content-slider {
  margin-bottom: 10px;
}

.admin-content-library .content-folders .content-slider .main-img {
  position: relative;
}

.main-img-subfolder {
  width: 193px;
  height: 150px;
  border-radius: 5px;
}

.admin-content-library .content-folders .content-slider .img-over {
  margin-bottom: 21px;
  height: 200px;
}

.admin-content-library .content-folders .content-slider .arrows-img {
  position: absolute;
  bottom: 0;
  right: -18px;
  width: 40px;
  height: 44px;
}

.admin-content-library .content-folders .content-slider .slide-title {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 5px;
}

.admin-content-library .content-folders .content-slider .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
}

.admin-content-library .content-folders .content-slider .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-content-library .content-folders .content-slider .owl-prev {
  position: absolute;
  top: 135px;
  left: -10px;
}

.admin-content-library .content-folders .content-slider .owl-next {
  position: absolute;
  top: 130px;
  right: -15px;
}

.admin-content-library .content-folders .content-slider .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-content-library .content-folders .content-slider .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-content-library .content-list #contentLibraryTab {
  border: none;
  align-items: center;
}

.admin-content-library .content-list .nav-item {
  width: 196px;
  margin-right: 35px;
}

.admin-content-library .content-list .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8D9296;
  margin-bottom: 20px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-content-library .content-list .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #12AEB4;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #12AEB4;
}

.admin-content-library .content-list .search-content {
  position: relative;
}

.admin-content-library .content-list .search-content input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #E5E5E5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}

.admin-content-library .content-list .search-icon {
  position: absolute;
  inset: 7px 7px 7px auto;
}

.admin-content-library .content-list .search-icon .fa-search {
  font-size: 39px;
  color: #7E828F;
}

.admin-content-library .content-list #contentLibraryTabContent {
  background: #FFFFFF;
  box-shadow: 0px 3px 17px #C7DCDA;
  border-radius: 4px;
  padding: 8px 22px 4px 48px;
  margin-top: 45px;
  margin-bottom: 100px;
}

.admin-content-library .content-list #contentLibraryTabContent .table tr:first-child td {
  border-top: none;
}

.admin-content-library .content-list #contentLibraryTabContent .table td:first-child {
  border-top: none;
  padding: 0;
  padding-bottom: 11px;
}

.admin-content-library .content-list #contentLibraryTabContent .table td {
  vertical-align: baseline;
  border-top: 1px solid #CCCFCF;
}

.admin-content-library .content-list #contentLibraryTabContent .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #060136;
  margin-bottom: 0px;
}

.admin-content-library .content-list #contentLibraryTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

/*content-library list view*/
.admin-content-library .list-view {
  margin-top: 38px;
  margin-bottom: 60px;
}

.admin-content-library .list-view .table tr:first-child td {
  border-top: none;
}

.admin-content-library .list-view .table td {
  vertical-align: middle;
  border-top: 1px solid #CCCFCF;
}

.admin-content-library .list-view .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 0px;
}

.admin-content-library .list-view .table-text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 0px;
}

.admin-content-library .list-view .table .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

.admin-content-library .list-view .table .heading {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.admin-content-library .list-view .table .action {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: right;
}

/* content library select template */
.content-choose-template {
  background: #FFFFFF;
  border-radius: 87px;
  margin: 90px;
  padding: 69px 120px 164px;
}

.content-choose-template .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #1C1818;
  text-align: center;
  margin-bottom: 32px;
}

.content-choose-template .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #7B6F6F;
  text-align: center;
  margin-bottom: 81px;
}

.content-choose-template .template {
  border: 1px solid #707070;
  border-radius: 43px;
  padding: 37px;
  margin-bottom: 41px;
}

.content-choose-template .template .image {
  display: flex;
  align-items: center;
  background-color: #EBF7F6;
}

.content-choose-template .template .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #12AEB4;
  text-align: center;
  margin-bottom: 32px;
}

.content-choose-template .template .content .progress {
  background-color: #E5E5E5;
  border-radius: 1px;
  box-shadow: none;
}

/* content choose template 2 */
.content-choose-template .content {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #655A5A;
}

.content-choose-template .video {
  position: relative;
}

.content-choose-template .video .video-icon {
  position: absolute;
  top: 30%;
  left: 40%;
}

/* content edit template */
.content-edit-template {
  text-align: center;
  padding: 68px 214px 200px 172px;
}

.content-edit-template .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #1C1818;
  margin-bottom: 32px;
}

.content-edit-template .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #7B6F6F;
  margin-bottom: 80px;
}

.content-edit-template .template {
  background-color: #ffffff;
  border: 2px solid #12AEB4;
  border-radius: 43px;
  padding: 45px 51px 72px;
  min-height: 324px;
}

.content-edit-template .template .image {
  display: flex;
  align-items: center;
  background-color: #EBF7F6;
}

.content-edit-template .template .content .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #12AEB4;
  margin-bottom: 32px;
}

.content-edit-template .template .content .progress {
  background-color: #E5E5E5;
  border-radius: 1px;
  box-shadow: none;
}

.content-edit-template .upload-media {
  display: flex;
  align-items: center;
  padding: 33px 51px;
}

.content-edit-template .upload-media .image {
  display: flex;
  align-items: center;
  background-color: #EBF7F6;
  height: 315px;
  margin-bottom: 14px;
}

.content-edit-template .upload-media .media-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #7B6F6F;
  margin-bottom: 0px;
}

.content-edit-template .upload-media .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12AEB4;
  border-radius: 9px;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  width: 295px;
  height: 57px;
}

.content-edit-template .text-area {
  background-color: #ffffff;
  border: 2px solid #12AEB4;
  border-radius: 43px;
  padding: 45px 51px 72px;
}

.content-edit-template .text-area textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  /* color: #B7B5B5; */
  border: none;
}

.content-edit-template .text-area textarea:focus {
  background-color: transparent;
  height: auto;
}

.content-edit-template .btn-custom1 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12AEB4;
  border-radius: 9px;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  width: 798px;
  height: 57px;
  margin-top: 133px;
}

.content-edit-template .upload-media .dropdown-menu {
  background: #EDEDED;
  border-radius: 36px;
  border: none;
  width: 458px;
  padding: 18px 23px 11px 46px;
  top: -60px !important;
  left: 16px !important;
}

.content-edit-template .upload-media .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  color: #655A5A;
  border-bottom: 1px solid #655A5A;
}

.content-edit-template .upload-media .dropdown-item:hover,
.content-edit-template .upload-media .dropdown-item:active {
  color: #12AEB4;
  background-color: transparent;
}

.content-edit-template .upload-media .dropdown-item:last-child {
  border: none;
}

/* content library upload photo modal */
#contentLibraryUploadPhoto .modal-content,
#contentLibraryUploadVideo .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 5px 5px rgba(0, 174, 179, 0.2784313725);
}

#contentLibraryUploadPhoto .go-back,
#contentLibraryUploadVideo .go-back {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px;
  color: #00AEB3;
}

#contentLibraryUploadPhoto .title,
#contentLibraryUploadVideo .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
  margin-bottom: 29px;
}

#contentLibraryUploadPhoto .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
  margin-bottom: 11px;
}

#contentLibraryUploadPhoto .modal-header,
#contentLibraryUploadVideo .modal-header {
  padding: 41px 40px;
}

#contentLibraryUploadPhoto .modal-body,
#contentLibraryUploadVideo .modal-body {
  padding: 13px 40px 41px 40px;
}

#contentLibraryUploadPhoto .uploaded-file .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 17px;
}

#contentLibraryUploadPhoto .uploaded-file .perc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #849292;
  float: right;
  margin-bottom: 17px;
}

#contentLibraryUploadPhoto .uploaded-file .progress {
  height: 8px;
}

#contentLibraryUploadPhoto .uploaded-file .progress-bar {
  background-color: #12AEB4;
  border-radius: 5px;
}

#contentLibraryUploadPhoto .btn-custom,
#contentLibraryUploadVideo .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffff;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 295px;
  height: 57px;
  margin-top: 40px;
}

/* content library upload video modal */
#contentLibraryUploadVideo .content-video {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #242424;
  background-color: #EBF7F6;
  border-color: #EBF7F6;
  border-radius: 4px;
  padding: 8px 23px 35px;
}

#contentLibraryUploadVideo .content-video .add-video {
  padding: 10px;
}

#contentLibraryUploadVideo .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  color: #3B4747;
  margin-bottom: 8px;
}

#contentLibraryUploadVideo .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #8D9296;
  background: #EBF7F6;
  border-radius: 4px;
}

#contentLibraryUploadVideo textarea {
  height: 106px;
  padding-right: 55px;
}

#contentLibraryUploadVideo .insert-code {
  position: relative;
}

#contentLibraryUploadVideo .right-img {
  position: absolute;
  top: 45px;
  right: 10px;
}

/* content library final page preview */
.content-final-preview.content-wrapper {
  background-color: #fff;
}

.content-final-preview section {
  padding-left: 45px;
  padding-right: 75px;
}

.content-final-preview .heading {
  margin-top: 49px;
}

.content-final-preview .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
  margin-bottom: 22px;
}

.content-final-preview .heading a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12AEB4;
  background: #EBFEFF;
  border-radius: 30px;
  padding: 10px 20px;
  width: 215px;
  height: 59px;
  margin-right: 28px;
}

.content-final-preview .breadcrumb {
  background-color: transparent;
}

.content-final-preview .breadcrumb-item a,
.content-final-preview .breadcrumb-item {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
}

.content-final-preview .breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #12AEB4;
  content: ">";
}

.content-final-preview .content-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 17px;
}

.content-final-preview .create-content {
  /* background: #EBF7F6; */
  background: #fff;
  border-radius: 4px;
  padding: 30px 25px 213px 17px;
  margin-right: 25px;
}

.content-final-preview .create-content-scroll {
  height: 1440px;
  overflow-y: scroll;
  border-right: 1px solid #E6EBF5;
}

.content-final-preview .create-content-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.content-final-preview .create-content-scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.content-final-preview .create-content-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.content-final-preview .create-content .template {
  background-color: #fff;
  /* border: 1px solid #707070; */
  border-radius: 32px;
  padding: 22px 30px;
  margin-bottom: 21px;
  position: relative;
}

.content-final-preview .template .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  color: #060136;
  margin-bottom: 29px;
}

.content-final-preview .template .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  margin-bottom: 23px;
  word-wrap: break-word;
}

.content-final-preview .template .arrow {
  position: absolute;
  top: -20px;
  right: -15px;
}

.content-final-preview .template .edit {
  position: absolute;
  right: -13px;
  bottom: 60px;
  width: 40px;
  height: 32px;
}

.content-final-preview .template .delete {
  position: absolute;
  right: -13px;
  bottom: 20px;
  width: 40px;
  height: 32px;
}

.content-final-preview .template .link {
  margin-bottom: 30px;
}

.content-final-preview .template .link a {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #12AEB4;
  text-decoration: underline;
}

.content-final-preview .template .download {
  background: #C7EBEB;
  border-radius: 5px;
  padding: 14px 20px;
  margin-bottom: 59px;
  position: relative;
}

.content-final-preview .template .download a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #12AEB4;
}

.content-final-preview .template .download .download-icon {
  position: absolute;
  top: -5px;
  right: 20px;
}

.content-final-preview .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #ffffff;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  max-width: 600px;
  height: 57px;
  margin-top: 56px;
}

/*member page css start*/
.admin-members .membership-search {
  position: relative;
}

.admin-members .membership-search input {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding-right: 64px;
}

.admin-members .search-icon {
  position: absolute;
  right: 14px;
  top: 4px;
}

.admin-members .member-download-link {
  text-align: center;
}

.admin-members .member-download-link .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #172B4D;
  margin-bottom: 0px;
}

.admin-members .member-download-link .link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #12AEB4;
  margin-bottom: 0px;
}

.admin-members .notification {
  position: relative;
  margin-right: 30px;
}

.admin-members .notification .oval {
  position: absolute;
  top: 8px;
  right: -4px;
}

.admin-members .user-info .dropdown-toggle {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #172B4D;
  margin-bottom: 0px;
}

.admin-members .user-info .dropdown-toggle::after {
  border: none;
}

.admin-members .back-to {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12AEB4;
  margin-bottom: 0px;
}

.admin-members .top-header {
  margin-top: 7px;
  margin-bottom: 21px;
}

.admin-members .top-header .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0A1629;
  margin-bottom: 0px;
}

.admin-members .top-header .calendar {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  background: #E6EDF5;
  border-radius: 14px;
  width: 280px;
  height: 48px;
  padding-left: 40px;
  background-image: url("../images/admin/icn-general-calendar.svg");
  background-repeat: no-repeat;
  background-position: 3% center;
  float: right;
}

.admin-members .top-buttons {
  padding-bottom: 13px;
  border-bottom: 1px solid #E6EBF5;
}

.admin-members .top-buttons .member-search {
  position: relative;
  margin-right: 15px;
}

.admin-members .top-buttons .member-search input {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding-right: 64px;
  width: 324px;
  height: 56px;
  margin-bottom: 16px;
}

.admin-members .top-buttons .search-icon {
  position: absolute;
  right: 14px;
  top: 5px;
}

.admin-members .top-buttons .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 14px;
  width: 212px;
  height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.admin-members .select-all {
  margin-top: 35px;
  margin-bottom: 19px;
}

.admin-members .select-all span {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7D8592;
}

.admin-members .select-all img {
  cursor: pointer;
}

.admin-members .member-list thead tr th {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #12AEB4;
  padding: 10px 5px;
}

.admin-members .member-list .dropdown #member-option i {
  color: #fff;
  background: #A0DBDD;
  border-radius: 14px;
  font-size: 18px;
  padding: 13px 19px;
}

.admin-members .member-list .dropdown.show #member-option {
  background: rgba(160, 219, 221, 0.3803921569);
  box-shadow: 0px 10px 10px rgba(160, 219, 221, 0.3803921569);
  padding: 17px 5px 8px 5px;
  border-radius: 50px;
}

.admin-members .member-list .dropdown.show #member-option i {
  box-shadow: 0px 10px 10px rgba(160, 219, 221, 0.3803921569);
  font-size: 22px;
  padding: 10px 16px;
  border-radius: 50px;
}

.admin-members .member-list tbody tr {
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.1019607843);
  border-radius: 24px;
}

.admin-members .member-list tbody tr td {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  vertical-align: middle;
  padding: 15px 5px;
}

.admin-members .member-list tbody tr td:last-child {
  width: 320px;
}

.admin-members .member-list tbody tr td .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow: none;
}

.admin-members .member-list tbody tr td .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #707070;
  background-color: #fff;
  box-shadow: none;
}

.admin-members .member-list tbody tr td .custom-control-label::after {
  width: 20px;
  height: 20px;
}

.admin-members .member-list tbody tr td .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2312AEB4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.admin-members .member-list tbody tr td .member-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 0px;
}

.admin-members .member-list tbody tr td .email {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #91929E;
  margin-bottom: 0px;
}

.admin-members .member-list tbody tr td .dropdown-menu {
  background: rgba(18, 174, 180, 0.1607843137);
  box-shadow: 5px 5px 10px rgba(34, 136, 133, 0.3607843137);
  border-radius: 25px;
  border: none;
  width: 239px;
}

.admin-members .member-list tbody tr td .dropdown-menu .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #19B0B6;
  letter-spacing: 0.38px;
}

.admin-members .member-list tbody tr td .dropdown-menu .dropdown-item.active,
.admin-members .member-list tbody tr td .dropdown-menu .dropdown-item:active {
  background-color: #ffffff;
}

.admin-members .member-list .pagination .number {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  margin-right: 21px;
}

.admin-members .member-list .pagination .arrow-left,
.admin-members .member-list .pagination .arrow-right {
  color: #C9CCD1;
  margin-right: 14px;
  font-size: 16px;
}

.admin-members .member-list .pagination .active {
  color: #12AEB4;
}

.admin-members .chat-list {
  margin-top: 35px;
  margin-bottom: 25px;
}

.admin-members .chat-list .chat {
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 24px;
  width: 75px;
  height: 848px;
  float: right;
  padding-top: 13px;
  padding-bottom: 13px;
  text-align: center;
}

.admin-members .chat-list .chat .user-image {
  width: 58px;
  height: 58px;
  margin: 4px 5px;
  border: 2px solid #FFFFFF;
  border-radius: 50px;
}

.admin-members .chat-list .chat .new-chat .chat-image {
  width: 42px;
  height: 42px;
  margin: 4px 5px;
  position: absolute;
  bottom: 0;
  right: 25px;
}

#filterMembersModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#filterMembersModal .modal-header {
  border-bottom: none;
}

#filterMembersModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#filterMembersModal .modal-body {
  padding: 0;
}

#filterMembersModal .member-level {
  padding-top: 22px;
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;
}

#filterMembersModal .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 9px;
}

#filterMembersModal select,
#filterMembersModal input {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 50px;
}

#filterMembersModal select {
  appearance: none;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

#filterMembersModal .member-join {
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;
}

#filterMembersModal .member-join .fa-calendar {
  position: absolute;
  top: 45px;
  right: 12px;
  font-size: 21px;
  color: #12AEB4;
}

#filterMembersModal .member-renew {
  padding-right: 30px;
  padding-bottom: 16px;
  padding-left: 30px;
}

#filterMembersModal .member-renew .fa-calendar {
  position: absolute;
  top: 45px;
  right: 12px;
  font-size: 21px;
  color: #12AEB4;
}

#filterMembersModal .member-list {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 35px;
}

#filterMembersModal .member-list .custom-checkbox {
  margin-right: 19px;
}

#filterMembersModal .member-list .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow: none;
}

#filterMembersModal .member-list .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #707070;
  background-color: #fff;
  box-shadow: none;
}

#filterMembersModal .member-list .custom-control-label::after {
  width: 20px;
  height: 20px;
}

#filterMembersModal .member-list .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2312AEB4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

#filterMembersModal .member-list .member-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  margin-left: 10px;
  margin-bottom: 0px;
}

#filterMembersModal .member-list .view-more {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12AEB4;
}

#filterMembersModal .social-group {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 4px;
  position: relative;
}

#filterMembersModal .social-group .social-bg {
  background-color: #EBF7F6;
  border-radius: 4px;
  padding: 15px;
}

#filterMembersModal .social-group input {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  height: 40px;
  border: 1px solid #12AEB4;
  border-radius: 20px;
  background-color: transparent;
}

#filterMembersModal .social-group .badge-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #0A1629;
  border-radius: 16px;
  margin-bottom: 12px;
  margin-right: 12px;
  border: 1px solid #12AEB4;
}

#filterMembersModal .social-group .badge-custom i {
  color: #12AEB4;
  font-size: 12px;
}

#filterMembersModal .match {
  padding-left: 30px;
  padding-right: 30px;
}

#filterMembersModal .match span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
}

#filterMembersModal .match img {
  width: 18px;
  height: 18px;
  margin-right: 9px;
}

#filterMembersModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12AEB4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 300px;
  height: 50px;
  margin-bottom: 50px;
}

#addFilesToBulkModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#addFilesToBulkModal .modal-header {
  border-bottom: 1px solid #E4E6E8;
}

#addFilesToBulkModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#addFilesToBulkModal .modal-body {
  padding: 0;
}

#addFilesToBulkModal .attach-file {
  padding-left: 30px;
  padding-right: 30px;
}

#addFilesToBulkModal .attach-file .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
  margin-bottom: 15px;
}

#addFilesToBulkModal .attach-file .input-file {
  text-align: center;
}

#addFilesToBulkModal .audience {
  padding-left: 30px;
  padding-right: 30px;
}

#addFilesToBulkModal .audience .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
}

#addFilesToBulkModal .audience .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
}

#addFilesToBulkModal .audience select {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  -webkit-appearance: none;
  appearance: none;
}

#addFilesToBulkModal .audience .view-more {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #12AEB4;
}

#addFilesToBulkModal .file-history {
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 16px;
}

#addFilesToBulkModal .file-history .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 21px;
  line-height: 31px;
  color: #3B4747;
  margin-bottom: 19px;
}

#addFilesToBulkModal .file-history .file-icon i {
  font-size: 50px;
  margin-right: 28px;
  color: #12AEB4;
}

#addFilesToBulkModal .file-history .file-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  color: #0A1629;
  margin-bottom: 10px;
}

#addFilesToBulkModal .file-history .date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7D8592;
  margin-bottom: 0px;
}

#addFilesToBulkModal .file-history .action-icon {
  font-size: 20px;
  text-align: center;
  color: #89D7DA;
}

#addFilesToBulkModal .file-history .action-icon .fa-trash-alt {
  margin-left: 10px;
}

#addFilesToBulkModal .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 21px;
  line-height: 28px;
  color: #fff;
  background: #12AEB4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 198px;
  height: 63px;
  margin-bottom: 34px;
}

#addFilesToBulkModal .audience .sub-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 50px;
  color: #12AEB4;
  margin-top: 20px;
  margin-bottom: 0px;
}

/* import Members Modal */
#importMembersModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#importMembersModal .modal-header {
  border-bottom: none;
}

#importMembersModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
}

#importMembersModal .modal-body {
  padding: 0;
}

#importMembersModal .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12AEB4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 287px;
  height: 48px;
  margin-top: 30px;
  margin-bottom: 20px;
}

#importMembersModal .instruction {
  padding-left: 42px;
  padding-right: 37px;
  padding-bottom: 18px;
}

#importMembersModal .instruction p {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #7D8592;
  margin-bottom: 0px;
}

#importMembersModal .instruction p:nth-last-child(2) {
  margin-bottom: 30px;
}

#importMembersModal .instruction small {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #7D8592;
}

#importMembersModal .instruction2 {
  padding-left: 42px;
  padding-right: 37px;
  padding-top: 18px;
  padding-bottom: 2px;
}

#importMembersModal .instruction2 ul li {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #7D8592;
}

#importMembersModal .btn-custom2 {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12AEB4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 150px;
  height: 48px;
  margin-bottom: 13px;
}

/* add/edit Members Modal */
#addEditMembersModal .modal-content {
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
  border-radius: 24px;
}

#addEditMembersModal .modal-header {
  border-bottom: none;
  padding-bottom: 0px;
}

#addEditMembersModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #0A1629;
}

#addEditMembersModal .modal-body {
  padding: 0;
}

#addEditMembersModal .member-info {
  padding-top: 23px;
  padding-left: 30px;
  padding-right: 30px;
}

#addEditMembersModal .member-info img {
  border-radius: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
}

#addEditMembersModal .member-info .upload-img {
  position: absolute;
  top: 0px;
  right: 30px;
  background: #D7F1F2;
  border-radius: 14px;
  padding: 8px 10px 8px 12px;
  margin-bottom: 0px;
  color: #12AEB4;
}

#addEditMembersModal .member-info .name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0A1629;
  margin-bottom: 0px;
}

#addEditMembersModal .member-info .text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0A1629;
  margin-bottom: 0px;
}

#addEditMembersModal .member-info .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #0A1629;
  margin-bottom: 7px;
}

#addEditMembersModal .member-info label,
#addEditMembersModal .member-level label,
#addEditMembersModal .additional-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #506278;
  margin-bottom: 0px;
}

#addEditMembersModal .member-info .form-control,
#addEditMembersModal .member-level .form-control,
#addEditMembersModal .additional-info .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

#addEditMembersModal .member-level {
  padding-top: 13px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 16px;
  border-bottom: 1px solid #E4E6E8;
}

#addEditMembersModal .member-level select {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7D8592;
  background-image: url("../images/admin/Polygon 65.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
  appearance: none;
}

#addEditMembersModal .member-level .custom-control-label::before {
  width: 20px;
  height: 20px;
  border: 1px solid #707070;
  background-color: #fff;
  box-shadow: none;
}

#addEditMembersModal .member-level .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #707070;
  background-color: #fff;
  box-shadow: none;
}

#addEditMembersModal .member-level .custom-control-label::after {
  width: 20px;
  height: 20px;
}

#addEditMembersModal .member-level .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2312AEB4' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

#addEditMembersModal .member-level .custom-control-label {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #0A1629;
}

#addEditMembersModal .member-level .fa-link,
#addEditMembersModal .member-level .fa-calendar {
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 21px;
  color: #12AEB4;
}

#addEditMembersModal .additional-info {
  padding-top: 13px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
}

#addEditMembersModal .additional-info .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  color: #0A1629;
  margin-bottom: 11px;
}

#addEditMembersModal .additional-info .fa-map-marker-alt,
#addEditMembersModal .additional-info .fa-calendar {
  position: absolute;
  top: 40px;
  right: 15px;
  font-size: 21px;
  color: #12AEB4;
}

#addEditMembersModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 300px;
  height: 48px;
  margin-bottom: 48px;
}

/*admin social wall*/
.content-wrapper.enduser-social-wall {
  background-color: #fff;
}

.enduser-social-wall .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0A1629;
}

.enduser-social-wall .calendar {
  position: relative;
}

.enduser-social-wall .calendar input {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #12AEB4;
  border-radius: 14px;
  background-color: #D7F1F2;
  padding-left: 50px;
  width: 60%;
}

.enduser-social-wall .calendar:after {
  content: "\f133";
  font-family: "FONT AWESOME 5 FREE";
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  color: #459ce7;
  font-size: 18px;
  position: absolute;
  top: 12px;
  left: 20px;
}

.enduser-social-wall #socialWallTab {
  border: none;
  align-items: center;
  margin-top: 30px;
}

.enduser-social-wall #socialWallTab .nav-item {
  width: 196px;
  margin-right: 35px;
}

.enduser-social-wall #socialWallTab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8D9296;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.enduser-social-wall #socialWallTab .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #4A62A0;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #4A62A0;
}

.enduser-social-wall #socialWallTab .search-library {
  position: relative;
}

.enduser-social-wall #socialWallTab .search-library input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  /* color: #8D91A1; */
  background: #FFFFFF;
  box-shadow: 0px 3px 9px #7284CC;
  border: 1px solid #E5E5E5;
  width: 520px;
  height: 58px;
  padding-right: 52px;
}

.enduser-social-wall #socialWallTab .search-icon {
  position: absolute;
  inset: 7px 7px 7px auto;
}

.enduser-social-wall #socialWallTab .search-icon .fa-search {
  font-size: 39px;
  color: #7E828F;
}

.enduser-social-wall #socialWallTab .btn-custom {
  background-color: #A0DBDD;
  border-radius: 14px;
  color: #fff;
  margin-left: 40px;
  width: 67px;
  height: 60px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.enduser-social-wall #socialWallTabContent {
  margin-top: 45px;
  margin-bottom: 100px;
}

.enduser-social-wall #socialWallTabContent .owl-stage {
  display: flex;
  flex-wrap: wrap;
}

.enduser-social-wall #socialWallTabContent .owl-item {
  padding: 18px;
}

.enduser-social-wall #socialWallTabContent .item {
  background: #FFFFFF;
  border: 3px solid #EBEBEB;
  box-shadow: 5px 5px 20px var(--color-grey);
  border-radius: 13px;
  padding: 16px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-bottom: 16px;
}

.enduser-social-wall #socialWallTabContent .item:hover {
  border: 3px solid var(--brand-color);
}

.enduser-social-wall #socialWallTabContent .item .img-over {
  height: 145px;
}

.enduser-social-wall #socialWallTabContent .item .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 8px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  height: 54px;
}

.enduser-social-wall #socialWallTabContent .item .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-top: 5px;
}

.enduser-social-wall #socialWallTabContent .item .slide-text .fa-history {
  color: #7284CC;
  font-size: 25px;
}

.enduser-social-wall #socialWallTabContent .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.enduser-social-wall #socialWallTabContent .owl-prev {
  position: absolute;
  top: 135px;
  left: -50px;
}

.enduser-social-wall #socialWallTabContent .owl-next {
  position: absolute;
  top: 130px;
  right: -60px;
}

.enduser-social-wall #socialWallTabContent .owl-prev img {
  width: 63px;
  height: 63px;
}

.enduser-social-wall #socialWallTabContent .owl-next img {
  width: 63px;
  height: 63px;
}

.enduser-social-wall .post-title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #0A1629;
  margin-bottom: 10px;
}

.enduser-social-wall .post-desc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #506278;
  margin-bottom: 14px;
}

.enduser-social-wall .post-desc-list {
  margin-bottom: 30px;
}

.enduser-social-wall .post-desc-list li {
  list-style-image: url("../images/enduser/noun-chat-254985.svg");
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #506278;
  margin-bottom: 14px;
}

.enduser-social-wall .post-area {
  border: 1px solid var(--brand-color);
  border-radius: 12px;
  padding: 30px 42px;
  margin-bottom: 58px;
}

.enduser-social-wall .post-area textarea.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #495057;
  border: none;
  border-radius: 5px;
  background-color: #F4F9FD;
  height: 200px;
  margin-bottom: 34px;
}

.enduser-social-wall .post-area .fa-ellipsis-v,
.fa-ellipsis-v {
  float: right;
  color: #7284CC;
  font-size: 22px;
}

.enduser-social-wall .post-area .btn-custom,
.help-modal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #fff;
  background-color: var(--brand-color);
  border-radius: 12px;
  width: 246px;
  height: 71px;
  float: right;
}

.enduser-social-wall .post-area .uploaded-img {
  position: relative;
  width: 239px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.enduser-social-wall .post-area .uploaded-img:nth-child(4n+0) {
  margin-right: 0px;
}

.enduser-social-wall .post-area .cross {
  position: absolute;
  top: 5px;
  right: 5px;
}

.enduser-social-wall .posts {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 12px;
  padding: 30px 42px;
  margin-bottom: 58px;
}

.enduser-social-wall .posts .media-title,
#showPostModal .posts .media-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 24px;
  color: #1E1F20;
  margin-bottom: 8px;
}

.enduser-social-wall .posts .comment-bg,
#showPostModal .posts .comment-bg {
  background-color: rgba(114, 132, 204, 0.1725490196);
  border-radius: 16px;
  padding: 10px 15px;
  margin-bottom: 26px;
}

.enduser-social-wall .posts .media-title.comment-name,
#showPostModal .posts .media-title.comment-name {
  color: #12AEB4;
}

.enduser-social-wall .posts .comment-bg .post-text,
#showPostModal .posts .comment-bg .post-text {
  border-bottom: 1px solid #7284CC;
}

.enduser-social-wall .posts .comment-bg .row:last-child .post-text,
#showPostModal .posts .comment-bg .row:last-child .post-text {
  border-bottom: none;
  padding-bottom: 0px;
}

.enduser-social-wall .posts .media-text,
#showPostModal .posts .media-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #6D7886;
}

.enduser-social-wall .posts .fa-ellipsis-v,
#showPostModal .posts .fa-ellipsis-v {
  float: right;
  color: #7284CC;
  font-size: 22px;
}

.enduser-social-wall .posts .post-text,
#showPostModal .posts .post-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6D7886;
  margin-bottom: 10px;
  padding-bottom: 10px;
  word-break: break-word;
  word-wrap: break-word;
}

.enduser-social-wall .posts .lg-img {
  width: 550px;
  height: 343px;
  margin-right: 17px;
  margin-bottom: 10px;
  cursor: pointer;
}

.enduser-social-wall .posts .sm-img {
  width: 253px;
  height: 163px;
  margin-right: 17px;
  margin-bottom: 17px;
  cursor: pointer;
}

.enduser-social-wall .posts .like,
.enduser-social-wall .posts .comment,
#showPostModal .posts .like,
#showPostModal .posts .comment {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 24px;
  color: #1E1F20;
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.enduser-social-wall .posts input.form-control,
#showPostModal .posts input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #6D7886;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #7284CC;
  border-radius: 14px;
  height: 34px;
}

.enduser-social-wall .posts .submit-img,
#socialCreateModal .topic .submit-img {
  cursor: pointer;
}

#showPostModal #postCarousel {
  padding: 0 20px;
  margin-top: -90px;
}

#showPostModal .carousel-control-prev {
  left: -50px;
  border: none;
  background: transparent;
}

#showPostModal .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2312AEB4' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

#showPostModal .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2312AEB4' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

#showPostModal .carousel-control-next {
  right: -50px;
  border: none;
  background: transparent;
}

#showPostModal .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

#showPostModal .owl-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -25px;
}

#showPostModal .owl-next {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -25px;
}

#showPostModal .social-modal-slider {
  margin-top: -90px;
}

#showPostModal .posts .scroll {
  max-height: 270px;
  overflow-y: scroll;
  border-right: 1px solid #ccc;
}

#showPostModal .posts .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#showPostModal .posts .scroll::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

#showPostModal .posts .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

#socialCreateModal .modal-header .close img {
  width: 35px;
}

#socialCreateModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #7284CC;
}

#socialCreateModal .modal-content {
  border-radius: 24px;
}

#socialCreateModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #3C3D43;
  margin-bottom: 17px;
}

#socialCreateModal .label-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 9px;
}

#socialCreateModal .label-title small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #A6A7AE;
}

#socialCreateModal input.form-control,
#socialCreateModal input.form-control:focus {
  background-color: rgba(114, 132, 204, 0.1725490196);
  color: #222;
  border-color: rgba(114, 132, 204, 0.1725490196);
  font-size: 16px;
  line-height: 25px;
  height: 58px;
}

#socialCreateModal textarea.form-control,
#socialCreateModal textarea.form-control:focus {
  background-color: rgba(114, 132, 204, 0.1725490196);
  color: #222;
  border-color: rgba(114, 132, 204, 0.1725490196);
  font-size: 16px;
  line-height: 25px;
  height: 143px;
}

#socialCreateModal .table thead tr th {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #7D8592;
  padding: 8px;
}

#socialCreateModal .table tbody tr td {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  padding: 8px;
}

#socialCreateModal .table-height {
  height: 214px;
  overflow: hidden;
}

#socialCreateModal .member-img {
  width: 24px;
  height: 24px;
  border-radius: 50px;
  margin-right: 10px;
}

#socialCreateModal .view-more {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #7284CC;
}

#socialCreateModal .topic {
  padding: 15px 10px;
  background-color: rgba(114, 132, 204, 0.1725490196);
}

#socialCreateModal .topic .badge {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0A1629;
  padding: 6px 20px;
  border: 1px solid #7284CC;
  border-radius: 20px;
  margin-bottom: 15px;
}

#socialCreateModal .topic .badge img {
  width: 20px;
  margin-left: 10px;
}

#socialCreateModal .topic input.form-control {
  height: 40px;
  background-color: rgba(114, 132, 204, 0.1725490196);
  border: 1px solid #7284CC;
  border-radius: 20px;
}

#socialCreateModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #FFFFFF;
  width: 330px;
  height: 57px;
  background: #7284CC;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  margin-top: 16px;
  margin-bottom: 32px;
}

/*admin courses */
.content-wrapper.admin-courses,
.content-wrapper.admin-student-queries {
  background-color: #fff;
  margin-right: 30px;
}

.admin-courses .title,
.admin-student-queries .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.admin-courses .title a,
.admin-student-queries .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.admin-courses .content-header .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  background: #12AEB4;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 14px;
  width: 323px;
  height: 48px;
  float: right;
}

.admin-courses .courses-folders {
  padding-left: 7.5px;
  margin-bottom: 34px;
}

.admin-courses .courses-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.admin-courses .courses-folders .title small {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 0px;
}

.admin-courses .courses-folders label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.admin-courses .courses-folders .sort,
.admin-courses .courses-folders .date {
  width: 18%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background: #EBF7F6;
  border-radius: 4px;
  padding-left: 20px;
}

.admin-courses .courses-folders .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #12AEB4;
  background: #C7EBEB;
  border-radius: 6px;
  width: 150px;
  height: 42px;
  margin-bottom: 40px;
}

.admin-courses .courses-folders .btn-custom:hover {
  background-color: #12AEB4;
  color: #fff;
}

.admin-courses .courses-folders .courses-slider {
  margin-top: 60px;
  margin-bottom: 45px;
}

.admin-courses .courses-folders .courses-slider .main-img {
  position: relative;
}

.admin-courses .courses-folders .courses-slider .img-over {
  margin-bottom: 14px;
  height: 160px;
}

.admin-courses .courses-folders .courses-slider .arrows-img {
  position: absolute;
  bottom: -20px;
  right: 0px;
  width: 40px;
  height: 44px;
}

.admin-courses .courses-folders .courses-slider .slide-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-bottom: 8px;
  word-break: break-word;
}

.admin-courses .courses-folders .courses-slider .slide-text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 10px;
}

.admin-courses .courses-folders .courses-slider .owl-stage-outer {
  padding: 30px 0px;
}

.admin-courses .courses-folders .courses-slider .item {
  background: #FFFFFF;
  box-shadow: 5px 5px 20px rgba(141, 146, 150, 0.2784313725);
  border: 1px solid #EBEBEB;
  border-radius: 13px;
  padding: 10px;
}

.admin-courses .courses-folders .courses-slider .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.admin-courses .courses-folders .courses-slider .owl-prev {
  position: absolute;
  top: 135px;
  left: -10px;
}

.admin-courses .courses-folders .courses-slider .owl-next {
  position: absolute;
  top: 130px;
  right: -15px;
}

.admin-courses .courses-folders .courses-slider .owl-prev img {
  width: 63px;
  height: 63px;
}

.admin-courses .courses-folders .courses-slider .owl-next img {
  width: 63px;
  height: 63px;
}

.admin-courses .courses-list #coursesTab,
.admin-courses .courses-folders #coursesTab {
  border: none;
  align-items: center;
  cursor: pointer;
}

.admin-courses .courses-list .nav-item,
.admin-courses .courses-folders .nav-item {
  width: 200px;
  margin-right: 35px;
}

.admin-courses .courses-list .nav-link,
.admin-courses .courses-folders .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #C9C9C9;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.admin-courses .courses-list .nav-link.active,
.admin-courses .courses-folders .nav-link.active {
  color: #8D9296;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #8D9296;
}

.admin-courses .courses-folders #viewAll-tab,
.admin-courses .courses-list #viewAll-tab {
  color: #8D9296;
}

.admin-courses .courses-folders .search-course,
.admin-courses .content-header .search-course {
  position: relative;
}

.admin-courses .courses-folders .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #E5E5E5;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-courses .content-header .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #E5E5E5;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-courses .courses-folders .search-icon,
.admin-courses .content-header .search-icon {
  position: absolute;
  inset: 7px 7px 7px auto;
}

.admin-courses .courses-folders .search-icon .fa-search {
  font-size: 39px;
  color: #7E828F;
}

.admin-courses .courses-list #coursesTabContent {
  background: #FFFFFF;
  box-shadow: 0px 3px 17px #C7DCDA;
  border-radius: 4px;
  padding: 8px 16px 4px 16px;
  margin-top: 45px;
  margin-bottom: 100px;
  max-height: 516px;
  overflow-y: scroll;
}

.admin-courses .courses-list #coursesTabContent::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-courses .courses-list #coursesTabContent::-webkit-scrollbar-thumb {
  background: #8D9296;
  border-radius: 10px;
  height: 50px;
}

.admin-courses .courses-list #coursesTabContent::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-courses .courses-list #coursesTabContent .table tr:first-child td {
  border-top: none;
}

.admin-courses .courses-list #coursesTabContent .table td:first-child {
  padding: 0;
  padding-bottom: 11px;
}

.admin-courses .courses-list #coursesTabContent .table td {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  vertical-align: baseline;
  border-top: 1px solid #CCCFCF;
}

.admin-courses .courses-list #coursesTabContent .table-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #060136;
  margin-bottom: 0px;
}

.admin-courses .courses-list #coursesTabContent .table td:nth-child(2),
.admin-courses .courses-list #coursesTabContent .table td:nth-child(3) {
  word-break: break-word;
  word-wrap: break-word;
}

.admin-courses .courses-list #coursesTabContent .btn-live {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #fff;
  background-color: rgba(141, 146, 150, 0.4980392157);
  border-radius: 5px;
  padding: 4px 34px;
  cursor: pointer;
}

.admin-courses .courses-list #coursesTabContent .btn-live:hover {
  background-color: #8D9296;
}

.admin-courses .courses-list #coursesTabContent .btn-live:focus {
  box-shadow: 0 0 0 0.15rem #8D9296;
}

.admin-courses .courses-list #coursesTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  padding-left: 10px;
  margin-bottom: 0px;
}

.admin-courses .courses-list .student-queries,
.admin-student-queries .student-queries {
  background: #FFFFFF;
  box-shadow: 0px 3px 24px #D7F1F2;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 16px;
  margin-bottom: 20px;
}

.admin-courses .courses-list .student-queries .queries-icon {
  position: relative;
}

.admin-courses .courses-list .student-queries .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #12AEB4;
  background: #C0E0E2;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
  position: absolute;
  top: -35px;
  right: 20px;
}

.admin-courses .courses-list .student-queries .queries-icon .question-img {
  position: absolute;
  top: -30px;
}

.admin-courses .courses-list .student-queries .title,
.admin-student-queries .student-queries .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  padding-bottom: 25px;
}

.admin-courses .courses-list .student-queries .search-queries,
.admin-student-queries .student-queries .search-queries {
  position: relative;
  margin-bottom: 31px;
}

.admin-courses .courses-list .student-queries .search-queries input,
.admin-student-queries .student-queries .search-queries input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  background: #F1F1F1;
  border-radius: 6px;
  border: 1px solid #E5E5E5;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-courses .courses-list .student-queries .search-icon,
.admin-student-queries .student-queries .search-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}

.admin-courses .courses-list .student-queries .search-icon img,
.admin-student-queries .student-queries .search-icon img {
  width: 29px;
}

.admin-courses .courses-list .student-queries .sub-title,
.admin-student-queries .student-queries .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #12AEB4;
  margin-bottom: 15px;
}

.admin-courses .courses-list .student-queries .messages-list,
.admin-student-queries .student-queries .messages-list {
  max-height: 510px;
  overflow-y: scroll;
  display: block;
  border-right: 2px solid #ccc;
  padding-right: 5px;
}

.admin-courses .courses-list .student-queries .messages-list::-webkit-scrollbar,
.admin-student-queries .student-queries .messages-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-courses .courses-list .student-queries .messages-list::-webkit-scrollbar-thumb,
.admin-student-queries .student-queries .messages-list::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.admin-courses .courses-list .student-queries .messages-list::-webkit-scrollbar-track,
.admin-student-queries .student-queries .messages-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-courses .courses-list .student-queries .messages-list .media,
.admin-student-queries .student-queries .messages-list .media {
  border-bottom: 1px solid #E8E8E8;
  padding: 10px;
}

.admin-courses .courses-list .student-queries .messages-list .media img,
.admin-student-queries .student-queries .messages-list .media img,
.admin-student-queries .message-detail .media img {
  width: 48px;
  height: 48px;
}

.admin-courses .courses-list .student-queries .messages-list .media .img-rounded,
.admin-student-queries .student-queries .messages-list .media .img-rounded,
.admin-student-queries .message-detail .media .img-rounded,
.admin-student-queries .message-detail .media .img-rounded {
  border-radius: 50px;
}

.admin-courses .courses-list .student-queries .messages-list .media:hover,
.admin-student-queries .student-queries .messages-list .media:hover {
  background-color: #F4F9FD;
  border-radius: 6px;
}

.admin-courses .courses-list .student-queries .messanger-name,
.admin-student-queries .student-queries .messanger-name,
.admin-student-queries .message-detail .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 15px;
  color: #333244;
  margin-bottom: 5px;
}

.admin-courses .courses-list .student-queries .msg-time,
.admin-student-queries .student-queries .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #7D8592;
}

.admin-courses .courses-list .student-queries .messanger-text,
.admin-student-queries .student-queries .messanger-text,
.admin-student-queries .message-detail .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #91929E;
  margin-bottom: 5px;
}

.admin-courses .courses-list .student-queries .msg-count,
.admin-student-queries .student-queries .msg-count {
  background-color: red;
  border: 2px solid #FFFFFF;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

.admin-courses .course-detail .course-img {
  margin-top: 50px;
  margin-bottom: 20px;
  position: relative;
}

.admin-courses .course-detail .edit-img {
  position: absolute;
  top: -8px;
  right: -9px;
}

.admin-courses .course-detail .course-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0A1629;
  background-color: #E6EDF5;
  border-radius: 23px;
  padding: 10px 30px;
  margin-right: 25px;
  margin-bottom: 0px;
}

.admin-courses .course-detail .student-img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #fff;
}

.admin-courses .course-detail .student-img img:nth-child(1),
.admin-courses .course-detail .student-img img:nth-child(2) {
  margin-right: -14px;
}

.admin-courses .course-detail .price {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
}

.admin-courses .course-detail .price span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #0A1629;
}

.admin-courses .course-detail .course-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #7D8592;
  margin-top: 30px;
  margin-bottom: 60px;
}

#selectFolderModal .select-folder .card .card-body .student-info,
#insideFolderModal .folder .card .card-body .student-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #7D8592;
  text-align: right;
}

#selectFolderModal .select-folder .card .card-body .student-info img:nth-child(1),
#insideFolderModal .folder .card .card-body .student-info img:nth-child(1),
#selectFolderModal .select-folder .card .card-body .student-info img:nth-child(2),
#insideFolderModal .folder .card .card-body .student-info img:nth-child(2) {
  margin-right: -7px;
}

#addLessonDetailsModal .direct-add-file {
  padding-right: 40px;
}

#addLessonDetailsModal .plus-icon {
  position: absolute;
  top: 20px;
  right: 12px;
}

#addLessonDetailsModal .uploaded-file {
  background: #C7EBEB;
  border-radius: 5px;
  padding: 4px 15px;
  position: relative;
}

#addLessonDetailsModal .uploaded-file span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #12AEB4;
}

#addLessonDetailsModal .cancel-file {
  position: absolute;
  top: -5px;
  right: 20px;
}

#uploadLessonModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #fff;
  background: #12AEB4;
  box-shadow: 5px 5px 10px rgba(31, 122, 126, 0.537254902);
  border-radius: 9px;
  width: 330px;
  height: 57px;
  margin-top: 20px;
}

/*student queries*/
.admin-student-queries .message-detail {
  background: #FFFFFF;
  box-shadow: 0px 3px 24px #D7F1F2;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 40px;
}

.admin-student-queries .message-detail .media img {
  width: 48px;
  height: 48px;
}

.admin-student-queries .message-detail .day-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7D8592;
  box-shadow: 0px 2px 16px rgba(196, 203, 214, 0.1019607843);
  border: 1px solid #E6EBF5;
  border-radius: 20px;
  padding: 2px 17px;
  margin-top: 40px;
  margin-bottom: 18px;
}

.admin-student-queries .message-detail .chat-messages .media {
  margin-bottom: 14px;
}

.admin-student-queries .message-detail .chat-messages .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 5px;
}

.admin-student-queries .message-detail .chat-messages .msg-time {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #7D8592;
}

.admin-student-queries .message-detail .chat-messages .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 5px;
}

.admin-student-queries .message-detail .chat-messages .link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15C0E6;
  background: rgba(21, 192, 230, 0.1019607843);
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 15px;
  display: inline-block;
}

.admin-student-queries .message-detail .chat-messages .link img {
  width: 21px;
  height: 21px;
}

.admin-student-queries .message-detail .chat-messages .attachment {
  border: 1px solid #D8DDE6;
  border-radius: 14px;
  padding: 13px;
  width: 90%;
}

.admin-student-queries .message-detail .chat-messages .attachment .attch-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0A1629;
  margin-bottom: 4px;
}

.admin-student-queries .message-detail .chat-messages .attachment .file-size {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91929E;
  margin-bottom: 0px;
}

.admin-student-queries .message-detail .chat-messages .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding: 6px;
  margin-top: 42px;
  margin-bottom: 42px;
}

.admin-student-queries .message-detail .chat-messages .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  height: 36px;
}

/*messages*/
.admin-messages {
  background-color: #fff;
}

.admin-messages .top-header {
  margin-top: 30px;
  margin-bottom: 30px;
}

.admin-messages .top-header .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  color: #0A1629;
  margin-bottom: 0px;
}

.admin-messages .top-header .calendar {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #12AEB4;
  background: #A0DBDD;
  border-radius: 14px;
  width: 358px;
  height: 60px;
  padding-left: 40px;
  background-image: url("../images/admin/icn-general-calendar.svg");
  background-repeat: no-repeat;
  background-position: 3% center;
  float: right;
}

.admin-messages .message-section {
  background-color: #ffffff;
  box-shadow: 0px 6px 58px rgba(196, 203, 214, 0.1019607843);
  border-radius: 24px;
  padding-top: 33px;
}

.admin-messages .student-queries {
  border-right: 1px solid #E8E8E8;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 20px;
}

.admin-messages .student-queries .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
  color: #0A1629;
  padding-bottom: 25px;
}

.admin-messages .student-queries .search-queries {
  position: relative;
  margin-top: 31px;
  margin-bottom: 31px;
}

.admin-messages .student-queries .search-queries input {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  width: 100%;
  height: 58px;
  padding-right: 52px;
}

.admin-messages .student-queries .search-icon {
  position: absolute;
  top: 7px;
  right: 7px;
}

.admin-messages .student-queries .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  color: #12AEB4;
  margin-bottom: 30px;
  padding-left: 20px;
}

.admin-messages .student-queries .messages-list {
  max-height: 510px;
  overflow-y: scroll;
  display: block;
  padding-right: 5px;
}

.admin-messages .student-queries .messages-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.admin-messages .student-queries .messages-list::-webkit-scrollbar-thumb {
  background: #12AEB4;
  border-radius: 10px;
  height: 50px;
}

.admin-messages .student-queries .messages-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

.admin-messages .student-queries .messages-list .media {
  padding: 10px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 5px 5px 5px rgba(18, 174, 180, 0.1882352941);
  border-radius: 24px;
}

.admin-messages .student-queries .messages-list .media:hover,
.admin-messages .student-queries .messages-list .media.active {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.4901960784);
  border-radius: 24px;
}

.admin-messages .student-queries .messages-list .media img {
  width: 44px;
  height: 44px;
}

.admin-messages .student-queries .messages-list .media .img-rounded,
.admin-messages .message-detail .media .img-rounded {
  border-radius: 50px;
}

.admin-messages .student-queries .messanger-name,
.admin-messages .message-detail .messanger-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  color: #0A1629;
  margin-bottom: 0px;
}

.admin-messages .student-queries .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #7D8592;
}

.admin-messages .student-queries .messanger-text,
.admin-messages .message-detail .messanger-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #91929E;
  margin-bottom: 0px;
}

.admin-messages .student-queries .msg-count {
  background-color: red;
  border: 2px solid #FFFFFF;
  border-radius: 9px;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #fff;
  width: 35px;
  height: 25px;
  text-align: center;
}

.admin-messages .message-detail {
  padding: 0px 16px 16px;
  margin-bottom: 40px;
}

.admin-messages .message-detail .media img {
  width: 56px;
  height: 56px;
}

.admin-messages .chat-messages .media img {
  width: 48px;
  height: 48px;
}

.admin-messages .message-detail .day-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7D8592;
  background-color: #fff;
  box-shadow: 0px 2px 16px rgba(196, 203, 214, 0.1019607843);
  border: 1px solid #E6EBF5;
  border-radius: 20px;
  padding: 2px 17px;
  margin-top: 40px;
  margin-bottom: 18px;
}

.admin-messages .message-detail .chat-messages .media {
  margin-bottom: 14px;
}

.admin-messages .message-detail .chat-messages .messanger-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #0A1629;
  margin-bottom: 5px;
}

.admin-messages .message-detail .chat-messages .msg-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 27px;
  color: #7D8592;
}

.admin-messages .message-detail .chat-messages .messanger-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 5px;
}

.admin-messages .message-detail .chat-messages .link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #15C0E6;
  background: rgba(21, 192, 230, 0.1019607843);
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 15px;
  display: inline-block;
}

.admin-messages .message-detail .chat-messages .link img {
  width: 21px;
  height: 21px;
}

.admin-messages .message-detail .chat-messages .attachment {
  border: 1px solid #D8DDE6;
  border-radius: 14px;
  padding: 13px;
  width: 90%;
}

.admin-messages .message-detail .chat-messages .attachment .attch-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0A1629;
  margin-bottom: 4px;
}

.admin-messages .message-detail .chat-messages .attachment .file-size {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91929E;
  margin-bottom: 0px;
}

.admin-messages .message-detail .chat-messages .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding: 6px;
  margin-top: 42px;
  margin-bottom: 42px;
}

.admin-messages .message-detail .chat-messages .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  background-color: transparent;
  border: none;
  height: 46px;
}

/*event*/
.admin-event {
  margin-left: 50px;
  margin-right: 50px;
}

.admin-event .top-header .title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 49px;
  color: #0A1629;
  margin-bottom: 0px;
}

.admin-event .top-header .calendar {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  background: #E6EDF5;
  border-radius: 14px;
  width: 280px;
  height: 48px;
  padding-left: 40px;
  background-image: url(../images/admin/icn-general-calendar.svg);
  background-repeat: no-repeat;
  background-position: 3% center;
  float: right;
}

.admin-event .top-buttons {
  margin-bottom: 40px;
}

.admin-event .top-buttons .member-search {
  position: relative;
  margin-right: 15px;
}

.admin-event .top-buttons .member-search input {
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding-right: 64px;
  width: 324px;
  height: 56px;
  margin-bottom: 16px;
}

.admin-event .top-buttons .search-icon {
  position: absolute;
  inset: 5px 5px 5px auto;
}

.admin-event .top-buttons .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 5px 10px 10px rgba(18, 174, 180, 0.5019607843);
  border-radius: 14px;
  width: 212px;
  height: 48px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 16px;
}

.admin-event .calendar {
  margin-bottom: 25px;
}

.admin-event .calendar .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  color: #1D2445;
  margin-bottom: 32px;
}

.admin-event .calendar .day {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #A0DBDD;
  background-color: rgba(18, 174, 180, 0.1568627451);
  border-radius: 12px;
  padding: 15px 10px;
  text-align: center;
  width: 48px;
  height: 75px;
  margin: 0 5px;
  margin-bottom: 10px;
}

.admin-event .calendar .day.active {
  background-color: #12AEB4;
  color: #fff;
}

.admin-event .upcoming-event {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px #C9C9C9;
  border-radius: 24px;
  padding: 24px;
  margin-bottom: 20px;
}

.admin-event .upcoming-event .title {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #0A1629;
  margin-bottom: 24px;
}

.admin-event .upcoming-event .attach-info {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
}

.admin-event .upcoming-event .attach-info .info {
  visibility: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #024F6C;
  background: rgba(160, 219, 221, 0.4588235294);
  border: 1px solid #12AEB4;
  border-radius: 13px;
  padding: 4px;
  width: 77px;
  position: absolute;
  bottom: 0px;
  right: 16px;
}

.admin-event .upcoming-event .attach-info:hover .info {
  visibility: visible;
}

.admin-event .upcoming-event .event-list {
  border-left: 4px solid #FFBD21;
  padding-left: 20px;
  margin-bottom: 24px;
}

.admin-event .upcoming-event .event-list.active {
  border-left: 4px solid #8D9296;
}

.admin-event .upcoming-event .event-list:last-child {
  margin-bottom: 0px;
}

.admin-event .upcoming-event .event-list.collegue {
  border-color: #12AEB4;
}

.admin-event .upcoming-event .event-list.you {
  border-color: #FFBD21;
}

.admin-event .upcoming-event .event-list .event-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 0px;
}

.admin-event .upcoming-event .event-list .event-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.27px;
  color: #343434;
  margin-bottom: 15px;
}

.admin-event .upcoming-event .event-list .event-time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
}

.admin-event .upcoming-event .event-list .event-link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #12AEB4;
  margin-bottom: 0px;
}

.admin-event .upcoming-event .event-list .hours {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #7D8592;
  background: #F4F9FD;
  border-radius: 8px;
  padding: 6px;
}

#addEventModal .modal-content,
#viewEventModal .modal-content {
  border-radius: 31px;
  border: 1px solid #D8D8D8;
  box-shadow: 0px 6px 58px rgba(121, 145, 173, 0.1960784314);
}

#addEventModal .modal-title,
#viewEventModal .modal-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 23px;
  color: #3C3D43;
}

#addEventModal label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #506278;
  margin-bottom: 10px;
}

#addEventModal .date {
  background-image: url("../images/admin/calendar-sm.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

#addEventModal .time {
  background-image: url("../images/admin/icn-general-time-gray.svg");
  background-repeat: no-repeat;
  background-position: 98% center;
}

#addEventModal .attach-info {
  display: inline-block;
  cursor: pointer;
  position: relative;
  margin-bottom: 15px;
}

#addEventModal .attach-info .info {
  visibility: hidden;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #A6A7AE;
  width: 160px;
  position: absolute;
  top: -30px;
  left: 30px;
}

#addEventModal .attach-info:hover .info {
  visibility: visible;
}

#addEventModal .repeat,
#addEventModal .audience {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  background-color: #EBF7F6;
  color: #242424;
  border-color: #EBF7F6;
  border-radius: 4px;
  font-size: 15px;
  line-height: 23px;
  padding: 10px 16px;
}

#addEventModal .btn-custom {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  background: #12AEB4;
  box-shadow: 0px 6px 12px rgba(63, 140, 255, 0.262745098);
  border-radius: 14px;
  width: 123px;
  height: 48px;
  margin: 20px 0px 10px 0px;
}

#viewEventModal .modal-header .edit {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #12AEB4;
  letter-spacing: -0.34px;
}

#viewEventModal .event-img {
  position: relative;
}

#viewEventModal .event-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

#viewEventModal .event-img .event-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.53px;
  color: #FFFFFF;
  position: absolute;
  left: 20px;
  bottom: 20px;
}

#viewEventModal .event-date,
#viewEventModal .event-type {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7D8592;
  padding-left: 2px;
  margin-bottom: 20px;
}

#viewEventModal .event-link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--brand-color);
}

#viewEventModal .participants .title,
#viewEventModal .event-description .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #343434;
}

#viewEventModal .event-description .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6D7886;
  margin-bottom: 20px;
  word-wrap: break-word;
}

#viewEventModal .participants img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  box-shadow: 0px 0px 10px #ccc;
}

#viewEventModal .participants .name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #404040;
  text-align: center;
  margin-bottom: 0px;
}

#viewEventModal .comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #343434;
  letter-spacing: -0.27px;
  margin-top: 23px;
}

#viewEventModal .comments .comment-list {
  max-height: 330px;
  overflow-y: scroll;
  display: block;
}

#viewEventModal .comments .comment-list::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

#viewEventModal .comments .comment-list::-webkit-scrollbar-thumb {
  background: #3F53A5;
  border-radius: 10px;
  height: 50px;
}

#viewEventModal .comments .comment-list::-webkit-scrollbar-track {
  border-radius: 10px;
}

#viewEventModal .comments .comment-list .media {
  padding: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

#viewEventModal .comments .comment-list .media:hover {
  background-color: #F4F9FD;
}

#viewEventModal .comments .comment-list .user-img {
  width: 26px;
  height: 26px;
}

#viewEventModal .comments .comment-list .comment-name {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  color: var(--brand-color);
  margin-bottom: 0px;
}

#viewEventModal .comments .comment-list .comment-text {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6D7886;
  margin-bottom: 0px;
}

#viewEventModal .comments .comment-list .date-time,
#viewEventModal .comments .comment-list .reply {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #919dae;
}

#viewEventModal .comments .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding: 2px 6px;
  margin-top: 20px;
  margin-bottom: 15px;
}

#viewEventModal .comments .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  background-color: transparent;
  border: none;
  height: 34px;
}

#viewEventModal .comments .new-msg img:first-child {
  width: 20px;
}

#viewEventModal .comments .new-msg img:Last-child {
  width: 34px;
  cursor: pointer;
}

#viewEventModal .comments .new-msg img:Last-child:hover {
  width: 36px;
}

.rbc-toolbar button {
  border-radius: 14px;
}

.rbc-toolbar button:hover {
  color: #fff;
  background-color: #C9C9C9;
  border-color: #8D9296;
}

.rbc-toolbar button:active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button:focus {
  color: #fff;
  background-color: #8D9296;
  border-color: #8D9296;
}

.rbc-toolbar button:active,
.rbc-toolbar button.rbc-active {
  color: #fff;
  background-color: #8D9296;
  border-color: #8D9296;
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  background-color: #8D9296;
}

.rbc-show-more {
  color: #8D9296;
}

.rbc-month-view {
  box-shadow: 5px 10px 10px #C9C9C9;
  border-radius: 24px;
}

.rbc-month-row:last-child .rbc-row-bg {
  border-bottom-right-radius: 24px;
}

.event-list .event-title img,
.admin-event .upcoming-event .event-list .event-text {
  cursor: pointer;
}

.admin-event .upcoming-event .scroll {
  max-height: 320px;
  overflow-y: scroll;
  padding-right: 15px;
  border-right: 1px solid #ccc;
}

.admin-event .upcoming-event .scroll::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.admin-event .upcoming-event .scroll::-webkit-scrollbar-thumb {
  background: #8D9296;
  border-radius: 10px;
  height: 50px;
}

.admin-event .upcoming-event .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.modal ::placeholder {
  color: #C6C6C6;
}

.modal :-ms-input-placeholder {
  color: #C6C6C6;
}

.modal ::-ms-input-placeholder {
  color: #C6C6C6;
}

/*********End user dashboard css start**********/
.enduser-dashboard .navbar-search-block .input-group-sm > .form-control:not(textarea),
.enduser-dashboard .navbar-search-block .input-group-sm > .custom-select {
  height: calc(1.8125rem + 2px);
}

.enduser-dashboard .nav-sidebar > .nav-item .nav-icon {
  width: 21px;
}

.enduser-dashboard:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header {
  background-color: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 38px rgba(193, 193, 193, 0.1607843137);
  border: 1px solid #E1E1E1;
}

.enduser-dashboard .navbar-expand .navbar-nav .nav-link {
  color: #2F4A92;
}

.enduser-dashboard .sidebar-dark-primary {
  background-color: #3F53A5;
  box-shadow: 0px 3px 39px rgba(141, 141, 141, 0.0901960784);
}

.enduser-dashboard .content-wrapper {
  background-color: #ffffff;
}

.enduser-dashboard .content-header h2 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  margin-bottom: 0;
}

.enduser-dashboard .content-header small {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7D8592;
}

.enduser-dashboard .explore-box {
  background-color: rgba(63, 83, 165, 0.6901960784);
  text-align: center;
  margin-bottom: 20px;
}

.enduser-dashboard .explore-box .company-info {
  padding-top: 13px;
  margin-bottom: 19.73px;
  margin-left: 92px;
}

@media (max-width: 400px) {
  .enduser-dashboard .explore-box .company-info {
    margin-left: 0px;
  }
}
.enduser-dashboard .explore-box .company-img {
  height: 130px;
  max-height: 130px;
  border-radius: 50%;
  width: 130px;
}

.enduser-dashboard .explore-box .company-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  color: #fff;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 0;
  word-wrap: break-word;
  word-break: break-word;
}

.enduser-dashboard .explore-box .company-name small {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.31px;
  color: #fff;
  text-transform: uppercase;
}

.enduser-dashboard .explore-box .explore-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 60px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
  border: 1px solid #3F53A5;
  border-radius: 6px;
  width: 197.86px;
  height: 55.87px;
  display: inline-block;
  margin-bottom: 30px;
}

.enduser-dashboard .explore-box i {
  font-size: 40px;
  color: #fff;
  margin-bottom: 35px;
}

.enduser-dashboard .news {
  margin-bottom: 81px;
}

.enduser-dashboard .news .col-md-4 {
  padding-left: 15px;
  padding-right: 15px;
}

.enduser-dashboard .news .heading,
.enduser-dashboard .latest .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  color: #333333;
  margin-bottom: 19px;
}

.enduser-dashboard .news .heading small {
  float: right;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2F4A92;
  padding-top: 40px;
}

.enduser-dashboard .news .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #333333;
  margin-top: 19px;
  margin-bottom: 0px;
}

.enduser-dashboard .news .date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #999999;
  margin-bottom: 0px;
}

.enduser-dashboard .news .card {
  border: none;
}

.enduser-dashboard .news .card-img-top {
  margin-bottom: 7px;
  border-radius: 5px;
}

.enduser-dashboard .news .comment img,
.enduser-dashboard .news .event img,
.enduser-dashboard .news .reward img,
.enduser-dashboard .news .favourite img {
  width: 40px;
  height: 40px;
}

.enduser-dashboard .news .comment {
  position: absolute;
  top: 9px;
  left: 12px;
}

.enduser-dashboard .news .favourite {
  position: absolute;
  top: 72px;
  left: 20px;
}

.enduser-dashboard .news .favourite .fa-heart {
  color: #3F53A5;
  padding: 6px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(19, 19, 20, 0.1019607843);
  border: 0.5px solid #F2F2F5;
  border-radius: 50px;
  font-size: 14px;
  margin-bottom: 10px;
}

.enduser-dashboard .news .comment p,
.enduser-dashboard .news .favourite p {
  font-family: "Lato-Bold", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #B2B2B6;
  margin-top: 0px;
  margin-bottom: 10px;
}

.enduser-dashboard .news .event {
  position: absolute;
  top: 9px;
  right: 12px;
}

.enduser-dashboard .news .reward {
  position: absolute;
  top: 9px;
  right: 12px;
}

.enduser-dashboard .info-card {
  margin-bottom: 89px;
}

.enduser-dashboard .info-card .card {
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(201, 201, 201, 0.7098039216);
  border: 1px solid #EBEBEB;
  border-radius: 6px;
  text-align: center;
  margin: 0 15px 30px;
  width: 160px;
  height: 166px;
}

.enduser-dashboard .info-card .card .img-border {
  width: 92px;
  height: 92px;
  border-radius: 50px;
  border: 8px solid rgba(235, 96, 96, 0.7019607843);
  margin-bottom: 10px;
}

.enduser-dashboard .info-card .card .img-bg {
  background-color: #EB6060;
  border-radius: 50px;
  padding: 18px;
}

.enduser-dashboard .info-card .card .card-img-top {
  width: 40px;
  height: 40px;
}

.enduser-dashboard .info-card .card .card-body {
  padding: 26px 16px;
  border-radius: 6px;
}

.enduser-dashboard .info-card .card .info-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 19px;
  line-height: 29px;
  color: #333244;
}

@media (max-width: 400px) {
  .enduser-dashboard .info-card .card {
    background-color: #fff;
    box-shadow: 5px 5px 20px rgba(201, 201, 201, 0.7098039216);
    border: 1px solid #EBEBEB;
    border-radius: 6px;
    text-align: center;
    margin: 0 28px 30px;
    width: 200px;
    height: 220px;
  }
  .enduser-dashboard .info-card .card .img-border {
    width: 70px;
    height: 70px;
    border-radius: 50px;
    border: 8px solid rgba(235, 96, 96, 0.7019607843);
    margin-bottom: 30px;
  }
  .enduser-dashboard .info-card .card .img-bg {
    background-color: #EB6060;
    border-radius: 50px;
    padding: 16px;
  }
  .enduser-dashboard .info-card .card .card-img-top {
    width: 20px;
    height: 20px;
  }
  .enduser-dashboard .info-card .card .card-body {
    padding: 26px 16px;
    border-radius: 6px;
  }
  .enduser-dashboard .info-card .card .info-title {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 19px;
    line-height: 29px;
    color: #333244;
  }
}
.enduser-dashboard .latest {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 68px;
}

.enduser-dashboard .latest .sub-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 64px;
  color: #333333;
  margin-bottom: 11px;
}

.enduser-dashboard .latest #latestTab {
  border: none;
}

.enduser-dashboard .latest #latestTab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: #333244;
  margin-bottom: 20px;
}

.enduser-dashboard .latest #latestTab #viewAll-tab {
  color: #2F4A92;
}

.enduser-dashboard .latest #latestTab .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgb(252, 144, 3);
  background-color: #fff;
  border: none;
  border-bottom: 3px solid rgb(252, 144, 3);
}

.enduser-dashboard .latest #latestTabContent {
  background-color: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding-top: 40px;
}

.enduser-dashboard #youFav .card,
.enduser-dashboard #suggEvent .card,
.enduser-dashboard #latestCont .card {
  background-color: transparent;
  width: 173px;
  margin: 0 16px;
  border: none;
}

.enduser-dashboard #youFav .card .card-img-top,
.enduser-dashboard #suggEvent .card .card-img-top,
.enduser-dashboard #latestCont .card .card-img-top {
  margin-bottom: 11px;
  width: 138px;
  height: 148px;
  border-radius: 8px;
}

.enduser-dashboard #youFav .card .badge-over,
.enduser-dashboard #suggEvent .card .badge-over,
.enduser-dashboard #latestCont .card .badge-over {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #FFFFFF;
  background-color: #F8A90D;
  padding: 3px 7px;
  border-radius: 10rem;
  box-shadow: 0px 3px 10px #F8A90D;
  position: absolute;
  top: 12px;
  left: 12px;
}

.enduser-dashboard #youFav .card .img-over,
.enduser-dashboard #suggEvent .card .img-over,
.enduser-dashboard #latestCont .card .img-over {
  position: absolute;
  top: 50px;
  left: 40px;
}

.enduser-dashboard #youFav .card .title,
.enduser-dashboard #suggEvent .card .title,
.enduser-dashboard #latestCont .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 14px;
  width: 138px;
  min-height: 43px;
}

.enduser-dashboard #youFav .card .text,
.enduser-dashboard #suggEvent .card .text,
.enduser-dashboard #latestCont .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5F686F;
  margin-bottom: 18px;
}

.enduser-dashboard .calendar {
  background-color: #fff;
  border-radius: 6px;
  padding-bottom: 75px;
}

.enduser-dashboard .calendar .day {
  background-color: rgba(63, 83, 165, 0.1568627451);
  color: #2F4A92;
  border-radius: 4px;
  padding: 25px 9px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  width: 55px;
  height: 97px;
  margin: 0 10px;
  margin-bottom: 10px;
}

.enduser-dashboard .calendar .day span:first-child {
  font-size: 16px;
  line-height: 25px;
}

.enduser-dashboard .calendar .day span:nth-child(2) {
  font-size: 14px;
  line-height: 21px;
}

.enduser-dashboard .calendar .day.active {
  background-color: #2F4A92;
  color: #fff;
}

.enduser-dashboard .calendar .dots {
  background-color: rgba(47, 74, 146, 0.7098039216);
  width: 7px;
  height: 7px;
  border-radius: 10px;
  padding: 0px 5px;
  font-size: 7px;
}

.enduser-dashboard .calendar .dots.active {
  background-color: #2F4A92;
}

.enduser-dashboard .calendar .card-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
}

.enduser-dashboard .calendar select {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background-color: #fff;
  width: 282px;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 0px 20px;
}

.enduser-dashboard .suggested {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 57px;
}

.enduser-dashboard .suggested .sub-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 32px;
  line-height: 64px;
  color: #333333;
  margin-bottom: 47px;
}

.enduser-dashboard .suggested #suggestedTab {
  border: none;
  justify-content: space-evenly;
}

.enduser-dashboard .suggested #suggestedTab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 26px;
  line-height: 39px;
  color: #999999;
  padding-bottom: 46px;
  margin-bottom: 65px;
}

.enduser-dashboard .suggested #suggestedTab .nav-link.active {
  font-size: 25px;
  line-height: 38px;
  color: #3F53A5;
  background-color: #fff;
  border: none;
  border-bottom: 4px solid #2F4A92;
}

.enduser-dashboard #all .card {
  background-color: transparent;
  width: 221px;
  margin: 0 17px 15px;
  border: none;
}

.enduser-dashboard #all .card .card-img-top {
  margin-bottom: 11px;
  width: 221px;
  height: 290px;
}

.enduser-dashboard #all .card .img-over {
  position: absolute;
  top: 12px;
  right: 17px;
}

.enduser-dashboard #all .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 17px;
  min-height: 46px;
}

.enduser-dashboard #all .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.36px;
  color: #5F686F;
  margin-bottom: 16px;
}

.enduser-dashboard #post {
  background-color: rgba(63, 83, 165, 0.1568627451);
  border-radius: 12px;
  padding: 13px 35px 33px;
}

.enduser-dashboard #post .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  color: #0A1629;
  margin-bottom: 9px;
}

.enduser-dashboard #post .post-now {
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 6px;
}

.enduser-dashboard #post .post-now textarea.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8F92A1;
  margin-bottom: 9px;
  border: 0;
}

.enduser-dashboard #post .post-now .form-control:focus {
  height: auto;
  background-color: transparent;
}

.enduser-dashboard #post .post-now select.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 34px;
  color: #7D8592;
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 12px;
  background-color: #fff;
  width: 257px;
  height: 38px;
}

.enduser-dashboard #post .post-now select.form-control:focus {
  height: 38px;
  background-color: transparent;
}

.enduser-dashboard #post .title span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2F4A92;
  float: right;
}

.enduser-dashboard #post .latest-post {
  margin-bottom: 23px;
}

.enduser-dashboard #post .latest-post .card-body {
  padding: 16px 12px 10px 33px;
}

.enduser-dashboard #post .latest-post .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1E1F20;
  margin-bottom: 0;
}

.enduser-dashboard #post .latest-post .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8F92A1;
  margin-bottom: 0;
}

.enduser-dashboard #post .latest-post .course-no {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
  color: #8F92A1;
  float: right;
}

.enduser-dashboard #post .latest-post .content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8F92A1;
  margin-bottom: 22px;
}

.enduser-dashboard #post .latest-post input.form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8F92A1;
  box-shadow: 0px -3px 6px rgba(63, 83, 165, 0.1568627451);
  border: 1px solid #D8E0F0;
  border-radius: 12px;
  background-color: #fff;
  height: 38px;
}

.enduser-dashboard #post .latest-post input.form-control:focus {
  height: 38px;
  background-color: transparent;
}

.enduser-dashboard #videos .card,
.enduser-dashboard #photos .card {
  margin: 0px 22px;
  border: none;
}

.enduser-dashboard #videos .video-img,
.enduser-dashboard #photos .photo {
  box-shadow: 0px 7px 16px rgba(0, 0, 0, 0.1803921569);
  width: 308px;
  height: 218px;
  border-radius: 18px;
  margin-bottom: 28px;
}

.enduser-dashboard #videos .play-btn {
  position: absolute;
  left: 35%;
}

.enduser-dashboard #videos .side-icon,
.enduser-dashboard #photos .side-icon {
  justify-content: space-evenly;
  height: 218px;
}

.enduser-dashboard #videos .card .title,
.enduser-dashboard #photos .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 30px;
}

.enduser-dashboard #videos .card .text,
.enduser-dashboard #photos .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #5F686F;
  margin-bottom: 47px;
}

.enduser-dashboard #photos {
  margin-bottom: 18px;
}

.enduser-dashboard #courses,
.enduser-dashboard #challenges {
  margin-bottom: 56px;
}

.enduser-dashboard #courses .card,
.enduser-dashboard #challenges .card {
  width: 337px;
  margin: 0px 22px;
  margin-bottom: 32px;
  border: none;
}

.enduser-dashboard #courses .course-img,
.enduser-dashboard #challenges .course-img {
  margin-bottom: 17px;
  width: 337px;
  height: 263px;
  border-radius: 6px;
}

.enduser-dashboard #courses .user,
.enduser-dashboard #courses .like,
.enduser-dashboard #courses .lessons,
.enduser-dashboard #challenges .user,
.enduser-dashboard #challenges .like,
.enduser-dashboard #challenges .lessons {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #5F5982;
  margin-bottom: 8px;
}

.enduser-dashboard #courses .timing,
.enduser-dashboard #challenges .timing {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #5F686F;
  margin-bottom: 7px;
}

.enduser-dashboard #courses .title,
.enduser-dashboard #challenges .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #302D3A;
  margin-bottom: 22px;
}

.enduser-dashboard #courses .trainee span,
.enduser-dashboard #challenges .trainee span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7A7A7A;
}

.enduser-dashboard #courses .fees,
.enduser-dashboard #challenges .fees {
  font-family: "Poppins-Bold", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.36px;
  color: #3F53A5;
  margin-bottom: 0px;
}

/*end user profile pages*/
.enduser-dashboard section.profile {
  padding: 76px 38px 76px 56px;
  background-color: #ededf3;
}

.enduser-dashboard .profile #profile-tab {
  background-color: #EFF0F3;
  height: 100%;
}

.enduser-dashboard .profile #profile-tab .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 65px;
  color: #333244;
  background-color: transparent;
  padding-left: 21px;
}

.enduser-dashboard .profile #profile-tab .nav-link.active,
.enduser-dashboard .profile #profile-tab .show > .nav-link {
  color: #2F4A92;
  background-color: transparent;
}

.enduser-dashboard .profile #profile-tab .nav-link.active img {
  visibility: visible !important;
}

.enduser-dashboard .profile #profile-tabContent {
  padding: 25px 52px 44px 40px;
  background-color: #fff;
  height: 100%;
}

.enduser-dashboard .profile #profile-tabContent .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 61px;
  color: #3C3D43;
  margin-bottom: 26px;
}

.enduser-dashboard .profile #account-details .user-img {
  width: 160px;
  height: 160px;
  border: 10px solid rgba(0, 174, 179, 0.13);
  border-radius: 80px;
  margin-bottom: 30px;
}

.enduser-dashboard .profile #account-details .details {
  border-bottom: 1px solid #eee;
}

.enduser-dashboard .profile #account-details .details:nth-child(6) {
  margin-bottom: 63px;
}

.enduser-dashboard .profile #account-details .details span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 65px;
  color: #BCBCBC;
}

.enduser-dashboard .profile #account-details .details label {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 65px;
  color: #3C3D43;
  margin-bottom: 0px;
}

.enduser-dashboard .profile #account-details .plans,
.enduser-dashboard .profile #account-details .explore-plans {
  background: #D8DDF6;
  box-shadow: 0px 3px 15px rgba(119, 119, 119, 0.1607843137);
  border: 1px solid #E8E8E8;
  border-radius: 15px;
  margin-right: 38px;
  margin-bottom: 47px;
  width: 480px;
}

.enduser-dashboard .profile #account-details .plans .card-body,
.enduser-dashboard .profile #account-details .explore-plans .card-body {
  padding: 34px 55px 22px 34px;
}

.enduser-dashboard .profile #account-details .plans .plan-name,
.enduser-dashboard .profile #account-details .explore-plans .plan-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  margin-bottom: 19px;
}

.enduser-dashboard .profile #account-details .plans .plan-fee,
.enduser-dashboard .profile #account-details .explore-plans .plan-fee {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 18px;
}

.enduser-dashboard .profile #account-details .plans .subsc-date,
.enduser-dashboard .profile #account-details .plans .expiry-date {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #5A5A5A;
  margin-bottom: 17px;
}

.enduser-dashboard .profile #account-details .plans .btn-custom,
.enduser-dashboard .profile #account-details .explore-plans .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #fff;
  background: #3F53A5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 261px;
  height: 42px;
  margin-top: 17px;
}

.enduser-dashboard .profile #account-details .explore-plans {
  background: #fff;
  box-shadow: 5px 10px 10px #E8E8E8;
  border: 1px solid #E8E8E8;
}

.enduser-dashboard .profile #account-details .explore-plans:hover {
  box-shadow: 5px 10px 10px #3F53A5;
  border: 1px solid #E8E8E8;
}

.enduser-dashboard .profile #account-details .explore-plans .content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #5A5A5A;
}

.enduser-dashboard .profile #edit-account,
.enduser-dashboard .profile #change-password,
.enduser-dashboard .profile #notifications-settings,
.enduser-dashboard .profile #unsubscribe {
  margin-bottom: 170px;
}

.enduser-dashboard .profile #edit-account .user-img {
  width: 160px;
  height: 160px;
  border: 10px solid rgba(0, 174, 179, 0.13);
  border-radius: 80px;
}

.enduser-dashboard .profile #edit-account .fa-pencil-alt {
  background: #2F4A92;
  border: 5px solid #FFFFFF;
  border-radius: 50px;
  padding: 17px;
  color: #fff;
  margin-top: -50px;
  margin-right: -85px;
}

.enduser-dashboard .profile #edit-account label,
.enduser-dashboard .profile #change-password label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  /*line-height: 65px;*/
  color: #333244;
  margin-bottom: 13px;
}

.enduser-dashboard .profile #edit-account .form-control,
.enduser-dashboard .profile #change-password .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 28px;
  background: #EEEEEE;
  border-radius: 11px;
  padding-left: 49px;
  margin-bottom: 30px;
  height: 62px;
}

.enduser-dashboard .profile #edit-account .btn-white,
.enduser-dashboard .profile #change-password .btn-white,
.enduser-dashboard .profile #notifications-settings .btn-white {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #3B4E9B;
  background: #fff;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 44px;
  margin-right: 40px;
}

.enduser-dashboard .profile #edit-account .btn-white:hover,
.enduser-dashboard .profile #change-password .btn-white:hover,
.enduser-dashboard .profile #notifications-settings .btn-white:hover {
  color: #fff;
  background: #3F53A5;
}

.enduser-dashboard .profile #edit-account .btn-blue,
.enduser-dashboard .profile #change-password .btn-blue,
.enduser-dashboard .profile #notifications-settings .btn-blue,
.enduser-dashboard .profile #unsubscribe .btn-blue {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background: var(--brand-color);
  box-shadow: 5px 5px 10px var(--brand-color);
  border-radius: 9px;
  width: 261px;
  height: 58px;
  margin-top: 44px;
}

.enduser-dashboard .profile #notifications-settings .sub-title,
.enduser-dashboard .profile #unsubscribe .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 47px;
  color: #3C3D43;
  margin-bottom: 23px;
}

.enduser-dashboard .profile #notifications-settings .notification-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 29px;
  color: #5A5A5A;
  margin-bottom: 41px;
}

.enduser-dashboard .profile #unsubscribe .tab-message {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 47px;
  color: #3C3D43;
  margin-bottom: 22px;
}

/*end user plan detail page*/
.enduser-dashboard .plan-details,
.enduser-dashboard .payment-details {
  background-color: #ededf3;
  padding: 49px;
}

.enduser-dashboard .content-header .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  padding-bottom: 23px;
}

.enduser-dashboard .content-header .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .plan-details .card,
.enduser-dashboard .payment-details .card {
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
}

.enduser-dashboard .plan-details .plan,
.enduser-dashboard .payment-details .plan {
  background-color: #D8DDF6;
  padding: 58px 90px 40px 73px;
}

.enduser-dashboard .plan-details .plan .title,
.enduser-dashboard .payment-details .plan .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 54px;
  color: #3C3D43;
  margin-bottom: 32px;
}

.enduser-dashboard .plan-details .plan .content,
.enduser-dashboard .payment-details .plan .content {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 34px;
  color: #5A5A5A;
}

.enduser-dashboard .plan-details .tc,
.enduser-dashboard .payment-details .tc {
  padding: 58px 90px 40px 73px;
}

.enduser-dashboard .plan-details .tc .text,
.enduser-dashboard .payment-details .tc .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 65px;
  color: #3C3D43;
  margin-bottom: 32px;
}

.enduser-dashboard .plan-details .tc .text a,
.enduser-dashboard .payment-details .tc .text a {
  color: #3F53A5;
}

.enduser-dashboard .plan-details .tc .text a:hover,
.enduser-dashboard .payment-details .tc .text a:hover {
  text-decoration: underline;
}

.enduser-dashboard .plan-details .tc ul {
  padding-left: 25px;
}

.enduser-dashboard .plan-details .tc ul li {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #5A5A5A;
  margin-bottom: 31px;
}

.enduser-dashboard .plan-details .tc ul li::marker {
  color: #3F53A5;
  font-size: 25px;
}

.enduser-dashboard .plan-details .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background-color: #3F53A5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 414px;
  height: 58px;
  margin-top: 32px;
  margin-bottom: 434px;
}

.enduser-dashboard .payment-details .card-detail .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
  font-size: 17px;
  line-height: 26px;
  color: #666666;
  margin-bottom: 25px;
}

.enduser-dashboard .payment-details .card-detail label {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3F53A5;
  margin-bottom: 33px;
}

.enduser-dashboard .payment-details .card-detail .form-group {
  margin-bottom: 33px;
}

.enduser-dashboard .payment-details .card-detail .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  background-color: #EEEEEE;
  color: #B4B4B4;
  padding-left: 32px;
  height: 63px;
}

.enduser-dashboard .payment-details .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #fff;
  background-color: #3F53A5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  width: 414px;
  height: 58px;
  margin-top: 111px;
  margin-bottom: 269px;
}

#paymentSuccessfull .close,
#paymentDone .close {
  opacity: 1;
}

#paymentDone .modal-header {
  padding: 32px 25px;
}

#paymentDone .modal-content {
  border: 1px solid #D8D8D8;
  border-radius: 15px;
}

#paymentDone .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0C0A08;
  margin-bottom: 24px;
}

#paymentDone .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #A6A7AE;
  margin-bottom: 43px;
}

/*Audio library css start*/
.enduser-dashboard .audio-library .playlist .title,
.enduser-dashboard .video-library .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.enduser-dashboard .audio-library .playlist .name,
.enduser-dashboard .video-library .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .audio-library .playlist .left-img,
.enduser-dashboard .video-library .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
}

.enduser-dashboard .audio-library .playlist .right-img,
.enduser-dashboard .video-library .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .audio-library .discover,
.enduser-dashboard .video-library .discover {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .discover .border-line,
.enduser-dashboard .video-library .discover .border-line {
  margin-bottom: 34px;
}

.enduser-dashboard .audio-library .discover .border-line .title,
.enduser-dashboard .video-library .discover .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .discover .border-line label,
.enduser-dashboard .video-library .discover .border-line label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .discover .border-line .form-control,
.enduser-dashboard .video-library .discover .border-line .form-control {
  width: 15%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background: #E0E3F0;
  border-radius: 4px;
  padding-left: 20px;
}

.enduser-dashboard .audio-library .discover .border-line::after,
.enduser-dashboard .video-library .discover .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 65%;
}

.enduser-dashboard .audio-library .discover-slider .text-over,
.enduser-dashboard .video-library .discover-slider .text-over {
  position: absolute;
  top: 75px;
  left: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.enduser-dashboard .audio-library .discover-slider .owl-stage-outer,
.enduser-dashboard .video-library .discover-slider .owl-stage-outer {
  padding: 20px 0px;
}

.enduser-dashboard .audio-library .discover-slider .item img,
.enduser-dashboard .video-library .discover-slider .item img {
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
}

.enduser-dashboard .audio-library .discover-slider .item img:hover,
.enduser-dashboard .video-library .discover-slider .item img:hover {
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1803921569);
  border-radius: 7px;
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
  transform: scale(1.05);
}

.enduser-dashboard .audio-library .discover-slider .owl-dots .owl-dot span,
.enduser-dashboard .video-library .discover-slider .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: #E5E5E5;
}

.enduser-dashboard .audio-library .discover-slider .owl-dots .owl-dot.active span,
.enduser-dashboard .audio-library .discover-slider .owl-dots .owl-dot:hover span,
.enduser-dashboard .video-library .discover-slider .owl-dots .owl-dot.active span,
.enduser-dashboard .video-library .discover-slider .owl-dots .owl-dot:hover span {
  background: #2F4A92;
}

.enduser-dashboard .audio-library .new-audio,
.enduser-dashboard .video-library .new-video {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .new-audio .border-line,
.enduser-dashboard .video-library .new-video .border-line {
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .new-audio .border-line .title,
.enduser-dashboard .video-library .new-video .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .new-audio .border-line::after,
.enduser-dashboard .video-library .new-video .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 80%;
  margin-left: 20px;
}

.enduser-dashboard .audio-library .new-audio .card,
.enduser-dashboard .video-library .new-video .card {
  background-color: transparent;
  width: 138px;
  min-height: 272px;
  margin: 0 7px;
  border: none;
  cursor: pointer;
}

.enduser-dashboard .audio-library .new-audio .card .card-img-top,
.enduser-dashboard .video-library .new-video .card .card-img-top {
  width: 138px;
  height: 148px;
  box-shadow: 0px 10px 20px rgba(201, 201, 201, 0.6470588235);
  border-radius: 10px;
  margin-bottom: 15px;
}

.enduser-dashboard .audio-library .new-audio .card .card-img-top:hover,
.enduser-dashboard .video-library .new-video .card .card-img-top:hover {
  box-shadow: 0px 20px 40px rgba(141, 146, 150, 0.6470588235);
}

.enduser-dashboard .audio-library .new-audio .card .title,
.enduser-dashboard .video-library .new-video .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #010004;
  margin-bottom: 4px;
}

.enduser-dashboard .audio-library .new-audio .card .text,
.enduser-dashboard .video-library .new-video .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: #929AA5;
}

.enduser-dashboard .audio-library .popular-folder,
.enduser-dashboard .video-library .popular-folder {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .popular-folder .border-line,
.enduser-dashboard .video-library .popular-folder .border-line {
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .popular-folder .border-line .title,
.enduser-dashboard .video-library .popular-folder .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .popular-folder .border-line::after,
.enduser-dashboard .video-library .popular-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 78%;
  margin-left: 20px;
}

.enduser-dashboard .audio-library .popular-folder .card,
.enduser-dashboard .video-library .popular-folder .card {
  background-color: transparent;
  width: 138px;
  margin: 0 12px;
  border: none;
  cursor: pointer;
}

.enduser-dashboard .audio-library .popular-folder .card .card-img-top,
.enduser-dashboard .video-library .popular-folder .card .card-img-top {
  width: 132px;
  height: 111px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}

.enduser-dashboard .audio-library .popular-folder .card .card-img-top1,
.enduser-dashboard .video-library .popular-folder .card .card-img-top1 {
  width: 132px;
  height: 111px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.enduser-dashboard .audio-library .popular-folder .card .title,
.enduser-dashboard .video-library .popular-folder .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #010004;
  margin-bottom: 25px;
}

.enduser-dashboard .audio-library .audios,
.enduser-dashboard .video-library .videos {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-library .audios .border-line,
.enduser-dashboard .video-library .videos .border-line {
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .audios .border-line .title,
.enduser-dashboard .video-library .videos .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .audios .border-line::after,
.enduser-dashboard .video-library .videos .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .audio-library .audios #audioTab,
.enduser-dashboard .video-library .videos #videoTab {
  border: none;
}

.enduser-dashboard .audio-library .audios .nav-link,
.enduser-dashboard .video-library .videos .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  color: #C9C9C9;
  margin-bottom: 20px;
}

.enduser-dashboard .audio-library .audios .nav-link.active,
.enduser-dashboard .video-library .videos .nav-link.active {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #8D9296;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #8D9296;
}

.enduser-dashboard .audio-library .audios #viewAll-tab,
.enduser-dashboard .video-library .videos #viewAll-tab {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8D9296;
}

.enduser-dashboard .audio-library .audios #audioTabContent,
.enduser-dashboard .video-library .videos #videoTabContent {
  background-color: #fff;
  box-shadow: 0px 3px 17px #C9C9C9;
  border-radius: 4px;
  padding: 29px 37px 39px 60px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .title,
.enduser-dashboard .video-library .videos #videoTabContent .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .text,
.enduser-dashboard .video-library .videos #videoTabContent .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929AA5;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .views,
.enduser-dashboard .video-library .videos #videoTabContent .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-library .audios #audioTabContent .dropdown-menu,
.enduser-dashboard .video-library .videos #videoTabContent .dropdown-menu {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px #3F53A5;
  border: 1px solid #D6D6D6;
}

.enduser-dashboard .audio-library .audios #audioTabContent .dropdown-item,
.enduser-dashboard .video-library .videos #videoTabContent .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3F53A5;
  padding: 12px 30px;
}

.enduser-dashboard .audio-library #audioTabContent .table-hover tbody tr:hover,
.enduser-dashboard .video-library #videoTabContent .table-hover tbody tr:hover {
  background-color: rgba(201, 201, 201, 0.2);
}

.enduser-dashboard .audio-library #audioTabContent .dropdown-item:hover,
.enduser-dashboard .video-library #videoTabContent .dropdown-item:hover {
  background-image: url("../images/audio/Polygon 136.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.enduser-dashboard .audio-library #audioTabContent .dropdown-item.active,
.enduser-dashboard .audio-library #audioTabContent .dropdown-item:active,
.enduser-dashboard .video-library #videoTabContent .dropdown-item.active,
.enduser-dashboard .video-library #videoTabContent .dropdown-item:active {
  background-color: transparent;
}

.enduser-dashboard .audio-library .new-audio .scroll,
.enduser-dashboard .video-library .new-video .scroll {
  overflow-y: scroll;
  max-height: 540px;
  padding-top: 16px;
  border-right: 1px solid #e4e4e5;
}

.enduser-dashboard .audio-library .new-audio .scroll::-webkit-scrollbar,
.enduser-dashboard .video-library .new-video .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .audio-library .new-audio .scroll::-webkit-scrollbar-thumb,
.enduser-dashboard .video-library .new-video .scroll::-webkit-scrollbar-thumb {
  background: #8D9296;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard .audio-library .new-audio .scroll::-webkit-scrollbar-track,
.enduser-dashboard .video-library .new-video .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.enduser-dashboard .audio-library .popular-folder .scroll,
.enduser-dashboard .video-library .popular-folder .scroll {
  overflow-y: scroll;
  max-height: 515px;
  border-right: 1px solid #e4e4e5;
}

.enduser-dashboard .audio-library .popular-folder .scroll::-webkit-scrollbar,
.enduser-dashboard .video-library .popular-folder .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .audio-library .popular-folder .scroll::-webkit-scrollbar-thumb,
.enduser-dashboard .video-library .popular-folder .scroll::-webkit-scrollbar-thumb {
  background: #8D9296;
  border-radius: 10px;
  height: 50px;
  min-height: 50px;
}

.enduser-dashboard .audio-library .popular-folder .scroll::-webkit-scrollbar-track,
.enduser-dashboard .video-library .popular-folder .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.enduser-dashboard .audio-library .audio .scroll,
.enduser-dashboard .video-library .videos .scroll {
  overflow-y: scroll;
  max-height: 515px;
  border-right: 2px solid #ccc;
}

.enduser-dashboard .audio-library .audio .scroll::-webkit-scrollbar,
.enduser-dashboard .video-library .videos .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .audio-library .audio .scroll::-webkit-scrollbar-thumb,
.enduser-dashboard .video-library .videos .scroll::-webkit-scrollbar-thumb {
  background: #8D9296;
  border-radius: 10px;
  height: 50px;
  min-height: 50px;
}

.enduser-dashboard .audio-library .audio .scroll::-webkit-scrollbar-track,
.enduser-dashboard .video-library .videos .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*Search css start*/
#searchModal .modal-content {
  background-color: #fff;
  box-shadow: 5px 10px 10px #3F53A5;
  border: none;
  border-radius: 10px;
  margin-bottom: 33px;
}

#searchModal .modal-body {
  padding: 0;
}

#searchModal .modal-body .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8D91A1;
  background-color: #fff;
  border: none;
  border-radius: 10px;
  padding-left: 35px;
  padding-right: 65px;
  height: 72px;
}

#searchModal .modal-body .search {
  position: absolute;
  top: 18px;
  right: 20px;
}

#searchModal .modal-body .search .fa-search {
  font-size: 34px;
  color: #3F53A5;
  text-shadow: 1px 3px 5px #3b4747;
}

#searchModal .result {
  border-bottom: 1px solid #ccc;
}

#searchModal .result .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #A8A8A8;
  padding-left: 11px;
  margin-top: 26px;
  margin-bottom: 25px;
}

#searchModal .result .list {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #8D91A1;
  padding-left: 11px;
  margin-bottom: 19px;
}

#searchModal .result .list:last-child {
  margin-bottom: 30px;
}

#searchModal .scroll {
  overflow-y: scroll;
  max-height: 362px;
}

#searchModal .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

#searchModal .scroll::-webkit-scrollbar-thumb {
  background: #3f53a5;
  border-radius: 10px;
  height: 50px;
}

#searchModal .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*create playlist css start*/
#createPlaylistModal .modal-content {
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 12px;
}

#createPlaylistModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 33px;
  line-height: 50px;
  color: #333244;
}

#createPlaylistModal .form-control {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #333244;
  background-color: #EEEEEE;
  padding-left: 49px;
  border-radius: 10px;
}

#createPlaylistModal input.form-control {
  height: 107px;
}

#createPlaylistModal textarea.form-control {
  height: 306px;
  padding-top: 30px;
  margin-bottom: 45px;
}

#createPlaylistModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 38px;
  background: #3F53A5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  color: #fff;
  width: 100%;
  height: 83px;
  margin-bottom: 21px;
}

#createPlaylistModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #AFAFAF;
  margin-bottom: 46px;
}

/*Add playlist css start*/
#addPlaylistModal .modal-content {
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border-radius: 12px;
}

#addPlaylistModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 55px;
  color: #333244;
}

#addPlaylistModal .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  background: #3F53A5;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  border-radius: 9px;
  color: #fff;
  width: 269px;
  height: 89px;
  margin-top: 24px;
  margin-bottom: 54px;
}

#addPlaylistModal .playlist-img {
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.6470588235);
  border-radius: 10px;
  width: 142px;
  height: 133px;
}

#addPlaylistModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 39px;
  color: #2F4A92;
}

#addPlaylistModal .add {
  background: #FFFFFF;
  box-shadow: 5px 5px 10px rgba(63, 83, 165, 0.5411764706);
  width: 61px;
  height: 52px;
  text-align: center;
}

#addPlaylistModal .add .fa-plus {
  font-size: 22px;
  line-height: 44px;
  color: #2F4A92;
}

#addPlaylistModal .your-playlist {
  margin-bottom: 35px;
}

/*playlist added successfully modal*/
#playlistAddedModal .modal-content {
  border: 1px solid #DEDEDE;
  box-shadow: none;
  border-radius: 15px;
}

#playlistAddedModal .modal-body {
  padding-left: 44px;
  padding-right: 44px;
}

#playlistAddedModal .success-img {
  margin-top: -100px;
  margin-left: -70px;
}

#playlistAddedModal .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0C0A08;
  margin-bottom: 24px;
}

#playlistAddedModal .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #A6A7AE;
  margin-bottom: 43px;
}

/*my playlist page css start*/
.enduser-dashboard .my-playlist {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .my-playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .my-playlist .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .my-playlist .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 72%;
  margin-left: 20px;
}

.enduser-dashboard .my-playlist .playlist {
  margin-bottom: 24px;
}

.enduser-dashboard .my-playlist .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.enduser-dashboard .my-playlist .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
  /*box-shadow: 0px 6px 5px #00AEB31C;*/
}

.enduser-dashboard .my-playlist .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .my-playlist .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .my-playlist .playlist-board {
  background-color: #2F4A92;
  border-radius: 15px;
  padding: 22px 25px 37px 42px;
  margin-bottom: 51px;
}

.enduser-dashboard .my-playlist .playlist-board .upload-file {
  position: unset;
  border: 10px solid rgba(255, 255, 255, 0.56);
  border-radius: 25px;
  margin-right: 76px;
}

.enduser-dashboard .my-playlist .playlist-board #choose-file {
  display: none;
  visibility: hidden;
}

.enduser-dashboard .my-playlist .playlist-board #preview_img {
  background-color: #ffffff;
  border-radius: 15px;
  width: 186px;
  height: 164px;
}

.enduser-dashboard .my-playlist .playlist-board .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 39px;
  line-height: 59px;
  color: #FFFFFF;
  margin-bottom: 10px;
}

.enduser-dashboard .my-playlist .playlist-board .desc {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 28px;
  color: #FFFFFF;
  margin-bottom: 0px;
}

.enduser-dashboard .my-playlist .playlist-board .fa-ellipsis-h {
  color: #fff;
  font-size: 50px;
  line-height: 0.5;
}

.enduser-dashboard .my-playlist .find .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #333244;
  margin-bottom: 30px;
}

.enduser-dashboard .my-playlist .search input {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px #3F53A5;
  height: 58px;
}

.enduser-dashboard .my-playlist .search .fa-search {
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 34px;
  color: #3F53A5;
  text-shadow: 1px 3px 5px #3b4747;
}

/*audio folder page css start*/
.enduser-dashboard .audio-folder {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .audio-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .audio-folder .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .audio-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 78%;
  margin-left: 20px;
}

.enduser-dashboard .audio-folder .playlist {
  margin-bottom: 24px;
}

.enduser-dashboard .audio-folder .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.enduser-dashboard .audio-folder .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
  /*box-shadow: 0px 6px 5px #00AEB31C;*/
}

.enduser-dashboard .audio-folder .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .audio-folder .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .audio-folder .folder-info {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-folder .folder-info {
  margin-bottom: 34px;
}

.enduser-dashboard .audio-folder .folder-info .info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7D8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .audio-folder .folder-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .audio-folder .folder-info .form-control {
  width: 15%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background: #E0E3F0;
  border-radius: 4px;
  padding-left: 20px;
}

.enduser-dashboard .audio-folder .audio-folders .card {
  background-color: transparent;
  width: 250px;
  margin: 0 16px 77px;
  text-align: center;
}

.enduser-dashboard .audio-folder .audio-folders .card-img-top {
  width: 236px;
  height: 198px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}

.enduser-dashboard .audio-folder .audio-folders .card-img-top1 {
  width: 236px;
  height: 198px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.enduser-dashboard .audio-folder .audio-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #010004;
  margin-top: 27px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-folder .audio-folders .file-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-folder .audio-folders .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

/*audio top result page css start*/
.enduser-dashboard .audio-top-result,
.enduser-dashboard .video-top-result {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .audio-top-result .title,
.enduser-dashboard .video-top-result .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .audio-top-result .title a,
.enduser-dashboard .video-top-result .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .audio-top-result .border-line::after,
.enduser-dashboard .video-top-result .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 72%;
  margin-left: 20px;
}

.enduser-dashboard .audio-top-result .playlist,
.enduser-dashboard .video-top-result .playlist {
  margin-bottom: 24px;
}

.enduser-dashboard .audio-top-result .playlist .title,
.enduser-dashboard .video-top-result .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.enduser-dashboard .audio-top-result .playlist .left-img,
.enduser-dashboard .video-top-result .playlist .left-img {
  position: absolute;
  right: 300px;
  top: 30px;
  z-index: 1;
  /*box-shadow: 0px 6px 5px #00AEB31C;*/
}

.enduser-dashboard .audio-top-result .playlist .name,
.enduser-dashboard .video-top-result .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  position: relative;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .audio-top-result .playlist .right-img,
.enduser-dashboard .video-top-result .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 26px;
  z-index: 1;
}

.enduser-dashboard .audio-top-result .audio-list .card {
  background-color: transparent;
  width: 429px;
  padding: 32px 70px 28px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-top-result .audio-list .card:hover {
  box-shadow: 0px 3px 27px #3F53A5;
  border-radius: 7px;
}

.enduser-dashboard .audio-top-result .audio-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #060136;
  margin-bottom: 35px;
}

.enduser-dashboard .audio-top-result .audio-list .card .audio-img {
  width: 250px;
  height: 208px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-top-result .audio-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 180px;
  left: 190px;
}

.enduser-dashboard .audio-top-result .audio-list .card .fav-icon {
  width: 28px;
  height: 25px;
  float: right;
  margin: 0 20px 0px 0;
}

.enduser-dashboard .audio-top-result .audio-list .card .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-list .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-top-result .audio-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .folders .title,
.enduser-dashboard .video-top-result .folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .folders .border-line::after,
.enduser-dashboard .video-top-result .folders .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 90%;
  margin-left: 20px;
}

.enduser-dashboard .audio-top-result .folders .card {
  background-color: transparent;
  width: 218px;
  margin: 25px 11px 38px;
  padding-top: 38px;
  padding-bottom: 35px;
}

.enduser-dashboard .audio-top-result .folders .card .card-img-top {
  width: 173px;
  height: 170px;
  border-radius: 50%;
  margin-bottom: 21px;
}

.enduser-dashboard .audio-top-result .folders .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-top-result .folders .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  color: #7D8592;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-files .view-all,
.enduser-dashboard .video-top-result .video-files .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2F4A92;
  float: right;
  margin-top: -40px;
  margin-right: 40px;
}

.enduser-dashboard .audio-top-result .audio-files .border-line::after,
.enduser-dashboard .video-top-result .video-files .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 88%;
  margin-left: 20px;
}

.enduser-dashboard .audio-top-result .audio-files .table-responsive,
.enduser-dashboard .video-top-result .video-files .table-responsive,
.enduser-dashboard .video-result-detail .video-files .table-responsive {
  background: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 29px 37px 39px 60px;
  margin-top: 54px;
}

.enduser-dashboard .audio-top-result .audio-files table .title,
.enduser-dashboard .video-top-result .video-files table .title,
.enduser-dashboard .video-result-detail .video-files table .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-top-result .audio-files table .text,
.enduser-dashboard .video-top-result .video-files table .text,
.enduser-dashboard .video-result-detail .video-files table .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929AA5;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-files table .views,
.enduser-dashboard .video-top-result .video-files table .views,
.enduser-dashboard .video-result-detail .video-files table .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-top-result .audio-files table .dropdown-menu,
.enduser-dashboard .video-top-result .video-files table .dropdown-menu,
.enduser-dashboard .video-result-detail .video-files table .dropdown-menu {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px #3F53A5;
  border: 1px solid #D6D6D6;
}

.enduser-dashboard .audio-top-result .audio-files table .dropdown-item,
.enduser-dashboard .video-top-result .video-files table .dropdown-item,
.enduser-dashboard .video-result-detail .video-files table .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3F53A5;
  padding: 12px 30px;
}

.enduser-dashboard .audio-top-result .audio-files .table-hover tr:hover,
.enduser-dashboard .video-top-result .video-files .table-hover tr:hover,
.enduser-dashboard .video-result-detail .video-files .table-hover tr:hover {
  background-color: rgba(186, 193, 222, 0.2);
}

.enduser-dashboard .audio-top-result .audio-files table .dropdown-item:hover,
.enduser-dashboard .video-top-result .video-files table .dropdown-item:hover,
.enduser-dashboard .video-result-detail .video-files table .dropdown-item:hover {
  background-image: url("../images/audio/Polygon 136.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.enduser-dashboard .audio-top-result .audio-files .dropdown-item.active,
.enduser-dashboard .audio-top-result .audio-files .dropdown-item:active,
.enduser-dashboard .video-top-result .video-files .dropdown-item.active,
.enduser-dashboard .video-top-result .video-files .dropdown-item:active,
.enduser-dashboard .video-result-detail .video-files .dropdown-item.active,
.enduser-dashboard .video-result-detail .video-files .dropdown-item:active {
  background-color: transparent;
}

/*audio top result detail page css start*/
.enduser-dashboard .audio-result-detail,
.enduser-dashboard .video-result-detail {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .audio-result-detail .title,
.enduser-dashboard .video-result-detail .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .audio-result-detail .title a,
.enduser-dashboard .video-result-detail .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .audio-result-detail .border-line::after,
.enduser-dashboard .video-result-detail .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 72%;
  margin-left: 20px;
}

.enduser-dashboard .audio-result-detail .audio-list .card {
  background-color: transparent;
  width: 330px;
  padding: 32px 25px 28px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 46px;
}

.enduser-dashboard .audio-result-detail .audio-list .card:hover {
  box-shadow: 0px 3px 27px #3F53A5;
  border-radius: 7px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .audio-img {
  width: 250px;
  height: 208px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 115px;
  left: 140px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .fav-icon {
  width: 28px;
  height: 25px;
  float: right;
  margin: 0 20px 0px 0;
}

.enduser-dashboard .audio-result-detail .audio-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 9px;
}

.enduser-dashboard .audio-result-detail .audio-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .now-playing .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  text-align: right;
}

.enduser-dashboard .audio-result-detail .now-playing .play-box {
  background: #2F4A92;
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 161px 0px 33px;
}

.enduser-dashboard .audio-result-detail .now-playing .play-img img {
  border: 10px solid #fff;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  margin-top: -85px;
  float: left;
}

.enduser-dashboard .audio-result-detail .now-playing .play-controls {
  background: #FFFFFF;
  box-shadow: 1px 1px 15px #E1E4F1;
  border-radius: 17px;
  width: 432px;
  padding: 30px 20px 0px;
}

.enduser-dashboard .audio-result-detail .now-playing .play-controls .detail {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: -0.32px;
  color: #646464;
  padding-top: 22px;
  margin-bottom: 6px;
  text-align: center;
}

.enduser-dashboard .audio-result-detail .folder-files .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .audio-result-detail .folder-files .table-responsive {
  background: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  padding: 29px 37px 39px 60px;
  margin-top: 10px;
}

.enduser-dashboard .audio-result-detail .folder-files table .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 7px;
}

.enduser-dashboard .audio-result-detail .folder-files table .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929AA5;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .folder-files table .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .audio-result-detail .folder-files table .dropdown-menu {
  background: #FFFFFF;
  box-shadow: 5px 10px 10px #3F53A5;
  border: 1px solid #D6D6D6;
}

.enduser-dashboard .audio-result-detail .folder-files table .dropdown-item {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #3F53A5;
  padding: 12px 30px;
}

.enduser-dashboard .audio-result-detail .folder-files .table-hover tr:hover {
  background-color: rgba(186, 193, 222, 0.2);
}

.enduser-dashboard .audio-result-detail .folder-files table .dropdown-item:hover {
  background-image: url("../images/audio/Polygon 136.svg");
  background-repeat: no-repeat;
  background-position: right center;
}

.enduser-dashboard .audio-result-detail .folder-files .dropdown-item.active,
.enduser-dashboard .audio-result-detail .folder-files .dropdown-item:active {
  background-color: transparent;
}

/*video top result css start*/
.enduser-dashboard .video-library #videoTabContent .video-img,
.enduser-dashboard .video-top-result .video-files .video-img,
.enduser-dashboard .video-result-detail .video-files .video-img {
  position: relative;
}

.enduser-dashboard .video-library #videoTabContent .play-btn,
.enduser-dashboard .video-top-result .video-files .play-btn,
.enduser-dashboard .video-result-detail .video-files .play-btn {
  position: absolute;
  top: 20px;
  left: 30px;
}

.enduser-dashboard .video-top-result .video-list .card {
  background-color: transparent;
  width: 429px;
  padding: 32px 23px 28px;
  text-align: center;
  margin-right: 35px;
  margin-bottom: 46px;
}

.enduser-dashboard .video-top-result .video-list .card:hover {
  box-shadow: 0px 3px 27px #3F53A5;
  border-radius: 7px;
}

.enduser-dashboard .video-top-result .video-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #060136;
  margin-bottom: 35px;
}

.enduser-dashboard .video-top-result .video-list .card .audio-img {
  width: 385px;
  height: 208px;
  margin: 0 auto;
  margin-bottom: 27px;
}

.enduser-dashboard .video-top-result .video-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 180px;
  left: 190px;
}

.enduser-dashboard .video-top-result .video-list .card .fav-icon {
  width: 28px;
  height: 25px;
  margin: 0 0px 0px 20px;
}

.enduser-dashboard .video-top-result .video-list .card .sub-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 25px;
}

.enduser-dashboard .video-top-result .video-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .video-top-result .popular-folder {
  padding-left: 7.5px;
  margin-bottom: 38px;
}

.enduser-dashboard .video-top-result .popular-folder .border-line {
  margin-bottom: 25px;
}

.enduser-dashboard .video-top-result .popular-folder .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .video-top-result .popular-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 90%;
  margin-left: 20px;
}

.enduser-dashboard .video-top-result .popular-folder .card {
  background-color: transparent;
  width: 188px;
  margin: 0 34px;
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
}

.enduser-dashboard .video-top-result .popular-folder .card .card-img-top {
  width: 179px;
  height: 151px;
  border-radius: 4px;
  margin-bottom: 15px;
  position: relative;
}

.enduser-dashboard .video-top-result .popular-folder .card .card-img-top1 {
  width: 179px;
  height: 151px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  left: 8px;
}

.enduser-dashboard .video-top-result .popular-folder .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 9px;
}

.enduser-dashboard .video-top-result .popular-folder .card .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 29px;
  color: #7D8592;
  margin-bottom: 35px;
}

.enduser-dashboard .video-result-detail .video-files {
  margin-top: 43px;
}

.enduser-dashboard .video-result-detail .video-files .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 82%;
  margin-left: 20px;
}

/*video top result detail*/
.enduser-dashboard .video-result-detail .video-list .card {
  background-color: transparent;
  width: 370px;
  padding: 32px 25px 28px;
  text-align: center;
  margin-bottom: 46px;
}

.enduser-dashboard .video-result-detail .video-list .card:hover {
  box-shadow: 0px 3px 27px #3F53A5;
  border-radius: 7px;
}

.enduser-dashboard .video-result-detail .video-list .card .video-img {
  width: 270px;
  height: 270px;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 26px;
}

.enduser-dashboard .video-result-detail .video-list .card .play-img {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 145px;
  left: 155px;
}

.enduser-dashboard .video-result-detail .video-list .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
  margin-bottom: 22px;
}

.enduser-dashboard .video-result-detail .video-list .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8D91A1;
  padding-left: 10px;
  margin-bottom: 0px;
}

.enduser-dashboard .video-result-detail .video-list .card .fav-icon {
  width: 28px;
  height: 25px;
  margin-left: 10px;
}

/*content library folder*/
.enduser-dashboard .content-wrapper.content-library-folder {
  background-color: #EDEDF3;
}

.enduser-dashboard .content-library-folder {
  padding-top: 37px;
  padding-left: 8px;
}

.enduser-dashboard .content-library-folder .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .content-library-folder .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .content-library-folder .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 66%;
  margin-left: 20px;
}

.enduser-dashboard .content-library-folder .folder-info {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .content-library-folder .folder-info {
  margin-bottom: 34px;
}

.enduser-dashboard .content-library-folder .folder-info .info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7D8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .content-library-folder .folder-info label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
  margin-right: 16px;
}

.enduser-dashboard .content-library-folder .content-folders {
  background-color: #fff;
  margin: 40px 42px 40px 0px;
  padding: 45px 50px;
  border-radius: 7px;
}

.enduser-dashboard .content-library-folder .content-folders .card {
  background-color: transparent;
  width: 255px;
  margin-right: 32px;
  margin-bottom: 54px;
}

.enduser-dashboard .content-library-folder .content-folders .card-img-top {
  width: 164px;
  height: 243px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.enduser-dashboard .content-library-folder .content-folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #333244;
  margin-bottom: 6px;
}

.enduser-dashboard .content-library-folder .content-folders .file-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #7D8592;
  margin-bottom: 9px;
}

/*contant library*/
.enduser-dashboard .content-library {
  padding-top: 37px;
  padding-left: 8px;
  margin-bottom: 48px;
}

.enduser-dashboard .content-library .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
}

.enduser-dashboard .content-library .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 88%;
  margin-left: 20px;
}

.enduser-dashboard .content-library .most-popular-content .card {
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
  margin-bottom: 24px;
}

.enduser-dashboard .content-library .most-popular-content .card .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #060136;
  margin-bottom: 37px;
}

.enduser-dashboard .content-library .most-popular-content .card .slider-img {
  width: 144px;
  height: 215px;
  margin: 0 auto;
  margin-bottom: 23px;
}

.enduser-dashboard .content-library .most-popular-content .card .views {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8D91A1;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .most-popular-content .card .comm {
  width: 28px;
  height: 25px;
  margin-right: 10px;
}

.enduser-dashboard .content-library .most-popular-content .card .fav-icon {
  width: 28px;
  height: 25px;
  margin-left: 10px;
}

.enduser-dashboard .content-library .most-popular-content .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #060136;
  margin-bottom: 20px;
}

.enduser-dashboard .content-library .most-popular-content .popular-slider .owl-prev {
  position: absolute;
  top: 80px;
  left: 0px;
}

.enduser-dashboard .content-library .most-popular-content .popular-slider .owl-next {
  position: absolute;
  top: 80px;
  right: 0px;
}

.enduser-dashboard .content-library .most-popular-content .popular-slider .owl-nav [class*=owl-] {
  margin: 0px;
  padding: 0px;
  background: none;
}

.enduser-dashboard .content-library .content-files .card {
  background: #FFFFFF;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .content-files .card-body {
  padding-bottom: 60px;
}

.enduser-dashboard .content-library .content-files .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 26px;
}

.enduser-dashboard .content-library .content-files .fa-filter {
  font-size: 15px;
  padding: 7px 9px;
  margin-left: 6px;
  color: #2F4A92;
  background: #E1E4F1;
  border-radius: 4px;
}

.enduser-dashboard .content-library .content-files .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2F4A92;
  float: right;
}

.enduser-dashboard .content-library .content-files .folder .folder-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #A8A8A8;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .content-files .folder .book {
  border-bottom: 1px solid #DEDEDE;
  padding-top: 23px;
  padding-bottom: 31px;
}

.enduser-dashboard .content-library .content-files .folder .book:last-child {
  border-bottom: none;
}

.enduser-dashboard .content-library .content-files .folder .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
  margin-bottom: 9px;
}

.enduser-dashboard .content-library .content-files .folder .book-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #929AA5;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .about-book {
  background: #FFFFFF;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .about-book .book-views {
  background-image: url("../images/enduser/book-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 65px 60px 95px 80px;
  margin-bottom: 44px;
}

.enduser-dashboard .content-library .about-book .book-views .book-img {
  width: 144px;
  height: 215px;
  margin-right: 32px;
}

.enduser-dashboard .content-library .about-book .book-views .side-icon {
  justify-content: center;
  height: 215px;
}

.enduser-dashboard .content-library .about-book .book-views .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  margin-bottom: 20px;
}

.enduser-dashboard .content-library .about-book .book-views .book-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #707070;
  margin-top: 18px;
}

.enduser-dashboard .content-library .about-book .media {
  margin-bottom: 19px;
}

.enduser-dashboard .content-library .about-book .left-round-img {
  width: 68px;
  height: 68px;
  border-radius: 50px;
  border: 1px solid #f9f9f9;
}

.enduser-dashboard .content-library .about-book .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 6px;
}

.enduser-dashboard .content-library .about-book .book-title .post-time {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #2F4A92;
  float: right;
}

.enduser-dashboard .content-library .about-book .folder-files {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #3F53A5;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .about-book .book-description {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  color: #707070;
  margin-bottom: 3px;
}

.enduser-dashboard .content-library .book-comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-top: 32px;
  margin-bottom: 24px;
}

.enduser-dashboard .content-library .book-comments .title .send {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8D91A1;
  float: right;
}

.enduser-dashboard .content-library .book-comments .title .send .fa-paper-plane {
  background: rgba(63, 83, 165, 0.168627451);
  border-radius: 3px;
  font-size: 20px;
  color: #2F4A92;
  padding: 9px 12px;
  margin-left: 10px;
}

.enduser-dashboard .content-library .book-comments .share-thought {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8D91A1;
  background: #F1F1F1;
  border-color: #F1F1F1;
  border-radius: 6px;
}

.enduser-dashboard .content-library .book-comments .left-round-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #f1f1f1;
}

.enduser-dashboard .content-library .book-comments {
  background: #FFFFFF;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
  margin-bottom: 24px;
}

.enduser-dashboard .content-library .book-comments .media {
  padding: 30px 0px;
  border-bottom: 1px solid #DEDEDE;
}

.enduser-dashboard .content-library .book-comments .media:last-child {
  border-bottom: none;
}

.enduser-dashboard .content-library .book-comments .media .name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 10px;
}

.enduser-dashboard .content-library .book-comments .media .name .time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
  float: right;
}

.enduser-dashboard .content-library .book-comments .media .comment {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #91929E;
  margin-bottom: 0px;
}

.enduser-dashboard .content-library .folders {
  background: #FFFFFF;
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
}

.enduser-dashboard .content-library .folders .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 20px;
}

.enduser-dashboard .content-library .folders .search-content {
  position: relative;
}

.enduser-dashboard .content-library .folders .search-content input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8D91A1;
  background: #F1F1F1;
  border-radius: 6px;
  height: 36px;
  margin-bottom: 25px;
}

.enduser-dashboard .content-library .folders .search-icon {
  position: absolute;
  bottom: 8px;
  right: 20px;
}

.enduser-dashboard .content-library .folders .search-icon .fa-search {
  font-size: 12px;
  color: #7E828F;
}

.enduser-dashboard .content-library .folders .folder-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 30px;
}

.enduser-dashboard .content-library .folders .folder-info .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #2F4A92;
  float: right;
}

.enduser-dashboard .content-library .folders .book {
  padding-left: 15px;
}

.enduser-dashboard .content-library .folders .book img {
  box-shadow: 10px 8px 17px rgba(63, 83, 165, 0.5411764706);
  border-radius: 7px;
  width: 137px;
  margin-bottom: 30px;
}

.enduser-dashboard .content-library .folders .book .book-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #333244;
  margin-bottom: 6px;
}

.enduser-dashboard .content-library .folders .book .desc {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 26px;
}

.enduser-dashboard .content-library .folders .scroll {
  max-height: 1300px;
  overflow-y: scroll;
  display: block;
  border-right: 1px solid #ccc;
  padding-right: 20px;
}

.enduser-dashboard .content-library .folders .scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.enduser-dashboard .content-library .folders .scroll::-webkit-scrollbar-thumb {
  background: #2F4A92;
  border-radius: 2px;
  height: 50px;
}

.enduser-dashboard .content-library .folders .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* enduser dashboard content show */
.enduser-dashboard .show-content .template,
.enduser-dashboard .show-content .template2,
.enduser-dashboard .show-content .template3 {
  margin-bottom: 60px;
}

.enduser-dashboard .show-content .template .title,
.enduser-dashboard .show-content .template2 .title,
.enduser-dashboard .show-content .template3 .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 46px;
  color: #7284CC;
  margin-bottom: 8px;
}

.enduser-dashboard .show-content .template .text,
.enduser-dashboard .show-content .template2 .text,
.enduser-dashboard .show-content .template3 .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #929AA5;
  margin-bottom: 16px;
}

.enduser-dashboard .show-content .template img,
.enduser-dashboard .show-content .template3 img {
  width: 288px;
  height: 315px;
}

.enduser-dashboard .show-content .template2 img {
  width: 650px;
  height: 315px;
}

/*end user courses*/
.enduser-dashboard .content-header .title a {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .courses .queries-icon {
  margin-right: 40px;
}

.enduser-dashboard .courses .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2F4A92;
  background: #E0E3F0;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
}

.enduser-dashboard .courses .queries-icon .question-img {
  margin-left: -20px;
}

.enduser-dashboard .courses .playlist {
  position: relative;
}

.enduser-dashboard .courses .playlist .title {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  padding-bottom: 0;
}

.enduser-dashboard .courses .playlist .left-img {
  position: absolute;
  left: -20px;
  z-index: 1;
}

.enduser-dashboard .courses .playlist .name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #263A3B;
  width: 322px;
  padding: 7px 30px;
  border: 1px solid #DBDBDB;
  border-radius: 20px;
  margin-top: 7px;
}

.enduser-dashboard .courses .playlist .right-img {
  position: absolute;
  right: 0px;
  top: 28px;
  z-index: 1;
}

.enduser-dashboard .courses .discover {
  padding-left: 7.5px;
  margin-bottom: 46px;
}

.enduser-dashboard .courses .discover .border-line {
  margin-bottom: 34px;
}

.enduser-dashboard .courses .discover .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 65%;
}

.enduser-dashboard .courses .discover .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .discover label {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .discover .form-control {
  width: 15%;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #8B8B8B;
  background: #E0E3F0;
  border-radius: 4px;
  padding-left: 20px;
}

.enduser-dashboard .courses .discover .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 65%;
}

.enduser-dashboard .courses .discover-slider .item img {
  transition: linear 0.25s;
  -webkit-transition: linear 0.25s;
}

.enduser-dashboard .courses .discover-slider .text-over {
  position: absolute;
  top: 75px;
  left: 20px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
}

.enduser-dashboard .courses .discover-slider .owl-dots .owl-dot span {
  width: 16px;
  height: 16px;
  background: #E5E5E5;
}

.enduser-dashboard .courses .discover-slider .owl-dots .owl-dot.active span,
.enduser-dashboard .courses .discover-slider .owl-dots .owl-dot:hover span {
  background: #2F4A92;
}

.enduser-dashboard .courses .your-course {
  margin-bottom: 50px;
}

.enduser-dashboard .courses .your-course .border-line .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .your-course .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .courses .your-course .search-course {
  position: relative;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px #7284CC;
  border: 1px solid #E5E5E5;
  width: 394px;
  height: 56px;
  padding-right: 52px;
}

.enduser-dashboard .courses .your-course .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enduser-dashboard .courses .your-course .filter-icon {
  margin-left: 27px;
  margin-right: 48px;
  margin-bottom: 20px;
}

.certificate-setion {
  width: 800px;
  height: 600px;
  background-color: white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.certificate-setion .top-left-border {
  border-top-left-radius: 5px;
  border-bottom-right-radius: 10px;
  width: 7em;
  height: 7em;
  position: absolute;
  left: 0;
  top: 0;
  clip-path: polygon(0 0, 0 0, 100% 0, 0 100%, 0 0, 0 0, 0 0, 0 0);
}

.certificate-setion .bottom-right-border {
  border-radius: 5px;
  width: 7em;
  height: 7em;
  position: absolute;
  bottom: -6.5px;
  right: 0;
  clip-path: polygon(0 100%, 3em 3em, 7em 0em, 100% 7em);
}

.top-right-border {
  width: 7em;
  height: 7em;
  position: absolute;
  top: 0;
  right: 0;
}

.bottom-left-border {
  width: 7em;
  height: 7em;
  position: absolute;
  left: 0;
  bottom: 0;
}

.certificate-logo {
  width: 40%;
  height: 12em;
  padding: 5px;
  object-fit: contain;
}

.certificate-common-style {
  font-size: 16px;
  font-weight: 500;
  color: black;
}

.certificate-common-style2 {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.certificate-border-all {
  width: 95%;
  height: 95%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.certificate-main-content {
  text-align: center;
  width: 90%;
}

.enduser-dashboard .courses .your-course .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2F4A92;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .book-box {
  width: 191px;
  min-height: 146px;
  background: #FFFFFF;
  box-shadow: 5px 10px 10px #8794C7;
  border-radius: 24px;
  padding: 12px 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  margin-bottom: 20px;
}

.enduser-dashboard .courses .your-course .book-box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0A1629;
}

.enduser-dashboard .courses .your-course .book-box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7D8592;
  margin-bottom: 0px;
}

.enduser-dashboard .courses .your-course .book-box .fee {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #2F4A92;
}

.enduser-dashboard .courses .your-course .scroll {
  max-height: 840px;
  overflow-y: scroll;
  display: block;
}

.enduser-dashboard .courses .your-course .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .courses .your-course .scroll::-webkit-scrollbar-thumb {
  background: #3F53A5;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard .courses .your-course .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*courses view all */
.enduser-dashboard .courses .your-course #coursesTab {
  border: none;
  align-items: center;
  margin-bottom: 60px;
}

.enduser-dashboard .courses .your-course #coursesTab::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 58%;
  margin-left: 20px;
}

.enduser-dashboard .courses .your-course .nav-item {
  width: 180px;
  margin-right: 10px;
}

.enduser-dashboard .courses .your-course .nav-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #8D9296;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.enduser-dashboard .courses .your-course .nav-link.active {
  color: #3F53A5;
  background-color: #fff;
  border: none;
  border-bottom: 3px solid #3F53A5;
}

.enduser-dashboard .courses #coursesTabContent .box {
  width: 394px;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #EBEBEB;
  border-radius: 13px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  cursor: pointer;
}

.enduser-dashboard .courses #coursesTabContent .box:hover,
.course-detail .top-result .box:hover {
  box-shadow: 4px 5px 16px #3F53A5;
}

.enduser-dashboard .courses #coursesTabContent .box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-top: 14px;
  margin-bottom: 8px;
}

.enduser-dashboard .courses #coursesTabContent .box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 11px;
}

.enduser-dashboard .courses #coursesTabContent .box .text:last-child {
  margin-bottom: 0px;
}

.enduser-dashboard .courses #coursesTabContent .box .student img {
  margin-top: -10px;
  margin-right: -5px;
  border-radius: 50px;
}

.enduser-dashboard .courses #coursesTabContent .scroll {
  max-height: 1010px;
  overflow-y: scroll;
  display: block;
}

.enduser-dashboard .courses #coursesTabContent .scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.enduser-dashboard .courses #coursesTabContent .scroll::-webkit-scrollbar-thumb {
  background: #3F53A5;
  border-radius: 10px;
  height: 50px;
}

.enduser-dashboard .courses #coursesTabContent .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

/*course detail page*/
.enduser-dashboard .course-detail .return-page {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
}

.enduser-dashboard .course-detail .return-page::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 75%;
  margin-left: 20px;
}

.enduser-dashboard .course-detail .search-course {
  position: relative;
}

.enduser-dashboard .course-detail .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px rgba(200, 245, 241, 0.6196078431);
  border: 1px solid #E5E5E5;
  width: 520px;
  height: 56px;
  padding-right: 52px;
}

.enduser-dashboard .course-detail .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enduser-dashboard .course-detail .detail-page .course-img {
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .detail-page .course-info {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0A1629;
  background-color: #E6EDF5;
  border-radius: 23px;
  padding: 10px 30px;
  margin-right: 25px;
  margin-bottom: 0px;
}

.enduser-dashboard .course-detail .detail-page .student-img img {
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.enduser-dashboard .course-detail .detail-page .student-img img:nth-child(1),
.enduser-dashboard .course-detail .detail-page .student-img img:nth-child(2) {
  margin-right: -14px;
}

.enduser-dashboard .course-detail .detail-page .price {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 23px;
  color: #7D8592;
}

.enduser-dashboard .course-detail .detail-page .price span {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #0A1629;
}

.enduser-dashboard .course-detail .detail-page .course-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 31px;
  color: #7D8592;
  margin-top: 30px;
  margin-bottom: 18px;
  text-align: justify;
}

.enduser-dashboard .course-detail .detail-page .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2F4A92;
  background: #E0E3F0;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
}

.enduser-dashboard .course-detail .detail-page .queries-icon .question-img {
  margin-left: -20px;
}

.enduser-dashboard .course-detail .lesson .title,
.enduser-dashboard .lesson-detail .lesson .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 32px;
  line-height: 48px;
  color: #333244;
  margin-bottom: 0px;
}

.enduser-dashboard .course-detail .lesson .border-line::after,
.enduser-dashboard .lesson-detail .lesson .border-line::after {
  display: inline-block;
  content: "";
  border-bottom: 1px solid #E4E4E5;
  width: 85%;
  margin-left: 20px;
}

.enduser-dashboard .course-detail .lesson .search-course,
.enduser-dashboard .lesson-detail .lesson .search-course {
  position: relative;
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .lesson .search-course input,
.enduser-dashboard .lesson-detail .lesson .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #8D91A1;
  background: #FFFFFF;
  box-shadow: 0px 3px 9px #7284cc;
  border: 1px solid #E5E5E5;
  width: 394px;
  height: 56px;
  padding-right: 52px;
}

.enduser-dashboard .course-detail .lesson .search-icon,
.enduser-dashboard .lesson-detail .lesson .search-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enduser-dashboard .course-detail .lesson .filter-icon,
.enduser-dashboard .lesson-detail .lesson .filter-icon {
  margin-left: 27px;
  margin-right: 27px;
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .lesson .view-all,
.enduser-dashboard .lesson-detail .lesson .view-all {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #2F4A92;
  margin-bottom: 20px;
}

.enduser-dashboard .course-detail .lesson .table-responsive,
.enduser-dashboard .lesson-detail .lesson .table-responsive {
  background: rgba(63, 83, 165, 0.1568627451);
  box-shadow: 0px 3px 22px rgba(226, 233, 242, 0.1607843137);
  border-radius: 10px;
  margin-bottom: 32px;
}

.enduser-dashboard .course-detail .lesson table tr td,
.enduser-dashboard .lesson-detail .lesson table tr td {
  vertical-align: middle;
  padding: 8px;
}

.enduser-dashboard .course-detail .lesson table tr td .live-btn,
.enduser-dashboard .lesson-detail .lesson table tr td .live-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  background: #2F4A92;
  border-radius: 5px;
  padding: 2px 10px;
  width: 98px;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(1),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(2),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(1),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(2) {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #060136;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(3),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(4),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(5),
.enduser-dashboard .course-detail .lesson table tr td:nth-child(7),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(3),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(4),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(5),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(7) {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(8),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(8) {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2F4A92;
  width: 92px;
}

.enduser-dashboard .course-detail .lesson table tr td:nth-child(4),
.enduser-dashboard .lesson-detail .lesson table tr td:nth-child(4) {
  width: 115px;
}

.enduser-dashboard .course-detail .lesson table .student-img,
.enduser-dashboard .lesson-detail .lesson table .student-img {
  width: 92px;
}

.enduser-dashboard .course-detail .lesson table .student-img img,
.enduser-dashboard .lesson-detail .lesson table .student-img img {
  width: 35px;
  height: 35px;
  border-radius: 50px;
}

.enduser-dashboard .course-detail .lesson table .student-img img:nth-child(1),
.enduser-dashboard .course-detail .lesson table .student-img img:nth-child(2),
.enduser-dashboard .lesson-detail .lesson table .student-img img:nth-child(1),
.enduser-dashboard .lesson-detail .lesson table .student-img img:nth-child(2) {
  margin-right: -14px;
}

/*lesson detail*/
.enduser-dashboard .lesson-detail .lesson-img {
  background: #FFFFFF;
  box-shadow: 9px 21px 41px rgba(0, 0, 0, 0.3490196078);
  border-radius: 15px;
  padding: 12px;
  margin-top: 75px;
  margin-bottom: 27px;
  position: relative;
}

.enduser-dashboard .lesson-detail .lesson-img .fee {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333244;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 14px;
  padding: 3px 18px;
  position: absolute;
  top: 20px;
  left: 40px;
}

.enduser-dashboard .lesson-detail .student-img img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
}

.enduser-dashboard .lesson-detail .student-img img:nth-child(1),
.enduser-dashboard .lesson-detail .student-img img:nth-child(2) {
  margin-right: -14px;
}

.enduser-dashboard .lesson-detail .member {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
  text-align: right;
  margin-bottom: 100px;
}

.enduser-dashboard .lesson-detail .attach-files .title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #7D8592;
  margin-bottom: 24px;
}

.enduser-dashboard .lesson-detail .attach-img img {
  margin-bottom: 27px;
}

.enduser-dashboard .lesson-detail .attach-files .book-name {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #060136;
  margin-top: 7px;
  margin-bottom: 30px;
}

.enduser-dashboard .lesson-detail .lesson-title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 35px;
  line-height: 53px;
  color: #060136;
  margin-bottom: 23px;
}

.enduser-dashboard .lesson-detail .heading {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #060136;
  margin-top: 30px;
  margin-bottom: 29px;
}

.enduser-dashboard .lesson-detail .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 30px;
  color: #7D8592;
  margin-bottom: 29px;
}

.enduser-dashboard .lesson-detail .queries-icon span {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #2F4A92;
  background: #E0E3F0;
  border-radius: 20px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 10px 26px;
}

.enduser-dashboard .lesson-detail .queries-icon .question-img {
  margin-left: -20px;
}

.enduser-dashboard .lesson-detail .lesson-comments .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 31px;
  color: #333244;
  margin-bottom: 24px;
}

.enduser-dashboard .lesson-detail .lesson-comments .send {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8D91A1;
}

.enduser-dashboard .lesson-detail .lesson-comments .send .fa-paper-plane {
  background: rgba(63, 83, 165, 0.168627451);
  border-radius: 3px;
  font-size: 20px;
  color: #2F4A92;
  padding: 9px 12px;
  margin-left: 17px;
}

.enduser-dashboard .lesson-detail .lesson-comments textarea {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #8D91A1;
  background: #F1F1F1;
  border-color: #F1F1F1;
  border-radius: 6px;
  width: 80%;
}

.enduser-dashboard .lesson-detail .lesson-comments .media {
  padding: 20px 0px;
  border-bottom: 1px solid #DEDEDE;
}

.enduser-dashboard .lesson-detail .lesson-comments .media:last-child {
  border-bottom: none;
}

.enduser-dashboard .lesson-detail .lesson-comments .left-round-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  border: 1px solid #f1f1f1;
}

.enduser-dashboard .lesson-detail .lesson-comments .media .name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 29px;
  color: #333244;
  margin-bottom: 10px;
}

.enduser-dashboard .lesson-detail .lesson-comments .media .name .time {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #7D8592;
  float: right;
}

.enduser-dashboard .lesson-detail .lesson-comments .media .comment {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  color: #91929E;
  margin-bottom: 0px;
}

.enduser-dashboard .lesson-detail .hr {
  border: 4px solid #E4E4E4;
  margin-top: 27px;
  margin-bottom: 27px;
}

/*lesson detail attachment modal*/
#attachmentFileModal .modal-content {
  background: #FFFFFF;
  box-shadow: 0px 3px 27px rgba(47, 74, 146, 0.7098039216);
  border: 1px solid #D8D8D8;
  border-radius: 30px;
}

#attachmentFileModal .modal-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #3B4747;
  margin-bottom: 0px;
}

#attachmentFileModal .modal-header {
  padding: 39px 39px 16px;
}

#attachmentFileModal .modal-body {
  padding: 52px 68px;
}

#attachmentFileModal .files {
  background: #E6EDF5;
  border-radius: 7px;
  padding: 10px;
  margin-bottom: 27px;
}

#attachmentFileModal .file-title {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #060136;
  margin-bottom: 0px;
}

#attachmentFileModal .download-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 13px;
  color: #ACACAC;
}

/*Courses Top result*/
.course-detail .top-result .box {
  width: 394px;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #EBEBEB;
  border-radius: 13px;
  padding: 15px;
  margin-bottom: 30px;
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  cursor: pointer;
}

.course-detail .top-result .lesson-img {
  position: relative;
}

.course-detail .top-result .lesson-img .fee {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #333244;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 14px;
  padding: 3px 18px;
  position: absolute;
  top: 20px;
  left: 20px;
}

.course-detail .top-result .box .title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: #333244;
  margin-top: 14px;
  margin-bottom: 8px;
}

.course-detail .top-result .box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 11px;
}

.course-detail .top-result .box .text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7D8592;
  margin-bottom: 11px;
}

.course-detail .top-result .box .student img {
  margin-top: -10px;
  margin-right: -5px;
  border-radius: 50px;
}

/*course ask question*/
.course-ask-question .course-answer {
  background: #E0E3F0;
  border: 1px solid #E8E8E8;
  border-radius: 6px;
}

.course-ask-question .course-answer .text {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #4C565F;
  padding: 14px 29px;
}

.course-ask-question .course-answer .search-course {
  position: relative;
  margin: 14px 29px;
}

.course-ask-question .course-answer .search-course input {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #4C565F;
  background: #F1F1F1;
  border-radius: 6px;
  border: none;
  height: 45px;
}

.course-ask-question .course-answer .search-course .search-icon {
  position: absolute;
  top: 8px;
  right: 15px;
}

.course-ask-question .course-answer .search-course .search-icon img {
  width: 29px;
}

.course-ask-question #courseAnswer .btn-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #4C565F;
}

.course-ask-question .course-answer .btn-custom {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: #3F53A5;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-ask-question #courseAnswer .answer-border {
  border-bottom: 1px solid #fff;
  padding: 14px 29px;
}

.course-ask-question #courseAnswer .btn-link {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #4C565F;
}

.course-ask-question #courseAnswer .collapse p {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #8B8E94;
}

.course-ask-question #courseAnswer .btn-link.collapsed::after {
  content: "\f107";
  font-family: "FONT AWESOME 5 FREE";
  font-style: normal;
  font-weight: 900;
  text-decoration: inherit;
  color: #3F53A5;
  float: right;
  margin-top: -20px;
}

.course-ask-question #courseAnswer .btn-link::after {
  content: "\f105";
  font-family: "FONT AWESOME 5 FREE";
  font-style: normal;
  font-weight: 900;
  text-decoration: inherit;
  color: #3F53A5;
  float: right;
  margin-top: -20px;
}

.course-ask-question .message-detail {
  background: #FFFFFF;
  box-shadow: 0px 3px 24px rgba(47, 74, 146, 0.6392156863);
  border: 1px solid #E8E8E8;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 40px;
}

.course-ask-question .message-detail .media .img-rounded {
  border-radius: 50px;
  width: 48px;
  height: 48px;
}

.course-ask-question .message-detail .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 17px;
  line-height: 15px;
  color: #333244;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #91929E;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .day-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #7D8592;
  box-shadow: 0px 2px 16px rgba(196, 203, 214, 0.1019607843);
  border: 1px solid #E6EBF5;
  border-radius: 20px;
  padding: 2px 17px;
  margin-top: 40px;
  margin-bottom: 18px;
}

.course-ask-question .message-detail .chat-messages .media {
  margin-bottom: 14px;
}

.course-ask-question .message-detail .media .img-rounded {
  border-radius: 50px;
}

.course-ask-question .message-detail .chat-messages .messanger-name {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .chat-messages .messanger-text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0A1629;
  margin-bottom: 5px;
}

.course-ask-question .message-detail .chat-messages .link {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #3F53A5;
  background: #E6EDF5;
  border-radius: 10px;
  padding: 6px 16px;
  margin-top: 15px;
  display: inline-block;
}

.course-ask-question .message-detail .chat-messages .link img {
  width: 21px;
  height: 21px;
}

.course-ask-question .message-detail .chat-messages .attachment {
  border: 1px solid #D8DDE6;
  border-radius: 14px;
  padding: 13px;
  width: 90%;
}

.course-ask-question .message-detail .chat-messages .attachment .attch-title {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0A1629;
  margin-bottom: 4px;
}

.course-ask-question .message-detail .chat-messages .attachment .file-size {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #91929E;
  margin-bottom: 0px;
}

.course-ask-question .message-detail .chat-messages .new-msg {
  box-shadow: 0px 1px 2px rgba(184, 200, 224, 0.2235294118);
  border: 1px solid #D8E0F0;
  border-radius: 14px;
  padding: 6px;
  margin-top: 42px;
  margin-bottom: 42px;
}

.course-ask-question .message-detail .chat-messages .new-msg .form-control {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background-color: transparent;
  border: none;
  height: 36px;
}

/*multiple membership modal  */
#multiple-membership .scroll {
  max-height: 300px;
  overflow-y: scroll;
  margin-right: -10px;
  padding-right: 10px;
  border-right: 1px solid #ccc;
}

#multiple-membership .scroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

#multiple-membership .scroll::-webkit-scrollbar-thumb {
  background: #3F53A5;
  border-radius: 10px;
  height: 50px;
}

#multiple-membership .scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.modal-backdrop {
  background-color: transparent;
}

.modal-backdrop.show {
  opacity: 0.95;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
}

.multiPurposePopup .modal-dialog {
  top: 30%;
}

.multiPurposePopup .modal-content {
  border-radius: 10px;
  border-color: var(--brand-color);
}

.multiPurposePopup .modal-header {
  justify-content: center;
  gap: 1rem;
}

.notification.dropdown-menu.dropdown-menu-lg.dropdown-menu-right {
  max-width: max-content;
  margin-top: 9px;
}

.notification.dropdown-menu-right {
  right: -7rem !important;
}

.notification-box {
  display: flex;
  flex-direction: row;
}

.notification-box span {
  font-weight: bold;
  font-size: 1.3em;
}

.notification-box a {
  font-weight: bold;
  font-size: 1em;
}

.notification-container {
  display: flex;
}

.notification-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.notification-content span {
  flex: 1 0 50%;
}

.notification-content span:last-child {
  text-align: right;
}

.notification-box .dropdown-item:hover,
.notification-list:hover {
  background-color: var(--brand-color-light);
}

.notification-list .dropdown-item:hover {
  background-color: transparent;
}

.notification-list .check,
.box-header .check {
  width: 12px;
  height: 12px;
  border-radius: 2.5px;
  color: white;
  border: 1px solid var(--brand-color);
  background-color: white;
  cursor: pointer;
}

.box-header {
  background-color: var(--brand-color);
  border-color: rgb(212, 210, 210);
  border-style: solid;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-width: 1px;
  margin: 0;
  padding: 8px 16px 8px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-header .btn-sm {
  padding: 3px 12px;
  font-size: 12px;
  line-height: 20px;
  background-color: white;
  border-color: white;
  color: var(--brand-color);
}

all-notification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.all-notification-content {
  display: flex;
  justify-content: space-between;
}

.mark-as-read-for-all {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.25rem;
  position: absolute;
  right: -5px;
  visibility: hidden;
  width: 8rem;
  height: 1.5rem;
}

.all-notification-container:hover .mark-as-read-for-all {
  visibility: visible;
}

.all-notification-container:hover .time {
  visibility: hidden;
}

.mark-as-read-for-all .mark-as-read {
  visibility: inherit;
}

.mark-as-read {
  position: absolute;
  top: 20%;
  right: 5px;
  transform: translateY(-50%);
  color: var(--brand-color);
  visibility: hidden;
  display: flex;
  gap: 0.25rem;
}

.action-icon {
  width: 33px;
  height: 23px;
  border: 1px solid var(--brand-color);
  border-radius: 15px;
  color: var(--brand-color);
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-container:hover .mark-as-read {
  visibility: visible;
}

.notification-container:hover .time {
  visibility: hidden;
}

.content-sidebar {
  height: 100%;
  width: 290px;
  position: absolute;
  right: 0;
  background: white;
  color: var(--brand-color-light);
  border-left: 2px solid;
  border-color: var(--brand-color-light);
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
}

body:not(.content-sidebar) .hide {
  transform: translateX(100%);
  display: none;
}

.offcanvas-body {
  width: 100%;
  padding: 0;
  margin: 0;
}

.progress-box {
  display: flex;
  align-items: center;
  flex-direction: row;
  color: var(--brand-color-light);
  gap: 0.5rem;
  position: absolute;
  top: 0;
  width: 94%;
  height: 5em;
  bottom: 1.5em;
  border-bottom: 1px solid #d1d7dc;
}

.progress-box > span {
  font-size: smaller;
}

.progress-box .trophy {
  border-radius: 50%;
  padding-right: 5px;
  padding: 1px 5px;
  color: var(--brand-color);
}

.custom-tooltip {
  background: #ffffff;
  color: #000;
  width: 150px;
  z-index: 99999;
  border: 1px solid var(--brand-color-light);
  border-radius: 3px;
}

.custom-tooltip .tooltip-inner {
  background: #ffffff;
  color: #000;
}

.custom-tooltip .tooltip-arrow {
  display: none;
}

.progress-container {
  width: 84%;
  max-width: 600px;
  padding: 10px 5px;
}

.offcanvas-header .btn-close {
  margin-top: -15px;
  margin-right: -15px;
}

.progress-bar-background {
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  height: 5px;
  position: relative;
  width: 100%;
}

.offcanvas {
  width: 20em !important;
}

.progress-bar-fill {
  background-color: var(--brand-color);
  height: 100%;
  width: 0;
  transition: width 0.3s ease;
}

.progress-text {
  margin-top: 10px;
  font-size: 10px;
  color: #333;
}

.content-sidebar .content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 2px solid;
  border-top: 2px solid;
  border-color: var(--brand-color-light);
  gap: 3rem;
}

.content-sidebar .content-heading {
  font-size: 17px;
  line-height: 26px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin-bottom: 0px;
  color: var(--brand-color);
}

.content-header .collapsse-sidebar {
  text-align: right;
  color: var(--brand-color);
}

.content-sidebar .content-header .collapsse-sidebar > svg {
  border: 1px solid;
  border-color: var(--brand-color);
  border-radius: 5px;
  transition: background-color 0.1s ease;
}

.content-sidebar .content-header .collapsse-sidebar > svg:hover {
  background-color: var(--brand-color-light);
  color: white;
}

.offcanvas-body {
  overflow-y: hidden;
}

.section {
  width: 98%;
  height: inherit;
  background: white;
  position: relative;
  cursor: pointer;
  margin-top: 3em;
  height: 98%;
  padding-bottom: 15px;
  overflow-y: auto;
  flex: 1;
  padding-right: 5px;
}

.section::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
}

.section::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 10px;
}

.section::-webkit-scrollbar-thumb {
  background-color: #2d2f31;
  border-radius: 10px;
}

.lesson-prev-next-btn {
  width: 45%;
  color: white;
  background-color: var(--brand-color) !important;
  text-align: center;
  border: 1px solid var(--brand-color) !important;
  border-color: var(--brand-color) !important;
}

.lesson-prev-next-btn:focus,
.lesson-prev-next-btn:hover {
  background-color: var(--brand-color) !important;
  border: 1px solid var(--brand-color) !important;
  border-color: var(--brand-color) !important;
}

.section .section-item .section-btn {
  color: #2d2f31;
  background: white;
  font-size: 15px;
  text-decoration: none;
  display: flex;
  padding: 5px 15px 15px 15px;
  line-height: 23px;
  transition: box-shadow 0.3s ease, transform 0.3s ease, border-bottom 0.3s ease;
  font-weight: 500;
  text-transform: capitalize;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 50px;
  position: relative;
  width: 100%;
  border: transparent;
  outline: transparent;
  border-bottom: 1px solid #d1d7dc;
}

.section .section-item .section-btn i {
  margin-right: 15px;
  color: #2d2f31;
}

.section .section-item .section-btn .dropdown {
  position: absolute;
  right: 0;
  margin: 20px;
  transition: transform 0.3s ease;
}

.section .section-item .section-btn .dropdown.rotated {
  transform: rotate(90deg);
}

.section .section-item .section-btn .counter {
  font-weight: lighter;
  font-size: 12px;
  color: #2d2f31;
  position: absolute;
  bottom: 0px;
  left: 4em;
}

.sub-items {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
  transition: border 0.2s ease;
  cursor: pointer;
  font-weight: 100;
  font-size: 14px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  transition: background-color 0.2 ease;
}

.sub-items:hover {
  background-color: #d1d7dc;
}

.sub-items-selected {
  border-bottom: 1px solid #d1d7dc;
  border-top: 1px solid #d1d7dc;
  background-color: #d1d7dc;
}

.section .section-item .sub-items a {
  text-decoration: none;
  word-break: break-word;
  color: #2d2f31;
}

.lesson-status-checkbox {
  width: 16px;
  height: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 100%;
  padding: 2px;
  border-color: #2d2f31;
  margin-left: 0.5em;
}

.section-lesson-completeion-checkbox {
  width: 16px;
  height: 16px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 100%;
  padding: 2px;
  border-color: #2d2f31;
  position: absolute;
  top: 7px;
}

.lesson-status-checkbox > svg {
  visibility: hidden;
}

.hover-area {
  position: absolute;
  width: 65px;
  height: 40px;
  display: flex;
  align-items: start;
  justify-content: center;
  right: 0;
  top: 5rem;
  transition: transform 0.1s ease;
}

.reopen-button {
  width: 100%;
  height: 100%;
  background: var(--brand-color);
  color: white;
  border: none;
  padding: 2px;
}

.hover-area:hover {
  transform: scale(1.1);
}

.for-content-wrapper {
  margin-right: 300px;
}

.content-wrapper.admin-courses .course-detail .description-content.text > div > figure {
  width: 100% !important;
  margin-bottom: 20px !important;
}

.content-wrapper.admin-courses .course-detail .description-content.text > div > figure > img {
  height: 100% !important;
  width: 99% !important;
  object-fit: fill !important;
}

.content-wrapper.admin-courses .course-detail .description-content.text > div > figure > iframe,
.content-wrapper.admin-courses .course-detail .description-content.text > div > figure > video {
  height: 100% !important;
  width: 99% !important;
  background-color: black;
}

@media (min-width: 1600px) {
  #setupGuide .modal-dialog,
  #stepsSetup .modal-dialog,
  #wantToCreateModal .modal-dialog,
  #createFolderModal .modal-dialog,
  #addVideoDetailsModal .modal-dialog,
  #addAudioDetailsModal .modal-dialog,
  #addLessonDetailsModal .modal-dialog,
  #addAudioModal .modal-dialog,
  #addVideoModal .modal-dialog,
  #createSubFolderModal .modal-dialog,
  #uploadAudioModal .modal-dialog,
  #uploadVideoModal .modal-dialog,
  #uploadLessonModal .modal-dialog,
  #selectSubFolderModal .modal-dialog,
  #createMembershipLevelModal .modal-dialog,
  #membershipBillingOptionsModal .modal-dialog,
  #renewalAndSuceessModal .modal-dialog,
  #popupMessageModal .modal-dialog,
  #selectFolderModal .modal-dialog,
  #contentLibraryUploadPhoto .modal-dialog,
  #contentLibraryUploadVideo .modal-dialog,
  #selectAudioSubFolderModal .modal-dialog,
  #selectVideoSubFolderModal .modal-dialog,
  #insideFolderModal .modal-dialog,
  #addFilesToBulkModal .modal-dialog,
  #filterMembersModal .modal-dialog,
  #importMembersModal .modal-dialog,
  #addEditMembersModal .modal-dialog,
  #addEventModal .modal-dialog,
  #attachmentFileModal .modal-dialog {
    max-width: 696px;
  }
  #paymentSuccessfull .modal-dialog,
  #paymentDone .modal-dialog,
  #playlistAddedModal .modal-dialog,
  #audioAddedModal .modal-dialog,
  #videoAddedModal .modal-dialog,
  #lessonAddedModal .modal-dialog {
    max-width: 608px;
  }
  #landingPage .modal-dialog,
  #viewEventModal .modal-dialog {
    max-width: 596px;
  }
  #searchModal .modal-dialog {
    max-width: 688px;
  }
  #createPlaylistModal .modal-dialog {
    max-width: 552px;
  }
  #reConfirmModal .modal-dialog {
    max-width: 893px;
  }
  #audioPreviewModal .modal-dialog,
  #videoPreviewModal .modal-dialog {
    max-width: 613px;
  }
  #socialCreateModal .modal-dialog {
    max-width: 625px;
  }
}
@media (min-width: 992px) {
  #login .col-lg-5,
  #login .col-lg-6,
  #register .col-lg-6,
  #forgot .col-lg-6,
  #create-password .col-lg-6,
  #account-verification .col-lg-6,
  #welcome .col-lg-5,
  #multiple-membership .col-lg-5,
  #user-membership-login .col-lg-5,
  #user-welcome .col-lg-5 {
    -ms-flex: 0 0 45.439%;
    flex: 0 0 45.439%;
    max-width: 45.439%;
  }
}
@media (min-width: 992px) {
  #user-welcome .col-lg-5 {
    -ms-flex: 0 0 55.439%;
    flex: 0 0 55.439%;
    max-width: 55.439%;
  }
}
@media (min-width: 576px) {
  #verifiedModal .modal-sm {
    max-width: 402px;
  }
}
@media only screen and (max-width: 1600px) {
  .cinfo-title {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: #ffffff;
    font-size: xx-large;
    justify-content: center;
    margin: auto;
    padding: 10px;
  }
  .enduser-dashboard .news .title {
    font-size: 18px;
    line-height: 26px;
  }
}
.content-wrapper.admin-courses.module-courses .content-header .description-content.text > div > figure {
  width: 100% !important;
  margin-bottom: 20px !important;
  padding-bottom: 0px !important;
}

.content-wrapper.admin-courses.module-courses .content-header .description-content.text > div > figure > img {
  height: 100% !important;
  width: 99% !important;
  object-fit: fill !important;
}

.content-wrapper.admin-courses.module-courses .content-header .description-content.text > div > figure > iframe,
.content-wrapper.admin-courses.module-courses .content-header .description-content.text > div > figure > video {
  height: 100% !important;
  width: 99% !important;
  background-color: black;
  min-height: 300px;
}

.salespage-product-description .description-content .se-component.se-video-container > figure {
  margin-bottom: 20px !important;
  padding-bottom: 0px !important;
}

.salespage-product-description .description-content .se-component.se-video-container > figure > iframe,
.salespage-product-description .description-content .se-component.se-video-container > figure > video {
  height: 100% !important;
  width: 99% !important;
  min-height: 200px;
  background: black;
}

@media only screen and (max-width: 1440px) {
  #paymentSuccessfull .modal-dialog,
  #playlistAddedModal .modal-dialog,
  #audioAddedModal .modal-dialog,
  #videoAddedModal .modal-dialog,
  #lessonAddedModal .modal-dialog {
    max-width: 402px;
  }
  .modal-header .close img {
    width: 40px;
  }
  #setupGuide p {
    font-size: 20px;
    line-height: 35px;
  }
  #setupGuide .btn-setup {
    padding: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  #setupGuide .btn-setup img {
    width: 30px;
  }
  .enduser-dashboard .explore-box .explore-btn {
    font-size: 24px;
    line-height: 50px;
  }
  .enduser-dashboard .news .heading,
  .enduser-dashboard .latest .heading {
    font-size: 36px;
    line-height: 44px;
  }
  .enduser-dashboard .news .heading small {
    padding-top: 18px;
  }
  .enduser-dashboard .info-card {
    padding-left: 8px;
  }
  .enduser-dashboard .info-card .card {
    margin: 0 8px 30px;
    width: 155px;
    height: auto;
  }
  .enduser-dashboard .latest {
    padding-left: 0px;
    padding-right: 0px;
  }
  .enduser-dashboard .latest .sub-heading {
    font-size: 25px;
    line-height: 50px;
  }
  .enduser-dashboard .calendar select {
    width: 80%;
  }
  .enduser-dashboard .suggested {
    padding-left: 0px;
    padding-right: 0px;
  }
  .enduser-dashboard .suggested .sub-heading {
    font-size: 25px;
    line-height: 50px;
    margin-bottom: 20px;
  }
  .enduser-dashboard #youFav .card .img-over img,
  .enduser-dashboard #suggEvent .card .img-over img,
  .enduser-dashboard #latestCont .card .img-over img {
    width: 40px;
  }
  .enduser-dashboard .suggested #suggestedTab .nav-link {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 25px;
    margin-bottom: 40px;
  }
  .enduser-dashboard .suggested #suggestedTab .nav-link.active {
    font-size: 20px;
  }
  .enduser-dashboard #all .card {
    width: 190px;
  }
  .enduser-dashboard #all .card .card-img-top {
    width: 190px;
    height: 249px;
  }
  .enduser-dashboard #all .card .img-over img {
    width: 40px;
  }
  .enduser-dashboard #post .latest-post .card-body {
    padding: 16px;
  }
  .enduser-dashboard #post .latest-post .card-body .card-columns {
    justify-content: left;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .enduser-dashboard #post .latest-post .card-body .card-columns img {
    margin-bottom: 10px;
  }
  .enduser-dashboard #videos .video-img,
  .enduser-dashboard #photos .photo {
    width: 255px;
    height: 180px;
  }
  .enduser-dashboard #courses .card,
  .enduser-dashboard #challenges .card {
    width: 290px;
  }
  .enduser-dashboard #courses .course-img,
  .enduser-dashboard #challenges .course-img {
    width: 290px;
    height: 226px;
  }
  .enduser-dashboard #courses .title,
  .enduser-dashboard #challenges .title {
    font-size: 20px;
    line-height: 28px;
  }
  #login .card-body {
    padding: 10px 67px;
  }
  #login .login-title {
    font-size: 22px;
    line-height: 36px;
  }
  #login .btn-info {
    margin-top: 10px;
    margin-bottom: 0px;
  }
  #login .login-logo img,
  #register .register-logo img,
  #welcome .welcome-logo img {
    width: 100px;
  }
  #login .login-msg,
  #register .register-msg {
    margin-bottom: 12px;
  }
  #register {
    margin: 5% auto;
  }
  #register .card-body {
    padding: 16px 67px 16px;
  }
  #register .btn-info {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #forgot .card-body {
    padding: 30px 67px 20px;
  }
  #forgot .btn-info {
    margin-top: 0px;
  }
  #forgot .forgot-logo img {
    width: 250px;
  }
  #forgot .forgot-title {
    font-size: 24px;
    line-height: 38px;
  }
  #landingPage .title {
    font-size: 30px;
    line-height: 49px;
    margin-bottom: 0px;
  }
  #landingPage .sub-title {
    font-size: 18px;
    line-height: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  #landingPage .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  #landingPage .text img {
    width: 14px;
  }
  #landingPage .sub-title2 {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  #landingPage .btn-custom {
    height: 50px;
    font-size: 20px;
    line-height: 32px;
  }
  #tour .card-body {
    padding: 25px 40px;
  }
  #tour .tour-title {
    font-size: 20px;
    line-height: 32px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  #tour .tour-slider .tour-logo {
    width: 300px;
    margin: 0 auto;
  }
  #tour .tour-text {
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 6px;
  }
  #membership .membership-subtitle {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  #membership .membership-title {
    font-size: 20px;
    line-height: 32px;
  }
  #membership .card-body .btn-preview {
    height: 50px;
  }
  #welcome .card-body {
    padding: 16px 92px;
  }
  #welcome .company-info {
    margin: 0px auto 24px;
  }
  #welcome .welcome-title {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  #account-verification .card-body {
    padding: 16px 67px;
  }
  #account-verification .verification-img img {
    width: 220px;
  }
  #account-verification .verification-title {
    margin-top: 0px;
    margin-bottom: 10px;
  }
  #user-membership-login .card-body,
  #user-welcome .card-body {
    padding: 16px 38px;
  }
  #user-welcome .btn-info {
    margin-top: 0px;
    margin-bottom: 16px;
  }
  #user-membership-login {
    margin: 4% auto;
  }
  #user-membership-login .btn-info {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  #multiple-membership {
    margin: 4% auto;
    height: 80vh;
  }
  #multiple-membership .multiple-membership-logo {
    margin-bottom: 0px;
  }
  #multiple-membership .multiple-membership-logo img {
    width: 90px;
  }
  #multiple-membership .card-body {
    padding: 16px;
  }
  #multiple-membership .multiple-membership-title {
    font-size: 22px;
    line-height: 37px;
    margin-bottom: 0px;
  }
  #multiple-membership .multiple-membership-msg {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 10px;
  }
  #multiple-membership .btn-info {
    margin-top: 16px;
    margin-bottom: 0px;
  }
  .daterange input {
    width: 100%;
  }
  .content-header h1 {
    font-size: 1.8rem;
  }
  .activity-list h5 {
    font-size: 18px;
    line-height: 31px;
  }
  .steps-mainpage .margin-left {
    margin-left: 0%;
  }
  #stepsSetup .title,
  #stepsSetup .title1 {
    font-size: 22px;
    line-height: 25px;
  }
  #stepsSetup form {
    padding: 0px 16px;
  }
  #stepsSetup .go-back {
    font-size: 14px;
    line-height: 28px;
  }
  #stepsSetup .go-back img {
    width: 40px;
  }
  .admin-members .member-list tbody tr td:last-child {
    width: auto;
  }
  .admin-members .member-list tbody tr td .dropdown-menu {
    width: auto;
  }
  .admin-members .member-list tbody tr td .dropdown-menu .dropdown-item {
    font-size: 16px;
    line-height: 20px;
  }
  .admin-members .member-list tbody tr td .dropdown-menu {
    background: #dbf3f4;
  }
  .admin-members .top-header .title {
    font-size: 30px;
    line-height: 43px;
  }
  .admin-members .top-buttons .member-search input {
    height: 46px;
  }
  .admin-members .top-buttons .search-icon {
    width: 36px;
    inset: 5px 5px 5px auto;
  }
  .admin-members .top-buttons .btn-custom {
    font-size: 16px;
    line-height: 22px;
    width: 200px;
    height: 46px;
  }
  .content-wrapper.admin-content-library {
    margin-right: 0px;
  }
  .admin-content-library .info-img {
    width: 20px;
  }
  .admin-content-library .title,
  .admin-audio-library .title,
  .admin-video-library .title,
  .admin-courses .title,
  .admin-student-queries .title {
    font-size: 20px;
    line-height: 36px;
  }
  .admin-content-library .readinglist .name,
  .admin-audio-library .playlist .name,
  .admin-video-library .playlist .name {
    font-size: 14px;
    line-height: 23px;
    width: 320px;
  }
  .admin-content-library .readinglist .left-img,
  .admin-audio-library .playlist .left-img,
  .admin-video-library .playlist .left-img {
    width: 50px;
  }
  .admin-content-library .readinglist .right-img,
  .admin-audio-library .playlist .right-img,
  .admin-video-library .playlist .right-img {
    right: 12px;
  }
  .admin-content-library .content-folders .title,
  .admin-audio-library .audio-folders .title,
  .admin-video-library .video-folders .title {
    font-size: 20px;
    line-height: 36px;
  }
  .admin-content-library .content-folders .title img,
  .admin-audio-library .audio-folders .title img,
  .admin-video-library .video-folders .title img {
    width: 20px;
  }
  .admin-content-library .content-folders .sort,
  .admin-content-library .content-folders .date,
  .admin-audio-library .audio-folders .sort,
  .admin-audio-library .audio-folders .date,
  .admin-video-library .video-folders .sort,
  .admin-video-library .video-folders .date,
  .admin-courses .courses-folders .sort,
  .admin-courses .courses-folders .date {
    width: 150px;
    height: 42px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .arrows-img,
  .admin-video-library .video-folders .video-folders-slider .arrows-img,
  .admin-video-library .video-folders .inside-video-folder .arrows-img {
    font-size: 30px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .slide-title,
  .admin-video-library .video-folders .video-folders-slider .slide-title,
  .admin-video-library .video-folders .inside-video-folder .slide-title {
    font-size: 16px;
    line-height: 20px;
    padding-left: 10px;
  }
  .admin-audio-library .audio-folders .audio-folders-slider .slide-text,
  .admin-video-library .video-folders .video-folders-slider .slide-text,
  .admin-video-library .video-folders .inside-video-folder .slide-text {
    font-size: 12px;
    line-height: 19px;
    padding-left: 10px;
  }
  .admin-video-library .video-folders .video-info label {
    font-size: 15px;
    line-height: 30px;
  }
  .admin-video-library .video-folders .video-info label img {
    width: 20px;
  }
  .admin-video-library .video-folders .video-info label img:nth-child(2) {
    width: 12px;
  }
  .admin-video-library .video-list .after-search .video-title {
    font-size: 16px;
    line-height: 22px;
  }
  .admin-content-library .content-list .nav-item,
  .admin-audio-library .audio-list .nav-item,
  .admin-video-library .video-list .nav-item {
    width: 180px;
    margin-right: 0px;
  }
  .admin-content-library .content-list .nav-link,
  .admin-audio-library .audio-list .nav-link,
  .admin-video-library .video-list .nav-link,
  .admin-courses .courses-list .nav-link,
  .admin-courses .courses-folders .nav-link {
    font-size: 16px;
    line-height: 26px;
  }
  .admin-content-library .content-list .search-content input,
  .admin-audio-library .audio-list .search-library input,
  .admin-video-library .video-list .search-library input {
    width: 450px;
    height: 46px;
    font-size: 16px;
    line-height: 26px;
  }
  .admin-content-library .content-list .search-content .search-icon img,
  .admin-audio-library .audio-list .search-library .search-icon img,
  .admin-video-library .video-list .search-library .search-icon img {
    width: 32px;
  }
  .admin-content-library .content-list #contentLibraryTabContent,
  .admin-audio-library .audio-list #audioLibraryTabContent,
  .admin-video-library .video-list #videoLibraryTabContent {
    padding: 16px;
  }
  .admin-content-library .content-list #contentLibraryTabContent .table-title,
  .admin-audio-library .audio-list #audioLibraryTabContent .table-title,
  .admin-video-library .video-list #videoLibraryTabContent .table-title {
    font-size: 16px;
    line-height: 26px;
  }
  .admin-content-library .content-list #contentLibraryTabContent .views,
  .admin-audio-library .audio-list #audioLibraryTabContent .views,
  .admin-video-library .video-list #videoLibraryTabContent .views {
    font-size: 13px;
    line-height: 20px;
  }
  .content-wrapper.admin-courses,
  .content-wrapper.admin-student-queries {
    margin-right: 0px;
  }
  .content-wrapper.admin-courses .content-header {
    padding-left: 0px;
    padding-right: 0px;
  }
  .admin-courses .title a {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-courses .courses-folders {
    padding-left: 0px;
  }
  .admin-courses .content-header .btn-custom {
    font-size: 18px;
    line-height: 26px;
    width: 210px;
    height: 40px;
  }
  .admin-courses .courses-list .nav-item,
  .admin-courses .courses-folders .nav-item {
    width: 160px;
    margin-right: 0px;
  }
  .admin-courses .courses-folders .search-course input,
  .admin-courses .content-header .search-course input {
    width: 100%;
    height: 46px;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 10px;
  }
  .admin-courses .courses-folders .search-course .search-icon img,
  .admin-courses .content-header .search-course .search-icon img {
    width: 32px;
  }
  .admin-courses .courses-list .student-queries .queries-icon span {
    font-size: 13px;
    line-height: 16px;
    padding: 8px 26px;
    top: -33px;
    right: 17px;
  }
  .admin-courses .courses-list .student-queries .queries-icon .question-img {
    width: 75px;
    top: -22px;
  }
  .admin-courses .courses-list .student-queries .title,
  .admin-student-queries .student-queries .title {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 10px;
  }
  .admin-courses .courses-list .student-queries .search-queries,
  .admin-student-queries .student-queries .search-queries {
    margin-bottom: 21px;
  }
  .admin-courses .courses-list .student-queries .search-queries input,
  .admin-student-queries .student-queries .search-queries input {
    font-size: 18px;
    line-height: 28px;
    height: 46px;
  }
  .admin-courses .courses-list .student-queries .search-icon,
  .admin-student-queries .student-queries .search-icon {
    top: 10px;
    right: 12px;
  }
  .admin-courses .courses-list .student-queries .search-icon img,
  .admin-student-queries .student-queries .search-icon img {
    width: 25px;
  }
  .admin-courses .courses-list .student-queries .messages-list .media img,
  .admin-student-queries .student-queries .messages-list .media img,
  .admin-student-queries .message-detail .media img {
    width: 34px;
    height: 34px;
  }
  .admin-courses .courses-list .student-queries .messanger-name,
  .admin-student-queries .student-queries .messanger-name,
  .admin-student-queries .message-detail .messanger-name {
    font-size: 15px;
    line-height: 13px;
  }
  .admin-courses .courses-list .student-queries .msg-time,
  .admin-student-queries .student-queries .msg-time {
    font-size: 13px;
  }
  .admin-courses .courses-list .student-queries .messanger-text,
  .admin-student-queries .student-queries .messanger-text,
  .admin-student-queries .message-detail .messanger-text {
    font-size: 14px;
    line-height: 16px;
  }
  .admin-courses .courses-list .student-queries .msg-count,
  .admin-student-queries .student-queries .msg-count {
    font-size: 10px;
    line-height: 18px;
    width: 30px;
    height: 22px;
  }
  .admin-courses .course-detail .edit-img {
    width: 60px;
  }
  .admin-courses .course-detail .course-info {
    font-size: 14px;
    line-height: 23px;
    padding: 6px 30px;
  }
  .admin-courses .course-detail .student-img img {
    width: 30px;
    height: 30px;
  }
  .admin-courses .course-detail .price {
    font-size: 13px;
    line-height: 21px;
  }
  .admin-courses .course-detail .price span {
    font-size: 18px;
    line-height: 28px;
  }
  .admin-courses .course-detail .course-text {
    font-size: 14px;
    line-height: 27px;
  }
  #wantToCreateModal .modal-title,
  #createFolderModal .modal-title,
  #addAudioModal .modal-title,
  #addVideoModal .modal-title,
  #createSubFolderModal .title,
  #selectFolderModal .modal-title {
    font-size: 22px;
    line-height: 30px;
    padding-left: 16px;
  }
  #addVideoDetailsModal .modal-title,
  #addAudioDetailsModal .modal-title,
  #addLessonDetailsModal .title {
    font-size: 22px;
    line-height: 30px;
  }
  #addLessonDetailsModal .title,
  #addLessonDetailsModal .folder-name,
  #addAudioDetailsModal .folder-name,
  #addVideoDetailsModal .folder-name {
    padding-left: 16px;
  }
  #createSubFolderModal small {
    padding-left: 16px;
  }
  #wantToCreateModal .modal-body,
  #addAudioModal .modal-body,
  #addVideoModal .modal-body {
    padding: 40px 16px;
  }
  #wantToCreateModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-file-alt,
  #addAudioModal .folder .fa-folder,
  #addVideoModal .folder .fa-folder,
  #wantToCreateModal .audio .fa-microphone,
  #wantToCreateModal .audio .fa-file-alt,
  #addAudioModal .audio .fa-microphone,
  #wantToCreateModal .video .fa-video,
  #addVideoModal .video .fa-video,
  #selectFolderModal .video .fa-video,
  #selectFolderModal .folder .fa-folder,
  #selectFolderModal .audio .fa-microphone,
  #wantToCreateModal .folder .fa-book,
  #wantToCreateModal .audio .fa-file-audio {
    font-size: 50px;
  }
  #wantToCreateModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-file-alt,
  #addAudioModal .folder .fa-folder,
  #addVideoModal .folder .fa-folder,
  #selectFolderModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-book,
  #wantToCreateModal .audio .fa-file-audio {
    padding: 45.5px 55px;
  }
  #wantToCreateModal .video .fa-video,
  #selectFolderModal .video .fa-video,
  #addVideoModal .video .fa-video {
    padding: 45.5px 52px;
  }
  #wantToCreateModal .audio .fa-microphone,
  #wantToCreateModal .audio .fa-file-alt,
  #addAudioModal .audio .fa-microphone,
  #selectFolderModal .audio .fa-microphone {
    padding: 45.5px 61.38px;
  }
  #wantToCreateModal .folder-img,
  #selectFolderModal .folder-img {
    padding: 30px 50px;
  }
  #addAudioModal .upload-audio-file,
  #addVideoModal .upload-video-file,
  #addVideoModal .upload-lesson-file {
    padding: 0px 30px 0px;
  }
  #uploadAudioModal .title,
  #uploadVideoModal .title,
  #uploadLessonModal .title,
  #uploadAudioModal .subtitle,
  #uploadVideoModal .subtitle,
  #uploadLessonModal .subtitle,
  #uploadAudioModal small,
  #uploadVideoModal small,
  #uploadLessonModal small {
    padding-left: 16px;
    margin-bottom: 0px;
    font-size: 18px;
  }
  #createFolderModal .btn-custom,
  #addVideoDetailsModal .btn-custom,
  #addAudioDetailsModal .btn-custom,
  #addLessonDetailsModal .btn-custom,
  #createSubFolderModal .btn-custom {
    width: 224px;
    font-size: 16px;
    line-height: 26px;
    height: 44px;
  }
  #createFolderModal .modal-header,
  #createFolderModal .modal-body,
  #addVideoDetailsModal .modal-header,
  #addVideoDetailsModal .modal-body,
  #addAudioDetailsModal .modal-header,
  #addAudioDetailsModal .modal-body,
  #addLessonDetailsModal .modal-header,
  #addLessonDetailsModal .modal-body,
  #createSubFolderModal .modal-header,
  #createSubFolderModal .modal-body,
  #uploadAudioModal .modal-header,
  #uploadAudioModal .modal-body,
  #uploadVideoModal .modal-header,
  #uploadVideoModal .modal-body,
  #uploadLessonModal .modal-header,
  #uploadLessonModal .modal-body {
    padding: 16px;
  }
  #createFolderModal .upload-file,
  #createSubFolderModal .upload-file,
  #addVideoDetailsModal .upload-file,
  #addAudioDetailsModal .upload-file {
    right: 6px;
  }
  #createFolderModal label,
  #addVideoDetailsModal label,
  #addAudioDetailsModal label,
  #addLessonDetailsModal label,
  #createSubFolderModal label,
  #createFolderModal .form-control,
  #addVideoDetailsModal .form-control,
  #addAudioDetailsModal .form-control,
  #addLessonDetailsModal .form-control,
  #createSubFolderModal .form-control,
  #renewalAndSuceessModal label {
    font-size: 14px;
    line-height: 23px;
  }
  #createFolderModal .form-control,
  #addVideoDetailsModal .form-control,
  #addAudioDetailsModal .form-control,
  #addLessonDetailsModal .form-control,
  #createSubFolderModal .form-control {
    padding-left: 12px;
  }
  #wantToCreateModal .select-folder,
  #selectSubFolderModal .folder {
    padding-left: 16px;
    padding-right: 16px;
  }
  #wantToCreateModal .select-folder .card,
  #selectSubFolderModal .folder .card {
    margin: 15px;
  }
  #wantToCreateModal .select-folder .card .card-body,
  #selectSubFolderModal .folder .card .card-body {
    padding: 16px;
  }
  #wantToCreateModal .select-folder .card .card-body .page-info img,
  #selectSubFolderModal .folder .card .card-body .page-info img {
    width: 30px;
  }
  #wantToCreateModal .select-folder .card .card-body .page-info,
  #selectSubFolderModal .folder .card .card-body .page-info {
    font-size: 12px;
    line-height: 18px;
  }
  #selectFolderModal .select-icon {
    padding: 0px;
  }
  #selectFolderModal .select-folder,
  #selectAudioSubFolderModal .folder,
  #selectVideoSubFolderModal .folder,
  #insideFolderModal .folder {
    padding: 0px;
  }
  #selectFolderModal .select-folder .audio-img,
  #selectAudioSubFolderModal .folder .audio-img,
  #selectVideoSubFolderModal .folder .video-img,
  #insideFolderModal .folder .audio-img {
    width: 130px;
    height: 100px;
  }
  #selectFolderModal .select-folder .card,
  #selectAudioSubFolderModal .folder .card,
  #selectVideoSubFolderModal .folder .card,
  #insideFolderModal .folder .card {
    margin: 15px 10px;
  }
  #selectFolderModal .select-folder .card .card-body,
  #selectAudioSubFolderModal .folder .card .card-body,
  #selectVideoSubFolderModal .folder .card .card-body,
  #insideFolderModal .folder .card .card-body {
    padding: 10px;
  }
  #selectFolderModal .select-folder .card .card-title,
  #selectAudioSubFolderModal .folder .card .card-title,
  #selectVideoSubFolderModal .folder .card .card-title,
  #insideFolderModal .folder .card .card-title {
    font-size: 16px;
    line-height: 24px;
  }
  #selectFolderModal .select-folder .card .card-text,
  #selectAudioSubFolderModal .folder .card .card-text,
  #selectVideoSubFolderModal .folder .card .card-text,
  #insideFolderModal .folder .card .card-text {
    font-size: 12px;
    line-height: 20px;
  }
  #selectFolderModal .select-folder .card .card-body .page-info,
  #selectAudioSubFolderModal .folder .card .card-body .page-info,
  #selectVideoSubFolderModal .folder .card .card-body .page-info,
  #insideFolderModal .folder .card .card-body .page-info {
    font-size: 12px;
    line-height: 19px;
  }
  #selectFolderModal .select-folder .card .card-body .page-info img,
  #selectAudioSubFolderModal .folder .card .card-body .page-info img,
  #selectVideoSubFolderModal .folder .card .card-body .page-info img,
  #insideFolderModal .folder .card .card-body .page-info img {
    width: 30px;
  }
  #selectFolderModal .select-folder .card .card-body .student-info img,
  #insideFolderModal .folder .card .card-body .student-info img {
    width: 20px;
  }
  #selectFolderModal .select-folder .btn-custom,
  #selectAudioSubFolderModal .folder .btn-custom,
  #selectVideoSubFolderModal .folder .btn-custom,
  #insideFolderModal .folder .btn-custom {
    font-size: 20px;
    line-height: 31px;
    width: 290px;
    height: 46px;
    margin-bottom: 15px;
  }
  #selectSubFolderModal .go-back,
  #createMembershipLevelModal .modal-body .go-back,
  #membershipBillingOptionsModal .modal-body .go-back,
  #contentLibraryUploadPhoto .go-back,
  #contentLibraryUploadVideo .go-back,
  #selectAudioSubFolderModal .go-back,
  #selectVideoSubFolderModal .go-back,
  #insideFolderModal .go-back,
  #renewalAndSuceessModal .go-back,
  #addLessonDetailsModal .go-back {
    font-size: 16px;
    line-height: 20px;
  }
  #selectSubFolderModal .go-back img,
  #createMembershipLevelModal .modal-body .go-back img,
  #membershipBillingOptionsModal .modal-body .go-back img,
  #contentLibraryUploadPhoto .go-back img,
  #contentLibraryUploadVideo .go-back img,
  #selectAudioSubFolderModal .go-back img,
  #selectVideoSubFolderModal .go-back img,
  #insideFolderModal .go-back img,
  #createSubFolderModal .go-back img,
  #uploadAudioModal .go-back img,
  #uploadVideoModal .go-back img,
  #uploadLessonModal .go-back img,
  #renewalAndSuceessModal .go-back img,
  #addLessonDetailsModal .go-back img {
    width: 40px;
  }
  #uploadAudioModal .uploaded-file .name,
  #uploadVideoModal .uploaded-file .name,
  #uploadLessonModal .uploaded-file .name {
    font-size: 14px;
    line-height: 24px;
  }
  #uploadAudioModal .uploaded-file .perc,
  #uploadVideoModal .uploaded-file .perc,
  #uploadLessonModal .uploaded-file .perc {
    font-size: 16px;
    line-height: 26px;
  }
  #uploadAudioModal .uploaded-file img,
  #uploadVideoModal .uploaded-file img,
  #uploadLessonModal .uploaded-file img {
    width: 40px;
  }
  #wantToCreateModal .select-folder .btn-custom,
  #selectSubFolderModal .folder .btn-custom {
    font-size: 20px;
    line-height: 30px;
    height: 46px;
  }
  .content-page-setting section {
    padding-left: 16px;
    padding-right: 16px;
  }
  #selectAudioSubFolderModal .folder .title,
  #selectVideoSubFolderModal .folder .title,
  #insideFolderModal .folder .title {
    font-size: 18px;
    line-height: 34px;
  }
  #insideFolderModal .folder .uploaded-file .file-title {
    font-size: 16px;
    line-height: 24px;
  }
  #insideFolderModal .folder .uploaded-file .file-text {
    font-size: 12px;
    line-height: 23px;
  }
  #audioPreviewModal .modal-title,
  #audioPreviewModal .audio-title,
  #videoPreviewModal .modal-title,
  #videoPreviewModal .video-title {
    font-size: 18px;
    line-height: 28px;
  }
  #audioPreviewModal .chat-like,
  #videoPreviewModal .chat-like {
    padding: 0px 10px;
  }
  #audioPreviewModal .chat-like img,
  #videoPreviewModal .chat-like img {
    width: 30px;
  }
  #audioPreviewModal .cursor .cursor-img,
  #videoPreviewModal .cursor .cursor-img {
    width: 17px;
    top: -9px;
  }
  #audioPreviewModal .timing span,
  #videoPreviewModal .timing span {
    font-size: 16px;
    line-height: 26px;
  }
  #audioPreviewModal .controls,
  #videoPreviewModal .controls {
    padding: 0px 40px;
  }
  #audioPreviewModal .controls img:nth-child(1),
  #audioPreviewModal .controls img:nth-child(2),
  #audioPreviewModal .controls img:nth-child(4),
  #audioPreviewModal .controls img:nth-child(5),
  #videoPreviewModal .controls img:nth-child(1),
  #videoPreviewModal .controls img:nth-child(2),
  #videoPreviewModal .controls img:nth-child(4),
  #videoPreviewModal .controls img:nth-child(5) {
    width: 22px;
  }
  #audioPreviewModal .controls img:nth-child(3),
  #videoPreviewModal .controls img:nth-child(3) {
    width: 70px;
  }
  .content-choose-template,
  .content-edit-template {
    border-radius: 50px;
    margin: 20px 10px;
    padding: 50px 30px 10px;
  }
  .content-choose-template .title,
  .content-edit-template .title {
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 16px;
  }
  .content-choose-template .text,
  .content-edit-template .text {
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 60px;
  }
  .content-choose-template .template,
  .content-edit-template .template {
    padding: 22px;
  }
  .content-choose-template .template .content .heading,
  .content-edit-template .template .content .heading {
    font-size: 20px;
    line-height: 36px;
  }
  .content-edit-template .upload-media .dropdown-menu {
    width: auto;
    padding-left: 30px;
  }
  .content-edit-template .upload-media .dropdown-item {
    font-size: 18px;
    line-height: 28px;
  }
  .content-edit-template .text-area {
    padding: 16px;
  }
  .content-edit-template .text-area textarea {
    font-size: 22px;
    line-height: 38px;
  }
  .content-edit-template .upload-media .image {
    height: 270px;
  }
  .content-edit-template .upload-media {
    padding: 33px 26px;
  }
  .content-edit-template .upload-media .btn-custom {
    font-size: 20px;
    line-height: 30px;
    width: 240px;
    height: 50px;
  }
  .content-edit-template .btn-custom1 {
    font-size: 20px;
    line-height: 31px;
    height: 50px;
  }
  #contentLibraryUploadPhoto .modal-header,
  #contentLibraryUploadVideo .modal-header,
  #contentLibraryUploadPhoto .modal-body,
  #contentLibraryUploadVideo .modal-body {
    padding: 16px;
  }
  #contentLibraryUploadPhoto .title,
  #contentLibraryUploadVideo .title {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  #contentLibraryUploadPhoto .label-title {
    font-size: 16px;
    line-height: 26px;
  }
  #contentLibraryUploadPhoto .btn-custom,
  #contentLibraryUploadVideo .btn-custom {
    font-size: 16px;
    line-height: 26px;
    height: 50px;
  }
  #createMembershipLevelModal .modal-body,
  #membershipBillingOptionsModal .modal-body {
    padding: 16px;
  }
  #createMembershipLevelModal .title {
    font-size: 20px;
    line-height: 30px;
  }
  #createMembershipLevelModal .subscription-info .card-body {
    padding: 16px;
  }
  .enduser-social-wall .title {
    font-size: 26px;
    line-height: 43px;
  }
  .enduser-social-wall .calendar input {
    width: 100%;
    height: 48px;
  }
  .enduser-social-wall #socialWallTabContent .owl-item {
    padding: 16px 6px;
  }
  .enduser-social-wall #socialWallTabContent .item {
    padding: 5px;
  }
  .enduser-social-wall #socialWallTabContent .item .slide-title {
    font-size: 14px;
    line-height: 23px;
  }
  .enduser-social-wall #socialWallTabContent .item .slide-text .fa-history {
    font-size: 16px;
  }
  .enduser-social-wall .post-area,
  .enduser-social-wall .posts {
    padding: 16px;
  }
  .enduser-social-wall .post-area .uploaded-img {
    width: 157px;
  }
  .enduser-social-wall .post-area .uploaded-img .img {
    width: 155px;
    height: 99px;
  }
  .enduser-social-wall .posts .sm-img {
    width: 179px;
    height: 112px;
  }
  .enduser-social-wall .post-area .btn-custom,
  .help-modal .btn-custom {
    width: 100%;
    height: 50px;
  }
  .enduser-social-wall .posts .lg-img {
    width: 343px;
    height: 240px;
  }
  #showPostModal #postCarousel {
    margin-top: -60px;
  }
  .enduser-social-wall #socialWallTab .nav-item {
    width: 130px;
    margin-right: 0px;
  }
  .enduser-social-wall #socialWallTab .nav-link {
    font-size: 16px;
    line-height: 25px;
  }
  .enduser-social-wall #socialWallTab .search-library input {
    width: 380px;
    height: 46px;
  }
  .enduser-social-wall #socialWallTab .search-icon img {
    width: 33px;
    inset: 5px 5px 5px auto;
  }
  .enduser-social-wall #socialWallTab .btn-custom {
    width: 45px;
    height: 45px;
    font-size: 15px;
    padding: 4px;
    margin-left: 20px;
  }
  .enduser-social-wall .post-area .cross {
    width: 18px;
  }
  #socialCreateModal .modal-title {
    font-size: 24px;
    line-height: 37px;
  }
  #socialCreateModal .title {
    font-size: 22px;
    line-height: 44px;
  }
  #socialCreateModal .btn-custom {
    width: 290px;
    height: 50px;
    font-size: 20px;
  }
  .enduser-dashboard section.profile {
    padding: 16px 8px;
  }
  .enduser-dashboard .profile #profile-tabContent {
    padding: 16px;
  }
  .enduser-dashboard .profile #profile-tabContent .title {
    font-size: 22px;
    line-height: 30px;
  }
  .enduser-dashboard .profile #account-details .details span,
  .enduser-dashboard .profile #account-details .details label {
    font-size: 16px;
  }
  .enduser-dashboard .profile #account-details .plans,
  .enduser-dashboard .profile #account-details .explore-plans {
    width: 350px;
    margin-right: 20px;
  }
  .enduser-dashboard .profile #account-details .plans:nth-child(even),
  .enduser-dashboard .profile #account-details .explore-plans:nth-child(even) {
    margin-right: 0px;
  }
  .enduser-dashboard .profile #account-details .plans .card-body,
  .enduser-dashboard .profile #account-details .explore-plans .card-body {
    padding: 16px;
  }
  .enduser-dashboard .profile #account-details .plans .plan-name,
  .enduser-dashboard .profile #account-details .explore-plans .plan-name,
  .enduser-dashboard .profile #account-details .plans .plan-fee,
  .enduser-dashboard .profile #account-details .explore-plans .plan-fee {
    font-size: 16px;
    line-height: 22px;
  }
  .enduser-dashboard .profile #account-details .plans .btn-custom,
  .enduser-dashboard .profile #account-details .explore-plans .btn-custom {
    height: 36px;
  }
  .enduser-dashboard .profile #edit-account .btn-white,
  .enduser-dashboard .profile #change-password .btn-white,
  .enduser-dashboard .profile #notifications-settings .btn-white,
  .enduser-dashboard .profile #edit-account .btn-blue,
  .enduser-dashboard .profile #change-password .btn-blue,
  .enduser-dashboard .profile #notifications-settings .btn-blue,
  .enduser-dashboard .profile #unsubscribe .btn-blue {
    height: 50px;
  }
  .enduser-dashboard .profile #notifications-settings .sub-title,
  .enduser-dashboard .profile #unsubscribe .sub-title {
    font-size: 20px;
    line-height: 39px;
  }
  .enduser-dashboard .profile #notifications-settings .notification-text {
    font-size: 14px;
  }
  .enduser-dashboard .profile #unsubscribe .tab-message {
    font-size: 15px;
    line-height: 41px;
  }
  .enduser-dashboard .content-library .folders .card-body {
    padding: 16px;
  }
  .enduser-dashboard .content-library .folders .book {
    padding-left: 0px;
  }
  .enduser-dashboard .content-library .title {
    font-size: 26px;
    line-height: 42px;
  }
  .enduser-dashboard .content-library-folder .title {
    font-size: 24px;
    line-height: 40px;
  }
  .enduser-dashboard .content-library-folder .content-folders .title {
    font-size: 20px;
    line-height: 33px;
  }
  .enduser-dashboard .content-library-folder .content-folders .file-info {
    font-size: 16px;
    line-height: 26px;
  }
  .enduser-dashboard .content-header .title {
    font-size: 24px;
    line-height: 37px;
  }
  .enduser-dashboard .plan-details .plan .title,
  .enduser-dashboard .payment-details .plan .title {
    font-size: 22px;
    line-height: 44px;
  }
  .enduser-dashboard .payment-details .btn-custom,
  .enduser-dashboard .plan-details .btn-custom {
    height: 50px;
  }
  #paymentSuccessfull .title {
    font-size: 24px;
    line-height: 46px;
    margin-bottom: 14px;
  }
  #paymentSuccessfull .text {
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 0px;
  }
  .enduser-dashboard .audio-library .audios .nav-link,
  .enduser-dashboard .video-library .videos .nav-link {
    font-size: 20px;
    line-height: 31px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent,
  .enduser-dashboard .video-library .videos #videoTabContent {
    padding: 16px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .title,
  .enduser-dashboard .video-library .videos #videoTabContent .title {
    font-size: 16px;
    line-height: 24px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .text,
  .enduser-dashboard .video-library .videos #videoTabContent .text {
    font-size: 14px;
    line-height: 22px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .views,
  .enduser-dashboard .video-library .videos #videoTabContent .views {
    font-size: 16px;
    line-height: 26px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .play-img,
  .enduser-dashboard .video-library .videos #videoTabContent .play-img,
  .enduser-dashboard .audio-library .audios #audioTabContent .fav-img,
  .enduser-dashboard .video-library .videos #videoTabContent .fav-img {
    width: 40px;
  }
  .enduser-dashboard .audio-library .audios #audioTabContent .drop-img,
  .enduser-dashboard .video-library .videos #videoTabContent .drop-img {
    width: 7px;
  }
  #createPlaylistModal .modal-title,
  #addPlaylistModal .modal-title {
    font-size: 22px;
    line-height: 39px;
  }
  #createPlaylistModal .form-control {
    padding-left: 25px;
    font-size: 18px;
    line-height: 26px;
  }
  #createPlaylistModal input.form-control {
    height: 75px;
  }
  #createPlaylistModal textarea.form-control {
    height: 200px;
    margin-bottom: 30px;
  }
  #createPlaylistModal .btn-custom {
    height: 50px;
    font-size: 22px;
    line-height: 34px;
    margin-bottom: 0px;
  }
  #createPlaylistModal .text {
    margin-bottom: 0px;
  }
  #addPlaylistModal .btn-custom {
    height: 60px;
    margin-top: 0px;
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 36px;
  }
  #addPlaylistModal .title {
    font-size: 20px;
    line-height: 33px;
    margin-bottom: 0px;
  }
  #addPlaylistModal .add {
    width: 45px;
    height: 42px;
  }
  #addPlaylistModal .add .fa-plus {
    font-size: 18px;
    line-height: 40px;
  }
  .enduser-dashboard .audio-folder .title {
    font-size: 22px;
    line-height: 38px;
  }
  .enduser-dashboard .audio-folder .border-line::after {
    width: 70%;
  }
  .enduser-dashboard .audio-folder .folder-info .form-control {
    width: 30%;
  }
  .enduser-dashboard .my-playlist .border-line::after {
    width: 55%;
  }
  .enduser-dashboard .my-playlist .title {
    font-size: 24px;
    line-height: 42px;
  }
  #playlistAddedModal .title {
    font-size: 22px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  #playlistAddedModal .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }
  .enduser-dashboard .audio-top-result .title,
  .enduser-dashboard .video-top-result .title,
  .enduser-dashboard .audio-result-detail .title,
  .enduser-dashboard .video-result-detail .title {
    font-size: 24px;
    line-height: 42px;
  }
  .enduser-dashboard .audio-top-result .border-line::after,
  .enduser-dashboard .video-top-result .border-line::after,
  .enduser-dashboard .audio-result-detail .border-line::after,
  .enduser-dashboard .video-result-detail .border-line::after {
    width: 60%;
  }
  .enduser-dashboard .audio-top-result .folders .title,
  .enduser-dashboard .video-top-result .folders .title {
    font-size: 24px;
    line-height: 38px;
  }
  .enduser-dashboard .audio-top-result .folders .card .title {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .audio-top-result .folders .card .text {
    font-size: 16px;
    line-height: 26px;
  }
  .enduser-dashboard .audio-top-result .audio-files .table-responsive,
  .enduser-dashboard .video-top-result .video-files .table-responsive,
  .enduser-dashboard .video-result-detail .video-files .table-responsive,
  .enduser-dashboard .audio-result-detail .folder-files .table-responsive {
    padding: 16px;
  }
  .enduser-dashboard .show-content .template .title,
  .enduser-dashboard .show-content .template2 .title,
  .enduser-dashboard .show-content .template3 .title {
    font-size: 22px;
    line-height: 38px;
  }
  #renewalAndSuceessModal .modal-header,
  #renewalAndSuceessModal .modal-body {
    padding: 16px;
  }
  #renewalAndSuceessModal .title {
    font-size: 22px;
    line-height: 22px;
  }
  #renewalAndSuceessModal .subtitle {
    font-size: 18px;
    line-height: 38px;
  }
  #renewalAndSuceessModal .nav-pills .nav-link {
    font-size: 14px;
  }
  #renewalAndSuceessModal .btn-custom {
    font-size: 20px;
    line-height: 30px;
    height: 46px;
  }
  #playlistAddedModal .modal-body,
  #audioAddedModal .modal-body,
  #videoAddedModal .modal-body,
  #lessonAddedModal .modal-body {
    padding-left: 16px;
    padding-right: 16px;
  }
  #audioAddedModal .title,
  #videoAddedModal .title,
  #lessonAddedModal .title {
    font-size: 22px;
    line-height: 44px;
    margin-bottom: 10px;
  }
  #audioAddedModal .text,
  #videoAddedModal .text,
  #lessonAddedModal .text {
    font-size: 14px;
    line-height: 24px;
  }
  .content-page-setting .title {
    font-size: 22px;
    line-height: 39px;
  }
  .content-page-setting .title img {
    width: 50px;
  }
  .content-page-setting .breadcrumb-item a,
  .content-page-setting .breadcrumb-item {
    font-size: 16px;
    line-height: 25px;
  }
  .content-page-setting .btn-custom {
    font-size: 20px;
    line-height: 32px;
    height: 50px;
  }
  .content-final-preview section {
    padding-left: 16px;
    padding-right: 16px;
  }
  .content-final-preview .title {
    font-size: 22px;
    line-height: 39px;
  }
  .content-final-preview .heading img {
    width: 60px;
  }
  .content-final-preview .heading a {
    font-size: 14px;
    line-height: 23px;
    width: 190px;
    height: 50px;
    margin-right: 8px;
  }
  .content-final-preview .heading a img {
    width: 30px;
  }
  .content-final-preview .breadcrumb-item a,
  .content-final-preview .breadcrumb-item {
    font-size: 16px;
    line-height: 25px;
  }
  .content-final-preview .template .title {
    font-size: 20px;
    line-height: 33px;
  }
  .content-final-preview .btn-custom {
    font-size: 20px;
    line-height: 30px;
    height: 50px;
  }
  #createMembershipLevelModal label {
    font-size: 14px;
    line-height: 23px;
  }
  #createMembershipLevelModal .btn-custom,
  #membershipBillingOptionsModal .btn-custom {
    font-size: 20px;
    line-height: 31px;
    height: 50px;
  }
  #addFilesToBulkModal .modal-title,
  #filterMembersModal .modal-title,
  #importMembersModal .modal-title,
  #addEditMembersModal .modal-title {
    font-size: 22px;
    line-height: 44px;
  }
  #addFilesToBulkModal .attach-file,
  #addFilesToBulkModal .audience,
  #addFilesToBulkModal .file-history {
    padding-left: 16px;
    padding-right: 16px;
  }
  #addFilesToBulkModal .attach-file .title,
  #addFilesToBulkModal .audience .title,
  #addFilesToBulkModal .file-history .title {
    font-size: 18px;
    line-height: 28px;
  }
  #addFilesToBulkModal .file-history .file-name {
    font-size: 16px;
    line-height: 24px;
  }
  #addFilesToBulkModal .btn-custom {
    font-size: 16px;
    line-height: 24px;
    height: 50px;
  }
  #playlistAddedModal .success-img,
  #audioAddedModal .success-img,
  #videoAddedModal .success-img,
  #lessonAddedModal .success-img {
    margin-top: -70px;
    margin-left: -35px;
    width: 300px;
  }
  .admin-courses .title a,
  .admin-student-queries .title a {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-student-queries .student-queries .title img,
  .admin-student-queries .message-detail .filters img,
  .admin-student-queries .message-detail .chat-messages .new-msg img:last-child {
    width: 34px;
    height: 34px;
  }
  .admin-student-queries .message-detail .chat-messages .messanger-name {
    font-size: 13px;
    line-height: 22px;
  }
  .admin-student-queries .message-detail .chat-messages .msg-time {
    font-size: 11px;
    line-height: 20px;
  }
  .admin-student-queries .message-detail .chat-messages .messanger-text {
    font-size: 12px;
    line-height: 20px;
  }
  .admin-student-queries .message-detail .chat-messages .link {
    font-size: 12px;
    line-height: 16px;
  }
  .admin-student-queries .message-detail .chat-messages .link img {
    width: 16px;
    height: 16px;
  }
  .admin-student-queries .message-detail .chat-messages .attachment {
    padding: 8px;
  }
  .admin-student-queries .message-detail .chat-messages .new-msg img:nth-child(1),
  .admin-student-queries .message-detail .chat-messages .new-msg img:nth-child(2),
  .admin-student-queries .message-detail .chat-messages .new-msg img:nth-child(3) {
    width: 18px;
  }
  .admin-student-queries .message-detail .chat-messages .new-msg {
    padding: 0px 6px;
  }
  .admin-student-queries .message-detail .chat-messages .new-msg .form-control {
    font-size: 14px;
    line-height: 20px;
  }
  /*messages*/
  .admin-messages .top-header .title {
    font-size: 25px;
    line-height: 31px;
  }
  .admin-messages .top-header .calendar {
    font-size: 16px;
    line-height: 26px;
    width: 300px;
    height: 42px;
  }
  .admin-messages .student-queries .title {
    font-size: 18px;
    line-height: 28px;
  }
  .admin-messages .student-queries .title img,
  .admin-messages .message-detail .filters img,
  .admin-messages .message-detail .chat-messages .new-msg img:last-child,
  .admin-messages .student-queries .search-icon img {
    width: 34px;
    height: 34px;
  }
  .admin-messages .student-queries .search-queries input {
    height: 48px;
    font-size: 16px;
    line-height: 23px;
  }
  .admin-messages .student-queries .sub-title {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-messages .message-detail .media img {
    width: 36px;
    height: 36px;
  }
  .admin-messages .student-queries .messages-list .media img,
  .admin-messages .chat-messages .media img {
    width: 34px;
    height: 34px;
  }
  .admin-messages .student-queries .messanger-name,
  .admin-messages .message-detail .messanger-name,
  .admin-messages .message-detail .chat-messages .messanger-name {
    font-size: 14px;
    line-height: 23px;
  }
  .admin-messages .student-queries .msg-time,
  .admin-messages .message-detail .chat-messages .msg-time {
    font-size: 12px;
  }
  .admin-messages .student-queries .messanger-text,
  .admin-messages .message-detail .messanger-text,
  .admin-messages .message-detail .chat-messages .messanger-text {
    font-size: 13px;
    line-height: 18px;
  }
  .admin-messages .message-detail .chat-messages .link {
    font-size: 12px;
    line-height: 16px;
  }
  .admin-messages .message-detail .chat-messages .link img {
    width: 16px;
    height: 16px;
  }
  .admin-messages .message-detail .chat-messages .attachment {
    padding: 8px;
  }
  .admin-messages .message-detail .chat-messages .new-msg {
    padding: 0px 6px;
  }
  .admin-messages .message-detail .chat-messages .new-msg img:nth-child(1),
  .admin-messages .message-detail .chat-messages .new-msg img:nth-child(2),
  .admin-messages .message-detail .chat-messages .new-msg img:nth-child(3) {
    width: 18px;
  }
  .admin-messages .message-detail .chat-messages .new-msg .form-control {
    font-size: 14px;
    line-height: 20px;
    height: 36px;
  }
  .admin-event .top-header .title {
    font-size: 30px;
    line-height: 43px;
  }
  .admin-event .top-header .calendar {
    font-size: 14px;
    line-height: 20px;
    height: 40px;
    width: 260px;
  }
  .admin-event .top-buttons .member-search input {
    height: 46px;
    width: 300px;
  }
  .admin-event .top-buttons .search-icon {
    width: 36px;
  }
  .admin-event .top-buttons .btn-custom {
    font-size: 16px;
    line-height: 22px;
    width: 200px;
    height: 46px;
  }
  .admin-video-library .video-folders .grid-view.scroll,
  .admin-audio-library .audio-folders .grid-view.scroll {
    max-height: 840px;
  }
  .admin-video-library .grid-view .folder .folder-title,
  .admin-audio-library .audio-folders .folder .folder-title {
    font-size: 16px;
    line-height: 25px;
  }
  .admin-video-library .grid-view .folder .folder-text,
  .admin-audio-library .audio-folders .folder .folder-text {
    font-size: 10px;
    line-height: 16px;
  }
  .admin-video-library .video-folders .list-view .table-title,
  .admin-audio-library .audio-folders .list-view .table-title {
    font-size: 16px;
    line-height: 25px;
  }
  .enduser-dashboard .courses .queries-icon .question-img {
    width: 90px;
  }
  .enduser-dashboard .courses .queries-icon span {
    padding: 6px 26px;
  }
  .enduser-dashboard .courses .playlist .title {
    font-size: 13px;
    line-height: 20px;
  }
  .enduser-dashboard .courses .playlist .left-img {
    width: 46px;
    left: -15px;
  }
  .enduser-dashboard .courses .playlist .name {
    font-size: 14px;
    line-height: 20px;
  }
  .enduser-dashboard .courses .playlist .right-img {
    width: 50px;
    top: 24px;
  }
  .enduser-dashboard .courses .discover .title,
  .enduser-dashboard .courses .your-course .border-line .title {
    font-size: 22px;
    line-height: 33px;
  }
  .enduser-dashboard .courses .your-course .border-line::after {
    width: 75%;
  }
  .enduser-dashboard .courses .your-course .search-course input {
    width: 100%;
    height: 46px;
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .courses .your-course .search-icon img {
    width: 28px;
  }
  .enduser-dashboard .courses .your-course .filter-icon {
    width: 40px;
  }
  .enduser-dashboard .courses .your-course .view-all {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .courses .your-course .book-box {
    margin-right: 14px;
  }
  .enduser-dashboard .courses .your-course .book-box .title {
    font-size: 13px;
    line-height: 20px;
  }
  .enduser-dashboard .courses .your-course .book-box .text {
    font-size: 11px;
    line-height: 15px;
  }
  .enduser-dashboard .courses .your-course .book-box .fee {
    font-size: 15px;
    line-height: 21px;
  }
  .enduser-dashboard .courses .your-course .book-box .check-circle {
    width: 30px;
  }
  .enduser-dashboard .courses .your-course .nav-link {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .courses #coursesTabContent .box,
  .course-detail .top-result .box {
    width: 300px;
    padding: 10px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .title,
  .course-detail .top-result .box .title {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 6px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .text,
  .course-detail .top-result .box .text {
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 8px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .student img,
  .course-detail .top-result .box .student img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .course-detail .search-course input,
  .enduser-dashboard .course-detail .lesson .search-course input,
  .enduser-dashboard .lesson-detail .lesson .search-course input {
    height: 46px;
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .course-detail .search-icon img,
  .enduser-dashboard .course-detail .lesson .search-icon img,
  .enduser-dashboard .lesson-detail .lesson .search-icon img {
    width: 30px;
  }
  .enduser-dashboard .course-detail .detail-page .course-info {
    font-size: 14px;
    line-height: 23px;
    padding: 6px 30px;
  }
  .enduser-dashboard .course-detail .detail-page .student-img img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .course-detail .detail-page .price {
    font-size: 13px;
    line-height: 21px;
  }
  .enduser-dashboard .course-detail .detail-page .price span {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .course-detail .detail-page .course-text {
    font-size: 14px;
    line-height: 27px;
  }
  .enduser-dashboard .course-detail .detail-page .queries-icon .question-img {
    width: 90px;
  }
  .enduser-dashboard .course-detail .lesson .title,
  .enduser-dashboard .lesson-detail .lesson .title {
    font-size: 24px;
    line-height: 37px;
  }
  .enduser-dashboard .course-detail .lesson table .live-btn,
  .enduser-dashboard .lesson-detail .lesson table .live-btn {
    font-size: 14px;
    line-height: 24px;
    padding: 2px 6px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(1),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(2),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(1),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(2) {
    font-size: 16px;
    line-height: 25px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(3),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(4),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(5),
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(7),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(3),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(4),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(5),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(7) {
    font-size: 15px;
    line-height: 24px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(7) img,
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(7) img {
    width: 24px;
  }
  .enduser-dashboard .course-detail .lesson table tr td:nth-child(8),
  .enduser-dashboard .lesson-detail .lesson table tr td:nth-child(8) {
    font-size: 13px;
    line-height: 21px;
  }
  .enduser-dashboard .lesson-detail .student-img img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .lesson-detail .attach-img img {
    width: 50px;
    height: 50px;
  }
  .enduser-dashboard .lesson-detail .attach-img img:first-child {
    width: 60px;
    height: 60px;
  }
  .enduser-dashboard .lesson-detail .lesson-img {
    padding: 10px;
    margin-top: 65px;
  }
  .enduser-dashboard .lesson-detail .lesson-img .fee,
  .course-detail .top-result .lesson-img .fee {
    font-size: 12px;
    line-height: 18px;
    left: 20px;
  }
  .enduser-dashboard .lesson-detail .member {
    font-size: 14px;
    line-height: 22px;
  }
  .enduser-dashboard .lesson-detail .attach-files .title,
  .enduser-dashboard .lesson-detail .heading {
    font-size: 15px;
    line-height: 24px;
  }
  .enduser-dashboard .lesson-detail .lesson-title {
    font-size: 24px;
    line-height: 42px;
  }
  .enduser-dashboard .lesson-detail .text {
    font-size: 14px;
    line-height: 26px;
  }
  .enduser-dashboard .lesson-detail .queries-icon .question-img {
    width: 90px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .title {
    font-size: 19px;
    line-height: 29px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .left-round-img {
    width: 40px;
    height: 40px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .media .name {
    font-size: 15px;
    line-height: 27px;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .media .comment {
    font-size: 13px;
    line-height: 23px;
  }
  #attachmentFileModal .modal-header {
    padding: 16px;
  }
  #attachmentFileModal .modal-title {
    font-size: 22px;
    line-height: 36px;
  }
  #attachmentFileModal .modal-body {
    padding: 32px;
  }
  #attachmentFileModal .attach-img {
    width: 60px;
    height: 60px;
  }
  #attachmentFileModal .file-title {
    font-size: 13px;
    line-height: 24px;
  }
  #attachmentFileModal .download-img {
    width: 30px;
    height: 30px;
  }
  #attachmentFileModal .download-text {
    font-size: 7px;
    line-height: 11px;
  }
  .course-ask-question .message-detail .media img {
    width: 34px;
    height: 34px;
  }
  .course-ask-question .message-detail .messanger-name {
    font-size: 15px;
    line-height: 13px;
  }
  .course-ask-question .message-detail .messanger-text {
    font-size: 14px;
    line-height: 16px;
  }
  .course-ask-question .message-detail .chat-messages .messanger-name {
    font-size: 13px;
    line-height: 22px;
  }
  .course-ask-question .message-detail .chat-messages .messanger-text {
    font-size: 12px;
    line-height: 20px;
  }
  .course-ask-question .message-detail .chat-messages .link img {
    width: 16px;
    height: 16px;
  }
  .course-ask-question .message-detail .chat-messages .link {
    font-size: 12px;
    line-height: 16px;
  }
  .course-ask-question .message-detail .chat-messages .attachment {
    padding: 8px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg {
    padding: 0px 6px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg img:nth-child(1),
  .course-ask-question .message-detail .chat-messages .new-msg img:nth-child(2),
  .course-ask-question .message-detail .chat-messages .new-msg img:nth-child(3) {
    width: 18px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg .form-control {
    font-size: 14px;
    line-height: 20px;
  }
  .course-ask-question .message-detail .chat-messages .new-msg img:last-child {
    width: 34px;
    height: 34px;
  }
  .enduser-dashboard .content-library .most-popular-content .card .title,
  .enduser-dashboard .content-library .content-files .title,
  .enduser-dashboard .content-library .folders .title,
  .enduser-dashboard .content-library .about-book .book-title,
  .enduser-dashboard .content-library .book-comments .title {
    font-size: 18px;
    line-height: 28px;
  }
  .enduser-dashboard .content-library .content-files .folder .book-title,
  .enduser-dashboard .content-library .book-comments .media .name {
    font-size: 16px;
    line-height: 24px;
  }
  .enduser-dashboard .content-library .content-files .folder .book-text,
  .enduser-dashboard .content-library .about-book .folder-files {
    font-size: 13px;
    line-height: 21px;
  }
  .enduser-dashboard .content-library .content-files .view-all,
  .enduser-dashboard .content-library .folders .folder-info .view-all {
    font-size: 15px;
    line-height: 24px;
  }
  .enduser-dashboard .content-library .about-book .left-round-img {
    width: 50px;
    height: 50px;
  }
  .enduser-dashboard .content-library .about-book .book-description,
  .enduser-dashboard .content-library .book-comments .media .comment,
  .enduser-dashboard .content-library .about-book .book-title .post-time {
    font-size: 12px;
    line-height: 23px;
  }
  .enduser-dashboard .content-library .book-comments .left-round-img {
    width: 40px;
    height: 40px;
  }
  .enduser-dashboard .content-library .book-comments .media .name .time {
    font-size: 14px;
    line-height: 23px;
  }
  .enduser-social-wall #socialWallTabContent .owl-prev {
    left: -50px;
  }
  .enduser-social-wall #socialWallTabContent .owl-next {
    right: -45px;
  }
  .enduser-social-wall #socialWallTabContent .owl-prev img {
    width: 40px;
    height: 40px;
  }
  .enduser-social-wall #socialWallTabContent .owl-next img {
    width: 40px;
    height: 40px;
  }
  .admin-event .upcoming-event {
    padding: 16px;
  }
}
@media only screen and (max-width: 1200px) {
  #banner,
  #login,
  #register,
  #forgot,
  #account-verification,
  #welcome,
  #tour,
  #membership,
  #multiple-membership,
  #user-membership-login,
  #user-welcome {
    height: auto;
    margin: 10px auto;
  }
  .enduser-dashboard .calendar select {
    width: 70%;
  }
  .notification.dropdown-menu.dropdown-menu-lg.dropdown-menu-right {
    max-width: max-content;
  }
  .notification.dropdown-menu-right {
    right: -7rem !important;
  }
}
@media only screen and (max-width: 1024px) {
  #tour .tour-slider .owl-next {
    width: 50px;
  }
  .enduser-dashboard #all .card {
    width: 200px;
    margin: 0px 10px 15px;
  }
  .enduser-dashboard #all .card .card-img-top {
    width: 200px;
    height: 290px;
  }
  .enduser-dashboard #post .post-now {
    margin-bottom: 30px;
  }
  #register .card-body {
    padding: 42px 30px 30px;
  }
  #account-verification .card-body {
    padding: 16px 38px;
  }
  #welcome .card-body {
    padding: 16px 40px;
  }
  .content-page-setting .btn-custom {
    font-size: 20px;
    line-height: 32px;
    width: 100%;
    height: 50px;
  }
  .enduser-dashboard .profile #profile-tab .nav-link {
    font-size: 14px;
    line-height: 40px;
  }
  .enduser-dashboard .content-library-folder .border-line::after {
    width: 50%;
  }
  .enduser-dashboard .plan-details,
  .enduser-dashboard .payment-details {
    padding: 16px;
  }
  .enduser-dashboard .plan-details .plan,
  .enduser-dashboard .payment-details .plan,
  .enduser-dashboard .plan-details .tc,
  .enduser-dashboard .payment-details .tc {
    padding: 30px;
  }
  .enduser-dashboard .audio-library .discover .border-line::after,
  .enduser-dashboard .video-library .discover .border-line::after,
  .enduser-dashboard .courses .discover .border-line::after {
    border: none;
    width: auto;
  }
  .enduser-dashboard .audio-library .discover .border-line .form-control,
  .enduser-dashboard .video-library .discover .border-line .form-control {
    width: 30%;
  }
  .enduser-dashboard .audio-library .new-audio .border-line::after,
  .enduser-dashboard .video-library .new-video .border-line::after {
    width: 70%;
  }
  .enduser-dashboard .audio-library .popular-folder .border-line::after,
  .enduser-dashboard .video-library .popular-folder .border-line::after {
    width: 60%;
  }
  .admin-video-library .video-list .after-search .sort-by select {
    margin-bottom: 10px;
  }
  .admin-video-library .video-list #videoLibraryTabContent #videoList .table tr td:nth-child(2) img,
  .admin-video-library .video-list #videoLibraryTabContent #scheduledVideos .table tr td:nth-child(2) img {
    width: 186px;
    height: 75px;
  }
  .admin-content-library .content-folders .sort,
  .admin-content-library .content-folders .date,
  .admin-audio-library .audio-folders .sort,
  .admin-audio-library .audio-folders .date,
  .admin-video-library .video-folders .sort,
  .admin-video-library .video-folders .date,
  .admin-courses .courses-folders .sort,
  .admin-courses .courses-folders .date {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .admin-messages .student-queries {
    padding: 0px;
    padding-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid #E8E8E8;
  }
  .enduser-dashboard .courses .your-course #coursesTab::after {
    border: none;
    width: auto;
  }
  .enduser-dashboard .course-detail .search-course input {
    width: auto;
  }
  .navbar-search-block {
    left: 25px;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 991px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav) .main-header,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 0px !important;
    padding-left: 0px;
    padding-right: 5px;
  }
  .enduser-dashboard #videos .card,
  .enduser-dashboard #photos .card {
    margin: 0px auto;
  }
  .enduser-dashboard #courses .card,
  .enduser-dashboard #challenges .card {
    margin: 0 auto 20px;
  }
  #showPostModal #postCarousel {
    margin-top: -25px;
    margin-bottom: 20px;
  }
  #showPostModal .carousel-control-prev {
    left: -30px;
  }
  #showPostModal .carousel-control-next {
    right: -30px;
  }
  .enduser-social-wall .posts .lg-img {
    width: 297px;
    height: 240px;
  }
  .admin-video-library .video-folders .grid-view,
  .admin-audio-library .audio-folders .grid-view {
    padding-left: 10px;
    padding-right: 20px;
  }
  .admin-video-library .video-folders .list-view,
  .admin-audio-library .audio-folders .list-view {
    padding-right: 10px;
  }
  .notification-box {
    font-size: calc(100% - 0.2em);
  }
  .notification-content {
    font-size: small;
    font-weight: 600;
  }
  .notification.dropdown-menu-right {
    right: -6rem !important;
  }
}
@media only screen and (max-width: 480px) {
  .notification-content:nth-child(1) {
    font-weight: normal;
  }
  .notification.dropdown-menu-right {
    right: -7rem !important;
  }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 6px;
    padding-left: 6px;
  }
  #setupGuide .btn-setup {
    width: auto;
  }
  #login .card-body,
  #welcome .card-body,
  #tour .card-body {
    padding: 30px 16px;
  }
  #filterMembersModal .modal-dialog {
    margin: 0 0 0 auto;
  }
  .enduser-dashboard .suggested #suggestedTab .nav-link {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .enduser-dashboard #youFav .card,
  .enduser-dashboard #suggEvent .card,
  .enduser-dashboard #latestCont .card {
    width: 165px;
  }
  .enduser-dashboard #post .post-now select.form-control {
    width: 68%;
    margin-left: 0px !important;
  }
  #tour .tour-slider .owl-nav {
    position: absolute;
    top: -40px;
    right: -10px;
  }
  #landingPage .sub-title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  #landingPage .text {
    font-size: 14px;
    line-height: 24px;
  }
  #landingPage .sub-title2 {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 30px;
  }
  #stepsSetup .company-logo {
    margin: 0 14% 0 auto;
  }
  #landingPage .btn-custom {
    width: 100%;
    font-size: 20px;
    line-height: 38px;
  }
  #stepsSetup form .btn-custom {
    width: 100%;
  }
  #stepsSetup .subscription img {
    width: 120px;
  }
  #stepsSetup .plan-info .card-body {
    padding: 16px;
  }
  #stepsSetup .plan-info .plan-name span {
    font-size: 14px;
  }
  #stepsSetup .plan-info .plan-type span {
    font-size: 12px;
  }
  #stepsSetup .plan-info .btn-outline-custom,
  #stepsSetup .plan-info .btn-custom {
    width: 100%;
  }
  .admin-content-library .readinglist .left-img,
  .admin-audio-library .playlist .left-img,
  .admin-video-library .playlist .left-img,
  .enduser-dashboard .audio-folder .playlist .left-img,
  .enduser-dashboard .my-playlist .playlist .left-img {
    right: 250px;
  }
  .admin-content-library .readinglist .name,
  .admin-audio-library .playlist .name,
  .admin-video-library .playlist .name,
  .enduser-dashboard .audio-folder .playlist .name,
  .enduser-dashboard .my-playlist .playlist .name {
    width: 268px;
  }
  .admin-content-library .content-folders .sort,
  .admin-content-library .content-folders .date,
  .admin-audio-library .audio-folders .sort,
  .admin-audio-library .audio-folders .date,
  .admin-video-library .video-folders .sort,
  .admin-video-library .video-folders .date,
  .admin-courses .courses-folders .sort,
  .admin-courses .courses-folders .date {
    width: 100%;
  }
  .admin-content-library .content-list .nav-item,
  .admin-content-library .content-list .search-content input,
  .admin-audio-library .audio-list .nav-item,
  .admin-audio-library .audio-list .search-library input,
  .admin-video-library .video-list .nav-item,
  .admin-video-library .video-list .search-library input,
  .enduser-dashboard .audio-folder .folder-info .form-control {
    width: 100%;
  }
  #wantToCreateModal .folder .fa-folder,
  #wantToCreateModal .folder .fa-file-alt,
  #addAudioModal .folder .fa-folder,
  #addVideoModal .folder .fa-folder,
  #wantToCreateModal .audio .fa-microphone,
  #wantToCreateModal .audio .fa-file-alt,
  #addAudioModal .audio .fa-microphone,
  #wantToCreateModal .video .fa-video,
  #addVideoModal .video .fa-video,
  #selectFolderModal .video .fa-video,
  #selectFolderModal .folder .fa-folder,
  #selectFolderModal .audio .fa-microphone,
  #wantToCreateModal .folder .fa-book,
  #wantToCreateModal .audio .fa-file-audio,
  #wantToCreateModal .folder-img,
  #selectFolderModal .folder-img {
    margin: 6px;
  }
  #wantToCreateModal .modal-title,
  #createFolderModal .modal-title,
  #addVideoDetailsModal .modal-title,
  #addAudioDetailsModal .modal-title,
  #addLessonDetailsModal .title,
  #addAudioModal .modal-title,
  #addVideoModal .modal-title,
  #createSubFolderModal .title,
  #selectFolderModal .modal-title {
    font-size: 18px;
    line-height: 35px;
    padding-left: 0px;
  }
  #createFolderModal .btn-custom,
  #addVideoDetailsModal .btn-custom,
  #addAudioDetailsModal .btn-custom,
  #addLessonDetailsModal .btn-custom,
  #createSubFolderModal .btn-custom {
    width: 100%;
  }
  #wantToCreateModal .select-folder .card,
  #selectSubFolderModal .folder .card {
    margin: 10px 10px 10px 5px;
  }
  #wantToCreateModal .select-folder .card .card-body,
  #selectSubFolderModal .folder .card .card-body {
    padding: 10px;
  }
  .content-choose-template .template .content .heading,
  .content-edit-template .template .content .heading {
    font-size: 14px;
    line-height: 20px;
  }
  .content-choose-template .template,
  .content-edit-template .template {
    padding: 12px;
  }
  .content-choose-template,
  .content-edit-template {
    margin: 20px 0px;
  }
  .content-edit-template .upload-media .btn-custom {
    font-size: 16px;
    line-height: 26px;
    width: 160px;
    height: 45px;
  }
  .enduser-dashboard .profile #account-details .plans .btn-custom,
  .enduser-dashboard .profile #account-details .explore-plans .btn-custom {
    width: 100%;
  }
  .enduser-dashboard .payment-details .card-detail form {
    padding: 16px;
  }
  .enduser-dashboard .payment-details .btn-custom,
  .enduser-dashboard .plan-details .btn-custom {
    margin: 16px;
  }
  .enduser-dashboard .plan-details .tc .text,
  .enduser-dashboard .payment-details .tc .text {
    line-height: 28px;
  }
  #paymentSuccessfull .title {
    font-size: 20px;
    line-height: 40px;
  }
  #paymentSuccessfull .text {
    font-size: 13px;
    line-height: 25px;
  }
  .enduser-dashboard .audio-library .new-audio .border-line::after,
  .enduser-dashboard .video-library .new-video .border-line::after {
    width: 60%;
  }
  .enduser-dashboard .audio-library .popular-folder .border-line::after,
  .enduser-dashboard .video-library .popular-folder .border-line::after {
    width: 45%;
  }
  .enduser-dashboard .my-playlist .playlist-board .upload-file {
    margin-right: 0px;
  }
  #showPostModal .carousel-control-prev {
    left: -60px;
  }
  #showPostModal .carousel-control-next {
    right: -60px;
  }
  .enduser-social-wall #socialWallTab .search-library input {
    width: 100%;
  }
  .enduser-social-wall .posts .show-media {
    flex-wrap: wrap;
  }
  .enduser-social-wall .post-area .btn-custom,
  .help-modal .btn-custom {
    height: auto;
  }
  #renewalAndSuceessModal .nav-pills .nav-link {
    font-size: 14px;
    padding: 16px 6px;
  }
  .admin-student-queries .message-detail {
    padding: 10px;
  }
  .admin-student-queries .student-queries .title img,
  .admin-student-queries .message-detail .filters img,
  .admin-student-queries .chat-messages .attachment img {
    width: 30px;
    height: 30px;
  }
  .admin-event {
    margin-left: 0px;
    margin-right: 0px;
  }
  .enduser-dashboard .courses .queries-icon {
    margin-right: 15px;
  }
  .enduser-dashboard .courses .playlist .name {
    width: 280px;
  }
  .enduser-dashboard .courses .discover .form-control {
    width: 100%;
  }
  .enduser-dashboard .courses .your-course .border-line::after {
    width: 45%;
  }
  .enduser-dashboard .courses .your-course .book-box {
    width: 100%;
  }
  .enduser-dashboard .courses .your-course .nav-item {
    width: 135px;
  }
  .enduser-dashboard .courses #coursesTabContent .box,
  .course-detail .top-result .box {
    width: 100%;
    padding: 10px;
    margin-right: 15px;
  }
  .enduser-dashboard .courses #coursesTabContent .box .banner-img,
  .course-detail .top-result .box .banner-img {
    width: 100%;
  }
  .enduser-dashboard .course-detail .return-page::after {
    border-bottom: none;
    width: auto;
  }
  .enduser-dashboard .lesson-detail .lesson-comments textarea {
    width: 100%;
  }
  .enduser-dashboard .lesson-detail .lesson-comments .send {
    margin-top: 10px;
  }
  .enduser-dashboard .lesson-detail .lesson-img {
    margin-top: 0px;
  }
  .enduser-dashboard .lesson-detail .member {
    margin-bottom: 30px;
  }
  .enduser-dashboard .lesson-detail .lesson .search-course input {
    width: 100%;
  }
  #attachmentFileModal .attach-img {
    width: 40px;
    height: 40px;
  }
  #attachmentFileModal .file-title {
    font-size: 12px;
    line-height: 23px;
  }
  #attachmentFileModal .download-img {
    width: 25px;
    height: 25px;
  }
  .course-ask-question .chat-messages .attachment img {
    width: 30px;
    height: 30px;
  }
  .enduser-dashboard .profile #edit-account .form-control,
  .enduser-dashboard .profile #change-password .form-control {
    padding-left: 12px;
  }
  .enduser-dashboard .profile #edit-account .btn-white,
  .enduser-dashboard .profile #change-password .btn-white,
  .enduser-dashboard .profile #notifications-settings .btn-white,
  .enduser-dashboard .profile #edit-account .btn-blue,
  .enduser-dashboard .profile #change-password .btn-blue,
  .enduser-dashboard .profile #notifications-settings .btn-blue,
  .enduser-dashboard .profile #unsubscribe .btn-blue {
    width: 100%;
  }
  .enduser-dashboard .profile #edit-account .btn-white,
  .enduser-dashboard .profile #change-password .btn-white,
  .enduser-dashboard .profile #notifications-settings .btn-white {
    margin-top: 10px;
    margin-right: 0px;
  }
  .enduser-dashboard .profile #edit-account .btn-blue,
  .enduser-dashboard .profile #change-password .btn-blue,
  .enduser-dashboard .profile #notifications-settings .btn-blue,
  .enduser-dashboard .profile #unsubscribe .btn-blue {
    margin-top: 30px;
  }
  .enduser-social-wall #socialWallTabContent .owl-prev {
    left: -33px;
  }
  .enduser-social-wall #socialWallTabContent .owl-next {
    right: -33px;
  }
}
/*------LOADER------ */
.loader {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
}

.spinner {
  width: 70px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -9px 0 0 -35px;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #4A62A0;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/*------LOADER------ */
.cursor-pointer {
  cursor: pointer;
}

.shadow-on-hover:hover {
  box-shadow: 0 0 10px 0 rgba(0, 174, 179, 0.5);
}

.user-image {
  width: 3rem;
  height: auto;
  object-fit: cover;
  border: 1px solid #e6e6e6;
  border-radius: 50%;
}

.help-modal .btn-custom {
  height: 80px;
  float: none;
  line-height: 4;
}

.triple-text-section {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin: 1em 0;
}

.mentions__mention {
  background-color: #b3beeb;
}

.camera-icon {
  color: var(--brand-color);
  width: 40px;
  height: 40px;
}

.close-modal {
  color: var(--brand-color);
  height: 40px;
  width: 40px;
}

.main-subs-expired-div {
  position: relative;
  width: 100%;
  height: 100%;
}

/* .inner-subs-div {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 30%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2em;
} */
.inner-subs-div {
  position: absolute;
  left: 35%;
  right: 25%;
  top: 25%;
  box-shadow: rgba(122, 215, 198, 0.34) 0px 7px 29px 0px;
  padding: 2em;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 1em;
}

.congradulation-section {
  position: relative;
}

.congradulation-section img:nth-child(1) {
  width: 12em;
  height: auto;
}

.congradulation-section p:nth-child(2) {
  color: #00aeb3 !important;
  font-size: 13px !important;
  color: #067578 !important;
}

.congradulation-section img:nth-child(3) {
  width: 6em;
  height: auto;
  position: absolute;
  left: 2%;
  z-index: 100;
  top: 15%;
  background-color: none !important;
}

.congrats-success-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #00aeb3;
  margin-top: 2em;
  padding: 1em;
  width: 60%;
  border-radius: 1em;
}

.congrats-success-div > label:nth-child(1) {
  font-weight: 400;
  color: white;
  font-size: 18px;
}

.congrats-success-div > label:nth-child(2) {
  text-align: center;
  color: white;
}

.payment-failed {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.payment-failed p {
  text-align: center;
  color: #056E71;
}

.failed-payment-div {
  background-color: #00aeb3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2em;
  max-width: 400px;
  border-radius: 1em;
  text-align: center;
  font-size: 1rem;
  margin: auto;
  box-sizing: border-box;
  overflow-wrap: break-word;
}

@media (max-width: 768px) {
  .failed-payment-div {
    width: 90%;
    font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  .failed-payment-div {
    width: 100%;
    font-size: 0.8rem;
  }
}
.back-to-subs {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-to-subs img {
  background-color: #bfeeef;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  padding: 0.5em;
}

.back-to-subs label {
  margin-left: 1em;
  color: #00AEB3;
}

.failed-payment-div > label:nth-child(1) {
  color: white;
  margin-top: 0.5em;
}

.failed-payment-div img:nth-child(2) {
  width: 3em;
  height: auto;
  margin-bottom: 5px;
}

.failed-payment-div > label:nth-child(3) {
  color: white;
  margin: 0;
}

.failed-payment-div > label:nth-child(4) {
  color: white;
}

.click-begib-lbl {
  width: 100%;
  background-color: white;
  margin-top: 1em;
  padding: 0.5em 1.5em;
  color: black;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.choose-subs-div img:nth-child(3) {
  background-color: white !important;
  width: 12em !important;
}

.choose-subs-div label:nth-child(2) {
  font-size: 13px !important;
  font-weight: 200 !important;
  color: black !important;
}

.subscription-section {
  padding: 0.5em 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: auto;
  height: 45em;
  width: 100%;
  justify-content: space-evenly;
  position: relative;
  gap: 1.5em;
}

.subscription-section .best-option-lbl {
  position: absolute;
  right: -3%;
  top: 16px;
  font-size: 30px;
  transform: rotate(35deg);
  color: #00aeb3;
  font-family: "Sacramento", cursive;
}

.subscription-section::-webkit-scrollbar {
  width: 3px;
}

.unsubscribe-bg {
  background: rgb(194, 241, 243) !important;
  font-size: 18px !important;
  font-weight: 400 !important;
}

.unsubscribe-bg:hover {
  background-color: #00aeb3 !important;
  color: white !important;
}

.subscription-section::-webkit-scrollbar-track {
  background-color: white;
}

.subscription-section::-webkit-scrollbar-thumb {
  background-color: #00aeb3;
  height: 5px;
}

.subscription-section::-webkit-scrollbar-thumb:hover {
  background-color: #00aeb3;
}

.subs-card-main {
  width: 44%;
  height: 90% em;
  display: flex;
  flex-direction: column;
  border-radius: 0.5em;
  box-shadow: rgba(18, 174, 180, 0.2392156863) 0px 2px 8px 0px;
  align-items: center;
  padding: 1em;
}

.subs-card-main label:nth-child(1) {
  font-weight: 600;
  font-size: 20px;
  color: #00aeb3;
}

.subs-card-main label:nth-child(2) {
  font-weight: 600;
  font-size: 14px;
  color: #00aeb3;
}

.subs-card-main label:nth-child(3) {
  font-weight: 400;
  font-size: 12px;
  color: #00aeb3;
}

.subs-card-main p:nth-child(4) {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 1.7em !important;
  margin: 2em 0;
  height: 5.5em;
}

.bullet-main-div {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-left: -10%;
  margin-bottom: 2em;
}

.bullet-main-div div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
}

.bullet-main-div div label:nth-child(1) {
  width: 1.3em;
  height: 1.3em;
  border-radius: 50%;
  background-color: #00aeb3;
}

.bullet-main-div div label:nth-child(2) {
  font-size: 16px;
  color: #4c4b4b;
}

.yearly-btn {
  padding: 0.5em 2.3em;
  border: none;
  outline: none;
  border-radius: 0.5em;
  background-color: #00aeb3;
}

.yearly-btn label:nth-child(1) {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin: 0;
}

.yearly-btn label:nth-child(2) {
  font-size: 18px;
  font-weight: 500;
  color: white;
  margin: 0;
}

.yearly-btn label:nth-child(3) {
  font-size: 14px;
  font-weight: 300;
  color: white;
  margin: 0;
}

.monthly-btn {
  border: none;
  outline: none;
  margin-top: 1em;
  padding: 0.6em 3.2em;
  border-radius: 0.5em;
  background-color: #00aeb3;
}

.payment-portal-logo-des {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-portal-logo-des img {
  width: 12em;
  height: auto;
}

.payment-portal-logo-des p {
  font-size: 11px !important;
  font-size: 200 !important;
  text-align: center;
  margin-top: 1em;
  color: #054143;
}

.expire-subs-div {
  position: absolute;
  left: 40%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  top: 30%;
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.monthly-btn label {
  color: white !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  margin: 0;
}

.subs-card-main button:hover {
  cursor: pointer;
}

.payment-portal-directed {
  width: 90%;
  margin: 1.5em auto;
  background-color: #00aeb3;
  padding: 1em 2em;
  border-radius: 1em;
}

.payment-portal-directed p {
  color: white !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  line-height: 1.3em !important;
  text-align: center;
}

.payment-continue {
  border: none;
  outline: none;
  margin: 1em 35%;
  padding: 0.7em 1.5em;
  border-radius: 0.5em;
  font-weight: 500;
}

.explore-subscription-section {
  width: 66.5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4.5em;
  position: relative;
}

.best-option-lbl {
  position: absolute;
  right: -10%;
  top: -15px;
  font-size: 40px;
  transform: rotate(35deg);
  color: #00aeb3;
  font-family: "Sacramento", cursive;
}

.congradulation-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.congradulation-section img {
  width: 15em;
  height: auto;
  background-color: white !important;
}

.congradulation-section p {
  text-align: center;
}

.congradulation-message {
  background-color: #00aeb3;
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 2em;
  border-radius: 1em;
  gap: 1em;
}

.congradulation-message label:nth-child(1) {
  font-size: 18px;
  font-weight: 400;
  color: white;
}

.congradulation-message label:nth-child(2) {
  font-size: 18px;
  color: white;
  text-align: center;
}

.click-to-begin-btn {
  outline: none;
  border: none;
  font-size: 18px;
  font-weight: 400;
  border-radius: 5px;
  padding: 0.5em 1em;
}

.congradulation-section p {
  width: 70%;
  font-size: 12px !important;
  line-height: 1.5em !important;
  font-weight: 100 !important;
  margin: 1em auto;
  color: #054143 !important;
}

.congradulation-message label:nth-child(3) {
  color: white !important;
}

.go-back-subs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
}

.go-back-subs label:nth-child(1) {
  width: 2.5em;
  height: 2.5em;
  background-color: #cbeced;
  border-radius: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.go-back-subs label:nth-child(2) {
  margin-left: 1em;
  color: #00aeb3;
}

.congradulation-message img:nth-child(2) {
  background-color: none !important;
  width: 3em !important;
  height: 5em !important;
}

.modal-success .modal-content {
  border-radius: 20px;
}
.modal-success .success-img {
  margin-top: -100px;
  margin-left: -70px;
}
.modal-success .title {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 50px;
  color: #0c0a08;
  margin-bottom: 24px;
}
.modal-success .title {
  font-size: 22px;
  line-height: 44px;
  margin-bottom: 10px;
}
.modal-success .text {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 34px;
  color: #a6a7ae;
  margin-bottom: 19px;
}
.modal-success .text {
  font-size: 14px;
  line-height: 24px;
}
@media only screen and (max-width: 1600px) {
  .modal-success .modal-dialog {
    max-width: 608px;
  }
}
@media only screen and (max-width: 1440px) {
  .modal-success .modal-dialog {
    max-width: 402px;
  }
}
.modal-success .modal-body {
  padding-left: 16px;
  padding-right: 16px;
}
.modal-success .success-img {
  margin-top: -58px;
  margin-left: -35px;
  width: 300px;
}

.btn-load-more {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  color: #ffffff;
  border-radius: 9px;
  width: 12em;
  height: 42px;
  margin-top: 2.3em;
}

.btn-primary {
  background-color: var(--brand-color);
  border: 1px solid var(--brand-color);
}
.btn-primary:hover {
  background-color: var(--brand-color);
  border: 1px solid #ffffff;
}

.edit-buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 0.5rem;
}
.edit-buttons-container .btn-cancel {
  background-color: #fff;
  color: var(--brand-color);
  border: 1px solid var(--brand-color);
  width: 5rem;
}
.edit-buttons-container .btn-save {
  background-color: var(--brand-color);
  width: 5rem;
}

.attachedLessonFiles__tab-btns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 0.7rem;
  margin-bottom: 2rem;
}

.attachedLessonFiles__tab-btn {
  background-color: var(--brand-color);
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.attachedLessonFiles__tab-btn--active {
  border: 4px solid var(--brand-color-light);
}

.attachedLessonFiles__tab-btn:hover .attachedLessonFiles__tab-btn-logo {
  filter: none;
}

.attachedLessonFiles__tab-btn-logo {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto;
  filter: invert(0.5);
}

.attachedLessonFiles__tab-btn--active .attachedLessonFiles__tab-btn-logo {
  filter: none;
}

.attachedLessonFiles__tab-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;
}

.attachedLessonFiles__tab-content-doc {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--brand-color);
  border-radius: 5px;
  box-sizing: border-box;
}

.attachedLessonFiles__file-name {
  flex: 1;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
  color: white;
}

.attachedLessonFiles__file-download {
  cursor: pointer;
  margin-left: 0.5rem;
}

.attachedLessonFiles__tab-content-docs-item-download-logo {
  width: 1.5rem;
  height: 1.5rem;
}

/* Media query for smaller screens */
@media (max-width: 800px) {
  .attachedLessonFiles__tab-content-doc {
    flex-direction: column;
    align-items: flex-start;
  }
  .attachedLessonFiles__file-name {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .attachedLessonFiles__file-download {
    align-self: flex-start;
    margin-left: 0;
  }
}
.return-link {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #7d8592;
}

.create-sub-item-link {
  color: #12aeb4;
}
.create-sub-item-link:hover {
  color: #12aeb4;
}

.user-img {
  height: 46px;
  width: 46px;
  border-radius: 50%;
}

:root {
  --brand-color: #12aeb4;
}

.swiper-button-next, .swiper-button-prev {
  color: var(--brand-color) !important;
}

.swiper-wrapper {
  width: 2544px;
  padding-left: 80px;
  padding-right: 80px;
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.slide-card {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 1px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: auto !important;
}
.slide-card--social-wall {
  border: 3px solid transparent;
}
.slide-card--social-wall:hover, .slide-card--social-wall--active {
  box-shadow: 8px 8px 10px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #12aeb4;
}
.slide-card__root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.slide-card__top-btn-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  margin-bottom: 0.25rem;
}
.slide-card__img_wrapper {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slide-card__img_wrapper__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}
.slide-card__title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  margin-top: 8px;
  word-break: break-word;
  word-wrap: break-word;
  flex: auto;
}
.slide-card__text {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #7d8592;
  margin-bottom: 10px;
}
.slide-card__actions {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  margin: 0.25rem 0px;
  font-size: 0.75rem;
  min-height: 1.5rem;
}
.slide-card__actions button {
  border: none;
  background: transparent;
  font-weight: 500;
}

.secure-checkout-section {
  padding: 10px 0;
}

.slide-card__img_wrapper {
  position: relative;
}

.slide-card__img_wrapper__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.slide-card__title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  margin-top: 8px;
  word-break: break-word;
  word-wrap: break-word;
  flex: auto;
}

.slide-card__actions {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  margin: 0.25rem 0px;
  font-size: 0.75rem;
}

:root {
  --brand-color: #12aeb4;
}

.sales-navbar {
  border-radius: 0% 0% 1.5% 1.5%/25%;
}

.returning-customer {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #0a1629;
  background-color: #e6edf5;
  border-radius: 23px;
  padding: 10px 30px;
  margin-right: 25px;
  margin-bottom: 0px;
}

.event-date {
  font-family: "Nunito Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7d8592;
  padding-left: 2px;
  margin-bottom: 20px;
}

.explore-plans-card {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border-radius: 1em;
  min-height: 350px;
  height: auto;
}

.explore-plans-card span {
  font-weight: bold;
}

.explore-plans-card .plan-name {
  display: flex;
  justify-content: space-between;
}

.purchase-button {
  width: 100%;
  min-width: 100%;
  border: none;
}

.hover-text {
  position: relative;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: black;
  font-size: 12px;
  background-color: #eff0f3;
  border-radius: 10px;
  padding: 5px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
  width: 100%;
  top: -5em;
  font-size: 0.9rem;
  right: 0%;
}

.slide-card__actions {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-end;
  align-items: center;
  margin: 0.25rem 0px;
  font-size: 0.75rem;
}

.common-enroll-btn,
.enroll-text,
.loading-text,
.enrolled-span {
  font-weight: bold;
  font-size: 0.75rem;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.common-enroll-btn {
  font-weight: bold;
  color: black;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.enrolled-span {
  color: black;
  /* Matching the button text color */
  background-color: transparent;
  /* If you want enrolled text to have no background */
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.slide-card__img_wrapper {
  position: relative;
}

.slide-card__img_wrapper__img {
  width: 100%;
  height: 13em;
  object-fit: fill;
  border-radius: 8px;
}

.slide-card__title {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 8px;
  margin-top: 8px;
  word-break: break-word;
  word-wrap: break-word;
  flex: auto;
}

.enduser-dashboard .profile #account-details .plans,
.enduser-dashboard .profile #account-details .explore-plans {
  background: #d8ddf6;
  box-shadow: 0px 3px 15px rgba(119, 119, 119, 0.1607843137);
  border: 1px solid #e8e8e8;
  border-radius: 1em;
  margin-right: 2.5em;
  margin-bottom: 3em;
  width: 18em !important;
  height: 29em;
}

.enduser-dashboard .profile #account-details .plans .card-body,
.enduser-dashboard .profile #account-details .explore-plans .card-body {
  padding: 20px;
}

.hover-text {
  position: relative;
  width: 100%;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  color: black;
  font-size: 12px;
  background-color: rgba(147, 151, 154, 0.2117647059);
  border-radius: 10px;
  padding: 5px;
}

.hover-text:hover .tooltip-text {
  visibility: visible;
  width: 100%;
  top: -5em;
  font-size: 0.9rem;
  right: 0%;
}

#user-welcome .user-welcome-note {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #7a869a;
  margin-bottom: 17.41px;
  text-align: justify;
}

.startCompleteIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -7em;
  margin-top: -20em;
}

.startCompleteIcon img {
  width: 30px;
  height: 30px;
  margin-bottom: 1em;
}

.hover-container {
  position: relative;
}

.tooltip-text {
  visibility: hidden;
  position: absolute;
  top: -2em;
  background-color: #d8ddf6;
  padding: 2px;
  font-size: 0.9rem;
  margin-bottom: 3em;
}

.hover-container:hover .tooltip-text {
  visibility: visible;
}

.download-data {
  width: 2em !important;
  height: 2em !important;
  display: inline-block;
}

.download-data .info-tooltip {
  visibility: hidden;
  width: 150px;
  color: black;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  right: 10%;
  top: -1em;
  z-index: 1;
}

.download-data:hover .info-tooltip {
  visibility: visible;
}

.highlight-card {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: auto !important;
}
.highlight-card:hover {
  border: 3px solid var(--brand-color);
}

.highlight-card-sub {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 18.5em !important;
}
.highlight-card-sub:hover {
  border: 3px solid var(--brand-color);
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--brand-color) !important;
}

.invoice-btn {
  height: 2.9em;
  width: 5em;
  border-radius: 9px;
  color: #fff;
  border: none;
  background-color: var(--brand-color);
  box-shadow: 5px 5px 10px var(--brand-color);
}

.description-content img {
  height: 22em;
  width: 100%;
  max-width: 40em;
}

.text-content img {
  height: 22em;
  width: 100%;
  display: block;
  object-fit: fill;
}

.audio-player-wrapper {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.audio-player-wrapper audio {
  width: 100%;
}

.dashboard-news img {
  height: 22em;
  width: auto;
  max-width: 40em;
}

.wisdome-icon {
  color: #c2c7d0;
}
.wisdome-icon:hover, .wisdome-icon:active, .wisdome-icon:focus {
  color: var(--bs-white);
}

/* Styling for the 404 page */
.not-found-page {
  text-align: center;
  color: #333;
  padding: 2rem;
}

.not-found-container {
  max-width: 700px;
  margin: auto;
  margin-top: 12%;
}

.error-code {
  font-size: 7rem;
  font-weight: bold;
  color: #211930;
  margin-bottom: 1rem;
}

.error-message {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.home-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #6567e6;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 5px;
  border: none;
}

.highlight-card-folder {
  background: #ffffff;
  box-shadow: 5px 5px 20px rgba(60, 118, 119, 0.2784313725);
  border: 3px solid #ebebeb;
  border-radius: 13px;
  padding: 10px;
  height: 22.7em;
}

.highlight-card-folder:hover {
  border: 3px solid var(--brand-color);
}

.search-socialWall {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  background: #ffffff;
  box-shadow: 0px 3px 9px #7284cc;
  border: 1px solid #e5e5e5;
  height: 58px;
  padding-right: 52px;
  position: relative;
}

.search-socialWall input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
}

.search-socialWall input:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.search-icon-social {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.load-more-button {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  border-radius: 6px;
  width: 150px;
  height: 42px;
  border: none;
  text-align: center !important;
}

.icon-button {
  background: none;
  border: none;
  outline: none !important;
  cursor: pointer;
}

.icon-button:focus {
  outline: none !important;
}

.courses-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: flex-start;
}

.course-item {
  flex: 0 0 250px;
  margin-right: 1rem;
}

.sales-page-image {
  height: 170px;
  width: 100%;
}
.sales-page-image img {
  border-radius: 8px;
  height: 100%;
}

.sales-page-content {
  height: 134px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.certificate-and-enroll-button {
  display: flex;
  align-items: center;
  gap: 1em;
}

.download-certificate-button {
  width: 15em;
  margin-left: 30%;
  margin-right: 40%;
  margin-top: 2em;
  border: none;
}

