.main-subs-expired-div {
  position: relative;
  width: 100%;
  height: 100%;
}

/* .inner-subs-div {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 30%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 2em;
} */
.inner-subs-div {
  position: absolute;
  left: 35%;
  right: 25%;
  top: 25%;
  box-shadow: rgb(122 215 198 / 34%) 0px 7px 29px 0px;
  padding: 2em;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 1em;
}
.congradulation-section{
  position: relative;
  }
  .congradulation-section img:nth-child(1){
    width: 12em;
    height: auto;
  }
  .congradulation-section p:nth-child(2){
    color: #00aeb3 !important;
    font-size: 13px !important;
    color: #067578 !important;
  }
  .congradulation-section img:nth-child(3){
    width: 6em;
    height: auto;
    position: absolute;
    left: 2%;
    z-index: 100;
    top: 15%;
    background-color: none !important;
  }
  .congrats-success-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #00aeb3;
    margin-top: 2em;
    padding: 1em;
    width: 60%;
    border-radius: 1em;
  }
  .congrats-success-div > label:nth-child(1){
    font-weight: 400;
    color: white;
    font-size: 18px;
  }
  .congrats-success-div > label:nth-child(2){
  text-align: center ;
  color: white;
  }
  .payment-failed{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
  }
  .payment-failed p{
    text-align: center;
    color: #056E71;
  }
  .failed-payment-div {
    background-color: #00aeb3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em; 
    max-width: 400px; 
    border-radius: 1em;
    text-align: center;
    font-size: 1rem;
    margin: auto;
    box-sizing: border-box;
    overflow-wrap: break-word; 
}
@media (max-width: 768px) {
  .failed-payment-div {
    width: 90%;
    font-size: 0.9rem; 
  }
}

@media (max-width: 480px) {
  .failed-payment-div {
    width: 100%; 
    font-size: 0.8rem; 
  }
}
  .back-to-subs{
    display: flex;
    flex-direction: row; 
    align-items: center;
  }
  .back-to-subs img{
  background-color: #bfeeef;
  width: 2.7em;
  height: 2.7em;
  border-radius: 50%;
  padding: .5em;
  }
  .back-to-subs label{
    margin-left: 1em;
    color: #00AEB3;
  }
  .failed-payment-div > label:nth-child(1){
    color: white;
    margin-top: .5em;
  }
  .failed-payment-div img:nth-child(2){
  width: 3em;
  height: auto;
  margin-bottom: 5px;
  }
  .failed-payment-div > label:nth-child(3){
    color: white;
    margin: 0;
  }
  .failed-payment-div > label:nth-child(4){
    color: white;
  }
  .click-begib-lbl{
    width: 100%;
    background-color: white;
    margin-top: 1em;
    padding:.5em 1.5em;
    color: black;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
  }

  .choose-subs-div img:nth-child(3){
    background-color: white !important ;
    width: 12em !important;
  }
  .choose-subs-div label:nth-child(2){
    font-size: 13px !important;
    font-weight: 200 !important;
    color: black !important;
  }
  .subscription-section{
    padding: .5em 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    height: 45em;
    width: 100%;
    justify-content: space-evenly;
    position: relative;
    gap: 1.5em;
  }
  .subscription-section .best-option-lbl{
    position: absolute;
    right: -3%;
    top: 16px;
    font-size: 30px;
    transform: rotate(35deg);
    color: #00aeb3;
    font-family: 'Sacramento', cursive;
  }
  .subscription-section::-webkit-scrollbar{
    width: 3px;
  }
  .unsubscribe-bg{
    background:rgb(194 241 243) !important;
    font-size: 18px !important;
    font-weight: 400 !important;
  }
  .unsubscribe-bg:hover{
    background-color: #00aeb3 !important;
    color: white !important;
  }
  .subscription-section::-webkit-scrollbar-track{
  background-color: white;
  }
  .subscription-section::-webkit-scrollbar-thumb{
    background-color: #00aeb3;
    height: 5px;
  }
  .subscription-section::-webkit-scrollbar-thumb:hover{
    background-color: #00aeb3;
  
  }
  .subs-card-main{
    width: 44%;
    height: 90%em;
    display: flex;
    flex-direction: column;
    border-radius: .5em;
    box-shadow: rgba(18, 174, 180, 0.2392156863) 0px 2px 8px 0px;
    align-items: center;
    padding: 1em;
  }.subs-card-main label:nth-child(1){
    font-weight: 600;
    font-size: 20px;
    color: #00aeb3;
  }
  .subs-card-main label:nth-child(2){
    font-weight: 600;
    font-size: 14px;
    color: #00aeb3;
  }
  .subs-card-main label:nth-child(3){
    font-weight: 400;
    font-size: 12px;
    color: #00aeb3;
  }
  .subs-card-main p:nth-child(4){
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 1.7em !important;
    margin: 2em 0;
    height: 5.5em;
  } 
  .bullet-main-div{
    display: flex;
    flex-direction: column;
    gap: .5em;
    margin-left: -10%;
    margin-bottom: 2em;
  }
  .bullet-main-div div{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
  .bullet-main-div div label:nth-child(1){
    width: 1.3em;
    height: 1.3em;
    border-radius: 50%;
    background-color: #00aeb3;
  }
  .bullet-main-div div label:nth-child(2){
    font-size: 16px;
    color: #4c4b4b;
  }
  .yearly-btn{
    padding: .5em 2.3em;
    border: none;
    outline: none;
    border-radius: .5em;
    background-color: #00aeb3;
  
  }
  .yearly-btn label:nth-child(1){
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin: 0;
  }
  .yearly-btn label:nth-child(2){
    font-size: 18px;
    font-weight: 500;
    color: white;
    margin: 0;
  }
  .yearly-btn label:nth-child(3){
    font-size: 14px;
    font-weight: 300;
    color: white;
    margin: 0;
  }
  .monthly-btn{
    border: none;
    outline: none;
    margin-top: 1em;
    padding: .6em 3.2em;
    border-radius: .5em;
    background-color: #00aeb3;
  }
  .payment-portal-logo-des{
    display: flex;
    flex-direction: column;
   align-items: center;
  }
  .payment-portal-logo-des img{
    width: 12em;
    height: auto;
  }
  .payment-portal-logo-des p{
    font-size: 11px !important;
    font-size: 200 !important;
    text-align: center;
    margin-top: 1em;
    color: #054143;
  
  }
  .expire-subs-div{
    position: absolute;
    left: 40%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    top: 30%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .monthly-btn label{
    color: white !important;
    font-size: 16px !important;
    font-weight: 300 !important;
    margin: 0;
  }
  .subs-card-main button:hover{
  cursor: pointer;
  }
  .payment-portal-directed{
    width: 90%;
    margin: 1.5em auto;
    background-color: #00aeb3;
    padding: 1em 2em;
    border-radius: 1em;
  }
  .payment-portal-directed p{
    color: white !important;
    font-size: 18px !important;
    font-weight: 300 !important;
    line-height: 1.3em !important;
    text-align: center;
  }
  .payment-continue{
    border: none;
    outline: none;
    margin: 1em 35%;
    padding: .7em 1.5em;
    border-radius: .5em;
    font-weight: 500;
  }
  .explore-subscription-section{
    width: 66.5%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 4.5em;
    position: relative;
  }
  .best-option-lbl{
    position: absolute;
    right: -10%;
    top: -15px;
    font-size: 40px;
    transform: rotate(35deg);
    color: #00aeb3;
    font-family: 'Sacramento', cursive;
  }
  .congradulation-section{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .congradulation-section img{
  width: 15em;
  height: auto;
  background-color: white !important;
  }
  .congradulation-section p{
    text-align: center;
  }
  .congradulation-message{
    background-color: #00aeb3;
    width: 60%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em 2em;
    border-radius: 1em;
    gap: 1em;
  }
  .congradulation-message label:nth-child(1){
    font-size: 18px;
    font-weight: 400;
    color: white;
  }
  .congradulation-message label:nth-child(2){
    font-size: 18px;
    color: white;
    text-align: center;
  }
  .click-to-begin-btn{
    outline: none;
    border: none;
    font-size: 18px;
    font-weight: 400;
    border-radius: 5px;
    padding: .5em 1em;
  }
  .congradulation-section p{
    width: 70%;
    font-size: 12px !important;
    line-height: 1.5em !important;
    font-weight: 100 !important;
    margin: 1em auto;
    color: #054143 !important;
    
  }
  .congradulation-message label:nth-child(3){
    color: white !important;
  }
  .go-back-subs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 2em;
  }
  .go-back-subs label:nth-child(1){
    width: 2.5em;
    height: 2.5em;
    background-color: #cbeced;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .go-back-subs label:nth-child(2){
    margin-left: 1em;
    color: #00aeb3;
  }
  .congradulation-message img:nth-child(2){
    background-color: none !important;
    width: 3em !important;
    height: 5em !important;
  }